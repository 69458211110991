/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: developer.contact@outfiz.vn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AppCountrySchema,
  AppCurrencyOption,
  AppEnumSchema,
  AppStateEntity,
  AuthCreateUser,
  AuthGroupCreateEntity,
  AuthGroupEntity,
  AuthLoginResultDTO,
  AuthLoginUser,
  AuthResetPasswordSchema,
  AuthUpdatePasswordSchema,
  AuthUserDetailEntity,
  AuthUserEntity,
  AuthUserUpdateEntity,
  BusinessPartnerCreate,
  BusinessPartnerSchema,
  FileDTO,
  MicrosoftAspNetCoreMvcProblemDetails,
  PaginatedListBusinessPartnerSchema,
  PaginatedListCategorySchema,
  PaginatedListGroupEntity,
  PaginatedListIncomingShipment,
  PaginatedListInventoryControl,
  PaginatedListPurchaseOrder,
  PaginatedListPurchaseOrderLineSolo,
  PaginatedListRawMaterialEntity,
  PaginatedListResourceDetailSchema,
  PaginatedListResourceStock,
  PaginatedListShelf,
  PaginatedListStockLineDetail,
  PaginatedListStockLocationSchema,
  PaginatedListStockMoveEntity,
  PaginatedListStockMoveLineEntity,
  PaginatedListStockSplitEntity,
  PaginatedListUserEntity,
  ProductCategorySchema,
  ProductDetailSchema,
  ProductRawMaterialCreateEntity,
  ProductRawMaterialDetailEntity,
  ProductRawMaterialUpdate,
  ProductResourceBaseSchema,
  ProductResourceDetailSchema,
  ProductUpdateSchema,
  PurchasingCreateFromBalanceRequest,
  PurchasingPurchaseOrderCreate,
  PurchasingPurchaseOrderDetail,
  PurchasingPurchaseOrderEdit,
  ResponseInfo,
  StockCreateLineDTO,
  StockIncomingShipmentCreate,
  StockIncomingShipmentDetail,
  StockIncomingShipmentUpdate,
  StockInventoryControlCreate,
  StockInventoryControlDetail,
  StockInventoryControlLineCreate,
  StockInventoryControlLineSchema,
  StockInventoryControlUpdate,
  StockLinesLine,
  StockLinesLineDetail,
  StockLinesResourceDetail,
  StockLotSchema,
  StockMoveAddLineResult,
  StockMoveCreateEntity,
  StockMoveDetail,
  StockMoveEntity,
  StockMoveLineEntity,
  StockMoveUpdateEntity,
  StockReceiveShipmentLine,
  StockShelf,
  StockShipmentComparisonLine,
  StockSplitRequest,
} from '../models/index';
import {
    AppCountrySchemaFromJSON,
    AppCountrySchemaToJSON,
    AppCurrencyOptionFromJSON,
    AppCurrencyOptionToJSON,
    AppEnumSchemaFromJSON,
    AppEnumSchemaToJSON,
    AppStateEntityFromJSON,
    AppStateEntityToJSON,
    AuthCreateUserFromJSON,
    AuthCreateUserToJSON,
    AuthGroupCreateEntityFromJSON,
    AuthGroupCreateEntityToJSON,
    AuthGroupEntityFromJSON,
    AuthGroupEntityToJSON,
    AuthLoginResultDTOFromJSON,
    AuthLoginResultDTOToJSON,
    AuthLoginUserFromJSON,
    AuthLoginUserToJSON,
    AuthResetPasswordSchemaFromJSON,
    AuthResetPasswordSchemaToJSON,
    AuthUpdatePasswordSchemaFromJSON,
    AuthUpdatePasswordSchemaToJSON,
    AuthUserDetailEntityFromJSON,
    AuthUserDetailEntityToJSON,
    AuthUserEntityFromJSON,
    AuthUserEntityToJSON,
    AuthUserUpdateEntityFromJSON,
    AuthUserUpdateEntityToJSON,
    BusinessPartnerCreateFromJSON,
    BusinessPartnerCreateToJSON,
    BusinessPartnerSchemaFromJSON,
    BusinessPartnerSchemaToJSON,
    FileDTOFromJSON,
    FileDTOToJSON,
    MicrosoftAspNetCoreMvcProblemDetailsFromJSON,
    MicrosoftAspNetCoreMvcProblemDetailsToJSON,
    PaginatedListBusinessPartnerSchemaFromJSON,
    PaginatedListBusinessPartnerSchemaToJSON,
    PaginatedListCategorySchemaFromJSON,
    PaginatedListCategorySchemaToJSON,
    PaginatedListGroupEntityFromJSON,
    PaginatedListGroupEntityToJSON,
    PaginatedListIncomingShipmentFromJSON,
    PaginatedListIncomingShipmentToJSON,
    PaginatedListInventoryControlFromJSON,
    PaginatedListInventoryControlToJSON,
    PaginatedListPurchaseOrderFromJSON,
    PaginatedListPurchaseOrderToJSON,
    PaginatedListPurchaseOrderLineSoloFromJSON,
    PaginatedListPurchaseOrderLineSoloToJSON,
    PaginatedListRawMaterialEntityFromJSON,
    PaginatedListRawMaterialEntityToJSON,
    PaginatedListResourceDetailSchemaFromJSON,
    PaginatedListResourceDetailSchemaToJSON,
    PaginatedListResourceStockFromJSON,
    PaginatedListResourceStockToJSON,
    PaginatedListShelfFromJSON,
    PaginatedListShelfToJSON,
    PaginatedListStockLineDetailFromJSON,
    PaginatedListStockLineDetailToJSON,
    PaginatedListStockLocationSchemaFromJSON,
    PaginatedListStockLocationSchemaToJSON,
    PaginatedListStockMoveEntityFromJSON,
    PaginatedListStockMoveEntityToJSON,
    PaginatedListStockMoveLineEntityFromJSON,
    PaginatedListStockMoveLineEntityToJSON,
    PaginatedListStockSplitEntityFromJSON,
    PaginatedListStockSplitEntityToJSON,
    PaginatedListUserEntityFromJSON,
    PaginatedListUserEntityToJSON,
    ProductCategorySchemaFromJSON,
    ProductCategorySchemaToJSON,
    ProductDetailSchemaFromJSON,
    ProductDetailSchemaToJSON,
    ProductRawMaterialCreateEntityFromJSON,
    ProductRawMaterialCreateEntityToJSON,
    ProductRawMaterialDetailEntityFromJSON,
    ProductRawMaterialDetailEntityToJSON,
    ProductRawMaterialUpdateFromJSON,
    ProductRawMaterialUpdateToJSON,
    ProductResourceBaseSchemaFromJSON,
    ProductResourceBaseSchemaToJSON,
    ProductResourceDetailSchemaFromJSON,
    ProductResourceDetailSchemaToJSON,
    ProductUpdateSchemaFromJSON,
    ProductUpdateSchemaToJSON,
    PurchasingCreateFromBalanceRequestFromJSON,
    PurchasingCreateFromBalanceRequestToJSON,
    PurchasingPurchaseOrderCreateFromJSON,
    PurchasingPurchaseOrderCreateToJSON,
    PurchasingPurchaseOrderDetailFromJSON,
    PurchasingPurchaseOrderDetailToJSON,
    PurchasingPurchaseOrderEditFromJSON,
    PurchasingPurchaseOrderEditToJSON,
    ResponseInfoFromJSON,
    ResponseInfoToJSON,
    StockCreateLineDTOFromJSON,
    StockCreateLineDTOToJSON,
    StockIncomingShipmentCreateFromJSON,
    StockIncomingShipmentCreateToJSON,
    StockIncomingShipmentDetailFromJSON,
    StockIncomingShipmentDetailToJSON,
    StockIncomingShipmentUpdateFromJSON,
    StockIncomingShipmentUpdateToJSON,
    StockInventoryControlCreateFromJSON,
    StockInventoryControlCreateToJSON,
    StockInventoryControlDetailFromJSON,
    StockInventoryControlDetailToJSON,
    StockInventoryControlLineCreateFromJSON,
    StockInventoryControlLineCreateToJSON,
    StockInventoryControlLineSchemaFromJSON,
    StockInventoryControlLineSchemaToJSON,
    StockInventoryControlUpdateFromJSON,
    StockInventoryControlUpdateToJSON,
    StockLinesLineFromJSON,
    StockLinesLineToJSON,
    StockLinesLineDetailFromJSON,
    StockLinesLineDetailToJSON,
    StockLinesResourceDetailFromJSON,
    StockLinesResourceDetailToJSON,
    StockLotSchemaFromJSON,
    StockLotSchemaToJSON,
    StockMoveAddLineResultFromJSON,
    StockMoveAddLineResultToJSON,
    StockMoveCreateEntityFromJSON,
    StockMoveCreateEntityToJSON,
    StockMoveDetailFromJSON,
    StockMoveDetailToJSON,
    StockMoveEntityFromJSON,
    StockMoveEntityToJSON,
    StockMoveLineEntityFromJSON,
    StockMoveLineEntityToJSON,
    StockMoveUpdateEntityFromJSON,
    StockMoveUpdateEntityToJSON,
    StockReceiveShipmentLineFromJSON,
    StockReceiveShipmentLineToJSON,
    StockShelfFromJSON,
    StockShelfToJSON,
    StockShipmentComparisonLineFromJSON,
    StockShipmentComparisonLineToJSON,
    StockSplitRequestFromJSON,
    StockSplitRequestToJSON,
} from '../models/index';

export interface BusinessPartnersCreateRequest {
    businessPartnerCreate?: BusinessPartnerCreate;
}

export interface BusinessPartnersDeleteManyRequest {
    ids?: string;
}

export interface BusinessPartnersDeleteOneRequest {
    id: number;
}

export interface BusinessPartnersGetRequest {
    id: number;
}

export interface BusinessPartnersSearchRequest {
    phone?: string;
    taxId?: string;
    countryId?: string;
    state?: string;
    query?: string;
    sortBy?: BusinessPartnersSearchSortByEnum;
    sort?: BusinessPartnersSearchSortEnum;
    ids?: string;
    page?: number;
    limit?: number;
}

export interface BusinessPartnersUpdateRequest {
    id: number;
    businessPartnerCreate?: BusinessPartnerCreate;
}

export interface CategoriesBulkDeleteRequest {
    ids?: string;
}

export interface CategoriesCreateRequest {
    productCategorySchema?: ProductCategorySchema;
}

export interface CategoriesDeleteRequest {
    id: number;
}

export interface CategoriesGetDetailRequest {
    id: number;
}

export interface CategoriesSearchRequest {
    name?: string;
    description?: string;
    parentId?: number;
    query?: string;
    sort?: CategoriesSearchSortEnum;
    sortBy?: string;
    ids?: string;
    orderBy?: string;
    page?: number;
    limit?: number;
}

export interface CategoriesUpdateRequest {
    id: number;
    productCategorySchema?: ProductCategorySchema;
}

export interface GroupsBulkDeleteRequest {
    ids?: string;
}

export interface GroupsCreateRequest {
    authGroupCreateEntity?: AuthGroupCreateEntity;
}

export interface GroupsDeleteRequest {
    id: string;
}

export interface GroupsGetDetailRequest {
    id: string;
}

export interface GroupsSearchRequest {
    query?: string;
    name?: string;
    description?: string;
    sort?: GroupsSearchSortEnum;
    sortBy?: string;
    ids?: string;
    orderBy?: string;
    page?: number;
    limit?: number;
}

export interface GroupsUpdateRequest {
    id: string;
    authGroupEntity?: AuthGroupEntity;
}

export interface IncomingShipmentCompareRequest {
    id: number;
}

export interface IncomingShipmentCreateRequest {
    stockIncomingShipmentCreate?: StockIncomingShipmentCreate;
}

export interface IncomingShipmentDeleteManyRequest {
    ids?: string;
}

export interface IncomingShipmentDeleteOneRequest {
    id: number;
}

export interface IncomingShipmentFileDeleteFileRequest {
    id: number;
    fileId: number;
}

export interface IncomingShipmentFileGetFileRequest {
    id: number;
    fileId: number;
}

export interface IncomingShipmentFileGetFilesRequest {
    id: number;
}

export interface IncomingShipmentFileUploadFileRequest {
    id: number;
}

export interface IncomingShipmentGetRequest {
    id: number;
}

export interface IncomingShipmentSearchRequest {
    query?: string;
    delFlag?: boolean;
    etaSince?: Date;
    etaUntil?: Date;
    state?: IncomingShipmentSearchStateEnum;
    sortBy?: IncomingShipmentSearchSortByEnum;
    sort?: IncomingShipmentSearchSortEnum;
    ids?: string;
    page?: number;
    limit?: number;
}

export interface IncomingShipmentUpdateRequest {
    id: number;
    stockIncomingShipmentUpdate?: StockIncomingShipmentUpdate;
}

export interface InventoryControlAddLineRequest {
    id: number;
    stockInventoryControlLineCreate?: StockInventoryControlLineCreate;
}

export interface InventoryControlApplyRequest {
    id: number;
}

export interface InventoryControlCreateRequest {
    stockInventoryControlCreate?: StockInventoryControlCreate;
}

export interface InventoryControlDeleteLineRequest {
    id: number;
    lineId: number;
}

export interface InventoryControlDeleteManyRequest {
    ids?: string;
}

export interface InventoryControlDeleteOneRequest {
    id: number;
}

export interface InventoryControlGetRequest {
    id: number;
}

export interface InventoryControlGetLinesRequest {
    id: number;
}

export interface InventoryControlSearchRequest {
    query?: string;
    delFlag?: boolean;
    since?: Date;
    until?: Date;
    appliedBy?: number;
    sortBy?: InventoryControlSearchSortByEnum;
    sort?: InventoryControlSearchSortEnum;
    ids?: string;
    page?: number;
    limit?: number;
}

export interface InventoryControlSetFinshedInventoryControlRequest {
    id: number;
    isFinished?: boolean;
}

export interface InventoryControlUpdateRequest {
    id: number;
    stockInventoryControlUpdate?: StockInventoryControlUpdate;
}

export interface LoginLoginRequest {
    useCookie?: boolean;
    authLoginUser?: AuthLoginUser;
}

export interface ProductsCopyRequest {
    id: number;
    body?: string;
}

export interface ProductsCreateRequest {
    productResourceBaseSchema?: ProductResourceBaseSchema;
}

export interface ProductsDeleteManyRequest {
    ids?: string;
}

export interface ProductsDeleteOneRequest {
    id: number;
}

export interface ProductsGetRequest {
    id: number;
}

export interface ProductsSearchRequest {
    query?: string;
    categoryIds?: Array<number>;
    currencyId?: string;
    quantityTypeId?: number;
    stockTracingCode?: ProductsSearchStockTracingCodeEnum;
    isActive?: boolean;
    sortBy?: ProductsSearchSortByEnum;
    sort?: ProductsSearchSortEnum;
    ids?: string;
    page?: number;
    limit?: number;
}

export interface ProductsUpdateRequest {
    id: number;
    productUpdateSchema?: ProductUpdateSchema;
}

export interface ProfileChangePasswordRequest {
    id: string;
    authUpdatePasswordSchema?: AuthUpdatePasswordSchema;
}

export interface ProfileUpdateRequest {
    id: string;
    authUserUpdateEntity?: AuthUserUpdateEntity;
}

export interface PurchaseOrderBulkCreateRequest {
    purchasingCreateFromBalanceRequest?: PurchasingCreateFromBalanceRequest;
}

export interface PurchaseOrderCreateRequest {
    purchasingPurchaseOrderCreate?: PurchasingPurchaseOrderCreate;
}

export interface PurchaseOrderDeleteManyRequest {
    ids?: string;
}

export interface PurchaseOrderDeleteOneRequest {
    id: number;
}

export interface PurchaseOrderExportExcelRequest {
    ids?: Array<number>;
}

export interface PurchaseOrderExportPdfRequest {
    id: number;
}

export interface PurchaseOrderExportPdfsRequest {
    ids?: Array<number>;
}

export interface PurchaseOrderFileDeleteFileRequest {
    id: number;
    fileId: number;
}

export interface PurchaseOrderFileGetFileRequest {
    id: number;
    fileId: number;
}

export interface PurchaseOrderFileGetFilesRequest {
    id: number;
}

export interface PurchaseOrderFileUploadFileRequest {
    id: number;
}

export interface PurchaseOrderGetRequest {
    id: number;
}

export interface PurchaseOrderGetLinesRequest {
    id: number;
    page?: number;
    limit?: number;
}

export interface PurchaseOrderSearchRequest {
    query?: string;
    confirmed?: boolean;
    businessPartnerId?: number;
    sortBy?: PurchaseOrderSearchSortByEnum;
    sort?: PurchaseOrderSearchSortEnum;
    ids?: string;
    page?: number;
    limit?: number;
}

export interface PurchaseOrderUpdateRequest {
    id: number;
    purchasingPurchaseOrderEdit?: PurchasingPurchaseOrderEdit;
}

export interface RawMaterialsCreateRequest {
    productRawMaterialCreateEntity?: ProductRawMaterialCreateEntity;
}

export interface RawMaterialsDeleteManyRequest {
    ids?: string;
}

export interface RawMaterialsDeleteOneRequest {
    id: number;
}

export interface RawMaterialsGetRequest {
    id: number;
}

export interface RawMaterialsSearchRequest {
    businessPartnerId?: string;
    categoryIds?: Array<number>;
    currencyId?: string;
    quantityTypeId?: string;
    stockTracingCode?: RawMaterialsSearchStockTracingCodeEnum;
    query?: string;
    isActive?: boolean;
    supplierArticleName?: string;
    supplierArticleNo?: string;
    sortBy?: RawMaterialsSearchSortByEnum;
    sort?: RawMaterialsSearchSortEnum;
    ids?: string;
    page?: number;
    limit?: number;
}

export interface RawMaterialsUpdateRequest {
    id: number;
    productRawMaterialUpdate?: ProductRawMaterialUpdate;
}

export interface ReceiveShipmentAddLineRequest {
    id: number;
    stockReceiveShipmentLine?: StockReceiveShipmentLine;
}

export interface ReceiveShipmentApplyRequest {
    id: number;
}

export interface ReceiveShipmentDeleteLineRequest {
    id: number;
    lineId: number;
}

export interface ReceiveShipmentGetStockMoveRequest {
    id: number;
}

export interface ResourceStockExcelExportRequest {
    categoryIds?: Array<number>;
    currencyId?: string;
    quantityTypeId?: string;
    articleNos?: Array<string>;
    stockTracingCode?: ResourceStockExcelExportStockTracingCodeEnum;
    query?: string;
    isActive?: boolean;
    type?: ResourceStockExcelExportTypeEnum;
    sortBy?: ResourceStockExcelExportSortByEnum;
    sort?: ResourceStockExcelExportSortEnum;
    ids?: string;
    stockLocations?: Array<number>;
}

export interface ResourceStockGetDetailRequest {
    id: number;
}

export interface ResourceStockGetLineRequest {
    id: number;
}

export interface ResourceStockGetLinesRequest {
    resourceId: number;
    stockLocationId?: number;
    filterEmpty?: boolean;
}

export interface ResourceStockLotsRequest {
    resourceId: number;
    stockLocationId?: number;
}

export interface ResourceStockSearchRequest {
    categoryIds?: Array<number>;
    currencyId?: string;
    quantityTypeId?: string;
    articleNos?: Array<string>;
    stockTracingCode?: ResourceStockSearchStockTracingCodeEnum;
    query?: string;
    isActive?: boolean;
    type?: ResourceStockSearchTypeEnum;
    sortBy?: ResourceStockSearchSortByEnum;
    sort?: ResourceStockSearchSortEnum;
    ids?: string;
    page?: number;
    limit?: number;
    stockLocations?: Array<number>;
}

export interface ResourcesGetDetailRequest {
    id: number;
}

export interface ResourcesSearchRequest {
    categoryIds?: Array<number>;
    currencyId?: string;
    quantityTypeId?: string;
    articleNos?: Array<string>;
    stockTracingCode?: ResourcesSearchStockTracingCodeEnum;
    query?: string;
    isActive?: boolean;
    type?: ResourcesSearchTypeEnum;
    sortBy?: ResourcesSearchSortByEnum;
    sort?: ResourcesSearchSortEnum;
    ids?: string;
    page?: number;
    limit?: number;
}

export interface StockLineGetStockLineRequest {
    id: number;
}

export interface StockLineSearchRequest {
    sortBy?: StockLineSearchSortByEnum;
    sort?: StockLineSearchSortEnum;
    ids?: string;
    page?: number;
    limit?: number;
}

export interface StockLocationGetDetailShelfRequest {
    id: number;
}

export interface StockLocationGetListShelfRequest {
    page?: number;
    limit?: number;
}

export interface StockLocationSearchRequest {
    page?: number;
    limit?: number;
}

export interface StockMovesAddLineRequest {
    id: number;
    stockCreateLineDTO?: StockCreateLineDTO;
}

export interface StockMovesApplyStockMoveRequest {
    id: number;
}

export interface StockMovesBulkDeleteRequest {
    ids?: Array<number>;
}

export interface StockMovesBulkPdfExportRequest {
    ids?: string;
}

export interface StockMovesCreateRequest {
    stockMoveCreateEntity?: StockMoveCreateEntity;
}

export interface StockMovesDeleteRequest {
    id: number;
}

export interface StockMovesDeleteLineRequest {
    id: number;
    ids?: string;
}

export interface StockMovesExcelExportRequest {
    plannedSince?: Date;
    plannedUntil?: Date;
    appliedSince?: Date;
    appliedUntil?: Date;
    sourceStockLocation?: number;
    destinationStockLocation?: number;
    createdBy?: number;
    appliedBy?: number;
    moveType?: StockMovesExcelExportMoveTypeEnum;
    sortBy?: StockMovesExcelExportSortByEnum;
    sort?: StockMovesExcelExportSortEnum;
    ids?: string;
}

export interface StockMovesGetDetailRequest {
    id: number;
}

export interface StockMovesGetListStockMoveLineRequest {
    id: number;
    page?: number;
    limit?: number;
}

export interface StockMovesPdfExportRequest {
    id: number;
}

export interface StockMovesSearchRequest {
    plannedSince?: Date;
    plannedUntil?: Date;
    appliedSince?: Date;
    appliedUntil?: Date;
    sourceStockLocation?: number;
    destinationStockLocation?: number;
    createdBy?: number;
    appliedBy?: number;
    moveType?: StockMovesSearchMoveTypeEnum;
    sortBy?: StockMovesSearchSortByEnum;
    sort?: StockMovesSearchSortEnum;
    ids?: string;
    page?: number;
    limit?: number;
}

export interface StockMovesUpdateRequest {
    id: number;
    stockMoveUpdateEntity?: StockMoveUpdateEntity;
}

export interface StockSplitCreateRequest {
    stockSplitRequest?: StockSplitRequest;
}

export interface StockSplitGetDetailRequest {
    id: number;
}

export interface StockSplitSearchRequest {
    plannedSince?: Date;
    plannedUntil?: Date;
    appliedSince?: Date;
    appliedUntil?: Date;
    sourceStockLocation?: number;
    destinationStockLocation?: number;
    createdBy?: number;
    appliedBy?: number;
    moveType?: StockSplitSearchMoveTypeEnum;
    sortBy?: StockSplitSearchSortByEnum;
    sort?: StockSplitSearchSortEnum;
    ids?: string;
    page?: number;
    limit?: number;
}

export interface UsersBulkDeleteRequest {
    ids?: string;
}

export interface UsersCreateRequest {
    authCreateUser?: AuthCreateUser;
}

export interface UsersDeleteRequest {
    id: number;
}

export interface UsersGetDetailRequest {
    id: number;
}

export interface UsersLockAccountRequest {
    id: number;
}

export interface UsersResetPasswordRequest {
    id: number;
    authResetPasswordSchema?: AuthResetPasswordSchema;
}

export interface UsersSearchRequest {
    query?: string;
    group?: string;
    phone?: string;
    email?: string;
    sortBy?: UsersSearchSortByEnum;
    sort?: UsersSearchSortEnum;
    ids?: string;
    page?: number;
    limit?: number;
}

export interface UsersUnLockAccountRequest {
    id: number;
}

export interface UsersUpdateRequest {
    id: number;
    authUserDetailEntity?: AuthUserDetailEntity;
}

/**
 * DefaultApi - interface
 * 
 * @export
 * @interface DefaultApiInterface
 */
export interface DefaultApiInterface {
    /**
     * 
     * @param {BusinessPartnerCreate} [businessPartnerCreate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    businessPartnersCreateRaw(requestParameters: BusinessPartnersCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>>;

    /**
     */
    businessPartnersCreate(requestParameters: BusinessPartnersCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo>;

    /**
     * 
     * @param {string} [ids] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    businessPartnersDeleteManyRaw(requestParameters: BusinessPartnersDeleteManyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>>;

    /**
     */
    businessPartnersDeleteMany(requestParameters: BusinessPartnersDeleteManyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    businessPartnersDeleteOneRaw(requestParameters: BusinessPartnersDeleteOneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>>;

    /**
     */
    businessPartnersDeleteOne(requestParameters: BusinessPartnersDeleteOneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    businessPartnersGetRaw(requestParameters: BusinessPartnersGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BusinessPartnerSchema>>;

    /**
     */
    businessPartnersGet(requestParameters: BusinessPartnersGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BusinessPartnerSchema>;

    /**
     * 
     * @param {string} [phone] 
     * @param {string} [taxId] 
     * @param {string} [countryId] 
     * @param {string} [state] 
     * @param {string} [query] 
     * @param {'Id' | 'Name' | 'Phone' | 'TaxId' | 'ZipCode' | 'City' | 'State' | 'Country'} [sortBy] 
     * @param {'ASC' | 'DESC'} [sort] 
     * @param {string} [ids] 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    businessPartnersSearchRaw(requestParameters: BusinessPartnersSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedListBusinessPartnerSchema>>;

    /**
     */
    businessPartnersSearch(requestParameters: BusinessPartnersSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedListBusinessPartnerSchema>;

    /**
     * 
     * @param {number} id 
     * @param {BusinessPartnerCreate} [businessPartnerCreate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    businessPartnersUpdateRaw(requestParameters: BusinessPartnersUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>>;

    /**
     */
    businessPartnersUpdate(requestParameters: BusinessPartnersUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo>;

    /**
     * 
     * @param {string} [ids] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    categoriesBulkDeleteRaw(requestParameters: CategoriesBulkDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>>;

    /**
     */
    categoriesBulkDelete(requestParameters: CategoriesBulkDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo>;

    /**
     * 
     * @param {ProductCategorySchema} [productCategorySchema] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    categoriesCreateRaw(requestParameters: CategoriesCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>>;

    /**
     */
    categoriesCreate(requestParameters: CategoriesCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    categoriesDeleteRaw(requestParameters: CategoriesDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>>;

    /**
     */
    categoriesDelete(requestParameters: CategoriesDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    categoriesGetDetailRaw(requestParameters: CategoriesGetDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductCategorySchema>>;

    /**
     */
    categoriesGetDetail(requestParameters: CategoriesGetDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductCategorySchema>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    categoriesGetListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProductCategorySchema>>>;

    /**
     */
    categoriesGetList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProductCategorySchema>>;

    /**
     * 
     * @param {string} [name] 
     * @param {string} [description] 
     * @param {number} [parentId] 
     * @param {string} [query] 
     * @param {'ASC' | 'DESC'} [sort] 
     * @param {string} [sortBy] 
     * @param {string} [ids] 
     * @param {string} [orderBy] 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    categoriesSearchRaw(requestParameters: CategoriesSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedListCategorySchema>>;

    /**
     */
    categoriesSearch(requestParameters: CategoriesSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedListCategorySchema>;

    /**
     * 
     * @param {number} id 
     * @param {ProductCategorySchema} [productCategorySchema] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    categoriesUpdateRaw(requestParameters: CategoriesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>>;

    /**
     */
    categoriesUpdate(requestParameters: CategoriesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    commonGetAppStateRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AppStateEntity>>;

    /**
     */
    commonGetAppState(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AppStateEntity>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    commonGetCountriesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AppCountrySchema>>>;

    /**
     */
    commonGetCountries(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AppCountrySchema>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    commonGetCurrenciesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AppCurrencyOption>>>;

    /**
     */
    commonGetCurrencies(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AppCurrencyOption>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    commonGetEnumsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AppEnumSchema>>;

    /**
     */
    commonGetEnums(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AppEnumSchema>;

    /**
     * 
     * @param {string} [ids] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    groupsBulkDeleteRaw(requestParameters: GroupsBulkDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>>;

    /**
     */
    groupsBulkDelete(requestParameters: GroupsBulkDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo>;

    /**
     * 
     * @param {AuthGroupCreateEntity} [authGroupCreateEntity] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    groupsCreateRaw(requestParameters: GroupsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>>;

    /**
     */
    groupsCreate(requestParameters: GroupsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    groupsDeleteRaw(requestParameters: GroupsDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>>;

    /**
     */
    groupsDelete(requestParameters: GroupsDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    groupsGetDetailRaw(requestParameters: GroupsGetDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthGroupEntity>>;

    /**
     */
    groupsGetDetail(requestParameters: GroupsGetDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthGroupEntity>;

    /**
     * 
     * @param {string} [query] 
     * @param {string} [name] 
     * @param {string} [description] 
     * @param {'ASC' | 'DESC'} [sort] 
     * @param {string} [sortBy] 
     * @param {string} [ids] 
     * @param {string} [orderBy] 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    groupsSearchRaw(requestParameters: GroupsSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedListGroupEntity>>;

    /**
     */
    groupsSearch(requestParameters: GroupsSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedListGroupEntity>;

    /**
     * 
     * @param {string} id 
     * @param {AuthGroupEntity} [authGroupEntity] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    groupsUpdateRaw(requestParameters: GroupsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>>;

    /**
     */
    groupsUpdate(requestParameters: GroupsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    incomingShipmentCompareRaw(requestParameters: IncomingShipmentCompareRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<StockShipmentComparisonLine>>>;

    /**
     */
    incomingShipmentCompare(requestParameters: IncomingShipmentCompareRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<StockShipmentComparisonLine>>;

    /**
     * 
     * @param {StockIncomingShipmentCreate} [stockIncomingShipmentCreate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    incomingShipmentCreateRaw(requestParameters: IncomingShipmentCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>>;

    /**
     */
    incomingShipmentCreate(requestParameters: IncomingShipmentCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo>;

    /**
     * 
     * @param {string} [ids] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    incomingShipmentDeleteManyRaw(requestParameters: IncomingShipmentDeleteManyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>>;

    /**
     */
    incomingShipmentDeleteMany(requestParameters: IncomingShipmentDeleteManyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    incomingShipmentDeleteOneRaw(requestParameters: IncomingShipmentDeleteOneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>>;

    /**
     */
    incomingShipmentDeleteOne(requestParameters: IncomingShipmentDeleteOneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo>;

    /**
     * 
     * @param {number} id 
     * @param {number} fileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    incomingShipmentFileDeleteFileRaw(requestParameters: IncomingShipmentFileDeleteFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>>;

    /**
     */
    incomingShipmentFileDeleteFile(requestParameters: IncomingShipmentFileDeleteFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo>;

    /**
     * 
     * @param {number} id 
     * @param {number} fileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    incomingShipmentFileGetFileRaw(requestParameters: IncomingShipmentFileGetFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>>;

    /**
     */
    incomingShipmentFileGetFile(requestParameters: IncomingShipmentFileGetFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    incomingShipmentFileGetFilesRaw(requestParameters: IncomingShipmentFileGetFilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FileDTO>>>;

    /**
     */
    incomingShipmentFileGetFiles(requestParameters: IncomingShipmentFileGetFilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FileDTO>>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    incomingShipmentFileUploadFileRaw(requestParameters: IncomingShipmentFileUploadFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>>;

    /**
     */
    incomingShipmentFileUploadFile(requestParameters: IncomingShipmentFileUploadFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    incomingShipmentGetRaw(requestParameters: IncomingShipmentGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StockIncomingShipmentDetail>>;

    /**
     */
    incomingShipmentGet(requestParameters: IncomingShipmentGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StockIncomingShipmentDetail>;

    /**
     * 
     * @param {string} [query] 
     * @param {boolean} [delFlag] 
     * @param {Date} [etaSince] 
     * @param {Date} [etaUntil] 
     * @param {'waiting_for_departure' | 'during_shipment' | 'processing' | 'done' | 'error'} [state] 
     * @param {'Id' | 'ActualTimeofDeparture' | 'EstimatedTimeofArrival' | 'TransportType' | 'Supplier' | 'State' | 'ShipmentName' | 'Comment'} [sortBy] 
     * @param {'ASC' | 'DESC'} [sort] 
     * @param {string} [ids] 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    incomingShipmentSearchRaw(requestParameters: IncomingShipmentSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedListIncomingShipment>>;

    /**
     */
    incomingShipmentSearch(requestParameters: IncomingShipmentSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedListIncomingShipment>;

    /**
     * 
     * @param {number} id 
     * @param {StockIncomingShipmentUpdate} [stockIncomingShipmentUpdate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    incomingShipmentUpdateRaw(requestParameters: IncomingShipmentUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>>;

    /**
     */
    incomingShipmentUpdate(requestParameters: IncomingShipmentUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo>;

    /**
     * 
     * @param {number} id 
     * @param {StockInventoryControlLineCreate} [stockInventoryControlLineCreate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    inventoryControlAddLineRaw(requestParameters: InventoryControlAddLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>>;

    /**
     */
    inventoryControlAddLine(requestParameters: InventoryControlAddLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    inventoryControlApplyRaw(requestParameters: InventoryControlApplyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>>;

    /**
     */
    inventoryControlApply(requestParameters: InventoryControlApplyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo>;

    /**
     * 
     * @param {StockInventoryControlCreate} [stockInventoryControlCreate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    inventoryControlCreateRaw(requestParameters: InventoryControlCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>>;

    /**
     */
    inventoryControlCreate(requestParameters: InventoryControlCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo>;

    /**
     * 
     * @param {number} id 
     * @param {number} lineId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    inventoryControlDeleteLineRaw(requestParameters: InventoryControlDeleteLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>>;

    /**
     */
    inventoryControlDeleteLine(requestParameters: InventoryControlDeleteLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo>;

    /**
     * 
     * @param {string} [ids] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    inventoryControlDeleteManyRaw(requestParameters: InventoryControlDeleteManyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>>;

    /**
     */
    inventoryControlDeleteMany(requestParameters: InventoryControlDeleteManyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    inventoryControlDeleteOneRaw(requestParameters: InventoryControlDeleteOneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>>;

    /**
     */
    inventoryControlDeleteOne(requestParameters: InventoryControlDeleteOneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    inventoryControlGetRaw(requestParameters: InventoryControlGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StockInventoryControlDetail>>;

    /**
     */
    inventoryControlGet(requestParameters: InventoryControlGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StockInventoryControlDetail>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    inventoryControlGetLinesRaw(requestParameters: InventoryControlGetLinesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<StockInventoryControlLineSchema>>>;

    /**
     */
    inventoryControlGetLines(requestParameters: InventoryControlGetLinesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<StockInventoryControlLineSchema>>;

    /**
     * 
     * @param {string} [query] 
     * @param {boolean} [delFlag] 
     * @param {Date} [since] 
     * @param {Date} [until] 
     * @param {number} [appliedBy] 
     * @param {'Id' | 'Name' | 'PlannedDatetime' | 'FinishedDatetime'} [sortBy] 
     * @param {'ASC' | 'DESC'} [sort] 
     * @param {string} [ids] 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    inventoryControlSearchRaw(requestParameters: InventoryControlSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedListInventoryControl>>;

    /**
     */
    inventoryControlSearch(requestParameters: InventoryControlSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedListInventoryControl>;

    /**
     * 
     * @param {number} id 
     * @param {boolean} [isFinished] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    inventoryControlSetFinshedInventoryControlRaw(requestParameters: InventoryControlSetFinshedInventoryControlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>>;

    /**
     */
    inventoryControlSetFinshedInventoryControl(requestParameters: InventoryControlSetFinshedInventoryControlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo>;

    /**
     * 
     * @param {number} id 
     * @param {StockInventoryControlUpdate} [stockInventoryControlUpdate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    inventoryControlUpdateRaw(requestParameters: InventoryControlUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>>;

    /**
     */
    inventoryControlUpdate(requestParameters: InventoryControlUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    loginGetLoginRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>>;

    /**
     */
    loginGetLogin(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean>;

    /**
     * 
     * @param {boolean} [useCookie] 
     * @param {AuthLoginUser} [authLoginUser] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    loginLoginRaw(requestParameters: LoginLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthLoginResultDTO>>;

    /**
     */
    loginLogin(requestParameters: LoginLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthLoginResultDTO>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    loginLogoutRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>>;

    /**
     */
    loginLogout(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo>;

    /**
     * 
     * @param {number} id 
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    productsCopyRaw(requestParameters: ProductsCopyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>>;

    /**
     */
    productsCopy(requestParameters: ProductsCopyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number>;

    /**
     * 
     * @param {ProductResourceBaseSchema} [productResourceBaseSchema] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    productsCreateRaw(requestParameters: ProductsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>>;

    /**
     */
    productsCreate(requestParameters: ProductsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo>;

    /**
     * 
     * @param {string} [ids] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    productsDeleteManyRaw(requestParameters: ProductsDeleteManyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>>;

    /**
     */
    productsDeleteMany(requestParameters: ProductsDeleteManyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    productsDeleteOneRaw(requestParameters: ProductsDeleteOneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>>;

    /**
     */
    productsDeleteOne(requestParameters: ProductsDeleteOneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    productsGetRaw(requestParameters: ProductsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductDetailSchema>>;

    /**
     */
    productsGet(requestParameters: ProductsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductDetailSchema>;

    /**
     * 
     * @param {string} [query] 
     * @param {Array<number>} [categoryIds] 
     * @param {string} [currencyId] 
     * @param {number} [quantityTypeId] 
     * @param {'None' | 'Lot' | 'Serial'} [stockTracingCode] 
     * @param {boolean} [isActive] 
     * @param {'Id' | 'Name' | 'Price' | 'CurrencyId' | 'isActive'} [sortBy] 
     * @param {'ASC' | 'DESC'} [sort] 
     * @param {string} [ids] 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    productsSearchRaw(requestParameters: ProductsSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedListResourceDetailSchema>>;

    /**
     */
    productsSearch(requestParameters: ProductsSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedListResourceDetailSchema>;

    /**
     * 
     * @param {number} id 
     * @param {ProductUpdateSchema} [productUpdateSchema] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    productsUpdateRaw(requestParameters: ProductsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>>;

    /**
     */
    productsUpdate(requestParameters: ProductsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo>;

    /**
     * 
     * @param {string} id 
     * @param {AuthUpdatePasswordSchema} [authUpdatePasswordSchema] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    profileChangePasswordRaw(requestParameters: ProfileChangePasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>>;

    /**
     */
    profileChangePassword(requestParameters: ProfileChangePasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    profileGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthUserEntity>>;

    /**
     */
    profileGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthUserEntity>;

    /**
     * 
     * @param {string} id 
     * @param {AuthUserUpdateEntity} [authUserUpdateEntity] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    profileUpdateRaw(requestParameters: ProfileUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>>;

    /**
     */
    profileUpdate(requestParameters: ProfileUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo>;

    /**
     * 
     * @param {PurchasingCreateFromBalanceRequest} [purchasingCreateFromBalanceRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    purchaseOrderBulkCreateRaw(requestParameters: PurchaseOrderBulkCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<number>>>;

    /**
     */
    purchaseOrderBulkCreate(requestParameters: PurchaseOrderBulkCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<number>>;

    /**
     * 
     * @param {PurchasingPurchaseOrderCreate} [purchasingPurchaseOrderCreate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    purchaseOrderCreateRaw(requestParameters: PurchaseOrderCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>>;

    /**
     */
    purchaseOrderCreate(requestParameters: PurchaseOrderCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo>;

    /**
     * 
     * @param {string} [ids] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    purchaseOrderDeleteManyRaw(requestParameters: PurchaseOrderDeleteManyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>>;

    /**
     */
    purchaseOrderDeleteMany(requestParameters: PurchaseOrderDeleteManyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    purchaseOrderDeleteOneRaw(requestParameters: PurchaseOrderDeleteOneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>>;

    /**
     */
    purchaseOrderDeleteOne(requestParameters: PurchaseOrderDeleteOneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo>;

    /**
     * 
     * @param {Array<number>} [ids] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    purchaseOrderExportExcelRaw(requestParameters: PurchaseOrderExportExcelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>>;

    /**
     */
    purchaseOrderExportExcel(requestParameters: PurchaseOrderExportExcelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    purchaseOrderExportPdfRaw(requestParameters: PurchaseOrderExportPdfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>>;

    /**
     */
    purchaseOrderExportPdf(requestParameters: PurchaseOrderExportPdfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob>;

    /**
     * 
     * @param {Array<number>} [ids] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    purchaseOrderExportPdfsRaw(requestParameters: PurchaseOrderExportPdfsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>>;

    /**
     */
    purchaseOrderExportPdfs(requestParameters: PurchaseOrderExportPdfsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob>;

    /**
     * 
     * @param {number} id 
     * @param {number} fileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    purchaseOrderFileDeleteFileRaw(requestParameters: PurchaseOrderFileDeleteFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>>;

    /**
     */
    purchaseOrderFileDeleteFile(requestParameters: PurchaseOrderFileDeleteFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo>;

    /**
     * 
     * @param {number} id 
     * @param {number} fileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    purchaseOrderFileGetFileRaw(requestParameters: PurchaseOrderFileGetFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>>;

    /**
     */
    purchaseOrderFileGetFile(requestParameters: PurchaseOrderFileGetFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    purchaseOrderFileGetFilesRaw(requestParameters: PurchaseOrderFileGetFilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FileDTO>>>;

    /**
     */
    purchaseOrderFileGetFiles(requestParameters: PurchaseOrderFileGetFilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FileDTO>>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    purchaseOrderFileUploadFileRaw(requestParameters: PurchaseOrderFileUploadFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>>;

    /**
     */
    purchaseOrderFileUploadFile(requestParameters: PurchaseOrderFileUploadFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    purchaseOrderGetRaw(requestParameters: PurchaseOrderGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PurchasingPurchaseOrderDetail>>;

    /**
     */
    purchaseOrderGet(requestParameters: PurchaseOrderGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PurchasingPurchaseOrderDetail>;

    /**
     * 
     * @param {number} id 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    purchaseOrderGetLinesRaw(requestParameters: PurchaseOrderGetLinesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedListPurchaseOrderLineSolo>>;

    /**
     */
    purchaseOrderGetLines(requestParameters: PurchaseOrderGetLinesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedListPurchaseOrderLineSolo>;

    /**
     * 
     * @param {string} [query] 
     * @param {boolean} [confirmed] 
     * @param {number} [businessPartnerId] 
     * @param {'ConfirmationDate' | 'OrderSequence' | 'BusinessPartner' | 'Comment' | 'PlannedDate' | 'CreatedAt' | 'Id'} [sortBy] 
     * @param {'ASC' | 'DESC'} [sort] 
     * @param {string} [ids] 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    purchaseOrderSearchRaw(requestParameters: PurchaseOrderSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedListPurchaseOrder>>;

    /**
     */
    purchaseOrderSearch(requestParameters: PurchaseOrderSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedListPurchaseOrder>;

    /**
     * 
     * @param {number} id 
     * @param {PurchasingPurchaseOrderEdit} [purchasingPurchaseOrderEdit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    purchaseOrderUpdateRaw(requestParameters: PurchaseOrderUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>>;

    /**
     */
    purchaseOrderUpdate(requestParameters: PurchaseOrderUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo>;

    /**
     * 
     * @param {ProductRawMaterialCreateEntity} [productRawMaterialCreateEntity] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    rawMaterialsCreateRaw(requestParameters: RawMaterialsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>>;

    /**
     */
    rawMaterialsCreate(requestParameters: RawMaterialsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo>;

    /**
     * 
     * @param {string} [ids] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    rawMaterialsDeleteManyRaw(requestParameters: RawMaterialsDeleteManyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>>;

    /**
     */
    rawMaterialsDeleteMany(requestParameters: RawMaterialsDeleteManyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    rawMaterialsDeleteOneRaw(requestParameters: RawMaterialsDeleteOneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>>;

    /**
     */
    rawMaterialsDeleteOne(requestParameters: RawMaterialsDeleteOneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    rawMaterialsGetRaw(requestParameters: RawMaterialsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductRawMaterialDetailEntity>>;

    /**
     */
    rawMaterialsGet(requestParameters: RawMaterialsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductRawMaterialDetailEntity>;

    /**
     * 
     * @param {string} [businessPartnerId] 
     * @param {Array<number>} [categoryIds] 
     * @param {string} [currencyId] 
     * @param {string} [quantityTypeId] 
     * @param {'None' | 'Lot' | 'Serial'} [stockTracingCode] 
     * @param {string} [query] 
     * @param {boolean} [isActive] 
     * @param {string} [supplierArticleName] 
     * @param {string} [supplierArticleNo] 
     * @param {'Id' | 'Name' | 'ArticleNo' | 'businessPartnerName' | 'LeadTime' | 'Moq' | 'StockTracing' | 'isActive'} [sortBy] 
     * @param {'ASC' | 'DESC'} [sort] 
     * @param {string} [ids] 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    rawMaterialsSearchRaw(requestParameters: RawMaterialsSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedListRawMaterialEntity>>;

    /**
     */
    rawMaterialsSearch(requestParameters: RawMaterialsSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedListRawMaterialEntity>;

    /**
     * 
     * @param {number} id 
     * @param {ProductRawMaterialUpdate} [productRawMaterialUpdate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    rawMaterialsUpdateRaw(requestParameters: RawMaterialsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>>;

    /**
     */
    rawMaterialsUpdate(requestParameters: RawMaterialsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo>;

    /**
     * 
     * @param {number} id 
     * @param {StockReceiveShipmentLine} [stockReceiveShipmentLine] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    receiveShipmentAddLineRaw(requestParameters: ReceiveShipmentAddLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StockMoveLineEntity>>;

    /**
     */
    receiveShipmentAddLine(requestParameters: ReceiveShipmentAddLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StockMoveLineEntity>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    receiveShipmentApplyRaw(requestParameters: ReceiveShipmentApplyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>>;

    /**
     */
    receiveShipmentApply(requestParameters: ReceiveShipmentApplyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo>;

    /**
     * 
     * @param {number} id 
     * @param {number} lineId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    receiveShipmentDeleteLineRaw(requestParameters: ReceiveShipmentDeleteLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>>;

    /**
     */
    receiveShipmentDeleteLine(requestParameters: ReceiveShipmentDeleteLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    receiveShipmentGetStockMoveRaw(requestParameters: ReceiveShipmentGetStockMoveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StockMoveEntity>>;

    /**
     */
    receiveShipmentGetStockMove(requestParameters: ReceiveShipmentGetStockMoveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StockMoveEntity>;

    /**
     * 
     * @param {Array<number>} [categoryIds] 
     * @param {string} [currencyId] 
     * @param {string} [quantityTypeId] 
     * @param {Array<string>} [articleNos] 
     * @param {'None' | 'Lot' | 'Serial'} [stockTracingCode] 
     * @param {string} [query] 
     * @param {boolean} [isActive] 
     * @param {'RawMaterial' | 'Product'} [type] 
     * @param {'Id' | 'ArticleNo' | 'Name' | 'Amount'} [sortBy] 
     * @param {'ASC' | 'DESC'} [sort] 
     * @param {string} [ids] 
     * @param {Array<number>} [stockLocations] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    resourceStockExcelExportRaw(requestParameters: ResourceStockExcelExportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>>;

    /**
     */
    resourceStockExcelExport(requestParameters: ResourceStockExcelExportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    resourceStockGetDetailRaw(requestParameters: ResourceStockGetDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StockLinesResourceDetail>>;

    /**
     */
    resourceStockGetDetail(requestParameters: ResourceStockGetDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StockLinesResourceDetail>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    resourceStockGetLineRaw(requestParameters: ResourceStockGetLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StockLinesLineDetail>>;

    /**
     */
    resourceStockGetLine(requestParameters: ResourceStockGetLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StockLinesLineDetail>;

    /**
     * 
     * @param {number} resourceId 
     * @param {number} [stockLocationId] 
     * @param {boolean} [filterEmpty] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    resourceStockGetLinesRaw(requestParameters: ResourceStockGetLinesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<StockLinesLine>>>;

    /**
     */
    resourceStockGetLines(requestParameters: ResourceStockGetLinesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<StockLinesLine>>;

    /**
     * 
     * @param {number} resourceId 
     * @param {number} [stockLocationId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    resourceStockLotsRaw(requestParameters: ResourceStockLotsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<StockLotSchema>>>;

    /**
     */
    resourceStockLots(requestParameters: ResourceStockLotsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<StockLotSchema>>;

    /**
     * 
     * @param {Array<number>} [categoryIds] 
     * @param {string} [currencyId] 
     * @param {string} [quantityTypeId] 
     * @param {Array<string>} [articleNos] 
     * @param {'None' | 'Lot' | 'Serial'} [stockTracingCode] 
     * @param {string} [query] 
     * @param {boolean} [isActive] 
     * @param {'RawMaterial' | 'Product'} [type] 
     * @param {'Id' | 'ArticleNo' | 'Name' | 'Amount'} [sortBy] 
     * @param {'ASC' | 'DESC'} [sort] 
     * @param {string} [ids] 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {Array<number>} [stockLocations] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    resourceStockSearchRaw(requestParameters: ResourceStockSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedListResourceStock>>;

    /**
     */
    resourceStockSearch(requestParameters: ResourceStockSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedListResourceStock>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    resourcesGetDetailRaw(requestParameters: ResourcesGetDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductResourceDetailSchema>>;

    /**
     */
    resourcesGetDetail(requestParameters: ResourcesGetDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductResourceDetailSchema>;

    /**
     * 
     * @param {Array<number>} [categoryIds] 
     * @param {string} [currencyId] 
     * @param {string} [quantityTypeId] 
     * @param {Array<string>} [articleNos] 
     * @param {'None' | 'Lot' | 'Serial'} [stockTracingCode] 
     * @param {string} [query] 
     * @param {boolean} [isActive] 
     * @param {'RawMaterial' | 'Product'} [type] 
     * @param {'Id' | 'Name' | 'ArticleNo' | 'businessPartnerName' | 'LeadTime' | 'Moq' | 'StockTracing' | 'isActive'} [sortBy] 
     * @param {'ASC' | 'DESC'} [sort] 
     * @param {string} [ids] 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    resourcesSearchRaw(requestParameters: ResourcesSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedListResourceDetailSchema>>;

    /**
     */
    resourcesSearch(requestParameters: ResourcesSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedListResourceDetailSchema>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    stockLineGetStockLineRaw(requestParameters: StockLineGetStockLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StockLinesLineDetail>>;

    /**
     */
    stockLineGetStockLine(requestParameters: StockLineGetStockLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StockLinesLineDetail>;

    /**
     * 
     * @param {'Id'} [sortBy] 
     * @param {'ASC' | 'DESC'} [sort] 
     * @param {string} [ids] 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    stockLineSearchRaw(requestParameters: StockLineSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedListStockLineDetail>>;

    /**
     */
    stockLineSearch(requestParameters: StockLineSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedListStockLineDetail>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    stockLocationGetDetailShelfRaw(requestParameters: StockLocationGetDetailShelfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StockShelf>>;

    /**
     */
    stockLocationGetDetailShelf(requestParameters: StockLocationGetDetailShelfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StockShelf>;

    /**
     * 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    stockLocationGetListShelfRaw(requestParameters: StockLocationGetListShelfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedListShelf>>;

    /**
     */
    stockLocationGetListShelf(requestParameters: StockLocationGetListShelfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedListShelf>;

    /**
     * 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    stockLocationSearchRaw(requestParameters: StockLocationSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedListStockLocationSchema>>;

    /**
     */
    stockLocationSearch(requestParameters: StockLocationSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedListStockLocationSchema>;

    /**
     * 
     * @param {number} id 
     * @param {StockCreateLineDTO} [stockCreateLineDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    stockMovesAddLineRaw(requestParameters: StockMovesAddLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StockMoveAddLineResult>>;

    /**
     */
    stockMovesAddLine(requestParameters: StockMovesAddLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StockMoveAddLineResult>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    stockMovesApplyStockMoveRaw(requestParameters: StockMovesApplyStockMoveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>>;

    /**
     */
    stockMovesApplyStockMove(requestParameters: StockMovesApplyStockMoveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo>;

    /**
     * 
     * @param {Array<number>} [ids] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    stockMovesBulkDeleteRaw(requestParameters: StockMovesBulkDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>>;

    /**
     */
    stockMovesBulkDelete(requestParameters: StockMovesBulkDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo>;

    /**
     * 
     * @param {string} [ids] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    stockMovesBulkPdfExportRaw(requestParameters: StockMovesBulkPdfExportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>>;

    /**
     */
    stockMovesBulkPdfExport(requestParameters: StockMovesBulkPdfExportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob>;

    /**
     * 
     * @param {StockMoveCreateEntity} [stockMoveCreateEntity] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    stockMovesCreateRaw(requestParameters: StockMovesCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>>;

    /**
     */
    stockMovesCreate(requestParameters: StockMovesCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    stockMovesDeleteRaw(requestParameters: StockMovesDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>>;

    /**
     */
    stockMovesDelete(requestParameters: StockMovesDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo>;

    /**
     * 
     * @param {number} id 
     * @param {string} [ids] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    stockMovesDeleteLineRaw(requestParameters: StockMovesDeleteLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>>;

    /**
     */
    stockMovesDeleteLine(requestParameters: StockMovesDeleteLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo>;

    /**
     * 
     * @param {Date} [plannedSince] 
     * @param {Date} [plannedUntil] 
     * @param {Date} [appliedSince] 
     * @param {Date} [appliedUntil] 
     * @param {number} [sourceStockLocation] 
     * @param {number} [destinationStockLocation] 
     * @param {number} [createdBy] 
     * @param {number} [appliedBy] 
     * @param {'Manual' | 'Incoming' | 'StockSplit' | 'InventoryControl'} [moveType] 
     * @param {'Id' | 'AppliedDate' | 'PlannedDate' | 'MoveType'} [sortBy] 
     * @param {'ASC' | 'DESC'} [sort] 
     * @param {string} [ids] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    stockMovesExcelExportRaw(requestParameters: StockMovesExcelExportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>>;

    /**
     */
    stockMovesExcelExport(requestParameters: StockMovesExcelExportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    stockMovesGetDetailRaw(requestParameters: StockMovesGetDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StockMoveDetail>>;

    /**
     */
    stockMovesGetDetail(requestParameters: StockMovesGetDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StockMoveDetail>;

    /**
     * 
     * @param {number} id 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    stockMovesGetListStockMoveLineRaw(requestParameters: StockMovesGetListStockMoveLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedListStockMoveLineEntity>>;

    /**
     */
    stockMovesGetListStockMoveLine(requestParameters: StockMovesGetListStockMoveLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedListStockMoveLineEntity>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    stockMovesPdfExportRaw(requestParameters: StockMovesPdfExportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>>;

    /**
     */
    stockMovesPdfExport(requestParameters: StockMovesPdfExportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob>;

    /**
     * 
     * @param {Date} [plannedSince] 
     * @param {Date} [plannedUntil] 
     * @param {Date} [appliedSince] 
     * @param {Date} [appliedUntil] 
     * @param {number} [sourceStockLocation] 
     * @param {number} [destinationStockLocation] 
     * @param {number} [createdBy] 
     * @param {number} [appliedBy] 
     * @param {'Manual' | 'Incoming' | 'StockSplit' | 'InventoryControl'} [moveType] 
     * @param {'Id' | 'AppliedDate' | 'PlannedDate' | 'MoveType'} [sortBy] 
     * @param {'ASC' | 'DESC'} [sort] 
     * @param {string} [ids] 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    stockMovesSearchRaw(requestParameters: StockMovesSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedListStockMoveEntity>>;

    /**
     */
    stockMovesSearch(requestParameters: StockMovesSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedListStockMoveEntity>;

    /**
     * 
     * @param {number} id 
     * @param {StockMoveUpdateEntity} [stockMoveUpdateEntity] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    stockMovesUpdateRaw(requestParameters: StockMovesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>>;

    /**
     */
    stockMovesUpdate(requestParameters: StockMovesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo>;

    /**
     * 
     * @param {StockSplitRequest} [stockSplitRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    stockSplitCreateRaw(requestParameters: StockSplitCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StockMoveEntity>>;

    /**
     */
    stockSplitCreate(requestParameters: StockSplitCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StockMoveEntity>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    stockSplitGetDetailRaw(requestParameters: StockSplitGetDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StockMoveEntity>>;

    /**
     */
    stockSplitGetDetail(requestParameters: StockSplitGetDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StockMoveEntity>;

    /**
     * 
     * @param {Date} [plannedSince] 
     * @param {Date} [plannedUntil] 
     * @param {Date} [appliedSince] 
     * @param {Date} [appliedUntil] 
     * @param {number} [sourceStockLocation] 
     * @param {number} [destinationStockLocation] 
     * @param {number} [createdBy] 
     * @param {number} [appliedBy] 
     * @param {'Manual' | 'Incoming' | 'StockSplit' | 'InventoryControl'} [moveType] 
     * @param {'Id' | 'AppliedDate' | 'PlannedDate' | 'MoveType'} [sortBy] 
     * @param {'ASC' | 'DESC'} [sort] 
     * @param {string} [ids] 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    stockSplitSearchRaw(requestParameters: StockSplitSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedListStockSplitEntity>>;

    /**
     */
    stockSplitSearch(requestParameters: StockSplitSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedListStockSplitEntity>;

    /**
     * 
     * @param {string} [ids] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    usersBulkDeleteRaw(requestParameters: UsersBulkDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>>;

    /**
     */
    usersBulkDelete(requestParameters: UsersBulkDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo>;

    /**
     * 
     * @param {AuthCreateUser} [authCreateUser] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    usersCreateRaw(requestParameters: UsersCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>>;

    /**
     */
    usersCreate(requestParameters: UsersCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    usersDeleteRaw(requestParameters: UsersDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>>;

    /**
     */
    usersDelete(requestParameters: UsersDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    usersGetDetailRaw(requestParameters: UsersGetDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthUserDetailEntity>>;

    /**
     */
    usersGetDetail(requestParameters: UsersGetDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthUserDetailEntity>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    usersLockAccountRaw(requestParameters: UsersLockAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>>;

    /**
     */
    usersLockAccount(requestParameters: UsersLockAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo>;

    /**
     * 
     * @param {number} id 
     * @param {AuthResetPasswordSchema} [authResetPasswordSchema] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    usersResetPasswordRaw(requestParameters: UsersResetPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>>;

    /**
     */
    usersResetPassword(requestParameters: UsersResetPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo>;

    /**
     * 
     * @param {string} [query] 
     * @param {string} [group] 
     * @param {string} [phone] 
     * @param {string} [email] 
     * @param {'Id' | 'Username' | 'Email' | 'LastLogin' | 'LastLogout' | 'IsActive' | 'IsVerifyEmail'} [sortBy] 
     * @param {'ASC' | 'DESC'} [sort] 
     * @param {string} [ids] 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    usersSearchRaw(requestParameters: UsersSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedListUserEntity>>;

    /**
     */
    usersSearch(requestParameters: UsersSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedListUserEntity>;

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    usersUnLockAccountRaw(requestParameters: UsersUnLockAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>>;

    /**
     */
    usersUnLockAccount(requestParameters: UsersUnLockAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo>;

    /**
     * 
     * @param {number} id 
     * @param {AuthUserDetailEntity} [authUserDetailEntity] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    usersUpdateRaw(requestParameters: UsersUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>>;

    /**
     */
    usersUpdate(requestParameters: UsersUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo>;

}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI implements DefaultApiInterface {

    /**
     */
    async businessPartnersCreateRaw(requestParameters: BusinessPartnersCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/business-partner/business-partners`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BusinessPartnerCreateToJSON(requestParameters['businessPartnerCreate']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseInfoFromJSON(jsonValue));
    }

    /**
     */
    async businessPartnersCreate(requestParameters: BusinessPartnersCreateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo> {
        const response = await this.businessPartnersCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async businessPartnersDeleteManyRaw(requestParameters: BusinessPartnersDeleteManyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>> {
        const queryParameters: any = {};

        if (runtime.exists(requestParameters, 'ids')) {
            queryParameters['ids'] = requestParameters['ids'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/business-partner/business-partners`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseInfoFromJSON(jsonValue));
    }

    /**
     */
    async businessPartnersDeleteMany(requestParameters: BusinessPartnersDeleteManyRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo> {
        const response = await this.businessPartnersDeleteManyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async businessPartnersDeleteOneRaw(requestParameters: BusinessPartnersDeleteOneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling businessPartnersDeleteOne().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/business-partner/business-partners/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseInfoFromJSON(jsonValue));
    }

    /**
     */
    async businessPartnersDeleteOne(requestParameters: BusinessPartnersDeleteOneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo> {
        const response = await this.businessPartnersDeleteOneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async businessPartnersGetRaw(requestParameters: BusinessPartnersGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BusinessPartnerSchema>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling businessPartnersGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/business-partner/business-partners/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BusinessPartnerSchemaFromJSON(jsonValue));
    }

    /**
     */
    async businessPartnersGet(requestParameters: BusinessPartnersGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BusinessPartnerSchema> {
        const response = await this.businessPartnersGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async businessPartnersSearchRaw(requestParameters: BusinessPartnersSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedListBusinessPartnerSchema>> {
        const queryParameters: any = {};

        if (runtime.exists(requestParameters, 'phone')) {
            queryParameters['Phone'] = requestParameters['phone'];
        }

        if (runtime.exists(requestParameters, 'taxId')) {
            queryParameters['TaxId'] = requestParameters['taxId'];
        }

        if (runtime.exists(requestParameters, 'countryId')) {
            queryParameters['CountryId'] = requestParameters['countryId'];
        }

        if (runtime.exists(requestParameters, 'state')) {
            queryParameters['State'] = requestParameters['state'];
        }

        if (runtime.exists(requestParameters, 'query')) {
            queryParameters['Query'] = requestParameters['query'];
        }

        if (runtime.exists(requestParameters, 'sortBy')) {
            queryParameters['SortBy'] = requestParameters['sortBy'];
        }

        if (runtime.exists(requestParameters, 'sort')) {
            queryParameters['Sort'] = requestParameters['sort'];
        }

        if (runtime.exists(requestParameters, 'ids')) {
            queryParameters['Ids'] = requestParameters['ids'];
        }

        if (runtime.exists(requestParameters, 'page')) {
            queryParameters['Page'] = requestParameters['page'];
        }

        if (runtime.exists(requestParameters, 'limit')) {
            queryParameters['Limit'] = requestParameters['limit'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/business-partner/business-partners`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedListBusinessPartnerSchemaFromJSON(jsonValue));
    }

    /**
     */
    async businessPartnersSearch(requestParameters: BusinessPartnersSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedListBusinessPartnerSchema> {
        const response = await this.businessPartnersSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async businessPartnersUpdateRaw(requestParameters: BusinessPartnersUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling businessPartnersUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/business-partner/business-partners/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BusinessPartnerCreateToJSON(requestParameters['businessPartnerCreate']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseInfoFromJSON(jsonValue));
    }

    /**
     */
    async businessPartnersUpdate(requestParameters: BusinessPartnersUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo> {
        const response = await this.businessPartnersUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async categoriesBulkDeleteRaw(requestParameters: CategoriesBulkDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>> {
        const queryParameters: any = {};

        if (runtime.exists(requestParameters, 'ids')) {
            queryParameters['ids'] = requestParameters['ids'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/product/categories`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseInfoFromJSON(jsonValue));
    }

    /**
     */
    async categoriesBulkDelete(requestParameters: CategoriesBulkDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo> {
        const response = await this.categoriesBulkDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async categoriesCreateRaw(requestParameters: CategoriesCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/product/categories`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProductCategorySchemaToJSON(requestParameters['productCategorySchema']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseInfoFromJSON(jsonValue));
    }

    /**
     */
    async categoriesCreate(requestParameters: CategoriesCreateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo> {
        const response = await this.categoriesCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async categoriesDeleteRaw(requestParameters: CategoriesDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling categoriesDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/product/categories/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseInfoFromJSON(jsonValue));
    }

    /**
     */
    async categoriesDelete(requestParameters: CategoriesDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo> {
        const response = await this.categoriesDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async categoriesGetDetailRaw(requestParameters: CategoriesGetDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductCategorySchema>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling categoriesGetDetail().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/product/categories/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductCategorySchemaFromJSON(jsonValue));
    }

    /**
     */
    async categoriesGetDetail(requestParameters: CategoriesGetDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductCategorySchema> {
        const response = await this.categoriesGetDetailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async categoriesGetListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProductCategorySchema>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/product/categories/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProductCategorySchemaFromJSON));
    }

    /**
     */
    async categoriesGetList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProductCategorySchema>> {
        const response = await this.categoriesGetListRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async categoriesSearchRaw(requestParameters: CategoriesSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedListCategorySchema>> {
        const queryParameters: any = {};

        if (runtime.exists(requestParameters, 'name')) {
            queryParameters['Name'] = requestParameters['name'];
        }

        if (runtime.exists(requestParameters, 'description')) {
            queryParameters['Description'] = requestParameters['description'];
        }

        if (runtime.exists(requestParameters, 'parentId')) {
            queryParameters['ParentId'] = requestParameters['parentId'];
        }

        if (runtime.exists(requestParameters, 'query')) {
            queryParameters['Query'] = requestParameters['query'];
        }

        if (runtime.exists(requestParameters, 'sort')) {
            queryParameters['Sort'] = requestParameters['sort'];
        }

        if (runtime.exists(requestParameters, 'sortBy')) {
            queryParameters['SortBy'] = requestParameters['sortBy'];
        }

        if (runtime.exists(requestParameters, 'ids')) {
            queryParameters['Ids_'] = requestParameters['ids'];
        }

        if (runtime.exists(requestParameters, 'orderBy')) {
            queryParameters['OrderBy'] = requestParameters['orderBy'];
        }

        if (runtime.exists(requestParameters, 'page')) {
            queryParameters['Page'] = requestParameters['page'];
        }

        if (runtime.exists(requestParameters, 'limit')) {
            queryParameters['Limit'] = requestParameters['limit'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/product/categories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedListCategorySchemaFromJSON(jsonValue));
    }

    /**
     */
    async categoriesSearch(requestParameters: CategoriesSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedListCategorySchema> {
        const response = await this.categoriesSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async categoriesUpdateRaw(requestParameters: CategoriesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling categoriesUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/product/categories/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProductCategorySchemaToJSON(requestParameters['productCategorySchema']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseInfoFromJSON(jsonValue));
    }

    /**
     */
    async categoriesUpdate(requestParameters: CategoriesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo> {
        const response = await this.categoriesUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async commonGetAppStateRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AppStateEntity>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/master/common/app-state`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AppStateEntityFromJSON(jsonValue));
    }

    /**
     */
    async commonGetAppState(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AppStateEntity> {
        const response = await this.commonGetAppStateRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async commonGetCountriesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AppCountrySchema>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/master/common/countries`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AppCountrySchemaFromJSON));
    }

    /**
     */
    async commonGetCountries(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AppCountrySchema>> {
        const response = await this.commonGetCountriesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async commonGetCurrenciesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AppCurrencyOption>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/master/common/currencies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AppCurrencyOptionFromJSON));
    }

    /**
     */
    async commonGetCurrencies(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AppCurrencyOption>> {
        const response = await this.commonGetCurrenciesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async commonGetEnumsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AppEnumSchema>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/master/common/enums`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AppEnumSchemaFromJSON(jsonValue));
    }

    /**
     */
    async commonGetEnums(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AppEnumSchema> {
        const response = await this.commonGetEnumsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async groupsBulkDeleteRaw(requestParameters: GroupsBulkDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>> {
        const queryParameters: any = {};

        if (runtime.exists(requestParameters, 'ids')) {
            queryParameters['ids'] = requestParameters['ids'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/auth/groups`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseInfoFromJSON(jsonValue));
    }

    /**
     */
    async groupsBulkDelete(requestParameters: GroupsBulkDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo> {
        const response = await this.groupsBulkDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async groupsCreateRaw(requestParameters: GroupsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/auth/groups`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthGroupCreateEntityToJSON(requestParameters['authGroupCreateEntity']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseInfoFromJSON(jsonValue));
    }

    /**
     */
    async groupsCreate(requestParameters: GroupsCreateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo> {
        const response = await this.groupsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async groupsDeleteRaw(requestParameters: GroupsDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling groupsDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/auth/groups/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseInfoFromJSON(jsonValue));
    }

    /**
     */
    async groupsDelete(requestParameters: GroupsDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo> {
        const response = await this.groupsDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async groupsGetDetailRaw(requestParameters: GroupsGetDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthGroupEntity>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling groupsGetDetail().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/auth/groups/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthGroupEntityFromJSON(jsonValue));
    }

    /**
     */
    async groupsGetDetail(requestParameters: GroupsGetDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthGroupEntity> {
        const response = await this.groupsGetDetailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async groupsSearchRaw(requestParameters: GroupsSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedListGroupEntity>> {
        const queryParameters: any = {};

        if (runtime.exists(requestParameters, 'query')) {
            queryParameters['Query'] = requestParameters['query'];
        }

        if (runtime.exists(requestParameters, 'name')) {
            queryParameters['Name'] = requestParameters['name'];
        }

        if (runtime.exists(requestParameters, 'description')) {
            queryParameters['Description'] = requestParameters['description'];
        }

        if (runtime.exists(requestParameters, 'sort')) {
            queryParameters['Sort'] = requestParameters['sort'];
        }

        if (runtime.exists(requestParameters, 'sortBy')) {
            queryParameters['SortBy'] = requestParameters['sortBy'];
        }

        if (runtime.exists(requestParameters, 'ids')) {
            queryParameters['Ids_'] = requestParameters['ids'];
        }

        if (runtime.exists(requestParameters, 'orderBy')) {
            queryParameters['OrderBy'] = requestParameters['orderBy'];
        }

        if (runtime.exists(requestParameters, 'page')) {
            queryParameters['Page'] = requestParameters['page'];
        }

        if (runtime.exists(requestParameters, 'limit')) {
            queryParameters['Limit'] = requestParameters['limit'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/auth/groups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedListGroupEntityFromJSON(jsonValue));
    }

    /**
     */
    async groupsSearch(requestParameters: GroupsSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedListGroupEntity> {
        const response = await this.groupsSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async groupsUpdateRaw(requestParameters: GroupsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling groupsUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/auth/groups/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AuthGroupEntityToJSON(requestParameters['authGroupEntity']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseInfoFromJSON(jsonValue));
    }

    /**
     */
    async groupsUpdate(requestParameters: GroupsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo> {
        const response = await this.groupsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async incomingShipmentCompareRaw(requestParameters: IncomingShipmentCompareRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<StockShipmentComparisonLine>>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling incomingShipmentCompare().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/stock/incoming-shipments/{id}/compare`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(StockShipmentComparisonLineFromJSON));
    }

    /**
     */
    async incomingShipmentCompare(requestParameters: IncomingShipmentCompareRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<StockShipmentComparisonLine>> {
        const response = await this.incomingShipmentCompareRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async incomingShipmentCreateRaw(requestParameters: IncomingShipmentCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/stock/incoming-shipments`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StockIncomingShipmentCreateToJSON(requestParameters['stockIncomingShipmentCreate']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseInfoFromJSON(jsonValue));
    }

    /**
     */
    async incomingShipmentCreate(requestParameters: IncomingShipmentCreateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo> {
        const response = await this.incomingShipmentCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async incomingShipmentDeleteManyRaw(requestParameters: IncomingShipmentDeleteManyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>> {
        const queryParameters: any = {};

        if (runtime.exists(requestParameters, 'ids')) {
            queryParameters['ids'] = requestParameters['ids'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/stock/incoming-shipments`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseInfoFromJSON(jsonValue));
    }

    /**
     */
    async incomingShipmentDeleteMany(requestParameters: IncomingShipmentDeleteManyRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo> {
        const response = await this.incomingShipmentDeleteManyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async incomingShipmentDeleteOneRaw(requestParameters: IncomingShipmentDeleteOneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling incomingShipmentDeleteOne().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/stock/incoming-shipments/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseInfoFromJSON(jsonValue));
    }

    /**
     */
    async incomingShipmentDeleteOne(requestParameters: IncomingShipmentDeleteOneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo> {
        const response = await this.incomingShipmentDeleteOneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async incomingShipmentFileDeleteFileRaw(requestParameters: IncomingShipmentFileDeleteFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling incomingShipmentFileDeleteFile().'
            );
        }

        if (!runtime.exists(requestParameters, 'fileId')) {
            throw new runtime.RequiredError(
                'fileId',
                'Required parameter "fileId" was null or undefined when calling incomingShipmentFileDeleteFile().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/stock/incoming-shipments/{id}/files/{fileId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))).replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters['fileId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseInfoFromJSON(jsonValue));
    }

    /**
     */
    async incomingShipmentFileDeleteFile(requestParameters: IncomingShipmentFileDeleteFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo> {
        const response = await this.incomingShipmentFileDeleteFileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async incomingShipmentFileGetFileRaw(requestParameters: IncomingShipmentFileGetFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling incomingShipmentFileGetFile().'
            );
        }

        if (!runtime.exists(requestParameters, 'fileId')) {
            throw new runtime.RequiredError(
                'fileId',
                'Required parameter "fileId" was null or undefined when calling incomingShipmentFileGetFile().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/stock/incoming-shipments/{id}/files/{fileId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))).replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters['fileId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async incomingShipmentFileGetFile(requestParameters: IncomingShipmentFileGetFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.incomingShipmentFileGetFileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async incomingShipmentFileGetFilesRaw(requestParameters: IncomingShipmentFileGetFilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FileDTO>>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling incomingShipmentFileGetFiles().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/stock/incoming-shipments/{id}/files`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FileDTOFromJSON));
    }

    /**
     */
    async incomingShipmentFileGetFiles(requestParameters: IncomingShipmentFileGetFilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FileDTO>> {
        const response = await this.incomingShipmentFileGetFilesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async incomingShipmentFileUploadFileRaw(requestParameters: IncomingShipmentFileUploadFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling incomingShipmentFileUploadFile().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/stock/incoming-shipments/{id}/files`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseInfoFromJSON(jsonValue));
    }

    /**
     */
    async incomingShipmentFileUploadFile(requestParameters: IncomingShipmentFileUploadFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo> {
        const response = await this.incomingShipmentFileUploadFileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async incomingShipmentGetRaw(requestParameters: IncomingShipmentGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StockIncomingShipmentDetail>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling incomingShipmentGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/stock/incoming-shipments/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StockIncomingShipmentDetailFromJSON(jsonValue));
    }

    /**
     */
    async incomingShipmentGet(requestParameters: IncomingShipmentGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StockIncomingShipmentDetail> {
        const response = await this.incomingShipmentGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async incomingShipmentSearchRaw(requestParameters: IncomingShipmentSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedListIncomingShipment>> {
        const queryParameters: any = {};

        if (runtime.exists(requestParameters, 'query')) {
            queryParameters['Query'] = requestParameters['query'];
        }

        if (runtime.exists(requestParameters, 'delFlag')) {
            queryParameters['DelFlag'] = requestParameters['delFlag'];
        }

        if (runtime.exists(requestParameters, 'etaSince')) {
            queryParameters['EtaSince'] = (requestParameters['etaSince'] as any).toISOString();
        }

        if (runtime.exists(requestParameters, 'etaUntil')) {
            queryParameters['EtaUntil'] = (requestParameters['etaUntil'] as any).toISOString();
        }

        if (runtime.exists(requestParameters, 'state')) {
            queryParameters['State'] = requestParameters['state'];
        }

        if (runtime.exists(requestParameters, 'sortBy')) {
            queryParameters['SortBy'] = requestParameters['sortBy'];
        }

        if (runtime.exists(requestParameters, 'sort')) {
            queryParameters['Sort'] = requestParameters['sort'];
        }

        if (runtime.exists(requestParameters, 'ids')) {
            queryParameters['Ids'] = requestParameters['ids'];
        }

        if (runtime.exists(requestParameters, 'page')) {
            queryParameters['Page'] = requestParameters['page'];
        }

        if (runtime.exists(requestParameters, 'limit')) {
            queryParameters['Limit'] = requestParameters['limit'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/stock/incoming-shipments`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedListIncomingShipmentFromJSON(jsonValue));
    }

    /**
     */
    async incomingShipmentSearch(requestParameters: IncomingShipmentSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedListIncomingShipment> {
        const response = await this.incomingShipmentSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async incomingShipmentUpdateRaw(requestParameters: IncomingShipmentUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling incomingShipmentUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/stock/incoming-shipments/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: StockIncomingShipmentUpdateToJSON(requestParameters['stockIncomingShipmentUpdate']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseInfoFromJSON(jsonValue));
    }

    /**
     */
    async incomingShipmentUpdate(requestParameters: IncomingShipmentUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo> {
        const response = await this.incomingShipmentUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async inventoryControlAddLineRaw(requestParameters: InventoryControlAddLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling inventoryControlAddLine().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/stock/inventory-controls/{id}/lines`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StockInventoryControlLineCreateToJSON(requestParameters['stockInventoryControlLineCreate']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseInfoFromJSON(jsonValue));
    }

    /**
     */
    async inventoryControlAddLine(requestParameters: InventoryControlAddLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo> {
        const response = await this.inventoryControlAddLineRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async inventoryControlApplyRaw(requestParameters: InventoryControlApplyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling inventoryControlApply().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/stock/inventory-controls/{id}/apply`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseInfoFromJSON(jsonValue));
    }

    /**
     */
    async inventoryControlApply(requestParameters: InventoryControlApplyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo> {
        const response = await this.inventoryControlApplyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async inventoryControlCreateRaw(requestParameters: InventoryControlCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/stock/inventory-controls`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StockInventoryControlCreateToJSON(requestParameters['stockInventoryControlCreate']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseInfoFromJSON(jsonValue));
    }

    /**
     */
    async inventoryControlCreate(requestParameters: InventoryControlCreateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo> {
        const response = await this.inventoryControlCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async inventoryControlDeleteLineRaw(requestParameters: InventoryControlDeleteLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling inventoryControlDeleteLine().'
            );
        }

        if (!runtime.exists(requestParameters, 'lineId')) {
            throw new runtime.RequiredError(
                'lineId',
                'Required parameter "lineId" was null or undefined when calling inventoryControlDeleteLine().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/stock/inventory-controls/{id}/lines/{lineId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))).replace(`{${"lineId"}}`, encodeURIComponent(String(requestParameters['lineId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseInfoFromJSON(jsonValue));
    }

    /**
     */
    async inventoryControlDeleteLine(requestParameters: InventoryControlDeleteLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo> {
        const response = await this.inventoryControlDeleteLineRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async inventoryControlDeleteManyRaw(requestParameters: InventoryControlDeleteManyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>> {
        const queryParameters: any = {};

        if (runtime.exists(requestParameters, 'ids')) {
            queryParameters['ids'] = requestParameters['ids'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/stock/inventory-controls`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseInfoFromJSON(jsonValue));
    }

    /**
     */
    async inventoryControlDeleteMany(requestParameters: InventoryControlDeleteManyRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo> {
        const response = await this.inventoryControlDeleteManyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async inventoryControlDeleteOneRaw(requestParameters: InventoryControlDeleteOneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling inventoryControlDeleteOne().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/stock/inventory-controls/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseInfoFromJSON(jsonValue));
    }

    /**
     */
    async inventoryControlDeleteOne(requestParameters: InventoryControlDeleteOneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo> {
        const response = await this.inventoryControlDeleteOneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async inventoryControlGetRaw(requestParameters: InventoryControlGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StockInventoryControlDetail>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling inventoryControlGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/stock/inventory-controls/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StockInventoryControlDetailFromJSON(jsonValue));
    }

    /**
     */
    async inventoryControlGet(requestParameters: InventoryControlGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StockInventoryControlDetail> {
        const response = await this.inventoryControlGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async inventoryControlGetLinesRaw(requestParameters: InventoryControlGetLinesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<StockInventoryControlLineSchema>>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling inventoryControlGetLines().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/stock/inventory-controls/{id}/lines`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(StockInventoryControlLineSchemaFromJSON));
    }

    /**
     */
    async inventoryControlGetLines(requestParameters: InventoryControlGetLinesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<StockInventoryControlLineSchema>> {
        const response = await this.inventoryControlGetLinesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async inventoryControlSearchRaw(requestParameters: InventoryControlSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedListInventoryControl>> {
        const queryParameters: any = {};

        if (runtime.exists(requestParameters, 'query')) {
            queryParameters['Query'] = requestParameters['query'];
        }

        if (runtime.exists(requestParameters, 'delFlag')) {
            queryParameters['DelFlag'] = requestParameters['delFlag'];
        }

        if (runtime.exists(requestParameters, 'since')) {
            queryParameters['Since'] = (requestParameters['since'] as any).toISOString();
        }

        if (runtime.exists(requestParameters, 'until')) {
            queryParameters['Until'] = (requestParameters['until'] as any).toISOString();
        }

        if (runtime.exists(requestParameters, 'appliedBy')) {
            queryParameters['AppliedBy'] = requestParameters['appliedBy'];
        }

        if (runtime.exists(requestParameters, 'sortBy')) {
            queryParameters['SortBy'] = requestParameters['sortBy'];
        }

        if (runtime.exists(requestParameters, 'sort')) {
            queryParameters['Sort'] = requestParameters['sort'];
        }

        if (runtime.exists(requestParameters, 'ids')) {
            queryParameters['Ids'] = requestParameters['ids'];
        }

        if (runtime.exists(requestParameters, 'page')) {
            queryParameters['Page'] = requestParameters['page'];
        }

        if (runtime.exists(requestParameters, 'limit')) {
            queryParameters['Limit'] = requestParameters['limit'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/stock/inventory-controls`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedListInventoryControlFromJSON(jsonValue));
    }

    /**
     */
    async inventoryControlSearch(requestParameters: InventoryControlSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedListInventoryControl> {
        const response = await this.inventoryControlSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async inventoryControlSetFinshedInventoryControlRaw(requestParameters: InventoryControlSetFinshedInventoryControlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling inventoryControlSetFinshedInventoryControl().'
            );
        }

        const queryParameters: any = {};

        if (runtime.exists(requestParameters, 'isFinished')) {
            queryParameters['isFinished'] = requestParameters['isFinished'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/stock/inventory-controls/{id}/setfinished`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseInfoFromJSON(jsonValue));
    }

    /**
     */
    async inventoryControlSetFinshedInventoryControl(requestParameters: InventoryControlSetFinshedInventoryControlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo> {
        const response = await this.inventoryControlSetFinshedInventoryControlRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async inventoryControlUpdateRaw(requestParameters: InventoryControlUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling inventoryControlUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/stock/inventory-controls/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: StockInventoryControlUpdateToJSON(requestParameters['stockInventoryControlUpdate']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseInfoFromJSON(jsonValue));
    }

    /**
     */
    async inventoryControlUpdate(requestParameters: InventoryControlUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo> {
        const response = await this.inventoryControlUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async loginGetLoginRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/auth/login`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async loginGetLogin(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.loginGetLoginRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async loginLoginRaw(requestParameters: LoginLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthLoginResultDTO>> {
        const queryParameters: any = {};

        if (runtime.exists(requestParameters, 'useCookie')) {
            queryParameters['useCookie'] = requestParameters['useCookie'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/auth/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthLoginUserToJSON(requestParameters['authLoginUser']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthLoginResultDTOFromJSON(jsonValue));
    }

    /**
     */
    async loginLogin(requestParameters: LoginLoginRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthLoginResultDTO> {
        const response = await this.loginLoginRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async loginLogoutRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/auth/login/logout`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseInfoFromJSON(jsonValue));
    }

    /**
     */
    async loginLogout(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo> {
        const response = await this.loginLogoutRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async productsCopyRaw(requestParameters: ProductsCopyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling productsCopy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/product/products/{Id}/copy`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async productsCopy(requestParameters: ProductsCopyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.productsCopyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async productsCreateRaw(requestParameters: ProductsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/product/products`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProductResourceBaseSchemaToJSON(requestParameters['productResourceBaseSchema']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseInfoFromJSON(jsonValue));
    }

    /**
     */
    async productsCreate(requestParameters: ProductsCreateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo> {
        const response = await this.productsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async productsDeleteManyRaw(requestParameters: ProductsDeleteManyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>> {
        const queryParameters: any = {};

        if (runtime.exists(requestParameters, 'ids')) {
            queryParameters['ids'] = requestParameters['ids'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/product/products`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseInfoFromJSON(jsonValue));
    }

    /**
     */
    async productsDeleteMany(requestParameters: ProductsDeleteManyRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo> {
        const response = await this.productsDeleteManyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async productsDeleteOneRaw(requestParameters: ProductsDeleteOneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling productsDeleteOne().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/product/products/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseInfoFromJSON(jsonValue));
    }

    /**
     */
    async productsDeleteOne(requestParameters: ProductsDeleteOneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo> {
        const response = await this.productsDeleteOneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async productsGetRaw(requestParameters: ProductsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductDetailSchema>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling productsGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/product/products/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDetailSchemaFromJSON(jsonValue));
    }

    /**
     */
    async productsGet(requestParameters: ProductsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductDetailSchema> {
        const response = await this.productsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async productsSearchRaw(requestParameters: ProductsSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedListResourceDetailSchema>> {
        const queryParameters: any = {};

        if (runtime.exists(requestParameters, 'query')) {
            queryParameters['Query'] = requestParameters['query'];
        }

        if (runtime.exists(requestParameters, 'categoryIds')) {
            queryParameters['CategoryIds'] = requestParameters['categoryIds'];
        }

        if (runtime.exists(requestParameters, 'currencyId')) {
            queryParameters['CurrencyId'] = requestParameters['currencyId'];
        }

        if (runtime.exists(requestParameters, 'quantityTypeId')) {
            queryParameters['QuantityTypeId'] = requestParameters['quantityTypeId'];
        }

        if (runtime.exists(requestParameters, 'stockTracingCode')) {
            queryParameters['StockTracingCode'] = requestParameters['stockTracingCode'];
        }

        if (runtime.exists(requestParameters, 'isActive')) {
            queryParameters['isActive'] = requestParameters['isActive'];
        }

        if (runtime.exists(requestParameters, 'sortBy')) {
            queryParameters['SortBy'] = requestParameters['sortBy'];
        }

        if (runtime.exists(requestParameters, 'sort')) {
            queryParameters['Sort'] = requestParameters['sort'];
        }

        if (runtime.exists(requestParameters, 'ids')) {
            queryParameters['Ids'] = requestParameters['ids'];
        }

        if (runtime.exists(requestParameters, 'page')) {
            queryParameters['Page'] = requestParameters['page'];
        }

        if (runtime.exists(requestParameters, 'limit')) {
            queryParameters['Limit'] = requestParameters['limit'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/product/products`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedListResourceDetailSchemaFromJSON(jsonValue));
    }

    /**
     */
    async productsSearch(requestParameters: ProductsSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedListResourceDetailSchema> {
        const response = await this.productsSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async productsUpdateRaw(requestParameters: ProductsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling productsUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/product/products/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProductUpdateSchemaToJSON(requestParameters['productUpdateSchema']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseInfoFromJSON(jsonValue));
    }

    /**
     */
    async productsUpdate(requestParameters: ProductsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo> {
        const response = await this.productsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async profileChangePasswordRaw(requestParameters: ProfileChangePasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling profileChangePassword().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/auth/profile/{id}/password`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AuthUpdatePasswordSchemaToJSON(requestParameters['authUpdatePasswordSchema']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseInfoFromJSON(jsonValue));
    }

    /**
     */
    async profileChangePassword(requestParameters: ProfileChangePasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo> {
        const response = await this.profileChangePasswordRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async profileGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthUserEntity>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/auth/profile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthUserEntityFromJSON(jsonValue));
    }

    /**
     */
    async profileGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthUserEntity> {
        const response = await this.profileGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async profileUpdateRaw(requestParameters: ProfileUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling profileUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/auth/profile/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AuthUserUpdateEntityToJSON(requestParameters['authUserUpdateEntity']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseInfoFromJSON(jsonValue));
    }

    /**
     */
    async profileUpdate(requestParameters: ProfileUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo> {
        const response = await this.profileUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async purchaseOrderBulkCreateRaw(requestParameters: PurchaseOrderBulkCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<number>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/purchasing/purchase-order/bulk-create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PurchasingCreateFromBalanceRequestToJSON(requestParameters['purchasingCreateFromBalanceRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async purchaseOrderBulkCreate(requestParameters: PurchaseOrderBulkCreateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<number>> {
        const response = await this.purchaseOrderBulkCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async purchaseOrderCreateRaw(requestParameters: PurchaseOrderCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/purchasing/purchase-order`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PurchasingPurchaseOrderCreateToJSON(requestParameters['purchasingPurchaseOrderCreate']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseInfoFromJSON(jsonValue));
    }

    /**
     */
    async purchaseOrderCreate(requestParameters: PurchaseOrderCreateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo> {
        const response = await this.purchaseOrderCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async purchaseOrderDeleteManyRaw(requestParameters: PurchaseOrderDeleteManyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>> {
        const queryParameters: any = {};

        if (runtime.exists(requestParameters, 'ids')) {
            queryParameters['ids'] = requestParameters['ids'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/purchasing/purchase-order`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseInfoFromJSON(jsonValue));
    }

    /**
     */
    async purchaseOrderDeleteMany(requestParameters: PurchaseOrderDeleteManyRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo> {
        const response = await this.purchaseOrderDeleteManyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async purchaseOrderDeleteOneRaw(requestParameters: PurchaseOrderDeleteOneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling purchaseOrderDeleteOne().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/purchasing/purchase-order/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseInfoFromJSON(jsonValue));
    }

    /**
     */
    async purchaseOrderDeleteOne(requestParameters: PurchaseOrderDeleteOneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo> {
        const response = await this.purchaseOrderDeleteOneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async purchaseOrderExportExcelRaw(requestParameters: PurchaseOrderExportExcelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        if (runtime.exists(requestParameters, 'ids')) {
            queryParameters['ids'] = requestParameters['ids'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/purchasing/purchase-order/excel-export`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async purchaseOrderExportExcel(requestParameters: PurchaseOrderExportExcelRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.purchaseOrderExportExcelRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async purchaseOrderExportPdfRaw(requestParameters: PurchaseOrderExportPdfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling purchaseOrderExportPdf().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/purchasing/purchase-order/pdf-export/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async purchaseOrderExportPdf(requestParameters: PurchaseOrderExportPdfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.purchaseOrderExportPdfRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async purchaseOrderExportPdfsRaw(requestParameters: PurchaseOrderExportPdfsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        if (runtime.exists(requestParameters, 'ids')) {
            queryParameters['ids'] = requestParameters['ids'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/purchasing/purchase-order/pdf-export`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async purchaseOrderExportPdfs(requestParameters: PurchaseOrderExportPdfsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.purchaseOrderExportPdfsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async purchaseOrderFileDeleteFileRaw(requestParameters: PurchaseOrderFileDeleteFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling purchaseOrderFileDeleteFile().'
            );
        }

        if (!runtime.exists(requestParameters, 'fileId')) {
            throw new runtime.RequiredError(
                'fileId',
                'Required parameter "fileId" was null or undefined when calling purchaseOrderFileDeleteFile().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/purchasing/purchase-order/{id}/files/{fileId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))).replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters['fileId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseInfoFromJSON(jsonValue));
    }

    /**
     */
    async purchaseOrderFileDeleteFile(requestParameters: PurchaseOrderFileDeleteFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo> {
        const response = await this.purchaseOrderFileDeleteFileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async purchaseOrderFileGetFileRaw(requestParameters: PurchaseOrderFileGetFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling purchaseOrderFileGetFile().'
            );
        }

        if (!runtime.exists(requestParameters, 'fileId')) {
            throw new runtime.RequiredError(
                'fileId',
                'Required parameter "fileId" was null or undefined when calling purchaseOrderFileGetFile().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/purchasing/purchase-order/{id}/files/{fileId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))).replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters['fileId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async purchaseOrderFileGetFile(requestParameters: PurchaseOrderFileGetFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.purchaseOrderFileGetFileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async purchaseOrderFileGetFilesRaw(requestParameters: PurchaseOrderFileGetFilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FileDTO>>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling purchaseOrderFileGetFiles().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/purchasing/purchase-order/{id}/files`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FileDTOFromJSON));
    }

    /**
     */
    async purchaseOrderFileGetFiles(requestParameters: PurchaseOrderFileGetFilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FileDTO>> {
        const response = await this.purchaseOrderFileGetFilesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async purchaseOrderFileUploadFileRaw(requestParameters: PurchaseOrderFileUploadFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling purchaseOrderFileUploadFile().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/purchasing/purchase-order/{id}/files`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseInfoFromJSON(jsonValue));
    }

    /**
     */
    async purchaseOrderFileUploadFile(requestParameters: PurchaseOrderFileUploadFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo> {
        const response = await this.purchaseOrderFileUploadFileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async purchaseOrderGetRaw(requestParameters: PurchaseOrderGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PurchasingPurchaseOrderDetail>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling purchaseOrderGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/purchasing/purchase-order/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PurchasingPurchaseOrderDetailFromJSON(jsonValue));
    }

    /**
     */
    async purchaseOrderGet(requestParameters: PurchaseOrderGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PurchasingPurchaseOrderDetail> {
        const response = await this.purchaseOrderGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async purchaseOrderGetLinesRaw(requestParameters: PurchaseOrderGetLinesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedListPurchaseOrderLineSolo>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling purchaseOrderGetLines().'
            );
        }

        const queryParameters: any = {};

        if (runtime.exists(requestParameters, 'page')) {
            queryParameters['Page'] = requestParameters['page'];
        }

        if (runtime.exists(requestParameters, 'limit')) {
            queryParameters['Limit'] = requestParameters['limit'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/purchasing/purchase-order/lines/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedListPurchaseOrderLineSoloFromJSON(jsonValue));
    }

    /**
     */
    async purchaseOrderGetLines(requestParameters: PurchaseOrderGetLinesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedListPurchaseOrderLineSolo> {
        const response = await this.purchaseOrderGetLinesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async purchaseOrderSearchRaw(requestParameters: PurchaseOrderSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedListPurchaseOrder>> {
        const queryParameters: any = {};

        if (runtime.exists(requestParameters, 'query')) {
            queryParameters['Query'] = requestParameters['query'];
        }

        if (runtime.exists(requestParameters, 'confirmed')) {
            queryParameters['Confirmed'] = requestParameters['confirmed'];
        }

        if (runtime.exists(requestParameters, 'businessPartnerId')) {
            queryParameters['BusinessPartnerId'] = requestParameters['businessPartnerId'];
        }

        if (runtime.exists(requestParameters, 'sortBy')) {
            queryParameters['SortBy'] = requestParameters['sortBy'];
        }

        if (runtime.exists(requestParameters, 'sort')) {
            queryParameters['Sort'] = requestParameters['sort'];
        }

        if (runtime.exists(requestParameters, 'ids')) {
            queryParameters['Ids'] = requestParameters['ids'];
        }

        if (runtime.exists(requestParameters, 'page')) {
            queryParameters['Page'] = requestParameters['page'];
        }

        if (runtime.exists(requestParameters, 'limit')) {
            queryParameters['Limit'] = requestParameters['limit'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/purchasing/purchase-order`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedListPurchaseOrderFromJSON(jsonValue));
    }

    /**
     */
    async purchaseOrderSearch(requestParameters: PurchaseOrderSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedListPurchaseOrder> {
        const response = await this.purchaseOrderSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async purchaseOrderUpdateRaw(requestParameters: PurchaseOrderUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling purchaseOrderUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/purchasing/purchase-order/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PurchasingPurchaseOrderEditToJSON(requestParameters['purchasingPurchaseOrderEdit']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseInfoFromJSON(jsonValue));
    }

    /**
     */
    async purchaseOrderUpdate(requestParameters: PurchaseOrderUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo> {
        const response = await this.purchaseOrderUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async rawMaterialsCreateRaw(requestParameters: RawMaterialsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/product/raw-materials`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProductRawMaterialCreateEntityToJSON(requestParameters['productRawMaterialCreateEntity']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseInfoFromJSON(jsonValue));
    }

    /**
     */
    async rawMaterialsCreate(requestParameters: RawMaterialsCreateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo> {
        const response = await this.rawMaterialsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async rawMaterialsDeleteManyRaw(requestParameters: RawMaterialsDeleteManyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>> {
        const queryParameters: any = {};

        if (runtime.exists(requestParameters, 'ids')) {
            queryParameters['ids'] = requestParameters['ids'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/product/raw-materials`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseInfoFromJSON(jsonValue));
    }

    /**
     */
    async rawMaterialsDeleteMany(requestParameters: RawMaterialsDeleteManyRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo> {
        const response = await this.rawMaterialsDeleteManyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async rawMaterialsDeleteOneRaw(requestParameters: RawMaterialsDeleteOneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling rawMaterialsDeleteOne().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/product/raw-materials/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseInfoFromJSON(jsonValue));
    }

    /**
     */
    async rawMaterialsDeleteOne(requestParameters: RawMaterialsDeleteOneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo> {
        const response = await this.rawMaterialsDeleteOneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async rawMaterialsGetRaw(requestParameters: RawMaterialsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductRawMaterialDetailEntity>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling rawMaterialsGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/product/raw-materials/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductRawMaterialDetailEntityFromJSON(jsonValue));
    }

    /**
     */
    async rawMaterialsGet(requestParameters: RawMaterialsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductRawMaterialDetailEntity> {
        const response = await this.rawMaterialsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async rawMaterialsSearchRaw(requestParameters: RawMaterialsSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedListRawMaterialEntity>> {
        const queryParameters: any = {};

        if (runtime.exists(requestParameters, 'businessPartnerId')) {
            queryParameters['BusinessPartnerId'] = requestParameters['businessPartnerId'];
        }

        if (runtime.exists(requestParameters, 'categoryIds')) {
            queryParameters['CategoryIds'] = requestParameters['categoryIds'];
        }

        if (runtime.exists(requestParameters, 'currencyId')) {
            queryParameters['CurrencyId'] = requestParameters['currencyId'];
        }

        if (runtime.exists(requestParameters, 'quantityTypeId')) {
            queryParameters['QuantityTypeId'] = requestParameters['quantityTypeId'];
        }

        if (runtime.exists(requestParameters, 'stockTracingCode')) {
            queryParameters['StockTracingCode'] = requestParameters['stockTracingCode'];
        }

        if (runtime.exists(requestParameters, 'query')) {
            queryParameters['Query'] = requestParameters['query'];
        }

        if (runtime.exists(requestParameters, 'isActive')) {
            queryParameters['IsActive'] = requestParameters['isActive'];
        }

        if (runtime.exists(requestParameters, 'supplierArticleName')) {
            queryParameters['SupplierArticleName'] = requestParameters['supplierArticleName'];
        }

        if (runtime.exists(requestParameters, 'supplierArticleNo')) {
            queryParameters['SupplierArticleNo'] = requestParameters['supplierArticleNo'];
        }

        if (runtime.exists(requestParameters, 'sortBy')) {
            queryParameters['SortBy'] = requestParameters['sortBy'];
        }

        if (runtime.exists(requestParameters, 'sort')) {
            queryParameters['Sort'] = requestParameters['sort'];
        }

        if (runtime.exists(requestParameters, 'ids')) {
            queryParameters['Ids'] = requestParameters['ids'];
        }

        if (runtime.exists(requestParameters, 'page')) {
            queryParameters['Page'] = requestParameters['page'];
        }

        if (runtime.exists(requestParameters, 'limit')) {
            queryParameters['Limit'] = requestParameters['limit'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/product/raw-materials`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedListRawMaterialEntityFromJSON(jsonValue));
    }

    /**
     */
    async rawMaterialsSearch(requestParameters: RawMaterialsSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedListRawMaterialEntity> {
        const response = await this.rawMaterialsSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async rawMaterialsUpdateRaw(requestParameters: RawMaterialsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling rawMaterialsUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/product/raw-materials/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProductRawMaterialUpdateToJSON(requestParameters['productRawMaterialUpdate']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseInfoFromJSON(jsonValue));
    }

    /**
     */
    async rawMaterialsUpdate(requestParameters: RawMaterialsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo> {
        const response = await this.rawMaterialsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async receiveShipmentAddLineRaw(requestParameters: ReceiveShipmentAddLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StockMoveLineEntity>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling receiveShipmentAddLine().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/stock/incoming-shipments/{id}/receive`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StockReceiveShipmentLineToJSON(requestParameters['stockReceiveShipmentLine']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StockMoveLineEntityFromJSON(jsonValue));
    }

    /**
     */
    async receiveShipmentAddLine(requestParameters: ReceiveShipmentAddLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StockMoveLineEntity> {
        const response = await this.receiveShipmentAddLineRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async receiveShipmentApplyRaw(requestParameters: ReceiveShipmentApplyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling receiveShipmentApply().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/stock/incoming-shipments/{id}/receive/apply`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseInfoFromJSON(jsonValue));
    }

    /**
     */
    async receiveShipmentApply(requestParameters: ReceiveShipmentApplyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo> {
        const response = await this.receiveShipmentApplyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async receiveShipmentDeleteLineRaw(requestParameters: ReceiveShipmentDeleteLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling receiveShipmentDeleteLine().'
            );
        }

        if (!runtime.exists(requestParameters, 'lineId')) {
            throw new runtime.RequiredError(
                'lineId',
                'Required parameter "lineId" was null or undefined when calling receiveShipmentDeleteLine().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/stock/incoming-shipments/{id}/receive/{lineId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))).replace(`{${"lineId"}}`, encodeURIComponent(String(requestParameters['lineId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseInfoFromJSON(jsonValue));
    }

    /**
     */
    async receiveShipmentDeleteLine(requestParameters: ReceiveShipmentDeleteLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo> {
        const response = await this.receiveShipmentDeleteLineRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async receiveShipmentGetStockMoveRaw(requestParameters: ReceiveShipmentGetStockMoveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StockMoveEntity>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling receiveShipmentGetStockMove().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/stock/incoming-shipments/{id}/receive`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StockMoveEntityFromJSON(jsonValue));
    }

    /**
     */
    async receiveShipmentGetStockMove(requestParameters: ReceiveShipmentGetStockMoveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StockMoveEntity> {
        const response = await this.receiveShipmentGetStockMoveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async resourceStockExcelExportRaw(requestParameters: ResourceStockExcelExportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        if (runtime.exists(requestParameters, 'categoryIds')) {
            queryParameters['CategoryIds'] = requestParameters['categoryIds'];
        }

        if (runtime.exists(requestParameters, 'currencyId')) {
            queryParameters['CurrencyId'] = requestParameters['currencyId'];
        }

        if (runtime.exists(requestParameters, 'quantityTypeId')) {
            queryParameters['QuantityTypeId'] = requestParameters['quantityTypeId'];
        }

        if (runtime.exists(requestParameters, 'articleNos')) {
            queryParameters['ArticleNos'] = requestParameters['articleNos'];
        }

        if (runtime.exists(requestParameters, 'stockTracingCode')) {
            queryParameters['StockTracingCode'] = requestParameters['stockTracingCode'];
        }

        if (runtime.exists(requestParameters, 'query')) {
            queryParameters['Query'] = requestParameters['query'];
        }

        if (runtime.exists(requestParameters, 'isActive')) {
            queryParameters['IsActive'] = requestParameters['isActive'];
        }

        if (runtime.exists(requestParameters, 'type')) {
            queryParameters['Type'] = requestParameters['type'];
        }

        if (runtime.exists(requestParameters, 'sortBy')) {
            queryParameters['SortBy'] = requestParameters['sortBy'];
        }

        if (runtime.exists(requestParameters, 'sort')) {
            queryParameters['Sort'] = requestParameters['sort'];
        }

        if (runtime.exists(requestParameters, 'ids')) {
            queryParameters['Ids'] = requestParameters['ids'];
        }

        if (runtime.exists(requestParameters, 'stockLocations')) {
            queryParameters['stockLocations'] = requestParameters['stockLocations'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/stock/stock/export`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async resourceStockExcelExport(requestParameters: ResourceStockExcelExportRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.resourceStockExcelExportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async resourceStockGetDetailRaw(requestParameters: ResourceStockGetDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StockLinesResourceDetail>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling resourceStockGetDetail().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/stock/stock/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StockLinesResourceDetailFromJSON(jsonValue));
    }

    /**
     */
    async resourceStockGetDetail(requestParameters: ResourceStockGetDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StockLinesResourceDetail> {
        const response = await this.resourceStockGetDetailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async resourceStockGetLineRaw(requestParameters: ResourceStockGetLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StockLinesLineDetail>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling resourceStockGetLine().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/stock/stock/lines/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StockLinesLineDetailFromJSON(jsonValue));
    }

    /**
     */
    async resourceStockGetLine(requestParameters: ResourceStockGetLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StockLinesLineDetail> {
        const response = await this.resourceStockGetLineRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async resourceStockGetLinesRaw(requestParameters: ResourceStockGetLinesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<StockLinesLine>>> {
        if (!runtime.exists(requestParameters, 'resourceId')) {
            throw new runtime.RequiredError(
                'resourceId',
                'Required parameter "resourceId" was null or undefined when calling resourceStockGetLines().'
            );
        }

        const queryParameters: any = {};

        if (runtime.exists(requestParameters, 'stockLocationId')) {
            queryParameters['stockLocationId'] = requestParameters['stockLocationId'];
        }

        if (runtime.exists(requestParameters, 'filterEmpty')) {
            queryParameters['filterEmpty'] = requestParameters['filterEmpty'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/stock/stock/lines/resource/{resourceId}`.replace(`{${"resourceId"}}`, encodeURIComponent(String(requestParameters['resourceId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(StockLinesLineFromJSON));
    }

    /**
     */
    async resourceStockGetLines(requestParameters: ResourceStockGetLinesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<StockLinesLine>> {
        const response = await this.resourceStockGetLinesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async resourceStockLotsRaw(requestParameters: ResourceStockLotsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<StockLotSchema>>> {
        if (!runtime.exists(requestParameters, 'resourceId')) {
            throw new runtime.RequiredError(
                'resourceId',
                'Required parameter "resourceId" was null or undefined when calling resourceStockLots().'
            );
        }

        const queryParameters: any = {};

        if (runtime.exists(requestParameters, 'stockLocationId')) {
            queryParameters['stockLocationId'] = requestParameters['stockLocationId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/stock/stock/lots/{resourceId}`.replace(`{${"resourceId"}}`, encodeURIComponent(String(requestParameters['resourceId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(StockLotSchemaFromJSON));
    }

    /**
     */
    async resourceStockLots(requestParameters: ResourceStockLotsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<StockLotSchema>> {
        const response = await this.resourceStockLotsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async resourceStockSearchRaw(requestParameters: ResourceStockSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedListResourceStock>> {
        const queryParameters: any = {};

        if (runtime.exists(requestParameters, 'categoryIds')) {
            queryParameters['CategoryIds'] = requestParameters['categoryIds'];
        }

        if (runtime.exists(requestParameters, 'currencyId')) {
            queryParameters['CurrencyId'] = requestParameters['currencyId'];
        }

        if (runtime.exists(requestParameters, 'quantityTypeId')) {
            queryParameters['QuantityTypeId'] = requestParameters['quantityTypeId'];
        }

        if (runtime.exists(requestParameters, 'articleNos')) {
            queryParameters['ArticleNos'] = requestParameters['articleNos'];
        }

        if (runtime.exists(requestParameters, 'stockTracingCode')) {
            queryParameters['StockTracingCode'] = requestParameters['stockTracingCode'];
        }

        if (runtime.exists(requestParameters, 'query')) {
            queryParameters['Query'] = requestParameters['query'];
        }

        if (runtime.exists(requestParameters, 'isActive')) {
            queryParameters['IsActive'] = requestParameters['isActive'];
        }

        if (runtime.exists(requestParameters, 'type')) {
            queryParameters['Type'] = requestParameters['type'];
        }

        if (runtime.exists(requestParameters, 'sortBy')) {
            queryParameters['SortBy'] = requestParameters['sortBy'];
        }

        if (runtime.exists(requestParameters, 'sort')) {
            queryParameters['Sort'] = requestParameters['sort'];
        }

        if (runtime.exists(requestParameters, 'ids')) {
            queryParameters['Ids'] = requestParameters['ids'];
        }

        if (runtime.exists(requestParameters, 'page')) {
            queryParameters['Page'] = requestParameters['page'];
        }

        if (runtime.exists(requestParameters, 'limit')) {
            queryParameters['Limit'] = requestParameters['limit'];
        }

        if (runtime.exists(requestParameters, 'stockLocations')) {
            queryParameters['stockLocations'] = requestParameters['stockLocations'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/stock/stock`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedListResourceStockFromJSON(jsonValue));
    }

    /**
     */
    async resourceStockSearch(requestParameters: ResourceStockSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedListResourceStock> {
        const response = await this.resourceStockSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async resourcesGetDetailRaw(requestParameters: ResourcesGetDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductResourceDetailSchema>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling resourcesGetDetail().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/product/resources/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductResourceDetailSchemaFromJSON(jsonValue));
    }

    /**
     */
    async resourcesGetDetail(requestParameters: ResourcesGetDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductResourceDetailSchema> {
        const response = await this.resourcesGetDetailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async resourcesSearchRaw(requestParameters: ResourcesSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedListResourceDetailSchema>> {
        const queryParameters: any = {};

        if (runtime.exists(requestParameters, 'categoryIds')) {
            queryParameters['CategoryIds'] = requestParameters['categoryIds'];
        }

        if (runtime.exists(requestParameters, 'currencyId')) {
            queryParameters['CurrencyId'] = requestParameters['currencyId'];
        }

        if (runtime.exists(requestParameters, 'quantityTypeId')) {
            queryParameters['QuantityTypeId'] = requestParameters['quantityTypeId'];
        }

        if (runtime.exists(requestParameters, 'articleNos')) {
            queryParameters['ArticleNos'] = requestParameters['articleNos'];
        }

        if (runtime.exists(requestParameters, 'stockTracingCode')) {
            queryParameters['StockTracingCode'] = requestParameters['stockTracingCode'];
        }

        if (runtime.exists(requestParameters, 'query')) {
            queryParameters['Query'] = requestParameters['query'];
        }

        if (runtime.exists(requestParameters, 'isActive')) {
            queryParameters['IsActive'] = requestParameters['isActive'];
        }

        if (runtime.exists(requestParameters, 'type')) {
            queryParameters['Type'] = requestParameters['type'];
        }

        if (runtime.exists(requestParameters, 'sortBy')) {
            queryParameters['SortBy'] = requestParameters['sortBy'];
        }

        if (runtime.exists(requestParameters, 'sort')) {
            queryParameters['Sort'] = requestParameters['sort'];
        }

        if (runtime.exists(requestParameters, 'ids')) {
            queryParameters['Ids'] = requestParameters['ids'];
        }

        if (runtime.exists(requestParameters, 'page')) {
            queryParameters['Page'] = requestParameters['page'];
        }

        if (runtime.exists(requestParameters, 'limit')) {
            queryParameters['Limit'] = requestParameters['limit'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/product/resources`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedListResourceDetailSchemaFromJSON(jsonValue));
    }

    /**
     */
    async resourcesSearch(requestParameters: ResourcesSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedListResourceDetailSchema> {
        const response = await this.resourcesSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async stockLineGetStockLineRaw(requestParameters: StockLineGetStockLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StockLinesLineDetail>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling stockLineGetStockLine().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/stock/stocklines/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StockLinesLineDetailFromJSON(jsonValue));
    }

    /**
     */
    async stockLineGetStockLine(requestParameters: StockLineGetStockLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StockLinesLineDetail> {
        const response = await this.stockLineGetStockLineRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async stockLineSearchRaw(requestParameters: StockLineSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedListStockLineDetail>> {
        const queryParameters: any = {};

        if (runtime.exists(requestParameters, 'sortBy')) {
            queryParameters['SortBy'] = requestParameters['sortBy'];
        }

        if (runtime.exists(requestParameters, 'sort')) {
            queryParameters['Sort'] = requestParameters['sort'];
        }

        if (runtime.exists(requestParameters, 'ids')) {
            queryParameters['Ids'] = requestParameters['ids'];
        }

        if (runtime.exists(requestParameters, 'page')) {
            queryParameters['Page'] = requestParameters['page'];
        }

        if (runtime.exists(requestParameters, 'limit')) {
            queryParameters['Limit'] = requestParameters['limit'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/stock/stocklines`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedListStockLineDetailFromJSON(jsonValue));
    }

    /**
     */
    async stockLineSearch(requestParameters: StockLineSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedListStockLineDetail> {
        const response = await this.stockLineSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async stockLocationGetDetailShelfRaw(requestParameters: StockLocationGetDetailShelfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StockShelf>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling stockLocationGetDetailShelf().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/stock/stocklocations/shelf/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StockShelfFromJSON(jsonValue));
    }

    /**
     */
    async stockLocationGetDetailShelf(requestParameters: StockLocationGetDetailShelfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StockShelf> {
        const response = await this.stockLocationGetDetailShelfRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async stockLocationGetListShelfRaw(requestParameters: StockLocationGetListShelfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedListShelf>> {
        const queryParameters: any = {};

        if (runtime.exists(requestParameters, 'page')) {
            queryParameters['Page'] = requestParameters['page'];
        }

        if (runtime.exists(requestParameters, 'limit')) {
            queryParameters['Limit'] = requestParameters['limit'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/stock/stocklocations/shelf`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedListShelfFromJSON(jsonValue));
    }

    /**
     */
    async stockLocationGetListShelf(requestParameters: StockLocationGetListShelfRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedListShelf> {
        const response = await this.stockLocationGetListShelfRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async stockLocationSearchRaw(requestParameters: StockLocationSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedListStockLocationSchema>> {
        const queryParameters: any = {};

        if (runtime.exists(requestParameters, 'page')) {
            queryParameters['Page'] = requestParameters['page'];
        }

        if (runtime.exists(requestParameters, 'limit')) {
            queryParameters['Limit'] = requestParameters['limit'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/stock/stocklocations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedListStockLocationSchemaFromJSON(jsonValue));
    }

    /**
     */
    async stockLocationSearch(requestParameters: StockLocationSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedListStockLocationSchema> {
        const response = await this.stockLocationSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async stockMovesAddLineRaw(requestParameters: StockMovesAddLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StockMoveAddLineResult>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling stockMovesAddLine().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/stock/stock-moves/line/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StockCreateLineDTOToJSON(requestParameters['stockCreateLineDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StockMoveAddLineResultFromJSON(jsonValue));
    }

    /**
     */
    async stockMovesAddLine(requestParameters: StockMovesAddLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StockMoveAddLineResult> {
        const response = await this.stockMovesAddLineRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async stockMovesApplyStockMoveRaw(requestParameters: StockMovesApplyStockMoveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling stockMovesApplyStockMove().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/stock/stock-moves/apply/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseInfoFromJSON(jsonValue));
    }

    /**
     */
    async stockMovesApplyStockMove(requestParameters: StockMovesApplyStockMoveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo> {
        const response = await this.stockMovesApplyStockMoveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async stockMovesBulkDeleteRaw(requestParameters: StockMovesBulkDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>> {
        const queryParameters: any = {};

        if (runtime.exists(requestParameters, 'ids')) {
            queryParameters['ids'] = requestParameters['ids'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/stock/stock-moves`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseInfoFromJSON(jsonValue));
    }

    /**
     */
    async stockMovesBulkDelete(requestParameters: StockMovesBulkDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo> {
        const response = await this.stockMovesBulkDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async stockMovesBulkPdfExportRaw(requestParameters: StockMovesBulkPdfExportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        if (runtime.exists(requestParameters, 'ids')) {
            queryParameters['ids'] = requestParameters['ids'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/stock/stock-moves/bulk-pdf-export`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async stockMovesBulkPdfExport(requestParameters: StockMovesBulkPdfExportRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.stockMovesBulkPdfExportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async stockMovesCreateRaw(requestParameters: StockMovesCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/stock/stock-moves`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StockMoveCreateEntityToJSON(requestParameters['stockMoveCreateEntity']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseInfoFromJSON(jsonValue));
    }

    /**
     */
    async stockMovesCreate(requestParameters: StockMovesCreateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo> {
        const response = await this.stockMovesCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async stockMovesDeleteRaw(requestParameters: StockMovesDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling stockMovesDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/stock/stock-moves/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseInfoFromJSON(jsonValue));
    }

    /**
     */
    async stockMovesDelete(requestParameters: StockMovesDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo> {
        const response = await this.stockMovesDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async stockMovesDeleteLineRaw(requestParameters: StockMovesDeleteLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling stockMovesDeleteLine().'
            );
        }

        const queryParameters: any = {};

        if (runtime.exists(requestParameters, 'ids')) {
            queryParameters['ids'] = requestParameters['ids'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/stock/stock-moves/line/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseInfoFromJSON(jsonValue));
    }

    /**
     */
    async stockMovesDeleteLine(requestParameters: StockMovesDeleteLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo> {
        const response = await this.stockMovesDeleteLineRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async stockMovesExcelExportRaw(requestParameters: StockMovesExcelExportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        if (runtime.exists(requestParameters, 'plannedSince')) {
            queryParameters['PlannedSince'] = (requestParameters['plannedSince'] as any).toISOString();
        }

        if (runtime.exists(requestParameters, 'plannedUntil')) {
            queryParameters['PlannedUntil'] = (requestParameters['plannedUntil'] as any).toISOString();
        }

        if (runtime.exists(requestParameters, 'appliedSince')) {
            queryParameters['AppliedSince'] = (requestParameters['appliedSince'] as any).toISOString();
        }

        if (runtime.exists(requestParameters, 'appliedUntil')) {
            queryParameters['AppliedUntil'] = (requestParameters['appliedUntil'] as any).toISOString();
        }

        if (runtime.exists(requestParameters, 'sourceStockLocation')) {
            queryParameters['SourceStockLocation'] = requestParameters['sourceStockLocation'];
        }

        if (runtime.exists(requestParameters, 'destinationStockLocation')) {
            queryParameters['DestinationStockLocation'] = requestParameters['destinationStockLocation'];
        }

        if (runtime.exists(requestParameters, 'createdBy')) {
            queryParameters['CreatedBy'] = requestParameters['createdBy'];
        }

        if (runtime.exists(requestParameters, 'appliedBy')) {
            queryParameters['AppliedBy'] = requestParameters['appliedBy'];
        }

        if (runtime.exists(requestParameters, 'moveType')) {
            queryParameters['MoveType'] = requestParameters['moveType'];
        }

        if (runtime.exists(requestParameters, 'sortBy')) {
            queryParameters['SortBy'] = requestParameters['sortBy'];
        }

        if (runtime.exists(requestParameters, 'sort')) {
            queryParameters['Sort'] = requestParameters['sort'];
        }

        if (runtime.exists(requestParameters, 'ids')) {
            queryParameters['Ids'] = requestParameters['ids'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/stock/stock-moves/excel-export`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async stockMovesExcelExport(requestParameters: StockMovesExcelExportRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.stockMovesExcelExportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async stockMovesGetDetailRaw(requestParameters: StockMovesGetDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StockMoveDetail>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling stockMovesGetDetail().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/stock/stock-moves/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StockMoveDetailFromJSON(jsonValue));
    }

    /**
     */
    async stockMovesGetDetail(requestParameters: StockMovesGetDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StockMoveDetail> {
        const response = await this.stockMovesGetDetailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async stockMovesGetListStockMoveLineRaw(requestParameters: StockMovesGetListStockMoveLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedListStockMoveLineEntity>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling stockMovesGetListStockMoveLine().'
            );
        }

        const queryParameters: any = {};

        if (runtime.exists(requestParameters, 'page')) {
            queryParameters['Page'] = requestParameters['page'];
        }

        if (runtime.exists(requestParameters, 'limit')) {
            queryParameters['Limit'] = requestParameters['limit'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/stock/stock-moves/line/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedListStockMoveLineEntityFromJSON(jsonValue));
    }

    /**
     */
    async stockMovesGetListStockMoveLine(requestParameters: StockMovesGetListStockMoveLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedListStockMoveLineEntity> {
        const response = await this.stockMovesGetListStockMoveLineRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async stockMovesPdfExportRaw(requestParameters: StockMovesPdfExportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling stockMovesPdfExport().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/stock/stock-moves/pdf-export/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async stockMovesPdfExport(requestParameters: StockMovesPdfExportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.stockMovesPdfExportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async stockMovesSearchRaw(requestParameters: StockMovesSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedListStockMoveEntity>> {
        const queryParameters: any = {};

        if (runtime.exists(requestParameters, 'plannedSince')) {
            queryParameters['PlannedSince'] = (requestParameters['plannedSince'] as any).toISOString();
        }

        if (runtime.exists(requestParameters, 'plannedUntil')) {
            queryParameters['PlannedUntil'] = (requestParameters['plannedUntil'] as any).toISOString();
        }

        if (runtime.exists(requestParameters, 'appliedSince')) {
            queryParameters['AppliedSince'] = (requestParameters['appliedSince'] as any).toISOString();
        }

        if (runtime.exists(requestParameters, 'appliedUntil')) {
            queryParameters['AppliedUntil'] = (requestParameters['appliedUntil'] as any).toISOString();
        }

        if (runtime.exists(requestParameters, 'sourceStockLocation')) {
            queryParameters['SourceStockLocation'] = requestParameters['sourceStockLocation'];
        }

        if (runtime.exists(requestParameters, 'destinationStockLocation')) {
            queryParameters['DestinationStockLocation'] = requestParameters['destinationStockLocation'];
        }

        if (runtime.exists(requestParameters, 'createdBy')) {
            queryParameters['CreatedBy'] = requestParameters['createdBy'];
        }

        if (runtime.exists(requestParameters, 'appliedBy')) {
            queryParameters['AppliedBy'] = requestParameters['appliedBy'];
        }

        if (runtime.exists(requestParameters, 'moveType')) {
            queryParameters['MoveType'] = requestParameters['moveType'];
        }

        if (runtime.exists(requestParameters, 'sortBy')) {
            queryParameters['SortBy'] = requestParameters['sortBy'];
        }

        if (runtime.exists(requestParameters, 'sort')) {
            queryParameters['Sort'] = requestParameters['sort'];
        }

        if (runtime.exists(requestParameters, 'ids')) {
            queryParameters['Ids'] = requestParameters['ids'];
        }

        if (runtime.exists(requestParameters, 'page')) {
            queryParameters['Page'] = requestParameters['page'];
        }

        if (runtime.exists(requestParameters, 'limit')) {
            queryParameters['Limit'] = requestParameters['limit'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/stock/stock-moves`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedListStockMoveEntityFromJSON(jsonValue));
    }

    /**
     */
    async stockMovesSearch(requestParameters: StockMovesSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedListStockMoveEntity> {
        const response = await this.stockMovesSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async stockMovesUpdateRaw(requestParameters: StockMovesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling stockMovesUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/stock/stock-moves/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: StockMoveUpdateEntityToJSON(requestParameters['stockMoveUpdateEntity']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseInfoFromJSON(jsonValue));
    }

    /**
     */
    async stockMovesUpdate(requestParameters: StockMovesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo> {
        const response = await this.stockMovesUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async stockSplitCreateRaw(requestParameters: StockSplitCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StockMoveEntity>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/stock/stock-split`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StockSplitRequestToJSON(requestParameters['stockSplitRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StockMoveEntityFromJSON(jsonValue));
    }

    /**
     */
    async stockSplitCreate(requestParameters: StockSplitCreateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StockMoveEntity> {
        const response = await this.stockSplitCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async stockSplitGetDetailRaw(requestParameters: StockSplitGetDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StockMoveEntity>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling stockSplitGetDetail().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/stock/stock-split/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StockMoveEntityFromJSON(jsonValue));
    }

    /**
     */
    async stockSplitGetDetail(requestParameters: StockSplitGetDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StockMoveEntity> {
        const response = await this.stockSplitGetDetailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async stockSplitSearchRaw(requestParameters: StockSplitSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedListStockSplitEntity>> {
        const queryParameters: any = {};

        if (runtime.exists(requestParameters, 'plannedSince')) {
            queryParameters['PlannedSince'] = (requestParameters['plannedSince'] as any).toISOString();
        }

        if (runtime.exists(requestParameters, 'plannedUntil')) {
            queryParameters['PlannedUntil'] = (requestParameters['plannedUntil'] as any).toISOString();
        }

        if (runtime.exists(requestParameters, 'appliedSince')) {
            queryParameters['AppliedSince'] = (requestParameters['appliedSince'] as any).toISOString();
        }

        if (runtime.exists(requestParameters, 'appliedUntil')) {
            queryParameters['AppliedUntil'] = (requestParameters['appliedUntil'] as any).toISOString();
        }

        if (runtime.exists(requestParameters, 'sourceStockLocation')) {
            queryParameters['SourceStockLocation'] = requestParameters['sourceStockLocation'];
        }

        if (runtime.exists(requestParameters, 'destinationStockLocation')) {
            queryParameters['DestinationStockLocation'] = requestParameters['destinationStockLocation'];
        }

        if (runtime.exists(requestParameters, 'createdBy')) {
            queryParameters['CreatedBy'] = requestParameters['createdBy'];
        }

        if (runtime.exists(requestParameters, 'appliedBy')) {
            queryParameters['AppliedBy'] = requestParameters['appliedBy'];
        }

        if (runtime.exists(requestParameters, 'moveType')) {
            queryParameters['MoveType'] = requestParameters['moveType'];
        }

        if (runtime.exists(requestParameters, 'sortBy')) {
            queryParameters['SortBy'] = requestParameters['sortBy'];
        }

        if (runtime.exists(requestParameters, 'sort')) {
            queryParameters['Sort'] = requestParameters['sort'];
        }

        if (runtime.exists(requestParameters, 'ids')) {
            queryParameters['Ids'] = requestParameters['ids'];
        }

        if (runtime.exists(requestParameters, 'page')) {
            queryParameters['Page'] = requestParameters['page'];
        }

        if (runtime.exists(requestParameters, 'limit')) {
            queryParameters['Limit'] = requestParameters['limit'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/stock/stock-split`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedListStockSplitEntityFromJSON(jsonValue));
    }

    /**
     */
    async stockSplitSearch(requestParameters: StockSplitSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedListStockSplitEntity> {
        const response = await this.stockSplitSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async usersBulkDeleteRaw(requestParameters: UsersBulkDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>> {
        const queryParameters: any = {};

        if (runtime.exists(requestParameters, 'ids')) {
            queryParameters['ids'] = requestParameters['ids'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/auth/users`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseInfoFromJSON(jsonValue));
    }

    /**
     */
    async usersBulkDelete(requestParameters: UsersBulkDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo> {
        const response = await this.usersBulkDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async usersCreateRaw(requestParameters: UsersCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/auth/users`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthCreateUserToJSON(requestParameters['authCreateUser']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseInfoFromJSON(jsonValue));
    }

    /**
     */
    async usersCreate(requestParameters: UsersCreateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo> {
        const response = await this.usersCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async usersDeleteRaw(requestParameters: UsersDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling usersDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/auth/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseInfoFromJSON(jsonValue));
    }

    /**
     */
    async usersDelete(requestParameters: UsersDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo> {
        const response = await this.usersDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async usersGetDetailRaw(requestParameters: UsersGetDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthUserDetailEntity>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling usersGetDetail().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/auth/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthUserDetailEntityFromJSON(jsonValue));
    }

    /**
     */
    async usersGetDetail(requestParameters: UsersGetDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthUserDetailEntity> {
        const response = await this.usersGetDetailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async usersLockAccountRaw(requestParameters: UsersLockAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling usersLockAccount().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/auth/users/{id}/lock`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseInfoFromJSON(jsonValue));
    }

    /**
     */
    async usersLockAccount(requestParameters: UsersLockAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo> {
        const response = await this.usersLockAccountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async usersResetPasswordRaw(requestParameters: UsersResetPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling usersResetPassword().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/auth/users/{id}/reset-password`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AuthResetPasswordSchemaToJSON(requestParameters['authResetPasswordSchema']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseInfoFromJSON(jsonValue));
    }

    /**
     */
    async usersResetPassword(requestParameters: UsersResetPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo> {
        const response = await this.usersResetPasswordRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async usersSearchRaw(requestParameters: UsersSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedListUserEntity>> {
        const queryParameters: any = {};

        if (runtime.exists(requestParameters, 'query')) {
            queryParameters['Query'] = requestParameters['query'];
        }

        if (runtime.exists(requestParameters, 'group')) {
            queryParameters['Group'] = requestParameters['group'];
        }

        if (runtime.exists(requestParameters, 'phone')) {
            queryParameters['Phone'] = requestParameters['phone'];
        }

        if (runtime.exists(requestParameters, 'email')) {
            queryParameters['Email'] = requestParameters['email'];
        }

        if (runtime.exists(requestParameters, 'sortBy')) {
            queryParameters['SortBy'] = requestParameters['sortBy'];
        }

        if (runtime.exists(requestParameters, 'sort')) {
            queryParameters['Sort'] = requestParameters['sort'];
        }

        if (runtime.exists(requestParameters, 'ids')) {
            queryParameters['Ids'] = requestParameters['ids'];
        }

        if (runtime.exists(requestParameters, 'page')) {
            queryParameters['Page'] = requestParameters['page'];
        }

        if (runtime.exists(requestParameters, 'limit')) {
            queryParameters['Limit'] = requestParameters['limit'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/auth/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedListUserEntityFromJSON(jsonValue));
    }

    /**
     */
    async usersSearch(requestParameters: UsersSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedListUserEntity> {
        const response = await this.usersSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async usersUnLockAccountRaw(requestParameters: UsersUnLockAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling usersUnLockAccount().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/auth/users/{id}/unlock`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseInfoFromJSON(jsonValue));
    }

    /**
     */
    async usersUnLockAccount(requestParameters: UsersUnLockAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo> {
        const response = await this.usersUnLockAccountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async usersUpdateRaw(requestParameters: UsersUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseInfo>> {
        if (!runtime.exists(requestParameters, 'id')) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling usersUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/1.0/auth/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AuthUserDetailEntityToJSON(requestParameters['authUserDetailEntity']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseInfoFromJSON(jsonValue));
    }

    /**
     */
    async usersUpdate(requestParameters: UsersUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseInfo> {
        const response = await this.usersUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const BusinessPartnersSearchSortByEnum = {
    Id: 'Id',
    Name: 'Name',
    Phone: 'Phone',
    TaxId: 'TaxId',
    ZipCode: 'ZipCode',
    City: 'City',
    State: 'State',
    Country: 'Country'
} as const;
export type BusinessPartnersSearchSortByEnum = typeof BusinessPartnersSearchSortByEnum[keyof typeof BusinessPartnersSearchSortByEnum];
/**
 * @export
 */
export const BusinessPartnersSearchSortEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type BusinessPartnersSearchSortEnum = typeof BusinessPartnersSearchSortEnum[keyof typeof BusinessPartnersSearchSortEnum];
/**
 * @export
 */
export const CategoriesSearchSortEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type CategoriesSearchSortEnum = typeof CategoriesSearchSortEnum[keyof typeof CategoriesSearchSortEnum];
/**
 * @export
 */
export const GroupsSearchSortEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type GroupsSearchSortEnum = typeof GroupsSearchSortEnum[keyof typeof GroupsSearchSortEnum];
/**
 * @export
 */
export const IncomingShipmentSearchStateEnum = {
    WaitingForDeparture: 'waiting_for_departure',
    DuringShipment: 'during_shipment',
    Processing: 'processing',
    Done: 'done',
    Error: 'error'
} as const;
export type IncomingShipmentSearchStateEnum = typeof IncomingShipmentSearchStateEnum[keyof typeof IncomingShipmentSearchStateEnum];
/**
 * @export
 */
export const IncomingShipmentSearchSortByEnum = {
    Id: 'Id',
    ActualTimeofDeparture: 'ActualTimeofDeparture',
    EstimatedTimeofArrival: 'EstimatedTimeofArrival',
    TransportType: 'TransportType',
    Supplier: 'Supplier',
    State: 'State',
    ShipmentName: 'ShipmentName',
    Comment: 'Comment'
} as const;
export type IncomingShipmentSearchSortByEnum = typeof IncomingShipmentSearchSortByEnum[keyof typeof IncomingShipmentSearchSortByEnum];
/**
 * @export
 */
export const IncomingShipmentSearchSortEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type IncomingShipmentSearchSortEnum = typeof IncomingShipmentSearchSortEnum[keyof typeof IncomingShipmentSearchSortEnum];
/**
 * @export
 */
export const InventoryControlSearchSortByEnum = {
    Id: 'Id',
    Name: 'Name',
    PlannedDatetime: 'PlannedDatetime',
    FinishedDatetime: 'FinishedDatetime'
} as const;
export type InventoryControlSearchSortByEnum = typeof InventoryControlSearchSortByEnum[keyof typeof InventoryControlSearchSortByEnum];
/**
 * @export
 */
export const InventoryControlSearchSortEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type InventoryControlSearchSortEnum = typeof InventoryControlSearchSortEnum[keyof typeof InventoryControlSearchSortEnum];
/**
 * @export
 */
export const ProductsSearchStockTracingCodeEnum = {
    None: 'None',
    Lot: 'Lot',
    Serial: 'Serial'
} as const;
export type ProductsSearchStockTracingCodeEnum = typeof ProductsSearchStockTracingCodeEnum[keyof typeof ProductsSearchStockTracingCodeEnum];
/**
 * @export
 */
export const ProductsSearchSortByEnum = {
    Id: 'Id',
    Name: 'Name',
    Price: 'Price',
    CurrencyId: 'CurrencyId',
    IsActive: 'isActive'
} as const;
export type ProductsSearchSortByEnum = typeof ProductsSearchSortByEnum[keyof typeof ProductsSearchSortByEnum];
/**
 * @export
 */
export const ProductsSearchSortEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type ProductsSearchSortEnum = typeof ProductsSearchSortEnum[keyof typeof ProductsSearchSortEnum];
/**
 * @export
 */
export const PurchaseOrderSearchSortByEnum = {
    ConfirmationDate: 'ConfirmationDate',
    OrderSequence: 'OrderSequence',
    BusinessPartner: 'BusinessPartner',
    Comment: 'Comment',
    PlannedDate: 'PlannedDate',
    CreatedAt: 'CreatedAt',
    Id: 'Id'
} as const;
export type PurchaseOrderSearchSortByEnum = typeof PurchaseOrderSearchSortByEnum[keyof typeof PurchaseOrderSearchSortByEnum];
/**
 * @export
 */
export const PurchaseOrderSearchSortEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type PurchaseOrderSearchSortEnum = typeof PurchaseOrderSearchSortEnum[keyof typeof PurchaseOrderSearchSortEnum];
/**
 * @export
 */
export const RawMaterialsSearchStockTracingCodeEnum = {
    None: 'None',
    Lot: 'Lot',
    Serial: 'Serial'
} as const;
export type RawMaterialsSearchStockTracingCodeEnum = typeof RawMaterialsSearchStockTracingCodeEnum[keyof typeof RawMaterialsSearchStockTracingCodeEnum];
/**
 * @export
 */
export const RawMaterialsSearchSortByEnum = {
    Id: 'Id',
    Name: 'Name',
    ArticleNo: 'ArticleNo',
    BusinessPartnerName: 'businessPartnerName',
    LeadTime: 'LeadTime',
    Moq: 'Moq',
    StockTracing: 'StockTracing',
    IsActive: 'isActive'
} as const;
export type RawMaterialsSearchSortByEnum = typeof RawMaterialsSearchSortByEnum[keyof typeof RawMaterialsSearchSortByEnum];
/**
 * @export
 */
export const RawMaterialsSearchSortEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type RawMaterialsSearchSortEnum = typeof RawMaterialsSearchSortEnum[keyof typeof RawMaterialsSearchSortEnum];
/**
 * @export
 */
export const ResourceStockExcelExportStockTracingCodeEnum = {
    None: 'None',
    Lot: 'Lot',
    Serial: 'Serial'
} as const;
export type ResourceStockExcelExportStockTracingCodeEnum = typeof ResourceStockExcelExportStockTracingCodeEnum[keyof typeof ResourceStockExcelExportStockTracingCodeEnum];
/**
 * @export
 */
export const ResourceStockExcelExportTypeEnum = {
    RawMaterial: 'RawMaterial',
    Product: 'Product'
} as const;
export type ResourceStockExcelExportTypeEnum = typeof ResourceStockExcelExportTypeEnum[keyof typeof ResourceStockExcelExportTypeEnum];
/**
 * @export
 */
export const ResourceStockExcelExportSortByEnum = {
    Id: 'Id',
    ArticleNo: 'ArticleNo',
    Name: 'Name',
    Amount: 'Amount'
} as const;
export type ResourceStockExcelExportSortByEnum = typeof ResourceStockExcelExportSortByEnum[keyof typeof ResourceStockExcelExportSortByEnum];
/**
 * @export
 */
export const ResourceStockExcelExportSortEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type ResourceStockExcelExportSortEnum = typeof ResourceStockExcelExportSortEnum[keyof typeof ResourceStockExcelExportSortEnum];
/**
 * @export
 */
export const ResourceStockSearchStockTracingCodeEnum = {
    None: 'None',
    Lot: 'Lot',
    Serial: 'Serial'
} as const;
export type ResourceStockSearchStockTracingCodeEnum = typeof ResourceStockSearchStockTracingCodeEnum[keyof typeof ResourceStockSearchStockTracingCodeEnum];
/**
 * @export
 */
export const ResourceStockSearchTypeEnum = {
    RawMaterial: 'RawMaterial',
    Product: 'Product'
} as const;
export type ResourceStockSearchTypeEnum = typeof ResourceStockSearchTypeEnum[keyof typeof ResourceStockSearchTypeEnum];
/**
 * @export
 */
export const ResourceStockSearchSortByEnum = {
    Id: 'Id',
    ArticleNo: 'ArticleNo',
    Name: 'Name',
    Amount: 'Amount'
} as const;
export type ResourceStockSearchSortByEnum = typeof ResourceStockSearchSortByEnum[keyof typeof ResourceStockSearchSortByEnum];
/**
 * @export
 */
export const ResourceStockSearchSortEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type ResourceStockSearchSortEnum = typeof ResourceStockSearchSortEnum[keyof typeof ResourceStockSearchSortEnum];
/**
 * @export
 */
export const ResourcesSearchStockTracingCodeEnum = {
    None: 'None',
    Lot: 'Lot',
    Serial: 'Serial'
} as const;
export type ResourcesSearchStockTracingCodeEnum = typeof ResourcesSearchStockTracingCodeEnum[keyof typeof ResourcesSearchStockTracingCodeEnum];
/**
 * @export
 */
export const ResourcesSearchTypeEnum = {
    RawMaterial: 'RawMaterial',
    Product: 'Product'
} as const;
export type ResourcesSearchTypeEnum = typeof ResourcesSearchTypeEnum[keyof typeof ResourcesSearchTypeEnum];
/**
 * @export
 */
export const ResourcesSearchSortByEnum = {
    Id: 'Id',
    Name: 'Name',
    ArticleNo: 'ArticleNo',
    BusinessPartnerName: 'businessPartnerName',
    LeadTime: 'LeadTime',
    Moq: 'Moq',
    StockTracing: 'StockTracing',
    IsActive: 'isActive'
} as const;
export type ResourcesSearchSortByEnum = typeof ResourcesSearchSortByEnum[keyof typeof ResourcesSearchSortByEnum];
/**
 * @export
 */
export const ResourcesSearchSortEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type ResourcesSearchSortEnum = typeof ResourcesSearchSortEnum[keyof typeof ResourcesSearchSortEnum];
/**
 * @export
 */
export const StockLineSearchSortByEnum = {
    Id: 'Id'
} as const;
export type StockLineSearchSortByEnum = typeof StockLineSearchSortByEnum[keyof typeof StockLineSearchSortByEnum];
/**
 * @export
 */
export const StockLineSearchSortEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type StockLineSearchSortEnum = typeof StockLineSearchSortEnum[keyof typeof StockLineSearchSortEnum];
/**
 * @export
 */
export const StockMovesExcelExportMoveTypeEnum = {
    Manual: 'Manual',
    Incoming: 'Incoming',
    StockSplit: 'StockSplit',
    InventoryControl: 'InventoryControl'
} as const;
export type StockMovesExcelExportMoveTypeEnum = typeof StockMovesExcelExportMoveTypeEnum[keyof typeof StockMovesExcelExportMoveTypeEnum];
/**
 * @export
 */
export const StockMovesExcelExportSortByEnum = {
    Id: 'Id',
    AppliedDate: 'AppliedDate',
    PlannedDate: 'PlannedDate',
    MoveType: 'MoveType'
} as const;
export type StockMovesExcelExportSortByEnum = typeof StockMovesExcelExportSortByEnum[keyof typeof StockMovesExcelExportSortByEnum];
/**
 * @export
 */
export const StockMovesExcelExportSortEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type StockMovesExcelExportSortEnum = typeof StockMovesExcelExportSortEnum[keyof typeof StockMovesExcelExportSortEnum];
/**
 * @export
 */
export const StockMovesSearchMoveTypeEnum = {
    Manual: 'Manual',
    Incoming: 'Incoming',
    StockSplit: 'StockSplit',
    InventoryControl: 'InventoryControl'
} as const;
export type StockMovesSearchMoveTypeEnum = typeof StockMovesSearchMoveTypeEnum[keyof typeof StockMovesSearchMoveTypeEnum];
/**
 * @export
 */
export const StockMovesSearchSortByEnum = {
    Id: 'Id',
    AppliedDate: 'AppliedDate',
    PlannedDate: 'PlannedDate',
    MoveType: 'MoveType'
} as const;
export type StockMovesSearchSortByEnum = typeof StockMovesSearchSortByEnum[keyof typeof StockMovesSearchSortByEnum];
/**
 * @export
 */
export const StockMovesSearchSortEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type StockMovesSearchSortEnum = typeof StockMovesSearchSortEnum[keyof typeof StockMovesSearchSortEnum];
/**
 * @export
 */
export const StockSplitSearchMoveTypeEnum = {
    Manual: 'Manual',
    Incoming: 'Incoming',
    StockSplit: 'StockSplit',
    InventoryControl: 'InventoryControl'
} as const;
export type StockSplitSearchMoveTypeEnum = typeof StockSplitSearchMoveTypeEnum[keyof typeof StockSplitSearchMoveTypeEnum];
/**
 * @export
 */
export const StockSplitSearchSortByEnum = {
    Id: 'Id',
    AppliedDate: 'AppliedDate',
    PlannedDate: 'PlannedDate',
    MoveType: 'MoveType'
} as const;
export type StockSplitSearchSortByEnum = typeof StockSplitSearchSortByEnum[keyof typeof StockSplitSearchSortByEnum];
/**
 * @export
 */
export const StockSplitSearchSortEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type StockSplitSearchSortEnum = typeof StockSplitSearchSortEnum[keyof typeof StockSplitSearchSortEnum];
/**
 * @export
 */
export const UsersSearchSortByEnum = {
    Id: 'Id',
    Username: 'Username',
    Email: 'Email',
    LastLogin: 'LastLogin',
    LastLogout: 'LastLogout',
    IsActive: 'IsActive',
    IsVerifyEmail: 'IsVerifyEmail'
} as const;
export type UsersSearchSortByEnum = typeof UsersSearchSortByEnum[keyof typeof UsersSearchSortByEnum];
/**
 * @export
 */
export const UsersSearchSortEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type UsersSearchSortEnum = typeof UsersSearchSortEnum[keyof typeof UsersSearchSortEnum];
