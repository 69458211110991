/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: developer.contact@outfiz.vn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StockSplitEntity
 */
export interface StockSplitEntity {
    /**
     * 
     * @type {number}
     * @memberof StockSplitEntity
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof StockSplitEntity
     */
    articleNo?: string;
    /**
     * 
     * @type {string}
     * @memberof StockSplitEntity
     */
    articleName?: string;
    /**
     * 
     * @type {string}
     * @memberof StockSplitEntity
     */
    batchNo?: string;
    /**
     * 
     * @type {number}
     * @memberof StockSplitEntity
     */
    totalAmount: number;
    /**
     * 
     * @type {number}
     * @memberof StockSplitEntity
     */
    packageCount: number;
    /**
     * 
     * @type {number}
     * @memberof StockSplitEntity
     */
    sourceStockLocationId: number;
    /**
     * 
     * @type {string}
     * @memberof StockSplitEntity
     */
    comment?: string;
    /**
     * 
     * @type {Date}
     * @memberof StockSplitEntity
     */
    appliedDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof StockSplitEntity
     */
    applier?: string;
}

/**
 * Check if a given object implements the StockSplitEntity interface.
 */
export function instanceOfStockSplitEntity(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('totalAmount' in value)) return false;
    if (!('packageCount' in value)) return false;
    if (!('sourceStockLocationId' in value)) return false;
    return true;
}

export function StockSplitEntityFromJSON(json: any): StockSplitEntity {
    return StockSplitEntityFromJSONTyped(json, false);
}

export function StockSplitEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockSplitEntity {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'articleNo': !exists(json, 'articleNo') ? undefined : json['articleNo'],
        'articleName': !exists(json, 'articleName') ? undefined : json['articleName'],
        'batchNo': !exists(json, 'batchNo') ? undefined : json['batchNo'],
        'totalAmount': json['totalAmount'],
        'packageCount': json['packageCount'],
        'sourceStockLocationId': json['sourceStockLocationId'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'appliedDate': !exists(json, 'appliedDate') ? undefined : (new Date(json['appliedDate'])),
        'applier': !exists(json, 'applier') ? undefined : json['applier'],
    };
}

export function StockSplitEntityToJSON(value?: StockSplitEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value['id'],
        'articleNo': value['articleNo'],
        'articleName': value['articleName'],
        'batchNo': value['batchNo'],
        'totalAmount': value['totalAmount'],
        'packageCount': value['packageCount'],
        'sourceStockLocationId': value['sourceStockLocationId'],
        'comment': value['comment'],
        'appliedDate': !exists(value, 'appliedDate') ? undefined : ((value['appliedDate'] as any).toISOString()),
        'applier': value['applier'],
    };
}

