import * as React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  ArrayField,
  Datagrid,
  // useResourceContext,
  useRecordContext,
} from 'react-admin';
import { Button, Grid } from '@mui/material';
import ShowActions from 'components/layout/actions/show';
import ShowTitle from 'components/commons/showTitle';
import ShowLabel from 'components/commons/showLabel';
import ShowField from 'components/commons/showField';
import Source from 'utils/source';
import { StockLinesLine, StockLinesResourceDetail } from 'api/models';
// import exportStockLineQrCode from '../stockLine/qrCode';
import PrintIcon from '@mui/icons-material/Print';
import exportStockLineQrCode from '../stockLine/qrCode';

interface PrintButtonProps {
  // eslint-disable-next-line react/no-unused-prop-types
  resourceName: string;
  // eslint-disable-next-line react/no-unused-prop-types
  articleNo: string;
  // eslint-disable-next-line react/no-unused-prop-types
  quantityTypeName: string;

}

function PrintButton(props: PrintButtonProps) {
  const record = useRecordContext<StockLinesLine>();

  return (
    <Button
      startIcon={<PrintIcon />}
      onClick={() => exportStockLineQrCode([{
        ...record,
        ...props,
      }])}
    />
  );
  /*
  */
}

function StockLineTable() {
  const record = useRecordContext<StockLinesResourceDetail>();
  const line = Source<StockLinesLine>();
  return (
    <ArrayField label="resources.stock/stock-moves.fields.stockMoveLines" source="lines">
      <Datagrid bulkActionButtons={false}>
        <TextField {...line('stockLocation.name')} />
        <TextField {...line('lotNo')} />
        <TextField {...line('amount')} />
        <PrintButton
          resourceName={record.name}
          articleNo={record.articleNo || ''}
          quantityTypeName={record.quantityTypeName}
        />
      </Datagrid>
    </ArrayField>
  );
}

export default function () {
  const s = Source<StockLinesResourceDetail>();
  return (
    <Show actions={<ShowActions />}>
      <SimpleShowLayout>
        <Grid container rowSpacing={1} spacing={1}>
          <Grid item xs={12} md={12}>
            <ShowTitle />
          </Grid>

          <Grid item xs={2} md={1.5}>
            <ShowLabel label="resources.product/raw-materials.fields.articleNo" />
          </Grid>
          <Grid item xs={8} md={9}>
            <ShowField {...s('articleNo')} className="highLightField" />
          </Grid>
          <Grid item xs={2} md={1.5} />

          <Grid item xs={2} md={1.5}>
            <ShowLabel label="resources.product/raw-materials.fields.name" />
          </Grid>
          <Grid item xs={8} md={9}>
            <ShowField {...s('name')} className="highLightField" />
          </Grid>
          <Grid item xs={2} md={1.5} />

          <Grid item md={2} />
          <Grid item xs={8} md={8}>
            <StockLineTable />
          </Grid>
          <Grid item md={2} />
        </Grid>
      </SimpleShowLayout>
    </Show>
  );
}
