/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: developer.contact@outfiz.vn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PurchasingPurchaseOrderList
 */
export interface PurchasingPurchaseOrderList {
    /**
     * 
     * @type {number}
     * @memberof PurchasingPurchaseOrderList
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof PurchasingPurchaseOrderList
     */
    orderSequence?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchasingPurchaseOrderList
     */
    businessPartner: string;
    /**
     * 
     * @type {number}
     * @memberof PurchasingPurchaseOrderList
     */
    businessPartnerId: number;
    /**
     * 
     * @type {Date}
     * @memberof PurchasingPurchaseOrderList
     */
    plannedDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof PurchasingPurchaseOrderList
     */
    confirmationDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PurchasingPurchaseOrderList
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof PurchasingPurchaseOrderList
     */
    comment?: string;
}

/**
 * Check if a given object implements the PurchasingPurchaseOrderList interface.
 */
export function instanceOfPurchasingPurchaseOrderList(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('businessPartner' in value)) return false;
    if (!('businessPartnerId' in value)) return false;
    if (!('plannedDate' in value)) return false;
    if (!('createdAt' in value)) return false;
    return true;
}

export function PurchasingPurchaseOrderListFromJSON(json: any): PurchasingPurchaseOrderList {
    return PurchasingPurchaseOrderListFromJSONTyped(json, false);
}

export function PurchasingPurchaseOrderListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PurchasingPurchaseOrderList {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'orderSequence': !exists(json, 'orderSequence') ? undefined : json['orderSequence'],
        'businessPartner': json['businessPartner'],
        'businessPartnerId': json['businessPartnerId'],
        'plannedDate': (new Date(json['plannedDate'])),
        'confirmationDate': !exists(json, 'confirmationDate') ? undefined : (new Date(json['confirmationDate'])),
        'createdAt': (new Date(json['createdAt'])),
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
    };
}

export function PurchasingPurchaseOrderListToJSON(value?: PurchasingPurchaseOrderList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value['id'],
        'orderSequence': value['orderSequence'],
        'businessPartner': value['businessPartner'],
        'businessPartnerId': value['businessPartnerId'],
        'plannedDate': ((value['plannedDate']).toISOString()),
        'confirmationDate': !exists(value, 'confirmationDate') ? undefined : ((value['confirmationDate'] as any).toISOString()),
        'createdAt': ((value['createdAt']).toISOString()),
        'comment': value['comment'],
    };
}

