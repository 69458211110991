/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: developer.contact@outfiz.vn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductCategorySchema
 */
export interface ProductCategorySchema {
    /**
     * 
     * @type {Date}
     * @memberof ProductCategorySchema
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof ProductCategorySchema
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof ProductCategorySchema
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ProductCategorySchema
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ProductCategorySchema
     */
    color?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductCategorySchema
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductCategorySchema
     */
    parentId?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductCategorySchema
     */
    readonly fullName: string;
    /**
     * 
     * @type {number}
     * @memberof ProductCategorySchema
     */
    readonly position: number;
}

/**
 * Check if a given object implements the ProductCategorySchema interface.
 */
export function instanceOfProductCategorySchema(value: object): boolean {
    if (!('createdAt' in value)) return false;
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    if (!('fullName' in value)) return false;
    if (!('position' in value)) return false;
    return true;
}

export function ProductCategorySchemaFromJSON(json: any): ProductCategorySchema {
    return ProductCategorySchemaFromJSONTyped(json, false);
}

export function ProductCategorySchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductCategorySchema {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'id': json['id'],
        'name': json['name'],
        'color': !exists(json, 'color') ? undefined : json['color'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
        'fullName': json['fullName'],
        'position': json['position'],
    };
}

export function ProductCategorySchemaToJSON(value?: ProductCategorySchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdAt': ((value['createdAt']).toISOString()),
        'updatedAt': !exists(value, 'updatedAt') ? undefined : ((value['updatedAt'] as any).toISOString()),
        'id': value['id'],
        'name': value['name'],
        'color': value['color'],
        'description': value['description'],
        'parentId': value['parentId'],
    };
}

