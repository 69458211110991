/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: developer.contact@outfiz.vn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PurchasingPurchaseOrderCreate
 */
export interface PurchasingPurchaseOrderCreate {
    /**
     * 
     * @type {number}
     * @memberof PurchasingPurchaseOrderCreate
     */
    businessPartnerId: number;
    /**
     * 
     * @type {Date}
     * @memberof PurchasingPurchaseOrderCreate
     */
    plannedDate: Date;
    /**
     * 
     * @type {string}
     * @memberof PurchasingPurchaseOrderCreate
     */
    prefix: string;
    /**
     * 
     * @type {string}
     * @memberof PurchasingPurchaseOrderCreate
     */
    comment?: string;
}

/**
 * Check if a given object implements the PurchasingPurchaseOrderCreate interface.
 */
export function instanceOfPurchasingPurchaseOrderCreate(value: object): boolean {
    if (!('businessPartnerId' in value)) return false;
    if (!('plannedDate' in value)) return false;
    if (!('prefix' in value)) return false;
    return true;
}

export function PurchasingPurchaseOrderCreateFromJSON(json: any): PurchasingPurchaseOrderCreate {
    return PurchasingPurchaseOrderCreateFromJSONTyped(json, false);
}

export function PurchasingPurchaseOrderCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): PurchasingPurchaseOrderCreate {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        
        'businessPartnerId': json['businessPartnerId'],
        'plannedDate': (new Date(json['plannedDate'])),
        'prefix': json['prefix'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
    };
}

export function PurchasingPurchaseOrderCreateToJSON(value?: PurchasingPurchaseOrderCreate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'businessPartnerId': value['businessPartnerId'],
        'plannedDate': ((value['plannedDate']).toISOString()),
        'prefix': value['prefix'],
        'comment': value['comment'],
    };
}

