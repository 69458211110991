const messages = {
  resources: {
    'stock/stocklines': {
      name: 'Hàng tồn kho |||| Hàng tồn kho',
      fields: {
        name: 'Hàng tồn kho',
        id: 'Id',
        resourceName: 'Tên hàng',
        resourceId: 'Tên hàng',
        amount: 'Số lượng',
        lotNo: 'Số lô',
        isDelete: 'Đã xoá',
        delFlag: 'Đã xoá',
        categoryId: 'Loại sản phẩm',
      },
    },
  },
  mai: {
    stockLine: {
      selectStockLine: 'StockLine',
    },
  },
};

export default messages;
