import { SelectArrayInput } from 'react-admin';
import { useStockLocations } from 'repositories/stock/stockLocations';
import React from 'react';
import EnumSelectField from 'components/input/enumSelectField';
import enumToOptions from 'utils/enumToOptions';
import { ResourceStockSearchTypeEnum, ResourceStockSearchRequest } from 'api';
import Source from 'utils/source';
import filters from '../../product/rawMaterials/filters';

interface FilterProps {
  label: string;
  source: string;
  alwaysOn?: boolean;
}

function StockLocationChooser(props: FilterProps) {
  const { data } = useStockLocations({ limit: 9000 });

  const stockLocations = React.useMemo(() => data?.list.map((x) => ({
    id: x.id,
    name: x.name,
  })), [data]);

  return (
    <SelectArrayInput
      placeholder="Quantity Types"
      choices={stockLocations}
      {...props}
    />
  );
}

const resourceTypeEnum = enumToOptions(ResourceStockSearchTypeEnum);
const s = Source<ResourceStockSearchRequest>();

export default [
  ...filters,
  <StockLocationChooser label="StockLocations" {...s('stockLocations')} alwaysOn />,
  <EnumSelectField {...s('type')} choices={resourceTypeEnum} />,
];
