/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: developer.contact@outfiz.vn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductResourceDetailSchema
 */
export interface ProductResourceDetailSchema {
    /**
     * 
     * @type {string}
     * @memberof ProductResourceDetailSchema
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ProductResourceDetailSchema
     */
    articleNo?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductResourceDetailSchema
     */
    isActive: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductResourceDetailSchema
     */
    isUniqueLotNo: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductResourceDetailSchema
     */
    currencyId: string;
    /**
     * 
     * @type {number}
     * @memberof ProductResourceDetailSchema
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof ProductResourceDetailSchema
     */
    stockTracing: ProductResourceDetailSchemaStockTracingEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductResourceDetailSchema
     */
    quantityTypeId: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductResourceDetailSchema
     */
    categories: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof ProductResourceDetailSchema
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ProductResourceDetailSchema
     */
    currencyName: string;
    /**
     * 
     * @type {string}
     * @memberof ProductResourceDetailSchema
     */
    currencySymbol: string;
    /**
     * 
     * @type {string}
     * @memberof ProductResourceDetailSchema
     */
    description: string;
}


/**
 * @export
 */
export const ProductResourceDetailSchemaStockTracingEnum = {
    None: 'None',
    Lot: 'Lot',
    Serial: 'Serial'
} as const;
export type ProductResourceDetailSchemaStockTracingEnum = typeof ProductResourceDetailSchemaStockTracingEnum[keyof typeof ProductResourceDetailSchemaStockTracingEnum];


/**
 * Check if a given object implements the ProductResourceDetailSchema interface.
 */
export function instanceOfProductResourceDetailSchema(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('isActive' in value)) return false;
    if (!('isUniqueLotNo' in value)) return false;
    if (!('currencyId' in value)) return false;
    if (!('price' in value)) return false;
    if (!('stockTracing' in value)) return false;
    if (!('quantityTypeId' in value)) return false;
    if (!('categories' in value)) return false;
    if (!('id' in value)) return false;
    if (!('currencyName' in value)) return false;
    if (!('currencySymbol' in value)) return false;
    if (!('description' in value)) return false;
    return true;
}

export function ProductResourceDetailSchemaFromJSON(json: any): ProductResourceDetailSchema {
    return ProductResourceDetailSchemaFromJSONTyped(json, false);
}

export function ProductResourceDetailSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductResourceDetailSchema {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'articleNo': !exists(json, 'articleNo') ? undefined : json['articleNo'],
        'isActive': json['isActive'],
        'isUniqueLotNo': json['isUniqueLotNo'],
        'currencyId': json['currencyId'],
        'price': json['price'],
        'stockTracing': json['stockTracing'],
        'quantityTypeId': json['quantityTypeId'],
        'categories': json['categories'],
        'id': json['id'],
        'currencyName': json['currencyName'],
        'currencySymbol': json['currencySymbol'],
        'description': json['description'],
    };
}

export function ProductResourceDetailSchemaToJSON(value?: ProductResourceDetailSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value['name'],
        'articleNo': value['articleNo'],
        'isActive': value['isActive'],
        'isUniqueLotNo': value['isUniqueLotNo'],
        'currencyId': value['currencyId'],
        'price': value['price'],
        'stockTracing': value['stockTracing'],
        'quantityTypeId': value['quantityTypeId'],
        'categories': value['categories'],
        'id': value['id'],
        'currencyName': value['currencyName'],
        'currencySymbol': value['currencySymbol'],
        'description': value['description'],
    };
}

