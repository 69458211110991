import { getApiQuery } from 'repositories/base';
import { ResourceStockExcelExportRequest } from 'api';
import repositories from '..';
import api from '../api';

const resource = '/stock/stocklines';

export const useStockLots = getApiQuery(api.resourceStockLots);
export const useResourceStockLines = getApiQuery(api.resourceStockGetLines);
export const useResourceStockLine = getApiQuery(api.resourceStockGetLine);

export default {
  getStockLines: (resourceId: any) => repositories.get(`${resource}?ResourceId=${resourceId}`),
  getStockLineDetail: (id: number) => api.stockLineGetStockLine({ id }),
  excelExport: (request?: ResourceStockExcelExportRequest) => api.resourceStockExcelExport(request).then((response) => {
    const url = window.URL.createObjectURL(response);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'stocks');
    document.body.appendChild(link);
    link.click();
  }),
};
