import {
  Grid, Table, TableBody, TableCell, TableHead, TableRow,
} from '@mui/material';
import { StockIncomingShipmentDetail } from 'api';
import urls from 'app/urls';
import ShowLabel from 'components/commons/showLabel';
import ShowTitle from 'components/commons/showTitle';
import FileView from 'components/fields/file/FileView';
import EnumField from 'components/fields/enumField';
import useHasAccess from 'hooks/useHasAccess';
import * as React from 'react';
import {
  Button,
  Confirm,
  DateField,
  EditButton,
  HttpError,
  Show,
  TabbedShowLayout,
  TextField,
  TopToolbar,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import { Link } from 'react-router-dom';
import formatString from 'utils/formatString';
import Source from 'utils/source';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import api from 'repositories/api';
import { useShipmentComparison } from 'repositories/stock/receiveShipment';
import Loading from 'components/shared/loading';
import ShowQuantity from 'components/commons/showQuantity';
import useTranslate from 'utils/translate';
import { incomingShipmentState, incomingShipmentTransportType } from './enum';

function ReceiveShipmentButton() {
  const hasAccess = useHasAccess();
  const shipment = useRecordContext<StockIncomingShipmentDetail>();

  return (
    <Button
      label="resources.stock/incomingShipment.fields.receiveShipment"
      component={Link}
      to={`/${formatString(urls.ReceiveShipment_GetStockMove.url, { id: shipment?.id?.toString() as string })}`}
      disabled={
        !shipment
        || shipment?.id == null
        || !!shipment?.appliedDate
        || !hasAccess(urls.ReceiveShipment_GetStockMove)
      }
    >
      <MoveToInboxIcon />
    </Button>
  );
}

function ApplyButton() {
  const [open, setOpen] = React.useState(false);
  const record = useRecordContext<StockIncomingShipmentDetail>();
  const refresh = useRefresh();
  const notify = useNotify();

  const handleConfirm = async () => {
    setOpen(false);

    try {
      const response = await api.receiveShipmentApply({ id: record.id as number });

      if (response.code === 200) {
        notify('mai.stockMove.apply_success', { type: 'success' });
        refresh();
      } else {
        notify('mai.stockMove.apply_failed', { type: 'warning' });
      }
    } catch (e) {
      if (e instanceof HttpError) {
        notify(e.message, { type: 'error' });
      }
    }
  };

  return (
    <>
      <Button
        variant="contained"
        label="mai.stockMove.apply_btn"
        sx={{ float: 'right' }}
        onClick={() => setOpen(true)}
        disabled={!!record?.appliedDate}
      />
      <Confirm
        isOpen={open}
        title="mai.stockMove.apply_confirm_title"
        content="mai.stockMove.apply_confirm_content"
        onConfirm={handleConfirm}
        onClose={() => setOpen(false)}
      />
    </>
  );
}

function IncomingShipmentToolbar() {
  return (
    <TopToolbar>
      <EditButton />
      <ReceiveShipmentButton />
    </TopToolbar>
  );
}

function IncomingShipmentTable() {
  const translate = useTranslate();
  const record = useRecordContext<StockIncomingShipmentDetail>();
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>#</TableCell>
          <TableCell>{translate('mai.incomingShipment.receive.ArticleNo')}</TableCell>
          <TableCell>{translate('mai.incomingShipment.receive.Resource')}</TableCell>
          <TableCell>{translate('mai.incomingShipment.receive.PlannedAmount')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {record.lines.map((row, index: number) => (
          <TableRow key={row.rawMaterialId}>
            <TableCell>{index + 1}</TableCell>
            <TableCell>{row?.articleNo}</TableCell>
            <TableCell>{row?.rawMaterialName}</TableCell>
            <TableCell>
              <ShowQuantity value={row.amount} quantityTypeId={row.quantityTypeId} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>

  );
}

function ComparisonView() {
  const translate = useTranslate();
  const record = useRecordContext<StockIncomingShipmentDetail>();
  const { data } = useShipmentComparison({ id: record.id as number }, { enabled: !!record });

  if (!data) {
    return <Loading />;
  }

  const totalPlanned = data.reduce((sum, line) => line.plannedAmount + sum, 0);
  const totalActual = data.reduce((sum, line) => line.actualAmount + sum, 0);

  return (
    <div>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>{translate('mai.incomingShipment.receive.ArticleNo')}</TableCell>
            <TableCell>{translate('mai.incomingShipment.receive.Resource')}</TableCell>
            <TableCell>{translate('mai.incomingShipment.receive.PlannedAmount')}</TableCell>
            <TableCell>{translate('mai.incomingShipment.receive.ActualAmount')}</TableCell>
            <TableCell>{translate('mai.incomingShipment.receive.Difference')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index: number) => (
            <TableRow key={row.rawMaterialId}>
              <TableCell>{index + 1}</TableCell>
              <TableCell>{row?.articleNo}</TableCell>
              <TableCell>{row?.resourceName}</TableCell>
              <TableCell>
                <ShowQuantity value={row.plannedAmount} quantityTypeId={row.quantityTypeId} />
              </TableCell>
              <TableCell>
                <ShowQuantity value={row.actualAmount} quantityTypeId={row.quantityTypeId} />
              </TableCell>
              <TableCell>
                {
                  row.actualAmount !== undefined && row.plannedAmount !== undefined && (
                    <ShowQuantity
                      value={row.actualAmount - row.plannedAmount}
                      quantityTypeId={row.quantityTypeId}
                    />
                  )
                }
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell>Total</TableCell>
            <TableCell colSpan={2} />
            <TableCell>{totalPlanned}</TableCell>
            <TableCell>{totalActual}</TableCell>
            <TableCell>{totalActual - totalPlanned}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <ApplyButton />
    </div>

  );
}

function ShipmentShowLayout() {
  const record = useRecordContext<StockIncomingShipmentDetail>();
  const hasAccess = useHasAccess();

  const s = Source<StockIncomingShipmentDetail>();

  return (

    <TabbedShowLayout>
      <TabbedShowLayout.Tab label="Summary">
        <Grid container rowSpacing={1} spacing={1} sx={{ marginLeft: '3px', marginTop: '3px', marginBottom: '10px' }}>
          <Grid item xs={12} md={12}>
            <ShowTitle />
          </Grid>

          <Grid item xs={2} />
          <Grid item xs={4}>
            <ShowLabel label="resources.stock/incomingShipment.fields.shipmentName" />
            <TextField fontSize={18} {...s('shipmentName')} />
          </Grid>
          <Grid item xs={4}>
            <ShowLabel label="resources.stock/incomingShipment.fields.estimatedTimeofArrival" />
            <DateField {...s('estimatedTimeofArrival')} />
          </Grid>
          <Grid item xs={2} />

          <Grid item xs={2} />
          <Grid item xs={8}>
            <ShowLabel label="resources.stock/incomingShipment.fields.comment" />
            <TextField fontSize={18} {...s('comment')} />
          </Grid>
          <Grid item xs={2} />

          <Grid item xs={2} />
          <Grid item xs={4}>
            <ShowLabel label="resources.stock/incomingShipment.fields.state" />
            <EnumField {...s('state')} choices={incomingShipmentState} />
          </Grid>
          <Grid item xs={4}>
            <ShowLabel label="resources.stock/incomingShipment.fields.supplier" />
            <TextField {...s('supplier')} />
          </Grid>
          <Grid item xs={2} />

          <Grid item xs={2} />

          <Grid item xs={4}>
            <ShowLabel label="resources.stock/incomingShipment.fields.transportType" />
            <EnumField {...s('transportType')} choices={incomingShipmentTransportType} />
          </Grid>
          <Grid item xs={4}>
            <ShowLabel label="resources.stock/incomingShipment.fields.estimatedTimeofDeparture" />
            <DateField showTime {...s('estimatedTimeofDeparture')} />
          </Grid>
          <Grid item xs={2} />
        </Grid>
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="Lines">
        <IncomingShipmentTable />
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="Files" disabled={!record?.id || !hasAccess(urls.IncomingShipmentFile_GetFiles)}>
        {
          record?.id && (
            <FileView
              endpoint={formatString(urls.IncomingShipmentFile_GetFiles.url, { id: record?.id?.toString() as string })}
              getUrl={urls.IncomingShipmentFile_GetFiles}
              uploadUrl={urls.IncomingShipmentFile_UploadFile}
            />
          )
        }
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="Comparison" disabled={!record?.stockMoveId}>
        <ComparisonView />

      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  );
}

export default function IncomingShipmentShow() {
  return (
    <Show actions={<IncomingShipmentToolbar />}>
      <ShipmentShowLayout />
    </Show>
  );
}
