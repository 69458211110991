import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from '@mui/material';
import { StockMoveLineEntity } from 'api';
import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';
import useTranslate from 'utils/translate';
import exportStockLineQrCode from 'resources/stock/stockLine/qrCode';
import ShowQuantity from 'components/commons/showQuantity';

export interface ReceiveShipmentTableProps {
  listMaterials: StockMoveLineEntity[],
  isDelete: boolean,
  onDelete: (id: number) => void;
}

export default function ReceiveShipmentTable(props: ReceiveShipmentTableProps) {
  const { listMaterials, isDelete, onDelete } = props;
  const translate = useTranslate();

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>{translate('mai.incomingShipment.receive.ArticleNo')}</TableCell>
            <TableCell>{translate('mai.incomingShipment.receive.Resource')}</TableCell>
            <TableCell>{translate('mai.incomingShipment.receive.LotNo')}</TableCell>
            <TableCell>{translate('mai.incomingShipment.receive.ActualAmount')}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {listMaterials.map((row, index: number) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell>{index + 1}</TableCell>
              <TableCell>{row?.articleNo}</TableCell>
              <TableCell>{row?.resourceName}</TableCell>
              <TableCell>{row?.stockLotName}</TableCell>
              <TableCell>
                <ShowQuantity value={row?.amount} quantityTypeId={row.quantityTypeId} />
              </TableCell>
              <TableCell>
                {
                  isDelete && (
                    <IconButton onClick={() => onDelete(row.id)}>
                      <DeleteIcon sx={{ color: '#d32f2f' }} />
                    </IconButton>
                  )
                }
                <IconButton
                  sx={{ marginLeft: '10px' }}
                  onClick={() => exportStockLineQrCode([{
                    ...row,
                    lotDate: row.stockLine.lotDate,
                    lotNo: row.stockLine.lotNo,
                    labelId: row.stockLine.labelId,
                  }])}
                >
                  <PrintIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
