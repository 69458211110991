/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: developer.contact@outfiz.vn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuthGroupEntity
 */
export interface AuthGroupEntity {
    /**
     * 
     * @type {string}
     * @memberof AuthGroupEntity
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AuthGroupEntity
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AuthGroupEntity
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthGroupEntity
     */
    readonly typeName?: string;
    /**
     * 
     * @type {number}
     * @memberof AuthGroupEntity
     */
    typeCode: number;
}

/**
 * Check if a given object implements the AuthGroupEntity interface.
 */
export function instanceOfAuthGroupEntity(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    if (!('typeCode' in value)) return false;
    return true;
}

export function AuthGroupEntityFromJSON(json: any): AuthGroupEntity {
    return AuthGroupEntityFromJSONTyped(json, false);
}

export function AuthGroupEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthGroupEntity {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'typeName': !exists(json, 'typeName') ? undefined : json['typeName'],
        'typeCode': json['typeCode'],
    };
}

export function AuthGroupEntityToJSON(value?: AuthGroupEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'description': value['description'],
        'typeCode': value['typeCode'],
    };
}

