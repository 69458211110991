import polyglotI18nProvider from 'ra-i18n-polyglot';
import { resolveBrowserLocale } from 'react-admin';
import loadBlob from 'utils/loadBlob';
import englishMessages from './en';
import vietnameseMessages from './vi';

const messages: any = {
  vi: vietnameseMessages,
  en: englishMessages,
};

export default function getI18nProvider() {
  let defaultLocale = resolveBrowserLocale('en');
  if (!messages[defaultLocale]) defaultLocale = 'en';
  const { translate, ...rest } = polyglotI18nProvider(
    (locale: string) => messages[locale],
    defaultLocale,
    { allowMissing: true },
  );
  return {
    translate: (key: string, options: any) => {
      if (rest.getLocale() === '-') {
        return key;
      }
      const result = translate(key, options);
      if (result === key) {
        // console.info(`Missing translation: ${result} (${rest.getLocale()})`);
        const defaultValue = options?.defaultValue;

        if (defaultValue) {
          return defaultValue;
        }
      }
      return result;
    },
    ...rest,
  };
}

export function GetMessagesCsv() {
  function getKeys(obj: Record<string, string | any>, target: Map<string, string>, prefix?: string) {
    if (!obj) {
      return target;
    }
    Object.entries(obj).forEach(([key, value]) => {
      const newKey = prefix ? `${prefix}.${key}` : key;
      if (typeof (value) === 'string') {
        target.set(newKey, value);
      } else if (value) {
        getKeys(value, target, newKey);
      }
    });
    return target;
  }

  const enMap = getKeys(messages.en, new Map<string, string>());
  const vnMap = getKeys(messages.vi, new Map<string, string>());

  const keys = new Set<string>();
  enMap.forEach((value, key) => keys.add(key));
  vnMap.forEach((value, key) => keys.add(key));

  const sortedKeys = Array.from(keys).sort();

  const columns = sortedKeys.map((key) => [
    key,
    enMap.get(key) || '',
    vnMap.get(key) || '',
  ]);

  let csv = 'key,english,vietnamese\n';
  csv += columns
    .map((row) => JSON.stringify(row))
    .join('\n')
    .replace(/(^\[)|(\]$)/mg, '') // remove opening [ and closing ] brackets from each line
    .replaceAll('\\"', '""'); // remove escaped separators

  loadBlob(new Blob([csv], { type: 'text/csv' }));
}
