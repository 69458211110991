import * as React from 'react';
import {
  TextField,
  ShowButton,
  EditButton,
  DeleteWithConfirmButton,
} from 'react-admin';
import ListActions from 'components/layout/actions/list';
import useHasAccess from 'hooks/useHasAccess';
import List from 'components/list/List';
import Datagrid from 'components/list/dataGrid';
import { ColorField } from 'react-admin-color-picker';
import filters from './filters';
import styles from './category.module.scss';

export default React.memo(() => {
  const hasAccess = useHasAccess();
  const canPut = hasAccess({ url: 'product/categories/{id}', httpMethod: 'PUT' });
  const canDelete = hasAccess({ url: 'product/categories/{id}', httpMethod: 'DELETE' });
  const canShow = hasAccess({ url: 'product/categories/{id}', httpMethod: 'GET' });
  return (
    <List filters={filters} actions={<ListActions exporter />}>
      <Datagrid className={styles.ctgTable}>
        <TextField source="name" />
        <TextField source="fullName" />
        <ColorField source="color" />
        <TextField source="position" />
        <TextField source="description" />
        {canShow && <ShowButton label="" />}
        {canPut && <EditButton label="" />}
        {canDelete && <DeleteWithConfirmButton label="" mutationMode="pessimistic" />}
      </Datagrid>
    </List>
  );
});
