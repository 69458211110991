/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: developer.contact@outfiz.vn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StockIncomingShipmentList
 */
export interface StockIncomingShipmentList {
    /**
     * 
     * @type {number}
     * @memberof StockIncomingShipmentList
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof StockIncomingShipmentList
     */
    state: StockIncomingShipmentListStateEnum;
    /**
     * 
     * @type {string}
     * @memberof StockIncomingShipmentList
     */
    supplier: string;
    /**
     * 
     * @type {string}
     * @memberof StockIncomingShipmentList
     */
    transportType: StockIncomingShipmentListTransportTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof StockIncomingShipmentList
     */
    estimatedTimeofArrival: Date;
    /**
     * 
     * @type {string}
     * @memberof StockIncomingShipmentList
     */
    shipmentName: string;
    /**
     * 
     * @type {string}
     * @memberof StockIncomingShipmentList
     */
    comment?: string;
    /**
     * 
     * @type {number}
     * @memberof StockIncomingShipmentList
     */
    stockMoveId?: number;
}


/**
 * @export
 */
export const StockIncomingShipmentListStateEnum = {
    WaitingForDeparture: 'waiting_for_departure',
    DuringShipment: 'during_shipment',
    Processing: 'processing',
    Done: 'done',
    Error: 'error'
} as const;
export type StockIncomingShipmentListStateEnum = typeof StockIncomingShipmentListStateEnum[keyof typeof StockIncomingShipmentListStateEnum];

/**
 * @export
 */
export const StockIncomingShipmentListTransportTypeEnum = {
    Sea: 'Sea',
    Air: 'Air',
    Truck: 'Truck',
    Express: 'Express',
    Other: 'Other'
} as const;
export type StockIncomingShipmentListTransportTypeEnum = typeof StockIncomingShipmentListTransportTypeEnum[keyof typeof StockIncomingShipmentListTransportTypeEnum];


/**
 * Check if a given object implements the StockIncomingShipmentList interface.
 */
export function instanceOfStockIncomingShipmentList(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('state' in value)) return false;
    if (!('supplier' in value)) return false;
    if (!('transportType' in value)) return false;
    if (!('estimatedTimeofArrival' in value)) return false;
    if (!('shipmentName' in value)) return false;
    return true;
}

export function StockIncomingShipmentListFromJSON(json: any): StockIncomingShipmentList {
    return StockIncomingShipmentListFromJSONTyped(json, false);
}

export function StockIncomingShipmentListFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockIncomingShipmentList {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'state': json['state'],
        'supplier': json['supplier'],
        'transportType': json['transportType'],
        'estimatedTimeofArrival': (new Date(json['estimatedTimeofArrival'])),
        'shipmentName': json['shipmentName'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'stockMoveId': !exists(json, 'stockMoveId') ? undefined : json['stockMoveId'],
    };
}

export function StockIncomingShipmentListToJSON(value?: StockIncomingShipmentList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value['id'],
        'state': value['state'],
        'supplier': value['supplier'],
        'transportType': value['transportType'],
        'estimatedTimeofArrival': ((value['estimatedTimeofArrival']).toISOString()),
        'shipmentName': value['shipmentName'],
        'comment': value['comment'],
        'stockMoveId': value['stockMoveId'],
    };
}

