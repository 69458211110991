/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: developer.contact@outfiz.vn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AppQuantityTypeOption } from './AppQuantityTypeOption';
import {
    AppQuantityTypeOptionFromJSON,
    AppQuantityTypeOptionFromJSONTyped,
    AppQuantityTypeOptionToJSON,
} from './AppQuantityTypeOption';

/**
 * 
 * @export
 * @interface AppEnumSchema
 */
export interface AppEnumSchema {
    /**
     * 
     * @type {Array<AppQuantityTypeOption>}
     * @memberof AppEnumSchema
     */
    quantityTypes: Array<AppQuantityTypeOption>;
}

/**
 * Check if a given object implements the AppEnumSchema interface.
 */
export function instanceOfAppEnumSchema(value: object): boolean {
    if (!('quantityTypes' in value)) return false;
    return true;
}

export function AppEnumSchemaFromJSON(json: any): AppEnumSchema {
    return AppEnumSchemaFromJSONTyped(json, false);
}

export function AppEnumSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppEnumSchema {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        
        'quantityTypes': ((json['quantityTypes'] as Array<any>).map(AppQuantityTypeOptionFromJSON)),
    };
}

export function AppEnumSchemaToJSON(value?: AppEnumSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'quantityTypes': ((value['quantityTypes'] as Array<any>).map(AppQuantityTypeOptionToJSON)),
    };
}

