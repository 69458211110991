/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: developer.contact@outfiz.vn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AppPermissionEntity
 */
export interface AppPermissionEntity {
    /**
     * 
     * @type {string}
     * @memberof AppPermissionEntity
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof AppPermissionEntity
     */
    httpMethod: string;
    /**
     * 
     * @type {string}
     * @memberof AppPermissionEntity
     */
    methodName: string;
    /**
     * 
     * @type {boolean}
     * @memberof AppPermissionEntity
     */
    isAuthorized: boolean;
}

/**
 * Check if a given object implements the AppPermissionEntity interface.
 */
export function instanceOfAppPermissionEntity(value: object): boolean {
    if (!('url' in value)) return false;
    if (!('httpMethod' in value)) return false;
    if (!('methodName' in value)) return false;
    if (!('isAuthorized' in value)) return false;
    return true;
}

export function AppPermissionEntityFromJSON(json: any): AppPermissionEntity {
    return AppPermissionEntityFromJSONTyped(json, false);
}

export function AppPermissionEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppPermissionEntity {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        
        'url': json['url'],
        'httpMethod': json['httpMethod'],
        'methodName': json['methodName'],
        'isAuthorized': json['isAuthorized'],
    };
}

export function AppPermissionEntityToJSON(value?: AppPermissionEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'url': value['url'],
        'httpMethod': value['httpMethod'],
        'methodName': value['methodName'],
        'isAuthorized': value['isAuthorized'],
    };
}

