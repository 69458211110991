/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: developer.contact@outfiz.vn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AppQuantityTypeOption
 */
export interface AppQuantityTypeOption {
    /**
     * 
     * @type {number}
     * @memberof AppQuantityTypeOption
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof AppQuantityTypeOption
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof AppQuantityTypeOption
     */
    decimals: number;
    /**
     * 
     * @type {string}
     * @memberof AppQuantityTypeOption
     */
    unit: string;
}

/**
 * Check if a given object implements the AppQuantityTypeOption interface.
 */
export function instanceOfAppQuantityTypeOption(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    if (!('decimals' in value)) return false;
    if (!('unit' in value)) return false;
    return true;
}

export function AppQuantityTypeOptionFromJSON(json: any): AppQuantityTypeOption {
    return AppQuantityTypeOptionFromJSONTyped(json, false);
}

export function AppQuantityTypeOptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppQuantityTypeOption {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'decimals': json['decimals'],
        'unit': json['unit'],
    };
}

export function AppQuantityTypeOptionToJSON(value?: AppQuantityTypeOption | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'decimals': value['decimals'],
        'unit': value['unit'],
    };
}

