/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: developer.contact@outfiz.vn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StockInventoryControlMoveLineSchema
 */
export interface StockInventoryControlMoveLineSchema {
    /**
     * 
     * @type {number}
     * @memberof StockInventoryControlMoveLineSchema
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof StockInventoryControlMoveLineSchema
     */
    changeAmount: number;
    /**
     * 
     * @type {string}
     * @memberof StockInventoryControlMoveLineSchema
     */
    articleNo?: string;
    /**
     * 
     * @type {string}
     * @memberof StockInventoryControlMoveLineSchema
     */
    articleName?: string;
    /**
     * 
     * @type {string}
     * @memberof StockInventoryControlMoveLineSchema
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof StockInventoryControlMoveLineSchema
     */
    lotNo?: string;
    /**
     * 
     * @type {string}
     * @memberof StockInventoryControlMoveLineSchema
     */
    createdBy?: string;
}

/**
 * Check if a given object implements the StockInventoryControlMoveLineSchema interface.
 */
export function instanceOfStockInventoryControlMoveLineSchema(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('changeAmount' in value)) return false;
    return true;
}

export function StockInventoryControlMoveLineSchemaFromJSON(json: any): StockInventoryControlMoveLineSchema {
    return StockInventoryControlMoveLineSchemaFromJSONTyped(json, false);
}

export function StockInventoryControlMoveLineSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockInventoryControlMoveLineSchema {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'changeAmount': json['changeAmount'],
        'articleNo': !exists(json, 'articleNo') ? undefined : json['articleNo'],
        'articleName': !exists(json, 'articleName') ? undefined : json['articleName'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'lotNo': !exists(json, 'lotNo') ? undefined : json['lotNo'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
    };
}

export function StockInventoryControlMoveLineSchemaToJSON(value?: StockInventoryControlMoveLineSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value['id'],
        'changeAmount': value['changeAmount'],
        'articleNo': value['articleNo'],
        'articleName': value['articleName'],
        'comment': value['comment'],
        'lotNo': value['lotNo'],
        'createdBy': value['createdBy'],
    };
}

