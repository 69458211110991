const messages = {
  resources: {
    'business-partner/business-partners': {
      name: 'Business partners',
      fields: {
        name: 'Name',
        phone: 'Phone',
        taxId: 'TaxID',
        address: 'Address',
        addressName: 'Address name',
        address1: 'Address 1',
        address2: 'Address 2',
        zipcode: 'Zip code',
        state: 'State',
        countryName: 'Country',
        countryId: 'Country',
        city: 'City',
      },
    },
  },
  mai: {
    validation: {
      phone: 'The phone number is invalid',
    },
  },
};

export default messages;
