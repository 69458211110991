const messages = {
  resources: {
    'business-partner/business-partners': {
      name: 'Đối tác kinh doanh',
      fields: {
        name: 'Tên',
        phone: 'Điện thoại',
        taxId: 'Mã số thuế',
        address: 'Địa chỉ',
        addressName: 'Tên địa chỉ',
        address1: 'Địa chỉ 1',
        address2: 'Địa chỉ 2',
        zipcode: 'Mã bưu điện',
        state: 'Tiểu bang',
        countryName: 'Quốc gia',
        countryId: 'Quốc gia',
        city: 'Thành phố',
      },
    },
  },
  mai: {
    validation: {
      phone: 'Số điện thoại không đúng',
    },
  },
};

export default messages;
