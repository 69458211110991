/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: developer.contact@outfiz.vn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StockInventoryControlLineSchema
 */
export interface StockInventoryControlLineSchema {
    /**
     * 
     * @type {number}
     * @memberof StockInventoryControlLineSchema
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof StockInventoryControlLineSchema
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof StockInventoryControlLineSchema
     */
    amountInStock?: number;
    /**
     * 
     * @type {number}
     * @memberof StockInventoryControlLineSchema
     */
    changeAmount: number;
    /**
     * 
     * @type {string}
     * @memberof StockInventoryControlLineSchema
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof StockInventoryControlLineSchema
     */
    articleNo?: string;
    /**
     * 
     * @type {string}
     * @memberof StockInventoryControlLineSchema
     */
    articleName?: string;
    /**
     * 
     * @type {string}
     * @memberof StockInventoryControlLineSchema
     */
    lotNo?: string;
    /**
     * 
     * @type {string}
     * @memberof StockInventoryControlLineSchema
     */
    shelf?: string;
    /**
     * 
     * @type {string}
     * @memberof StockInventoryControlLineSchema
     */
    createdBy?: string;
}

/**
 * Check if a given object implements the StockInventoryControlLineSchema interface.
 */
export function instanceOfStockInventoryControlLineSchema(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('amount' in value)) return false;
    if (!('changeAmount' in value)) return false;
    return true;
}

export function StockInventoryControlLineSchemaFromJSON(json: any): StockInventoryControlLineSchema {
    return StockInventoryControlLineSchemaFromJSONTyped(json, false);
}

export function StockInventoryControlLineSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockInventoryControlLineSchema {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'amount': json['amount'],
        'amountInStock': !exists(json, 'amountInStock') ? undefined : json['amountInStock'],
        'changeAmount': json['changeAmount'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'articleNo': !exists(json, 'articleNo') ? undefined : json['articleNo'],
        'articleName': !exists(json, 'articleName') ? undefined : json['articleName'],
        'lotNo': !exists(json, 'lotNo') ? undefined : json['lotNo'],
        'shelf': !exists(json, 'shelf') ? undefined : json['shelf'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
    };
}

export function StockInventoryControlLineSchemaToJSON(value?: StockInventoryControlLineSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value['id'],
        'amount': value['amount'],
        'amountInStock': value['amountInStock'],
        'changeAmount': value['changeAmount'],
        'comment': value['comment'],
        'articleNo': value['articleNo'],
        'articleName': value['articleName'],
        'lotNo': value['lotNo'],
        'shelf': value['shelf'],
        'createdBy': value['createdBy'],
    };
}

