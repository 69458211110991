/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: developer.contact@outfiz.vn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StockReceiveShipmentLine
 */
export interface StockReceiveShipmentLine {
    /**
     * 
     * @type {number}
     * @memberof StockReceiveShipmentLine
     */
    rawMaterialId: number;
    /**
     * 
     * @type {string}
     * @memberof StockReceiveShipmentLine
     */
    stockLotName: string;
    /**
     * 
     * @type {number}
     * @memberof StockReceiveShipmentLine
     */
    amount: number;
}

/**
 * Check if a given object implements the StockReceiveShipmentLine interface.
 */
export function instanceOfStockReceiveShipmentLine(value: object): boolean {
    if (!('rawMaterialId' in value)) return false;
    if (!('stockLotName' in value)) return false;
    if (!('amount' in value)) return false;
    return true;
}

export function StockReceiveShipmentLineFromJSON(json: any): StockReceiveShipmentLine {
    return StockReceiveShipmentLineFromJSONTyped(json, false);
}

export function StockReceiveShipmentLineFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockReceiveShipmentLine {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        
        'rawMaterialId': json['rawMaterialId'],
        'stockLotName': json['stockLotName'],
        'amount': json['amount'],
    };
}

export function StockReceiveShipmentLineToJSON(value?: StockReceiveShipmentLine | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rawMaterialId': value['rawMaterialId'],
        'stockLotName': value['stockLotName'],
        'amount': value['amount'],
    };
}

