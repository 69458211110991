/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: developer.contact@outfiz.vn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StockMoveLineEntity } from './StockMoveLineEntity';
import {
    StockMoveLineEntityFromJSON,
    StockMoveLineEntityFromJSONTyped,
    StockMoveLineEntityToJSON,
} from './StockMoveLineEntity';

/**
 * 
 * @export
 * @interface StockMoveEntity
 */
export interface StockMoveEntity {
    /**
     * 
     * @type {number}
     * @memberof StockMoveEntity
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof StockMoveEntity
     */
    plannedDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof StockMoveEntity
     */
    comment?: string;
    /**
     * 
     * @type {number}
     * @memberof StockMoveEntity
     */
    sourceStockLocationId?: number;
    /**
     * 
     * @type {number}
     * @memberof StockMoveEntity
     */
    destinationStockLocationId?: number;
    /**
     * 
     * @type {Date}
     * @memberof StockMoveEntity
     */
    appliedDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof StockMoveEntity
     */
    appliedBy?: number;
    /**
     * 
     * @type {string}
     * @memberof StockMoveEntity
     */
    applier?: string;
    /**
     * 
     * @type {number}
     * @memberof StockMoveEntity
     */
    totalAmount: number;
    /**
     * 
     * @type {string}
     * @memberof StockMoveEntity
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof StockMoveEntity
     */
    sourceStockLocationName?: string;
    /**
     * 
     * @type {string}
     * @memberof StockMoveEntity
     */
    destinationStockLocationName?: string;
    /**
     * 
     * @type {Array<StockMoveLineEntity>}
     * @memberof StockMoveEntity
     */
    lines: Array<StockMoveLineEntity>;
}

/**
 * Check if a given object implements the StockMoveEntity interface.
 */
export function instanceOfStockMoveEntity(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('totalAmount' in value)) return false;
    if (!('lines' in value)) return false;
    return true;
}

export function StockMoveEntityFromJSON(json: any): StockMoveEntity {
    return StockMoveEntityFromJSONTyped(json, false);
}

export function StockMoveEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockMoveEntity {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'plannedDate': !exists(json, 'plannedDate') ? undefined : (new Date(json['plannedDate'])),
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'sourceStockLocationId': !exists(json, 'sourceStockLocationId') ? undefined : json['sourceStockLocationId'],
        'destinationStockLocationId': !exists(json, 'destinationStockLocationId') ? undefined : json['destinationStockLocationId'],
        'appliedDate': !exists(json, 'appliedDate') ? undefined : (new Date(json['appliedDate'])),
        'appliedBy': !exists(json, 'appliedBy') ? undefined : json['appliedBy'],
        'applier': !exists(json, 'applier') ? undefined : json['applier'],
        'totalAmount': json['totalAmount'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'sourceStockLocationName': !exists(json, 'sourceStockLocationName') ? undefined : json['sourceStockLocationName'],
        'destinationStockLocationName': !exists(json, 'destinationStockLocationName') ? undefined : json['destinationStockLocationName'],
        'lines': ((json['lines'] as Array<any>).map(StockMoveLineEntityFromJSON)),
    };
}

export function StockMoveEntityToJSON(value?: StockMoveEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value['id'],
        'plannedDate': !exists(value, 'plannedDate') ? undefined : ((value['plannedDate'] as any).toISOString()),
        'comment': value['comment'],
        'sourceStockLocationId': value['sourceStockLocationId'],
        'destinationStockLocationId': value['destinationStockLocationId'],
        'appliedDate': !exists(value, 'appliedDate') ? undefined : ((value['appliedDate'] as any).toISOString()),
        'appliedBy': value['appliedBy'],
        'applier': value['applier'],
        'totalAmount': value['totalAmount'],
        'createdBy': value['createdBy'],
        'sourceStockLocationName': value['sourceStockLocationName'],
        'destinationStockLocationName': value['destinationStockLocationName'],
        'lines': ((value['lines'] as Array<any>).map(StockMoveLineEntityToJSON)),
    };
}

