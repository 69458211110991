import * as React from 'react';
import {
  TextField,
  EditButton,
  DeleteWithConfirmButton,
  useRecordContext,
  ShowButton,
} from 'react-admin';
import { AuthGroupEntity } from 'api';
import Source from 'utils/source';
import ListActions from 'components/layout/actions/list';
import TranslateField from 'components/commons/translateField';
import constants from 'utils/constants';
import useHasAccess from 'hooks/useHasAccess';
import List from 'components/list/List';
import Datagrid from 'components/list/dataGrid';
import filters from './filters';

function EditButtonGroupCustom() {
  const record = useRecordContext();
  if (record && record.typeCode === constants.GroupType.CUSTOM) return <EditButton label="" />;
  return null;
}

function DeleteWithConfirmButtonGroupCustom() {
  const record = useRecordContext();
  if (record && record.typeCode === constants.GroupType.CUSTOM) {
    return <DeleteWithConfirmButton mutationMode="pessimistic" label="" />;
  }
  return null;
}

export default React.memo(() => {
  const hasAccess = useHasAccess();
  const canPut = hasAccess({ url: 'auth/groups/{id}', httpMethod: 'PUT' });
  const canDelete = hasAccess({ url: 'auth/groups/{id}', httpMethod: 'DELETE' });
  const canShow = hasAccess({ url: 'auth/groups/{id}', httpMethod: 'GET' });
  const s = Source<AuthGroupEntity>();

  return (
    <List filters={filters} actions={<ListActions exporter />}>
      <Datagrid>
        <TextField {...s('id')} sortable={false} />
        <TextField {...s('name')} sortable={false} />
        <TranslateField {...s('typeName')} sortable={false} />
        <TextField {...s('description')} sortable={false} />
        {canShow && <ShowButton label="" />}
        {canPut && <EditButtonGroupCustom />}
        {canDelete && <DeleteWithConfirmButtonGroupCustom />}
      </Datagrid>
    </List>
  );
});
