const messages = {
  resources: {
    'auth/groups': {
      name: 'Group |||| Groups',
      fields: {
        name: 'Name of group',
        id: 'Key',
        description: 'Description',
        typeName: 'Type',
      },
    },
  },
  mai: {
    group: 'Group',
    permissions: 'Permissions',
  },
};

export default messages;
