/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: developer.contact@outfiz.vn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Paging } from './Paging';
import {
    PagingFromJSON,
    PagingFromJSONTyped,
    PagingToJSON,
} from './Paging';
import type { StockLocationSchema } from './StockLocationSchema';
import {
    StockLocationSchemaFromJSON,
    StockLocationSchemaFromJSONTyped,
    StockLocationSchemaToJSON,
} from './StockLocationSchema';

/**
 * 
 * @export
 * @interface PaginatedListStockLocationSchema
 */
export interface PaginatedListStockLocationSchema {
    /**
     * 
     * @type {Array<StockLocationSchema>}
     * @memberof PaginatedListStockLocationSchema
     */
    list: Array<StockLocationSchema>;
    /**
     * 
     * @type {Paging}
     * @memberof PaginatedListStockLocationSchema
     */
    paging: Paging;
}

/**
 * Check if a given object implements the PaginatedListStockLocationSchema interface.
 */
export function instanceOfPaginatedListStockLocationSchema(value: object): boolean {
    if (!('list' in value)) return false;
    if (!('paging' in value)) return false;
    return true;
}

export function PaginatedListStockLocationSchemaFromJSON(json: any): PaginatedListStockLocationSchema {
    return PaginatedListStockLocationSchemaFromJSONTyped(json, false);
}

export function PaginatedListStockLocationSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedListStockLocationSchema {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        
        'list': ((json['list'] as Array<any>).map(StockLocationSchemaFromJSON)),
        'paging': PagingFromJSON(json['paging']),
    };
}

export function PaginatedListStockLocationSchemaToJSON(value?: PaginatedListStockLocationSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'list': ((value['list'] as Array<any>).map(StockLocationSchemaToJSON)),
        'paging': PagingToJSON(value['paging']),
    };
}

