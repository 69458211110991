/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: developer.contact@outfiz.vn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StockSplitRequest
 */
export interface StockSplitRequest {
    /**
     * 
     * @type {number}
     * @memberof StockSplitRequest
     */
    stockLineId: number;
    /**
     * 
     * @type {number}
     * @memberof StockSplitRequest
     */
    amountPerUnit?: number;
    /**
     * 
     * @type {number}
     * @memberof StockSplitRequest
     */
    num?: number;
    /**
     * 
     * @type {string}
     * @memberof StockSplitRequest
     */
    comment?: string;
}

/**
 * Check if a given object implements the StockSplitRequest interface.
 */
export function instanceOfStockSplitRequest(value: object): boolean {
    if (!('stockLineId' in value)) return false;
    return true;
}

export function StockSplitRequestFromJSON(json: any): StockSplitRequest {
    return StockSplitRequestFromJSONTyped(json, false);
}

export function StockSplitRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockSplitRequest {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        
        'stockLineId': json['stockLineId'],
        'amountPerUnit': !exists(json, 'amountPerUnit') ? undefined : json['amountPerUnit'],
        'num': !exists(json, 'num') ? undefined : json['num'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
    };
}

export function StockSplitRequestToJSON(value?: StockSplitRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'stockLineId': value['stockLineId'],
        'amountPerUnit': value['amountPerUnit'],
        'num': value['num'],
        'comment': value['comment'],
    };
}

