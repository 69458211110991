import * as React from 'react';
import {
  Show,
  Tab,
  SimpleShowLayout,
  TabbedShowLayout,
  TextField,
  ArrayField,
  Datagrid,
  useRecordContext,
  TextInput,
  useResourceContext,
  useCreatePath,
} from 'react-admin';
import { Button, Dialog, Grid } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { StockLinesLine, ProductDetailSchema, ProductBillOfResourceLineDetail } from 'api/models';
import Source from 'utils/source';
import ShowActions from 'components/layout/actions/show';
import QuantityTypeField from 'components/fields/QuantityTypeField';
import CategoryField from 'components/fields/categoryField';
import PriceField from 'components/fields/priceField';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import api from 'repositories/api';
import ShowTitle from '../../../components/commons/showTitle';
import ShowLabel from '../../../components/commons/showLabel';
import ShowField from '../../../components/commons/showField';
import ShowBooleanField from '../../../components/commons/showBooleanField';
import NumberFormatField from '../../../components/commons/numberFormatField';

interface CopyProductForm {
  name: string;
}

function CopyProduct() {
  const resource = useResourceContext();
  const createPath = useCreatePath();
  const navigate = useNavigate();
  const record = useRecordContext<ProductDetailSchema>();
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const form = useForm<CopyProductForm>({
    defaultValues: {
      name: record?.name,
    },
  });
  React.useEffect(() => {
    if (record?.name) {
      form.setValue('name', record?.name);
      setIsLoading(false);
    }
  }, [record]);

  const id = record?.id;
  const name = form.watch('name');

  const submit = React.useCallback(
    (data: CopyProductForm) => {
      const onSubmit = async () => {
        setIsLoading(true);
        // api calls
        console.log('submit', data);
        const newId = await api.productsCopy({
          id,
          body: name,
        });
        // setIsLoading(false)
        const path = createPath({ type: 'edit', id: newId, resource });
        navigate(path);
      };
      onSubmit();
    },
    [name, record, setIsLoading],
  );

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        startIcon={<ContentCopyIcon />}
        style={{ padding: '4px 5px', lineHeight: '1.5' }}
      >
        Copy
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <p>
          Copy Order
          {id}
        </p>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(submit)}>
            <TextInput source="name" />
            <Button type="submit" disabled={isLoading || (record ? name === record.name : true)}>Copy</Button>
          </form>
        </FormProvider>
      </Dialog>
    </>
  );
}

export default function () {
  const s = Source<ProductDetailSchema>();
  const sl = Source<StockLinesLine>();
  const bl = Source<ProductBillOfResourceLineDetail>();
  return (
    <Show actions={<ShowActions><CopyProduct /></ShowActions>}>
      <SimpleShowLayout>
        <Grid container rowSpacing={1} spacing={1} sx={{ marginLeft: '3px', marginTop: '3px', marginBottom: '10px' }}>
          <Grid item xs={12} md={12}>
            <ShowTitle />
          </Grid>
          <Grid item xs={2} md={1.5} sx={{ borderBottom: '1' }}>
            <ShowLabel label="resources.product/products.fields.name" />
          </Grid>
          <Grid item xs={8} md={9}>
            <ShowField {...s('name')} className="highLightField" />
          </Grid>
          <Grid item xs={2} md={1.5} />
        </Grid>
      </SimpleShowLayout>
      <TabbedShowLayout>
        <Tab label="resources.product/products.tabs.detail">
          <Grid container rowSpacing={1} spacing={1} sx={{ marginLeft: '3px', marginTop: '3px', marginBottom: '10px' }}>
            <Grid className="show-row-odd" item xs={2} md={1.5} sx={{ borderBottom: '1' }}>
              <ShowLabel label="resources.product/products.fields.price" />
            </Grid>
            <Grid className="show-row-odd" item xs={8} md={9}>
              <PriceField {...s('price')} currencySource={s('currencyId').source} />
            </Grid>
            <Grid className="show-row-odd" item xs={2} md={1.5} />

            <Grid className="show-row-odd" item xs={2} md={1.5}>
              <ShowLabel label="resources.product/raw-materials.fields.categoryName" />
            </Grid>
            <Grid item xs={8} md={9} className="show-row-odd">
              <CategoryField {...s('categories')} />
            </Grid>
            <Grid item xs={2} md={1.5} className="show-row-odd" />

            <Grid item xs={2} md={1.5}>
              <ShowLabel label="resources.product/raw-materials.fields.quantityTypeName" />
            </Grid>
            <Grid item xs={8} md={9}>
              <ShowField {...s('quantityTypeName')} />
            </Grid>
            <Grid item xs={2} md={1.5} />

            <Grid item xs={2} md={1.5} className="show-row-odd">
              <ShowLabel label="resources.product/raw-materials.fields.stockTracingName" />
            </Grid>
            <Grid item xs={8} md={9} className="show-row-odd">
              <ShowField {...s('stockTracing')} showTime />
            </Grid>
            <Grid className="show-row-odd" item xs={2} md={1.5} />

            <Grid item xs={2} md={1.5}>
              <ShowLabel label="resources.product/raw-materials.fields.isActive" />
            </Grid>
            <Grid item xs={8} md={9}>
              <ShowBooleanField {...s('isActive')} />
            </Grid>
            <Grid item xs={2} md={1.5} />
          </Grid>
        </Tab>
        <Tab label="resources.product/products.tabs.billofresources">
          <Grid container rowSpacing={2} spacing={1} sx={{ marginLeft: '3px', marginTop: '0px', marginBottom: '10px' }}>
            <Grid item xs={8} md={8}>
              <ArrayField label={false} {...s('billOfResources.lines')}>
                <Datagrid bulkActionButtons={false}>
                  <TextField label="resources.billOfResources.fields.name" {...bl('resourceName')} />
                  <QuantityTypeField label="resources.billOfResources.fields.amount" {...bl('amount')} />
                  <TextField label="resources.billOfResources.fields.comment" {...bl('comment')} />
                </Datagrid>
              </ArrayField>
            </Grid>
          </Grid>
        </Tab>
        <Tab label="resources.product/products.tabs.stockLine">
          <Grid container rowSpacing={2} spacing={1} sx={{ marginLeft: '3px', marginTop: '0px', marginBottom: '10px' }}>
            <Grid item xs={8} md={8}>
              <ArrayField label={false} {...s('stockLines')}>
                <Datagrid bulkActionButtons={false}>
                  <TextField label="resources.stock/stocklines.fields.name" {...sl('stockLocation.name')} />
                  <NumberFormatField label="resources.stock/stocklines.fields.amount" {...sl('amount')} />
                  <TextField label="resources.stock/stocklines.fields.lotNo" {...sl('lotNo')} />
                </Datagrid>
              </ArrayField>
            </Grid>
          </Grid>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
}
