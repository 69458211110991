import { ProductResourceDetailSchema } from 'api/models/ProductResourceDetailSchema';
import ListActions from 'components/layout/actions/list';
import PriceField from 'components/fields/priceField';
import useHasAccess from 'hooks/useHasAccess';
import * as React from 'react';
import {
  BooleanField,
  DeleteWithConfirmButton,
  EditButton,
  ShowButton,
  TextField,
} from 'react-admin';
import Source from 'utils/source';
import List from 'components/list/List';
import Datagrid from 'components/list/dataGrid';
import CategoryField from 'components/fields/categoryField';
import filters from './filters';
import styles from './product.module.scss';

export default React.memo(() => {
  const hasAccess = useHasAccess();
  const canPut = hasAccess({ url: 'product/products/{id}', httpMethod: 'PUT' });
  const canDelete = hasAccess({ url: 'product/products/{id}', httpMethod: 'DELETE' });
  const canShow = hasAccess({ url: 'product/products/{id}', httpMethod: 'GET' });
  const s = Source<ProductResourceDetailSchema>();

  return (
    <List filters={filters} actions={<ListActions exporter />}>
      <Datagrid className={styles.prdTable}>
        <TextField {...s('name')} label="Name" />
        <PriceField {...s('price')} currencySource={s('currencyId').source} label="Price" />
        <CategoryField {...s('categories')} sortable={false} />
        <BooleanField {...s('isActive')} label="Active" />
        {canShow && <ShowButton label="" />}
        {canPut && <EditButton label="" />}
        {canDelete && <DeleteWithConfirmButton label="" mutationMode="pessimistic" />}
      </Datagrid>
    </List>
  );
});

// <TextField {...s('stockTracingCode')} label="StockTracing" />
// <TextField {...s('quantityTypeName')} label="Quatity Type" />
