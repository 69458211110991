import React from 'react';
import DateTimeRangeInput from 'components/commons/dateTimeRangeInput';
import StockLocationChooser from 'components/chooser/stockLocationChooser';
import UserInput from 'components/input/reference/user';
import EnumSelectField from 'components/input/enumSelectField';
import { StockMoveTypes } from 'repositories/stock/stockMoves';

function StockMoveTypeFilter({ source }: any) {
  return (
    <EnumSelectField
      source={source}
      choices={StockMoveTypes}
    />
  );
}

export default [
  <DateTimeRangeInput source="planed" since="planedSince" until="planedUntil" />,
  <DateTimeRangeInput source="applied" since="appliedSince" until="appliedUntil" />,
  <StockMoveTypeFilter source="moveType" alwaysOn />,
  <StockLocationChooser source="sourceStockLocation" />,
  <StockLocationChooser source="destinationStockLocation" />,
  <UserInput source="appliedBy" />,
  <UserInput source="createdBy" />,
];
