import { Container, Grid } from '@mui/material';
import { AuthUserDetailEntity } from 'api';
import React from 'react';
import {
  AutocompleteArrayInput, DateInput, email,
  ReferenceInput, regex, required, TextInput,
} from 'react-admin';
import Source from 'utils/source';
import EnumSelectField from 'components/input/enumSelectField';
import Genders from './enums';

function UserForm(): React.ReactElement {
  const s = Source<AuthUserDetailEntity>();

  return (
    <Container>
      <Grid container spacing={2} rowSpacing={5}>
        <Grid item xs={12} md={6}>
          <TextInput
            {...s('username')}
            validate={[
              required(),
              regex(/^[a-zA-Z0-9_.-]{4,50}$/, 'mai.msg.username_format_invalidate'),
            ]}
            fullWidth
            variant="outlined"
            helperText={false}
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ReferenceInput {...s('groupIds')} reference="auth/groups" isRequired>
            <AutocompleteArrayInput
              size="small"
              getOptionLabel={(value) => value.name}
              validate={required()}
              variant="outlined"
              helperText={false}
              fullWidth
            />
          </ReferenceInput>
        </Grid>
      </Grid>
      <Grid container spacing={2} rowSpacing={5}>
        <Grid item xs={12} md={6}>
          <TextInput
            {...s('information.firstname')}
            validate={required()}
            variant="outlined"
            helperText={false}
            fullWidth
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            {...s('information.lastname')}
            validate={required()}
            variant="outlined"
            helperText={false}
            fullWidth
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} rowSpacing={5}>
        <Grid item xs={4} md={1} />
        <Grid item xs={8} md={5}>
          <TextInput
            {...s('email')}
            validate={[required(), email()]}
            type="email"
            fullWidth
            variant="outlined"
            helperText={false}
            inputProps={{ maxLength: 255 }}
          />
        </Grid>
        <Grid item xs={8} md={5}>
          <TextInput
            {...s('information.phone')}
            variant="outlined"
            helperText={false}
            fullWidth
            validate={[
              regex(
                /^[(]?[0-9]{1,3}[)]? ?-?[0-9]{1,3} ?-?[0-9]{3,5} ?-?[0-9]{4}( ?-?[0-9]{3})? ?(w{1,10}s?d{1,6})?$/,
                'mai.msg.phone_validate_error',
              ),
            ]}
            inputProps={{ maxLength: 15 }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} rowSpacing={5}>
        <Grid item xs={4} md={1} />
        <Grid item xs={8} md={5}>
          <EnumSelectField
            {...s('information.gender')}
            choices={Genders}
            fullWidth
            helperText={false}
          />
        </Grid>
        <Grid item xs={8} md={5}>
          <DateInput
            {...s('information.birthday')}
            variant="outlined"
            helperText={false}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} rowSpacing={5}>
        <Grid item xs={4} md={1} />
        <Grid item xs={16} md={10}>
          <TextInput
            {...s('information.address')}
            variant="outlined"
            helperText={false}
            fullWidth
            inputProps={{ maxLength: 255 }}
            multiline
            rows={3}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} rowSpacing={5}>
        <Grid item xs={4} md={1} />
        <Grid item xs={16} md={10}>
          <TextInput
            multiline
            {...s('information.note')}
            variant="outlined"
            helperText={false}
            fullWidth
            inputProps={{ maxLength: 500 }}
            rows={3}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default UserForm;
