/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: developer.contact@outfiz.vn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AppPermissionEntity } from './AppPermissionEntity';
import {
    AppPermissionEntityFromJSON,
    AppPermissionEntityFromJSONTyped,
    AppPermissionEntityToJSON,
} from './AppPermissionEntity';
import type { AuthUserEntity } from './AuthUserEntity';
import {
    AuthUserEntityFromJSON,
    AuthUserEntityFromJSONTyped,
    AuthUserEntityToJSON,
} from './AuthUserEntity';

/**
 * 
 * @export
 * @interface AppStateEntity
 */
export interface AppStateEntity {
    /**
     * 
     * @type {AuthUserEntity}
     * @memberof AppStateEntity
     */
    user: AuthUserEntity;
    /**
     * 
     * @type {Array<AppPermissionEntity>}
     * @memberof AppStateEntity
     */
    permissions: Array<AppPermissionEntity>;
}

/**
 * Check if a given object implements the AppStateEntity interface.
 */
export function instanceOfAppStateEntity(value: object): boolean {
    if (!('user' in value)) return false;
    if (!('permissions' in value)) return false;
    return true;
}

export function AppStateEntityFromJSON(json: any): AppStateEntity {
    return AppStateEntityFromJSONTyped(json, false);
}

export function AppStateEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppStateEntity {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        
        'user': AuthUserEntityFromJSON(json['user']),
        'permissions': ((json['permissions'] as Array<any>).map(AppPermissionEntityFromJSON)),
    };
}

export function AppStateEntityToJSON(value?: AppStateEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user': AuthUserEntityToJSON(value['user']),
        'permissions': ((value['permissions'] as Array<any>).map(AppPermissionEntityToJSON)),
    };
}

