/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: developer.contact@outfiz.vn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuthLoginResultDTO
 */
export interface AuthLoginResultDTO {
    /**
     * 
     * @type {string}
     * @memberof AuthLoginResultDTO
     */
    token?: string;
}

/**
 * Check if a given object implements the AuthLoginResultDTO interface.
 */
export function instanceOfAuthLoginResultDTO(value: object): boolean {
    return true;
}

export function AuthLoginResultDTOFromJSON(json: any): AuthLoginResultDTO {
    return AuthLoginResultDTOFromJSONTyped(json, false);
}

export function AuthLoginResultDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthLoginResultDTO {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        
        'token': !exists(json, 'token') ? undefined : json['token'],
    };
}

export function AuthLoginResultDTOToJSON(value?: AuthLoginResultDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'token': value['token'],
    };
}

