const messages = {
  resources: {
    'auth/users': {
      name: 'Người dùng',
      fields: {
        fullname: 'Tên đầy đủ',
        firstname: 'Họ',
        lastname: 'Tên',
        username: 'Tên đăng nhập',
        email: 'Email',
        phone: 'Số điện thoại',
        Phone: 'Số điện thoại',
        lastLogin: 'Đăng nhập lần cuối',
        lastLogout: 'Đăng xuất lần cuối',
        isActive: 'Kích hoạt TK',
        isVerifyEmail: 'Xác thực Email',
        status: 'Trạng thái',
        Group: 'Nhóm tài khoản',
        Query: 'Tìm kiếm',
        groups: 'Nhóm tài khoản',
        groupIds: 'Nhóm tài khoản',
        gender: 'Giới tính',
        birthday: 'Sinh nhật',
        address: 'Địa chỉ',
        note: 'Ghi chú',
        password: 'Mật khẩu',
        confirmPassword: 'Nhập lại mật khẩu',
      },
    },
  },
  mai: {
    auth: {
      resetPassword: 'Đặt lại mật khẩu',
      lockAccount: 'Khoá',
      unlockAccount: 'Mở khoá',
    },
  },
};

export default messages;
