import { create, windowScheduler, keyResolver } from '@yornaath/batshit';
import { PaginatedListResourceDetailSchema, ProductResourceDetailSchema, ResourcesSearchRequest } from 'api';
import { ProductResourceDetailSchemaStockTracingEnum } from 'api/models/ProductResourceDetailSchema';
import { useQuery } from 'react-query';
import { QueryOptions, getApiQuery, getInfiniteApiQuery } from 'repositories/base';
import enumToOptions from 'utils/enumToOptions';
import api from '../api';

// eslint-disable-next-line import/prefer-default-export

export const useResourcesQuery = (query?: ResourcesSearchRequest) => useQuery<PaginatedListResourceDetailSchema>(
  ['Resources', query],
  () => api.resourcesSearch(query),
);

export const useResources = () => useQuery<PaginatedListResourceDetailSchema>(
  ['Resources'],
  () => api.resourcesSearch({ limit: 99999 }),
  { staleTime: 10 },
);

export const useInfiniteResourcesQuery = getInfiniteApiQuery(api.resourcesSearch, {});
export const useInfiniteRawMaterialsQuery = getInfiniteApiQuery(api.rawMaterialsSearch, {});

const resourceBatch = create<ProductResourceDetailSchema, number>({
  // The fetcher resolves the list of queries(here just a list of user ids as number) to one single api call.
  fetcher: async (ids: number[]) => {
    const result = await api.resourcesSearch({
      ids: ids.join(','),
      limit: 9999,
    });

    if (!result.list) {
      throw new Error('No list received');
    }

    return result.list;
  },
  // when we call users.fetch, this will resolve the correct user using the field `id`
  resolver: keyResolver('id'),
  // this will batch all calls to users.fetch that are made within 10 milliseconds.
  scheduler: windowScheduler(10),
});

export const useResourceQuery = (
  id: number,
  options?: QueryOptions<ProductResourceDetailSchema>,
) => useQuery<ProductResourceDetailSchema>(
  ['Resources', id],
  () => resourceBatch.fetch(id),
  options,
);

export const useRawMaterialQuery = getApiQuery(api.rawMaterialsGet);

export default useResourcesQuery;

export const StockTracingChoices = enumToOptions(
  ProductResourceDetailSchemaStockTracingEnum,
  (key) => `mai.rawMaterials.stockTracingCode.${key}`,
);
