import { StockMoveEntity, StockMovesSearchMoveTypeEnum } from 'api';
import NumberFormatField from 'components/commons/numberFormatField';
import DefaultBulkActions from 'components/layout/actions/bulkActions';
import useHasAccess from 'hooks/useHasAccess';
import * as React from 'react';
import {
  Datagrid, DateField, FunctionField, ReferenceField, ShowButton,
  TextField,
} from 'react-admin';
import Source from 'utils/source';
import { Badges, StatusBadge } from 'components/list/colorBadge';
import List from 'components/list/List';
import { CustomBulkExport } from 'resources/purchasing/purchaseOrder/list';
import urls from 'app/urls';
import filters from './filters';
import styles from '../stockMoves.module.scss';

function BulkActions() {
  return (
    <>
      <DefaultBulkActions />
      <CustomBulkExport action={urls.StockMoves_BulkPdfExport} />
      <CustomBulkExport action={urls.StockMoves_ExcelExport} />
    </>
  );
}

export default React.memo(() => {
  const hasAccess = useHasAccess();
  const canShow = hasAccess({ url: 'stock/stock-moves/{id}', httpMethod: 'GET' });
  const s = Source<StockMoveEntity>();
  return (
    <List
      filters={filters}
      filterDefaultValues={{ moveType: StockMovesSearchMoveTypeEnum.Manual }}
    >
      <Datagrid
        bulkActionButtons={<BulkActions />}
        className={styles.smTable}
        rowClick="show"
      >
        <FunctionField
          render={(record: StockMoveEntity) => {
            const isApplied = !!record.appliedDate;

            console.log(record.appliedDate, isApplied);
            return (
              <Badges>
                <StatusBadge green={isApplied} orange={!isApplied} />
              </Badges>
            );
          }}
          label="State"
        />

        <ReferenceField {...s('sourceStockLocationId')} reference="stock/stocklocations">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField {...s('destinationStockLocationId')} reference="stock/stocklocations">
          <TextField source="name" />
        </ReferenceField>
        <DateField {...s('plannedDate')} showTime />
        <DateField {...s('appliedDate')} showTime />
        <TextField {...s('applier')} />
        <TextField {...s('comment')} />
        <NumberFormatField {...s('totalAmount')} />
        {canShow && <ShowButton label="" />}
      </Datagrid>
    </List>
  );
});
