/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: developer.contact@outfiz.vn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProductBillOfResourceLineSchema } from './ProductBillOfResourceLineSchema';
import {
    ProductBillOfResourceLineSchemaFromJSON,
    ProductBillOfResourceLineSchemaFromJSONTyped,
    ProductBillOfResourceLineSchemaToJSON,
} from './ProductBillOfResourceLineSchema';

/**
 * 
 * @export
 * @interface ProductBillOfResourcesDTO
 */
export interface ProductBillOfResourcesDTO {
    /**
     * 
     * @type {Array<ProductBillOfResourceLineSchema>}
     * @memberof ProductBillOfResourcesDTO
     */
    lines?: Array<ProductBillOfResourceLineSchema>;
}

/**
 * Check if a given object implements the ProductBillOfResourcesDTO interface.
 */
export function instanceOfProductBillOfResourcesDTO(value: object): boolean {
    return true;
}

export function ProductBillOfResourcesDTOFromJSON(json: any): ProductBillOfResourcesDTO {
    return ProductBillOfResourcesDTOFromJSONTyped(json, false);
}

export function ProductBillOfResourcesDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductBillOfResourcesDTO {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        
        'lines': !exists(json, 'lines') ? undefined : ((json['lines'] as Array<any>).map(ProductBillOfResourceLineSchemaFromJSON)),
    };
}

export function ProductBillOfResourcesDTOToJSON(value?: ProductBillOfResourcesDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lines': !exists(value, 'lines') ? undefined : ((value['lines'] as Array<any>).map(ProductBillOfResourceLineSchemaToJSON)),
    };
}

