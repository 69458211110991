import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import {
  RaRecord,
  SaveButton,
  SaveButtonProps,
} from 'react-admin';

export default function CustomSaveButton<RecordType extends RaRecord = any>(props: SaveButtonProps<RecordType>) {
  const form = useFormContext();

  React.useEffect(() => {
    form.reset();
  }, [form.formState.isSubmitSuccessful]);

  return <SaveButton {...props} />;
}
