/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: developer.contact@outfiz.vn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PurchasingPurchaseOrderLineDetail
 */
export interface PurchasingPurchaseOrderLineDetail {
    /**
     * 
     * @type {number}
     * @memberof PurchasingPurchaseOrderLineDetail
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchasingPurchaseOrderLineDetail
     */
    rawMaterialId: number;
    /**
     * 
     * @type {number}
     * @memberof PurchasingPurchaseOrderLineDetail
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof PurchasingPurchaseOrderLineDetail
     */
    comment?: string;
    /**
     * 
     * @type {Date}
     * @memberof PurchasingPurchaseOrderLineDetail
     */
    confirmedDeliveryDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof PurchasingPurchaseOrderLineDetail
     */
    quantityTypeId: number;
    /**
     * 
     * @type {string}
     * @memberof PurchasingPurchaseOrderLineDetail
     */
    articleNo: string;
    /**
     * 
     * @type {string}
     * @memberof PurchasingPurchaseOrderLineDetail
     */
    rawMaterial: string;
}

/**
 * Check if a given object implements the PurchasingPurchaseOrderLineDetail interface.
 */
export function instanceOfPurchasingPurchaseOrderLineDetail(value: object): boolean {
    if (!('rawMaterialId' in value)) return false;
    if (!('amount' in value)) return false;
    if (!('quantityTypeId' in value)) return false;
    if (!('articleNo' in value)) return false;
    if (!('rawMaterial' in value)) return false;
    return true;
}

export function PurchasingPurchaseOrderLineDetailFromJSON(json: any): PurchasingPurchaseOrderLineDetail {
    return PurchasingPurchaseOrderLineDetailFromJSONTyped(json, false);
}

export function PurchasingPurchaseOrderLineDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): PurchasingPurchaseOrderLineDetail {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'rawMaterialId': json['rawMaterialId'],
        'amount': json['amount'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'confirmedDeliveryDate': !exists(json, 'confirmedDeliveryDate') ? undefined : (new Date(json['confirmedDeliveryDate'])),
        'quantityTypeId': json['quantityTypeId'],
        'articleNo': json['articleNo'],
        'rawMaterial': json['rawMaterial'],
    };
}

export function PurchasingPurchaseOrderLineDetailToJSON(value?: PurchasingPurchaseOrderLineDetail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value['id'],
        'rawMaterialId': value['rawMaterialId'],
        'amount': value['amount'],
        'comment': value['comment'],
        'confirmedDeliveryDate': !exists(value, 'confirmedDeliveryDate') ? undefined : ((value['confirmedDeliveryDate'] as any).toISOString()),
        'quantityTypeId': value['quantityTypeId'],
        'articleNo': value['articleNo'],
        'rawMaterial': value['rawMaterial'],
    };
}

