import * as React from 'react';
import {
  Tab,
  SimpleShowLayout,
  TextField,
  TabbedShowLayout,
  ArrayField,
  Datagrid,
  Show,
  useRecordContext,
} from 'react-admin';
import { Grid } from '@mui/material';
import ShowActions from 'components/layout/actions/show';
import ShowTitle from 'components/commons/showTitle';
import ShowLabel from 'components/commons/showLabel';
import ShowField from 'components/commons/showField';
import ShowBooleanField from 'components/commons/showBooleanField';
import PriceField from 'components/fields/priceField';
import ShowQuantityType from 'components/fields/QuantityTypeField';
import Source from 'utils/source';
import { ProductRawMaterialDetailEntity, StockLinesLine } from 'api';
import OpenOrderLines from 'resources/purchasing/purchaseOrder/lines';
import CategoryField from 'components/fields/categoryField';

function RawMaterialTitle() {
  const record = useRecordContext<ProductRawMaterialDetailEntity>();

  return (
    <span>
      Raw Material #
      {record?.articleNo}
    </span>
  );
}

export default function () {
  const s = Source<ProductRawMaterialDetailEntity>();
  const sl = Source<StockLinesLine>();
  return (
    <Show<ProductRawMaterialDetailEntity>
      actions={<ShowActions />}
      title={<RawMaterialTitle />}
    >
      <SimpleShowLayout>
        <Grid container rowSpacing={1} spacing={1} sx={{ marginLeft: '3px', marginTop: '3px', marginBottom: '10px' }}>
          <Grid item md={12}>
            <ShowTitle />
          </Grid>

          <Grid className="show-row-odd" item md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label="resources.product/raw-materials.fields.businessPartnerName" />
          </Grid>
          <Grid item md={10}>
            <ShowField {...s('supplierName')} className="highLightField" />
          </Grid>
          <Grid className="show-row-odd" item md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label="resources.product/raw-materials.fields.name" />
          </Grid>
          <Grid item md={10}>
            <ShowField {...s('name')} className="highLightField" />
          </Grid>

          <Grid className="show-row-odd" item md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label="resources.product/raw-materials.fields.articleNo" />
          </Grid>
          <Grid item md={10}>
            <ShowField {...s('articleNo')} />
          </Grid>

        </Grid>
      </SimpleShowLayout>
      <TabbedShowLayout>
        <Tab label="resources.product/raw-materials.fields.detail">
          <Grid container rowSpacing={1} spacing={1} sx={{ marginLeft: '3px', marginTop: '3px', marginBottom: '10px' }}>
            <Grid item xs={12} md={12}>
              <ShowTitle />
            </Grid>

            <Grid item xs={4} md={3}>
              <ShowLabel label="resources.product/raw-materials.fields.isActive" />
            </Grid>
            <Grid item xs={8} md={9}>
              <ShowBooleanField {...s('isActive')} trueText="Active" falseText="Inactive" showIcon={false} />
            </Grid>

            <Grid className="show-row-odd" item xs={4} md={3} sx={{ borderBottom: '1' }}>
              <ShowLabel label="resources.product/raw-materials.fields.supplierName" />
            </Grid>
            <Grid item xs={4} md={3}>
              <ShowField {...s('supplierArticleName')} />
            </Grid>
            <Grid item xs={4} md={3}>
              <ShowField {...s('supplierArticleNo')} />
            </Grid>
            <Grid item xs={0} md={3} />

            <Grid className="show-row-odd" item xs={4} md={3} sx={{ borderBottom: '1' }}>
              <ShowLabel label="resources.product/raw-materials.fields.leadTime" />
            </Grid>
            <Grid item xs={8} md={9}>
              <ShowField {...s('leadTime')} className="highLightField" />
            </Grid>

            <Grid className="show-row-odd" item xs={4} md={3} sx={{ borderBottom: '1' }}>
              <ShowLabel label="resources.product/raw-materials.fields.moq" />
            </Grid>
            <Grid className="show-row-odd" item xs={8} md={9}>
              <ShowQuantityType {...s('moq')} className="highLightField" />
            </Grid>

            <Grid className="show-row-odd" item xs={4} md={3} sx={{ borderBottom: '1' }}>
              <ShowLabel label="resources.product/raw-materials.fields.stockConditioning" />
            </Grid>
            <Grid className="show-row-odd" item xs={8} md={9}>
              <ShowQuantityType {...s('stockConditioning')} className="highLightField" />
            </Grid>

            <Grid className="show-row-odd" item xs={4} md={3} sx={{ borderBottom: '1' }}>
              <ShowLabel label="resources.product/raw-materials.fields.supplierConditioning" />
            </Grid>
            <Grid className="show-row-odd" item xs={8} md={9}>
              <ShowQuantityType {...s('supplierConditioning')} className="highLightField" />
            </Grid>

            <Grid className="show-row-odd" item xs={4} md={3} sx={{ borderBottom: '1' }}>
              <ShowLabel label="resources.product/raw-materials.fields.price" />
            </Grid>
            <Grid item xs={8} md={9} style={{ display: 'flex', flexDirection: 'row' }}>
              <PriceField currencySource="currencyId" />
            </Grid>
            <Grid className="show-row-odd" item xs={4} md={3} sx={{ borderBottom: '1' }}>
              <ShowLabel label="resources.product/raw-materials.fields.categoryName" />
            </Grid>
            <Grid item xs={8} md={9}>
              <CategoryField {...s('categories')} />
            </Grid>
            <Grid className="show-row-odd" item xs={4} md={3} sx={{ borderBottom: '1' }}>
              <ShowLabel label="resources.product/raw-materials.fields.quantityTypeName" />
            </Grid>
            <Grid item xs={8} md={9}>
              <ShowField {...s('quantityType.name')} />
            </Grid>
            <Grid className="show-row-odd" item xs={4} md={3} sx={{ borderBottom: '1' }}>
              <ShowLabel label="resources.product/raw-materials.fields.stockTracingName" />
            </Grid>
            <Grid item xs={8} md={9}>
              <ShowField {...s('stockTracing')} showTime />
            </Grid>
            <Grid item xs={2} md={1.5} />
          </Grid>
        </Tab>
        <Tab label="resources.stock/stocklines.fields.name">
          <ArrayField label={false} {...s('stockLines')}>
            <Datagrid bulkActionButtons={false}>
              <TextField {...sl('stockLocation.name')} label="Stock Location" />
              <TextField {...sl('amount')} />
              <TextField {...sl('lotNo')} />
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label="open orders">
          <OpenOrderLines />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
}
