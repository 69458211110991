import englishMessages from 'ra-language-english';
import commons from './commons/en';
import msg from './systemMessages/en';
import pageUsers from './pages/users/en';
import pageGroups from './pages/groups/en';
import pageRawMaterials from './pages/rawMaterials/en';
import pageWorkloads from './pages/workloads/en';
import pageBusinessPartners from './pages/businessPartners/en';
import pageCategories from './pages/categories/en';
import incomingShipment from './pages/incomingShipment/en';
import pageProducts from './pages/products/en';
import pageStockLines from './pages/stockLines/en';
import pageStockMoves from './pages/stockMoves/en';
import inventory from './pages/inventory/en';

const msgTitle: any = {};
const messages = {
  ...englishMessages,
  resources: {
    ...pageUsers.resources,
    ...pageGroups.resources,
    ...pageRawMaterials.resources,
    ...pageWorkloads.resources,
    ...pageBusinessPartners.resources,
    ...pageCategories.resources,
    ...pageProducts.resources,
    ...pageStockLines.resources,
    ...pageStockMoves.resources,
    ...incomingShipment.resources,
    ...inventory.resources,
  },
  mai: {
    ...commons,
    ...pageUsers.mai,
    ...pageGroups.mai,
    ...pageRawMaterials.mai,
    ...pageWorkloads.mai,
    ...pageBusinessPartners.mai,
    ...pageCategories.mai,
    ...pageProducts.mai,
    ...pageStockLines.mai,
    ...pageStockMoves.mai,
    ...incomingShipment.mai,
    ...inventory.mai,
    msg: {
      ...msg,
    },
    msgTitle: {
      ...msgTitle,
    },
  },
};

export default messages;
