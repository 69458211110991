import * as React from 'react';
import {
  Create,
  SimpleForm,
  PasswordInput,
  TopToolbar,
  ListButton,
  required,
  regex,
} from 'react-admin';
import { Grid } from '@mui/material';
import CreateToolBar from 'components/commons/createToolBar';
import useHasAccess from 'hooks/useHasAccess';
import { useGroupsQuery } from 'repositories/auth/users';
import UserForm from './userForm';

export default React.memo(() => {
  const hasAccess = useHasAccess();
  const canPost = hasAccess({ url: 'auth/users/', httpMethod: 'POST' });
  const canSearch = hasAccess({ url: 'auth/users/', httpMethod: 'GET' });
  const { data } = useGroupsQuery();

  if (!data) return null;

  const confirmPasswordValidation = (value: string, allValues: any) => {
    if (allValues.confirmPassword !== value) {
      return 'mai.msg.cornfirm_password_invalidate';
    }
    return undefined;
  };
  return (
    <Create
      actions={(
        <TopToolbar>
          {canSearch && <ListButton />}
        </TopToolbar>
      )}
      className="mai-form"
    >
      <SimpleForm toolbar={<CreateToolBar isSave={canPost} />}>
        <UserForm />
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item xs={4} md={1} />
          <Grid item xs={8} md={5}>
            <PasswordInput
              source="password"
              validate={[
                required(),
                regex(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,50}$/, 'mai.msg.password_format_invalidate'),
              ]}
              fullWidth
              variant="outlined"
              helperText={false}
              inputProps={{ maxLength: 50 }}
            />
          </Grid>
          <Grid item xs={8} md={5}>
            <PasswordInput
              source="confirmPassword"
              validate={[
                required(),
                confirmPasswordValidation,
              ]}
              fullWidth
              variant="outlined"
              helperText={false}
              inputProps={{ maxLength: 50 }}
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
});
