import React from 'react';
import {
  AutocompleteInput,
  ReferenceInput,
  TextInput,
} from 'react-admin';
import CustomSearchInput from 'components/commons/customSearchInput';

export default [
  <CustomSearchInput source="query" alwaysOn placeholder_i18n="mai.keyword" />,
  <TextInput
    source="Phone"
    inputProps={{ maxLength: 15 }}
    variant="outlined"
  />,
  <TextInput
    source="Email"
    inputProps={{ maxLength: 255 }}
    variant="outlined"
  />,

  <ReferenceInput source="group" reference="auth/groups" isRequired>
    <AutocompleteInput
      variant="outlined"
      helperText={false}
      fullWidth
    />
  </ReferenceInput>,
];
