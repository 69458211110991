/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: developer.contact@outfiz.vn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Paging } from './Paging';
import {
    PagingFromJSON,
    PagingFromJSONTyped,
    PagingToJSON,
} from './Paging';
import type { StockMoveEntity } from './StockMoveEntity';
import {
    StockMoveEntityFromJSON,
    StockMoveEntityFromJSONTyped,
    StockMoveEntityToJSON,
} from './StockMoveEntity';

/**
 * 
 * @export
 * @interface PaginatedListStockMoveEntity
 */
export interface PaginatedListStockMoveEntity {
    /**
     * 
     * @type {Array<StockMoveEntity>}
     * @memberof PaginatedListStockMoveEntity
     */
    list: Array<StockMoveEntity>;
    /**
     * 
     * @type {Paging}
     * @memberof PaginatedListStockMoveEntity
     */
    paging: Paging;
}

/**
 * Check if a given object implements the PaginatedListStockMoveEntity interface.
 */
export function instanceOfPaginatedListStockMoveEntity(value: object): boolean {
    if (!('list' in value)) return false;
    if (!('paging' in value)) return false;
    return true;
}

export function PaginatedListStockMoveEntityFromJSON(json: any): PaginatedListStockMoveEntity {
    return PaginatedListStockMoveEntityFromJSONTyped(json, false);
}

export function PaginatedListStockMoveEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedListStockMoveEntity {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        
        'list': ((json['list'] as Array<any>).map(StockMoveEntityFromJSON)),
        'paging': PagingFromJSON(json['paging']),
    };
}

export function PaginatedListStockMoveEntityToJSON(value?: PaginatedListStockMoveEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'list': ((value['list'] as Array<any>).map(StockMoveEntityToJSON)),
        'paging': PagingToJSON(value['paging']),
    };
}

