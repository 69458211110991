/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: developer.contact@outfiz.vn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StockCreateLineDTO
 */
export interface StockCreateLineDTO {
    /**
     * 
     * @type {number}
     * @memberof StockCreateLineDTO
     */
    stockLineId: number;
    /**
     * 
     * @type {number}
     * @memberof StockCreateLineDTO
     */
    shelfId?: number;
    /**
     * 
     * @type {number}
     * @memberof StockCreateLineDTO
     */
    amount: number;
    /**
     * 
     * @type {boolean}
     * @memberof StockCreateLineDTO
     */
    moveCompleteLine: boolean;
}

/**
 * Check if a given object implements the StockCreateLineDTO interface.
 */
export function instanceOfStockCreateLineDTO(value: object): boolean {
    if (!('stockLineId' in value)) return false;
    if (!('amount' in value)) return false;
    if (!('moveCompleteLine' in value)) return false;
    return true;
}

export function StockCreateLineDTOFromJSON(json: any): StockCreateLineDTO {
    return StockCreateLineDTOFromJSONTyped(json, false);
}

export function StockCreateLineDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockCreateLineDTO {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        
        'stockLineId': json['stockLineId'],
        'shelfId': !exists(json, 'shelfId') ? undefined : json['shelfId'],
        'amount': json['amount'],
        'moveCompleteLine': json['moveCompleteLine'],
    };
}

export function StockCreateLineDTOToJSON(value?: StockCreateLineDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'stockLineId': value['stockLineId'],
        'shelfId': value['shelfId'],
        'amount': value['amount'],
        'moveCompleteLine': value['moveCompleteLine'],
    };
}

