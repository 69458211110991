/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: developer.contact@outfiz.vn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductBillOfResourceLineSchema
 */
export interface ProductBillOfResourceLineSchema {
    /**
     * 
     * @type {number}
     * @memberof ProductBillOfResourceLineSchema
     */
    resourceId: number;
    /**
     * 
     * @type {number}
     * @memberof ProductBillOfResourceLineSchema
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof ProductBillOfResourceLineSchema
     */
    comment?: string;
}

/**
 * Check if a given object implements the ProductBillOfResourceLineSchema interface.
 */
export function instanceOfProductBillOfResourceLineSchema(value: object): boolean {
    if (!('resourceId' in value)) return false;
    if (!('amount' in value)) return false;
    return true;
}

export function ProductBillOfResourceLineSchemaFromJSON(json: any): ProductBillOfResourceLineSchema {
    return ProductBillOfResourceLineSchemaFromJSONTyped(json, false);
}

export function ProductBillOfResourceLineSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductBillOfResourceLineSchema {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        
        'resourceId': json['resourceId'],
        'amount': json['amount'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
    };
}

export function ProductBillOfResourceLineSchemaToJSON(value?: ProductBillOfResourceLineSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'resourceId': value['resourceId'],
        'amount': value['amount'],
        'comment': value['comment'],
    };
}

