/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: developer.contact@outfiz.vn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Paging } from './Paging';
import {
    PagingFromJSON,
    PagingFromJSONTyped,
    PagingToJSON,
} from './Paging';
import type { PurchasingPurchaseOrderLineSolo } from './PurchasingPurchaseOrderLineSolo';
import {
    PurchasingPurchaseOrderLineSoloFromJSON,
    PurchasingPurchaseOrderLineSoloFromJSONTyped,
    PurchasingPurchaseOrderLineSoloToJSON,
} from './PurchasingPurchaseOrderLineSolo';

/**
 * 
 * @export
 * @interface PaginatedListPurchaseOrderLineSolo
 */
export interface PaginatedListPurchaseOrderLineSolo {
    /**
     * 
     * @type {Array<PurchasingPurchaseOrderLineSolo>}
     * @memberof PaginatedListPurchaseOrderLineSolo
     */
    list: Array<PurchasingPurchaseOrderLineSolo>;
    /**
     * 
     * @type {Paging}
     * @memberof PaginatedListPurchaseOrderLineSolo
     */
    paging: Paging;
}

/**
 * Check if a given object implements the PaginatedListPurchaseOrderLineSolo interface.
 */
export function instanceOfPaginatedListPurchaseOrderLineSolo(value: object): boolean {
    if (!('list' in value)) return false;
    if (!('paging' in value)) return false;
    return true;
}

export function PaginatedListPurchaseOrderLineSoloFromJSON(json: any): PaginatedListPurchaseOrderLineSolo {
    return PaginatedListPurchaseOrderLineSoloFromJSONTyped(json, false);
}

export function PaginatedListPurchaseOrderLineSoloFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedListPurchaseOrderLineSolo {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        
        'list': ((json['list'] as Array<any>).map(PurchasingPurchaseOrderLineSoloFromJSON)),
        'paging': PagingFromJSON(json['paging']),
    };
}

export function PaginatedListPurchaseOrderLineSoloToJSON(value?: PaginatedListPurchaseOrderLineSolo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'list': ((value['list'] as Array<any>).map(PurchasingPurchaseOrderLineSoloToJSON)),
        'paging': PagingToJSON(value['paging']),
    };
}

