/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: developer.contact@outfiz.vn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BusinessPartnerAddressSchema } from './BusinessPartnerAddressSchema';
import {
    BusinessPartnerAddressSchemaFromJSON,
    BusinessPartnerAddressSchemaFromJSONTyped,
    BusinessPartnerAddressSchemaToJSON,
} from './BusinessPartnerAddressSchema';

/**
 * 
 * @export
 * @interface BusinessPartnerCreate
 */
export interface BusinessPartnerCreate {
    /**
     * 
     * @type {number}
     * @memberof BusinessPartnerCreate
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof BusinessPartnerCreate
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessPartnerCreate
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessPartnerCreate
     */
    taxId?: string;
    /**
     * 
     * @type {BusinessPartnerAddressSchema}
     * @memberof BusinessPartnerCreate
     */
    address: BusinessPartnerAddressSchema;
}

/**
 * Check if a given object implements the BusinessPartnerCreate interface.
 */
export function instanceOfBusinessPartnerCreate(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    if (!('phone' in value)) return false;
    if (!('address' in value)) return false;
    return true;
}

export function BusinessPartnerCreateFromJSON(json: any): BusinessPartnerCreate {
    return BusinessPartnerCreateFromJSONTyped(json, false);
}

export function BusinessPartnerCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): BusinessPartnerCreate {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'phone': json['phone'],
        'taxId': !exists(json, 'taxId') ? undefined : json['taxId'],
        'address': BusinessPartnerAddressSchemaFromJSON(json['address']),
    };
}

export function BusinessPartnerCreateToJSON(value?: BusinessPartnerCreate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'phone': value['phone'],
        'taxId': value['taxId'],
        'address': BusinessPartnerAddressSchemaToJSON(value['address']),
    };
}

