const messages = {
  resources: {
    'stock/stocklines': {
      name: 'StockLine |||| StockLines',
      fields: {
        id: 'Id',
        name: 'StockLine',
        resourceName: 'Resource Name',
        amount: 'Amount',
        lotNo: 'LotNo',
        isDelete: 'Deleted',
        delFlag: 'Deleted',
        categoryId: 'Category',
      },
    },
  },
  mai: {
    stockLine: {
      selectStockLine: 'StockLine',
    },
  },
};

export default messages;
