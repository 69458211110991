import * as React from 'react';
import {
  DateField,
} from 'react-admin';

export default React.memo((props:any) => {
  const { className, source, showTime } = props;
  return (
    <DateField className={className} source={source} showTime={showTime} sx={{ verticalAlign: 'text-top' }} />
  );
});
