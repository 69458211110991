import BusinessPartnerField from 'components/fields/businessPartner';
import React from 'react';
import {
  Identifier,
  useInput,
} from 'react-admin';

interface BusinessPartnerInputProps {
  source: string;
  defaultValue?: Identifier;
  required?: boolean;
}

export default function BusinessPartnerInput(props: BusinessPartnerInputProps) {
  const {
    source,
    defaultValue,
    required,
  } = props;

  const field = useInput<number>({
    source,
    defaultValue,
    isRequired: required,
  });

  console.log('jo', field);

  return (
    <BusinessPartnerField
      onChange={field.field.onChange}
      value={field.field.value || null}
    />
  );
}
