import React from 'react';
import { useQuery } from 'react-query';
import { Button, Card, CardContent } from '@mui/material';
import repository from 'repositories';
import { FileDTO } from 'api';
import useHasAccess from 'hooks/useHasAccess';
import { Endpoint } from 'app/urls';
import DownloadIcon from '@mui/icons-material/Download';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DoneIcon from '@mui/icons-material/Done';
import FileInput from './fileUpload';
import styles from './FileView.module.scss';

export function fileUrl(endpoint: string, id: number) {
  return `/api/1.0/${endpoint}/${id}`;
}

export function FileListView(props: { endpoint: string }) {
  const {
    endpoint,
  } = props;

  const { data: files } = useQuery([endpoint], async () => {
    const data = await repository.get<FileDTO[]>(endpoint);
    return data.data;
  }, { staleTime: 0 });

  return (
    <div className={styles.files}>
      <div className="files">
        {files?.map((file) => (
          <div className="file" key={file.id}>
            <p>{file.fileName}</p>
            <div>
              <Button target="_blank" href={fileUrl(endpoint, file.id)}><DownloadIcon /></Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

interface FileListViewProps {
  endpoint: string,
  getUrl?: Endpoint,
  uploadUrl?: Endpoint
}

export default function FileView(props: FileListViewProps) {
  const {
    getUrl,
    uploadUrl,
  } = props;
  const [isEdit, setIsEdit] = React.useState(false);
  const hasAccess = useHasAccess();
  const hasUploadAccess = uploadUrl === undefined ? true : hasAccess(uploadUrl);
  const hasShowAccess = getUrl === undefined ? true : hasAccess(getUrl);

  if (!hasShowAccess) {
    return null;
  }

  let inner;
  let header;
  if (!isEdit) {
    inner = <FileListView {...props} />;
    header = (
      <div className="title">
        <h3>Files</h3>
        <Button
          startIcon={<FileUploadIcon />}
          variant="outlined"
          onClick={() => setIsEdit(!isEdit)}
          disabled={!hasUploadAccess}
        >
          Upload
        </Button>
      </div>
    );
  } else {
    inner = <FileInput {...props} />;
    header = (
      <div className="title">
        <h3>File Upload</h3>
        <Button
          variant="outlined"
          onClick={() => setIsEdit(false)}
          startIcon={<DoneIcon />}
        >
          Done
        </Button>
      </div>
    );
  }

  return (
    <Card className={styles.fileView}>
      <CardContent>
        {header}
        {inner}
      </CardContent>
    </Card>
  );
}
