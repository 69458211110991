/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: developer.contact@outfiz.vn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuthUserInfo
 */
export interface AuthUserInfo {
    /**
     * 
     * @type {string}
     * @memberof AuthUserInfo
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthUserInfo
     */
    firstname?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthUserInfo
     */
    lastname?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthUserInfo
     */
    gender?: AuthUserInfoGenderEnum;
    /**
     * 
     * @type {Date}
     * @memberof AuthUserInfo
     */
    birthday?: Date;
    /**
     * 
     * @type {string}
     * @memberof AuthUserInfo
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthUserInfo
     */
    note?: string;
}


/**
 * @export
 */
export const AuthUserInfoGenderEnum = {
    Male: 'Male',
    Female: 'Female',
    Other: 'Other'
} as const;
export type AuthUserInfoGenderEnum = typeof AuthUserInfoGenderEnum[keyof typeof AuthUserInfoGenderEnum];


/**
 * Check if a given object implements the AuthUserInfo interface.
 */
export function instanceOfAuthUserInfo(value: object): boolean {
    return true;
}

export function AuthUserInfoFromJSON(json: any): AuthUserInfo {
    return AuthUserInfoFromJSONTyped(json, false);
}

export function AuthUserInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthUserInfo {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'firstname': !exists(json, 'firstname') ? undefined : json['firstname'],
        'lastname': !exists(json, 'lastname') ? undefined : json['lastname'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'birthday': !exists(json, 'birthday') ? undefined : (new Date(json['birthday'])),
        'address': !exists(json, 'address') ? undefined : json['address'],
        'note': !exists(json, 'note') ? undefined : json['note'],
    };
}

export function AuthUserInfoToJSON(value?: AuthUserInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'phone': value['phone'],
        'firstname': value['firstname'],
        'lastname': value['lastname'],
        'gender': value['gender'],
        'birthday': !exists(value, 'birthday') ? undefined : ((value['birthday'] as any).toISOString()),
        'address': value['address'],
        'note': value['note'],
    };
}

