/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: developer.contact@outfiz.vn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StockShipmentLineDetail
 */
export interface StockShipmentLineDetail {
    /**
     * 
     * @type {number}
     * @memberof StockShipmentLineDetail
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof StockShipmentLineDetail
     */
    rawMaterialId: number;
    /**
     * 
     * @type {number}
     * @memberof StockShipmentLineDetail
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof StockShipmentLineDetail
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof StockShipmentLineDetail
     */
    articleNo?: string;
    /**
     * 
     * @type {string}
     * @memberof StockShipmentLineDetail
     */
    rawMaterialName: string;
    /**
     * 
     * @type {number}
     * @memberof StockShipmentLineDetail
     */
    quantityTypeId: number;
}

/**
 * Check if a given object implements the StockShipmentLineDetail interface.
 */
export function instanceOfStockShipmentLineDetail(value: object): boolean {
    if (!('rawMaterialId' in value)) return false;
    if (!('amount' in value)) return false;
    if (!('rawMaterialName' in value)) return false;
    if (!('quantityTypeId' in value)) return false;
    return true;
}

export function StockShipmentLineDetailFromJSON(json: any): StockShipmentLineDetail {
    return StockShipmentLineDetailFromJSONTyped(json, false);
}

export function StockShipmentLineDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockShipmentLineDetail {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'rawMaterialId': json['rawMaterialId'],
        'amount': json['amount'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'articleNo': !exists(json, 'articleNo') ? undefined : json['articleNo'],
        'rawMaterialName': json['rawMaterialName'],
        'quantityTypeId': json['quantityTypeId'],
    };
}

export function StockShipmentLineDetailToJSON(value?: StockShipmentLineDetail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value['id'],
        'rawMaterialId': value['rawMaterialId'],
        'amount': value['amount'],
        'comment': value['comment'],
        'articleNo': value['articleNo'],
        'rawMaterialName': value['rawMaterialName'],
        'quantityTypeId': value['quantityTypeId'],
    };
}

