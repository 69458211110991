const messages = {
  resources: {
    'product/categories': {
      name: 'Categories',
      fields: {
        name: 'Name',
        fullName: 'Full Name',
        position: 'Position',
        description: 'Description',
        parentId: 'Parent',
        color: 'Color',
      },
    },
  },
  mai: {},
};

export default messages;
