/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: developer.contact@outfiz.vn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Paging } from './Paging';
import {
    PagingFromJSON,
    PagingFromJSONTyped,
    PagingToJSON,
} from './Paging';
import type { StockShelf } from './StockShelf';
import {
    StockShelfFromJSON,
    StockShelfFromJSONTyped,
    StockShelfToJSON,
} from './StockShelf';

/**
 * 
 * @export
 * @interface PaginatedListShelf
 */
export interface PaginatedListShelf {
    /**
     * 
     * @type {Array<StockShelf>}
     * @memberof PaginatedListShelf
     */
    list: Array<StockShelf>;
    /**
     * 
     * @type {Paging}
     * @memberof PaginatedListShelf
     */
    paging: Paging;
}

/**
 * Check if a given object implements the PaginatedListShelf interface.
 */
export function instanceOfPaginatedListShelf(value: object): boolean {
    if (!('list' in value)) return false;
    if (!('paging' in value)) return false;
    return true;
}

export function PaginatedListShelfFromJSON(json: any): PaginatedListShelf {
    return PaginatedListShelfFromJSONTyped(json, false);
}

export function PaginatedListShelfFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedListShelf {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        
        'list': ((json['list'] as Array<any>).map(StockShelfFromJSON)),
        'paging': PagingFromJSON(json['paging']),
    };
}

export function PaginatedListShelfToJSON(value?: PaginatedListShelf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'list': ((value['list'] as Array<any>).map(StockShelfToJSON)),
        'paging': PagingToJSON(value['paging']),
    };
}

