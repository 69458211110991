/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: developer.contact@outfiz.vn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SystemTupleInt64Decimal } from './SystemTupleInt64Decimal';
import {
    SystemTupleInt64DecimalFromJSON,
    SystemTupleInt64DecimalFromJSONTyped,
    SystemTupleInt64DecimalToJSON,
} from './SystemTupleInt64Decimal';

/**
 * 
 * @export
 * @interface PurchasingCreateFromBalanceRequest
 */
export interface PurchasingCreateFromBalanceRequest {
    /**
     * 
     * @type {string}
     * @memberof PurchasingCreateFromBalanceRequest
     */
    prefix: string;
    /**
     * 
     * @type {Date}
     * @memberof PurchasingCreateFromBalanceRequest
     */
    plannedDate: Date;
    /**
     * 
     * @type {Array<SystemTupleInt64Decimal>}
     * @memberof PurchasingCreateFromBalanceRequest
     */
    balance: Array<SystemTupleInt64Decimal>;
}

/**
 * Check if a given object implements the PurchasingCreateFromBalanceRequest interface.
 */
export function instanceOfPurchasingCreateFromBalanceRequest(value: object): boolean {
    if (!('prefix' in value)) return false;
    if (!('plannedDate' in value)) return false;
    if (!('balance' in value)) return false;
    return true;
}

export function PurchasingCreateFromBalanceRequestFromJSON(json: any): PurchasingCreateFromBalanceRequest {
    return PurchasingCreateFromBalanceRequestFromJSONTyped(json, false);
}

export function PurchasingCreateFromBalanceRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PurchasingCreateFromBalanceRequest {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        
        'prefix': json['prefix'],
        'plannedDate': (new Date(json['plannedDate'])),
        'balance': ((json['balance'] as Array<any>).map(SystemTupleInt64DecimalFromJSON)),
    };
}

export function PurchasingCreateFromBalanceRequestToJSON(value?: PurchasingCreateFromBalanceRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'prefix': value['prefix'],
        'plannedDate': ((value['plannedDate']).toISOString()),
        'balance': ((value['balance'] as Array<any>).map(SystemTupleInt64DecimalToJSON)),
    };
}

