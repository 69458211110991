/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: developer.contact@outfiz.vn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StockShipmentLineSchema
 */
export interface StockShipmentLineSchema {
    /**
     * 
     * @type {number}
     * @memberof StockShipmentLineSchema
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof StockShipmentLineSchema
     */
    rawMaterialId: number;
    /**
     * 
     * @type {number}
     * @memberof StockShipmentLineSchema
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof StockShipmentLineSchema
     */
    comment?: string;
}

/**
 * Check if a given object implements the StockShipmentLineSchema interface.
 */
export function instanceOfStockShipmentLineSchema(value: object): boolean {
    if (!('rawMaterialId' in value)) return false;
    if (!('amount' in value)) return false;
    return true;
}

export function StockShipmentLineSchemaFromJSON(json: any): StockShipmentLineSchema {
    return StockShipmentLineSchemaFromJSONTyped(json, false);
}

export function StockShipmentLineSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockShipmentLineSchema {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'rawMaterialId': json['rawMaterialId'],
        'amount': json['amount'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
    };
}

export function StockShipmentLineSchemaToJSON(value?: StockShipmentLineSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value['id'],
        'rawMaterialId': value['rawMaterialId'],
        'amount': value['amount'],
        'comment': value['comment'],
    };
}

