/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: developer.contact@outfiz.vn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductBillOfResourceLineDetail
 */
export interface ProductBillOfResourceLineDetail {
    /**
     * 
     * @type {number}
     * @memberof ProductBillOfResourceLineDetail
     */
    resourceId: number;
    /**
     * 
     * @type {string}
     * @memberof ProductBillOfResourceLineDetail
     */
    resourceName: string;
    /**
     * 
     * @type {number}
     * @memberof ProductBillOfResourceLineDetail
     */
    quantityTypeId: number;
    /**
     * 
     * @type {number}
     * @memberof ProductBillOfResourceLineDetail
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof ProductBillOfResourceLineDetail
     */
    comment?: string;
}

/**
 * Check if a given object implements the ProductBillOfResourceLineDetail interface.
 */
export function instanceOfProductBillOfResourceLineDetail(value: object): boolean {
    if (!('resourceId' in value)) return false;
    if (!('resourceName' in value)) return false;
    if (!('quantityTypeId' in value)) return false;
    if (!('amount' in value)) return false;
    return true;
}

export function ProductBillOfResourceLineDetailFromJSON(json: any): ProductBillOfResourceLineDetail {
    return ProductBillOfResourceLineDetailFromJSONTyped(json, false);
}

export function ProductBillOfResourceLineDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductBillOfResourceLineDetail {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        
        'resourceId': json['resourceId'],
        'resourceName': json['resourceName'],
        'quantityTypeId': json['quantityTypeId'],
        'amount': json['amount'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
    };
}

export function ProductBillOfResourceLineDetailToJSON(value?: ProductBillOfResourceLineDetail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'resourceId': value['resourceId'],
        'resourceName': value['resourceName'],
        'quantityTypeId': value['quantityTypeId'],
        'amount': value['amount'],
        'comment': value['comment'],
    };
}

