import * as React from 'react';
import {
  TextField,
} from 'react-admin';
import TranslateField from './translateField';

export default React.memo((props:any) => {
  const { className, source, isTranslate } = props;
  return isTranslate
    ? (<TranslateField className={className} source={source} sx={{ verticalAlign: 'text-top' }} />)
    : (<TextField className={className} source={source} sx={{ verticalAlign: 'text-top' }} />);
});
