/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: developer.contact@outfiz.vn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProductBillOfResourcesDetailDTO } from './ProductBillOfResourcesDetailDTO';
import {
    ProductBillOfResourcesDetailDTOFromJSON,
    ProductBillOfResourcesDetailDTOFromJSONTyped,
    ProductBillOfResourcesDetailDTOToJSON,
} from './ProductBillOfResourcesDetailDTO';
import type { StockLinesLine } from './StockLinesLine';
import {
    StockLinesLineFromJSON,
    StockLinesLineFromJSONTyped,
    StockLinesLineToJSON,
} from './StockLinesLine';

/**
 * 
 * @export
 * @interface ProductDetailSchema
 */
export interface ProductDetailSchema {
    /**
     * 
     * @type {string}
     * @memberof ProductDetailSchema
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailSchema
     */
    articleNo?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductDetailSchema
     */
    isActive: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductDetailSchema
     */
    isUniqueLotNo: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailSchema
     */
    currencyId: string;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailSchema
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailSchema
     */
    stockTracing: ProductDetailSchemaStockTracingEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailSchema
     */
    quantityTypeId: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductDetailSchema
     */
    categories: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailSchema
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailSchema
     */
    quantityTypeName: string;
    /**
     * 
     * @type {Array<StockLinesLine>}
     * @memberof ProductDetailSchema
     */
    stockLines: Array<StockLinesLine>;
    /**
     * 
     * @type {ProductBillOfResourcesDetailDTO}
     * @memberof ProductDetailSchema
     */
    billOfResources?: ProductBillOfResourcesDetailDTO;
}


/**
 * @export
 */
export const ProductDetailSchemaStockTracingEnum = {
    None: 'None',
    Lot: 'Lot',
    Serial: 'Serial'
} as const;
export type ProductDetailSchemaStockTracingEnum = typeof ProductDetailSchemaStockTracingEnum[keyof typeof ProductDetailSchemaStockTracingEnum];


/**
 * Check if a given object implements the ProductDetailSchema interface.
 */
export function instanceOfProductDetailSchema(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('isActive' in value)) return false;
    if (!('isUniqueLotNo' in value)) return false;
    if (!('currencyId' in value)) return false;
    if (!('price' in value)) return false;
    if (!('stockTracing' in value)) return false;
    if (!('quantityTypeId' in value)) return false;
    if (!('categories' in value)) return false;
    if (!('id' in value)) return false;
    if (!('quantityTypeName' in value)) return false;
    if (!('stockLines' in value)) return false;
    return true;
}

export function ProductDetailSchemaFromJSON(json: any): ProductDetailSchema {
    return ProductDetailSchemaFromJSONTyped(json, false);
}

export function ProductDetailSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductDetailSchema {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'articleNo': !exists(json, 'articleNo') ? undefined : json['articleNo'],
        'isActive': json['isActive'],
        'isUniqueLotNo': json['isUniqueLotNo'],
        'currencyId': json['currencyId'],
        'price': json['price'],
        'stockTracing': json['stockTracing'],
        'quantityTypeId': json['quantityTypeId'],
        'categories': json['categories'],
        'id': json['id'],
        'quantityTypeName': json['quantityTypeName'],
        'stockLines': ((json['stockLines'] as Array<any>).map(StockLinesLineFromJSON)),
        'billOfResources': !exists(json, 'billOfResources') ? undefined : ProductBillOfResourcesDetailDTOFromJSON(json['billOfResources']),
    };
}

export function ProductDetailSchemaToJSON(value?: ProductDetailSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value['name'],
        'articleNo': value['articleNo'],
        'isActive': value['isActive'],
        'isUniqueLotNo': value['isUniqueLotNo'],
        'currencyId': value['currencyId'],
        'price': value['price'],
        'stockTracing': value['stockTracing'],
        'quantityTypeId': value['quantityTypeId'],
        'categories': value['categories'],
        'id': value['id'],
        'quantityTypeName': value['quantityTypeName'],
        'stockLines': ((value['stockLines'] as Array<any>).map(StockLinesLineToJSON)),
        'billOfResources': ProductBillOfResourcesDetailDTOToJSON(value['billOfResources']),
    };
}

