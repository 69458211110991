/* eslint-disable max-len */
import { Grid } from '@mui/material';
import { ResponseInfo } from 'api';
import ProductChooser from 'components/chooser/productChooser';
import { ResourceQuantityInput } from 'components/input/quantity/resourceFields';
import CustomSaveButton from 'components/input/saveButton';
import React from 'react';
import {
  Button,
  Edit,
  FormDataConsumer,
  HttpError,
  SimpleForm,
  TextInput,
} from 'react-admin';
import EditActions from 'components/layout/actions/edit';
import { useParams } from 'react-router-dom';
import api from 'repositories/api';
import repositories, { useReceiveShipment } from 'repositories/stock/receiveShipment';
import useNotify from 'utils/notify';
import { useFormContext } from 'react-hook-form';
import exportStockLineQrCode from 'resources/stock/stockLine/qrCode';
import ReceiveShipmentTable from './form/receiveShipmentTable';
import BarcodeHandler from './barcode';
import FormState from './form/state';

function generateRandomLotNo() {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const date = new Date();
  let result = `${date.getFullYear()}-${date.getUTCMonth() + 1}-${date.getUTCDate()}-`;
  for (let i = 0; i < 4; i += 1) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }
  return result;
}

interface LotNoGeneratorProps {
  source: string
}

function LotNoGenerator(props: LotNoGeneratorProps) {
  const {
    source,
  } = props;
  const formState = useFormContext();
  const onClick = () => {
    formState.setValue(source, generateRandomLotNo());
  };
  const value = formState.watch(source);

  return (
    <Button
      variant="outlined"
      disabled={!!value}
      onClick={onClick}
    >
      <span>Generate random LotNo</span>
    </Button>
  );
}

export default function () {
  const { id } = useParams();
  const shipmentId = Number(id);
  const notify = useNotify();
  const { data: stockMove, refetch } = useReceiveShipment({ id: shipmentId });

  const handleSave = async (data: FormState) => {
    const postData = data;
    if (postData.resourceId === undefined || postData.amount === undefined || postData.stockLotName === undefined) {
      throw new Error('invalid amount');
    }
    try {
      const response = await api.receiveShipmentAddLine({
        id: shipmentId,
        stockReceiveShipmentLine: {
          rawMaterialId: postData.resourceId,
          amount: postData.amount,
          stockLotName: postData.stockLotName,
        },
      });
      await refetch();
      exportStockLineQrCode([{
        ...response,
        lotDate: response.stockLine.lotDate,
        lotNo: response.stockLine.lotNo,
        labelId: response.stockLine.labelId,
      }]);
    } catch (e) {
      if (e instanceof HttpError) {
        notify(e, { type: 'error' });
      }
    }
  };
  const onDelete = async (lineId: number) => {
    const response: ResponseInfo = await repositories.deleteReceiveLine({
      id: shipmentId,
      lineId,
    });
    if (response.code === 200) {
      await refetch();
    }
  };

  return (
    <div>
      <Edit actions={<EditActions />}>
        <ReceiveShipmentTable
          listMaterials={stockMove?.lines ?? []}
          isDelete={!stockMove?.appliedDate}
          onDelete={onDelete}
        />

      </Edit>
      <SimpleForm
        mode="onSubmit"
        onSubmit={(ev) => handleSave(ev)}
        toolbar={<CustomSaveButton />}
      >
        {
          stockMove && !stockMove?.appliedDate && (
            <>
              <Grid container spacing={2} rowSpacing={0.5} sx={{ marginTop: '20px', marginBottom: '20px' }}>
                <Grid item xs={4} md={1} />
                <Grid item xs={4} md={5}>
                  <ProductChooser
                    source="resourceId"
                    isFullWidth
                    required
                    label="resources.manufacturing/workloads.fields.resources"
                    sx={{ width: '40%' }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} rowSpacing={0.5}>
                <Grid item xs={4} md={1} />
                <Grid item xs={8} md={5}>
                  <TextInput
                    source="stockLotName"
                    fullWidth
                    required
                    label="mai.incomingShipment.receive.LotNo"
                  />
                </Grid>
                <Grid item xs={8} md={5}>
                  <LotNoGenerator source="stockLotName" />
                </Grid>
              </Grid>

              <Grid container spacing={2} rowSpacing={0.5}>
                <Grid item xs={4} md={1} />
                <Grid item xs={8} md={5}>
                  <FormDataConsumer>
                    {({ formData }) => (
                      <ResourceQuantityInput
                        className="x"
                        source="amount"
                        required
                        label="mai.incomingShipment.receive.Amount"
                        resourceId={Number(formData.resourceId)}
                      />

                    )}
                  </FormDataConsumer>
                </Grid>
              </Grid>
              <BarcodeHandler onSubmit={handleSave} />
            </>
          )
        }

      </SimpleForm>

    </div>
  );
}
