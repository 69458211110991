const messages = {
  resources: {
    'auth/users': {
      name: 'User |||| Users',
      fields: {
        fullname: 'Full name',
        firstname: 'First name',
        lastname: 'Last name',
        username: 'Username',
        email: 'Email',
        phone: 'Phone',
        Phone: 'Phone',
        lastLogin: 'Last login',
        lastLogout: 'Last logout',
        isActive: 'Active',
        isVerifyEmail: 'Verify email',
        status: 'Status',
        Group: 'Group',
        Query: 'Search',
        groups: 'Groups',
        groupIds: 'Groups',
        gender: 'Gender',
        birthday: 'Date of birth',
        address: 'Address',
        note: 'Note',
        password: 'Password',
        confirmPassword: 'Confirm password',
      },
    },
  },
  mai: {
    auth: {
      resetPassword: 'Reset Password',
      lockAccount: 'Lock',
      unlockAccount: 'UnLock',
    },
  },
};

export default messages;
