import { Grid } from '@mui/material';
import { ProductUpdateSchema } from 'api';
import EditActions from 'components/layout/actions/edit';
import PriceInput from 'components/input/priceInput';
import Loading from 'components/shared/loading';
import * as React from 'react';
import {
  BooleanInput,
  Edit,
  FormDataConsumer,
  required,
  SelectInput,
  TabbedForm,
  TextInput,
} from 'react-admin';
import { useCurrencies, useEnumQuery } from 'repositories/app';
import Source from 'utils/source';
import { CategoryInput } from 'components/chooser/categoryChoose';
import BillOfResourcesForm from '../billOfResourceForm';

export default React.memo(() => {
  const { data: currencies } = useCurrencies();
  const { data: enums } = useEnumQuery();
  const s = Source<ProductUpdateSchema>();

  if (!currencies || !enums) {
    return <Loading />;
  }
  return (
    <Edit
      actions={<EditActions />}
      mutationMode="pessimistic"
      className="mai-form"
    >
      <TabbedForm>
        <TabbedForm.Tab label="base">
          <Grid container spacing={2} rowSpacing={0.5}>
            <Grid item xs={2} md={1} />
            <Grid item xs={8} md={10}>
              <TextInput
                {...s('name')}
                validate={required()}
                fullWidth
                inputProps={{ maxLength: 100 }}
                variant="outlined"
                helperText={false}
              />
            </Grid>
            <Grid item xs={2} md={1} />

            <Grid item xs={2} md={1} />
            <Grid item xs={4} md={3}>
              <CategoryInput
                {...s('categories')}
                fullWidth
                variant="outlined"
                validate={[required()]}
                required
              />
            </Grid>
            <Grid item xs={4} md={3}>
              <SelectInput
                {...s('currencyId')}
                choices={currencies}
                validate={[required()]}
                fullWidth
                variant="outlined"
                helperText={false}
              />
            </Grid>
            <Grid item xs={4} md={3}>
              <BooleanInput {...s('isActive')} />
            </Grid>
            <Grid item xs={2} md={2} />

            <Grid item xs={2} md={1} />
            <Grid item xs={4} md={5}>
              <FormDataConsumer>
                {({ formData }) => <PriceInput {...s('price')} currencyId={formData.currencyId} />}
              </FormDataConsumer>
            </Grid>
            <Grid item xs={2} md={1} />
          </Grid>
        </TabbedForm.Tab>
        <TabbedForm.Tab label="resources.billOfResources">
          <BillOfResourcesForm source="billOfResources" />
        </TabbedForm.Tab>
      </TabbedForm>
    </Edit>
  );
});
