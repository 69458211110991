const messages = {
  resources: {
    'stock/stock-moves': {
      name: 'Di chuyển tài nguyên',
      fields: {
        comment: 'Ghi chú',
        plannedDate: 'Thời gian dự định',
        appliedDate: 'Thời gian thực thi',
        appliedBy: 'Người thực thi',
        applier: 'Người thực thi',
        planed: 'Dự định',
        planedSince: 'Dự định từ',
        planedUntil: 'Dự định đến',
        applied: 'Thực thi',
        appliedSince: 'Thực thi từ',
        appliedUntil: 'Thực thi đến',
        stockMoveLines: 'Chi tiết di chuyển',
        resourceId: 'Tài nguyên',
        totalAmount: 'Tổng số lượng',
        source_location: 'Nguồn',
        target_location: 'Đích',
        creator: 'Người tạo',
      },
    },
  },
  mai: {
    stockMove: {
      apply_btn: 'THỰC THI',
      apply_confirm_title: 'Thực thi di chuyển tài nguyên',
      apply_confirm_content: 'Bạn có muốn thực thi việc di chuyển tài nguyên này không?',
      apply_success: 'Đã thực thi thành công',
      apply_failed: 'Thực thi thất bại',
      undo_apply_btn: 'HOÀN TÁC',
      undo_apply_confirm_title: 'Hoàn tác di chuyển tài nguyên',
      undo_apply_confirm_content: 'Bạn có muốn hoàn tác việc di chuyển tài nguyên này không?',
      undo_apply_success: 'Hoàn tác thành công',
      undo_apply_failed: 'Hoàn tác thất bại',
    },
    stockRawMaterials: {
      suplierEntered: 'Nhà cung cấp đã nhập',
    },
  },
};

export default messages;
