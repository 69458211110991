const messages = {
  profile: 'Profile',
  changePassword: 'Change password',
  downloadTranslations: 'Download Translations',
  logout: 'Logout',
  firstname: 'First name',
  lastname: 'Last name',
  username: 'Username',
  email: 'Email',
  phone: 'Phone',
  lastLogin: 'Last login',
  lastLogout: 'Last logout',
  isActive: 'Active',
  isVerifyEmail: 'Verify email',
  status: 'Status',
  group: 'Group',
  groups: 'Groups',
  gender: 'Gender',
  genders: {
    Male: 'Male',
    Female: 'Female',
    Other: 'Other',
  },
  birthday: 'Date of birth',
  address: 'Address',
  note: 'Note',
  password: 'Password',
  oldPassword: 'Old Password',
  newPassword: 'New Password',
  confirmPassword: 'Confirm password',
  using: 'Using',
  locking: 'Loking',
  default_group: 'Default group',
  private_group: 'Private group',
  custom_group: 'Custom group',
  resources: 'Resources',
  resource: 'Resource',
  categories: 'Categories',
  amount: 'Amount',
  comment: 'Comment',
  configuration: 'Configuration',
  darklightmode: 'Dark/Light Mode',
  dark: 'Dark',
  light: 'Light',
  keyword: 'Keyword',
  signin: 'SIGN IN',
  detail: 'Details',
  dashboard: 'Dashboard',
  second: 'second',
  day: 'day',
  confirm: 'Confirm',
  menu: {
    dashboard: 'Dashboard',
    parties: 'Parties',
    users: 'Users',
    groups: 'Groups',
    business_partners: 'Business partners',
    products: 'Products',
    categories: 'Categories',
    raw_materials: 'Raw materials',
    workloads: 'Workloads',
    stock: 'Stock',
    incoming_shipments: 'Incoming Shipments',
    stocksplit: 'Split',
    receive_shipments: 'Receive Shipments',
    stock_lines: 'Stock lines',
    moves: 'Moves',
    production: 'Production',
    purchasing: 'Purchasing',
    inventory_controls: 'Inventory Controls',
    purchase_order: 'Purchase Order',
  },
};

export default messages;
