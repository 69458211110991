const messages = {
  resources: {
    'auth/groups': {
      name: 'Nhóm tài khoản |||| Nhóm tài khoản',
      fields: {
        name: 'Tên nhóm',
        id: 'Key',
        description: 'Mô tả về nhóm',
        typeName: 'Loại',
      },
    },
  },
  mai: {
    group: 'Nhóm tài khoản',
    permissions: 'Quyền hạn',
  },
};

export default messages;
