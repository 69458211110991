import {
  Button, Dialog, DialogContent, DialogTitle,
} from '@mui/material';
import React from 'react';
import {
  TextInput, useListContext, useNotify, useRecordSelection, useResourceContext,
} from 'react-admin';
import api from 'repositories/api';
import { FormProvider, useForm } from 'react-hook-form';
import Source from 'utils/source';
import CustomDatePicker from 'components/input/date/CustomDatePicker';
import PasteBillOfResources from 'resources/product/pasteBillOfResources';
import styles from './batchCreate.module.scss';

interface FormData {
  plannedDate: Date,
  prefix: string
}
interface BatchFormProps {
  onSubmit: (arg0: FormData) => void,
}

function BatchForm(props: BatchFormProps) {
  const {
    onSubmit,
  } = props;
  const list = useListContext();
  const form = useForm<FormData>();
  const s = Source<FormData>();

  const submit = React.useMemo(() => form.handleSubmit((data: FormData) => {
    const newData = { ...data };
    if (data.prefix.toUpperCase().startsWith('ADV')) {
      newData.prefix = data.prefix.slice(3);
    }
    list.setSort({ field: 'createdAt', order: 'DESC' });
    onSubmit(newData);
  }), [form, list, onSubmit]);

  return (
    <form {...form} onSubmit={submit} className={styles.form}>
      <FormProvider {...form}>
        <CustomDatePicker {...s('plannedDate')} />
        <TextInput {...s('prefix')} />
        <Button type="submit">Submit</Button>
      </FormProvider>
    </form>
  );
}

export default function BatchCreate() {
  const [items, setItems] = React.useState<[number, number][]>([]);
  const resource = useResourceContext();
  const [, { select }] = useRecordSelection(resource);
  const { refetch } = useListContext();
  const notify = useNotify();

  const close = React.useCallback(() => {
    setItems([]);
  }, [setItems]);

  const submit = React.useCallback(
    async (data: FormData) => {
      console.log(data);
      const ids = await api.purchaseOrderBulkCreate({
        purchasingCreateFromBalanceRequest: {
          balance: items.map((x) => ({ item1: x[0], item2: x[1] })),
          ...data,
        },
      });
      notify('orders created', { type: 'success' });
      // unselect();
      refetch();
      select(ids);
      close();
    },
    [notify, refetch, select, close, items],
  );

  return (
    <>
      <PasteBillOfResources onSubmit={async (data) => setItems(data)} />
      <Dialog open={items.length > 0} onClose={close}>
        <DialogTitle>Paste Balance</DialogTitle>
        <DialogContent
          className={styles.dialog_content}
          autoFocus
        >
          <BatchForm onSubmit={submit} />
        </DialogContent>
      </Dialog>
    </>
  );
}
