/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: developer.contact@outfiz.vn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StockShipmentLineDetail } from './StockShipmentLineDetail';
import {
    StockShipmentLineDetailFromJSON,
    StockShipmentLineDetailFromJSONTyped,
    StockShipmentLineDetailToJSON,
} from './StockShipmentLineDetail';

/**
 * 
 * @export
 * @interface StockIncomingShipmentDetail
 */
export interface StockIncomingShipmentDetail {
    /**
     * 
     * @type {number}
     * @memberof StockIncomingShipmentDetail
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof StockIncomingShipmentDetail
     */
    state: StockIncomingShipmentDetailStateEnum;
    /**
     * 
     * @type {string}
     * @memberof StockIncomingShipmentDetail
     */
    supplier: string;
    /**
     * 
     * @type {string}
     * @memberof StockIncomingShipmentDetail
     */
    transportType: StockIncomingShipmentDetailTransportTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof StockIncomingShipmentDetail
     */
    estimatedTimeofArrival: Date;
    /**
     * 
     * @type {string}
     * @memberof StockIncomingShipmentDetail
     */
    shipmentName: string;
    /**
     * 
     * @type {string}
     * @memberof StockIncomingShipmentDetail
     */
    comment?: string;
    /**
     * 
     * @type {number}
     * @memberof StockIncomingShipmentDetail
     */
    stockMoveId?: number;
    /**
     * 
     * @type {number}
     * @memberof StockIncomingShipmentDetail
     */
    supplierId: number;
    /**
     * 
     * @type {Date}
     * @memberof StockIncomingShipmentDetail
     */
    estimatedTimeofDeparture?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StockIncomingShipmentDetail
     */
    actualTimeofDeparture?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StockIncomingShipmentDetail
     */
    appliedDate?: Date;
    /**
     * 
     * @type {Array<StockShipmentLineDetail>}
     * @memberof StockIncomingShipmentDetail
     */
    lines: Array<StockShipmentLineDetail>;
}


/**
 * @export
 */
export const StockIncomingShipmentDetailStateEnum = {
    WaitingForDeparture: 'waiting_for_departure',
    DuringShipment: 'during_shipment',
    Processing: 'processing',
    Done: 'done',
    Error: 'error'
} as const;
export type StockIncomingShipmentDetailStateEnum = typeof StockIncomingShipmentDetailStateEnum[keyof typeof StockIncomingShipmentDetailStateEnum];

/**
 * @export
 */
export const StockIncomingShipmentDetailTransportTypeEnum = {
    Sea: 'Sea',
    Air: 'Air',
    Truck: 'Truck',
    Express: 'Express',
    Other: 'Other'
} as const;
export type StockIncomingShipmentDetailTransportTypeEnum = typeof StockIncomingShipmentDetailTransportTypeEnum[keyof typeof StockIncomingShipmentDetailTransportTypeEnum];


/**
 * Check if a given object implements the StockIncomingShipmentDetail interface.
 */
export function instanceOfStockIncomingShipmentDetail(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('state' in value)) return false;
    if (!('supplier' in value)) return false;
    if (!('transportType' in value)) return false;
    if (!('estimatedTimeofArrival' in value)) return false;
    if (!('shipmentName' in value)) return false;
    if (!('supplierId' in value)) return false;
    if (!('lines' in value)) return false;
    return true;
}

export function StockIncomingShipmentDetailFromJSON(json: any): StockIncomingShipmentDetail {
    return StockIncomingShipmentDetailFromJSONTyped(json, false);
}

export function StockIncomingShipmentDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockIncomingShipmentDetail {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'state': json['state'],
        'supplier': json['supplier'],
        'transportType': json['transportType'],
        'estimatedTimeofArrival': (new Date(json['estimatedTimeofArrival'])),
        'shipmentName': json['shipmentName'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'stockMoveId': !exists(json, 'stockMoveId') ? undefined : json['stockMoveId'],
        'supplierId': json['supplierId'],
        'estimatedTimeofDeparture': !exists(json, 'estimatedTimeofDeparture') ? undefined : (new Date(json['estimatedTimeofDeparture'])),
        'actualTimeofDeparture': !exists(json, 'actualTimeofDeparture') ? undefined : (new Date(json['actualTimeofDeparture'])),
        'appliedDate': !exists(json, 'appliedDate') ? undefined : (new Date(json['appliedDate'])),
        'lines': ((json['lines'] as Array<any>).map(StockShipmentLineDetailFromJSON)),
    };
}

export function StockIncomingShipmentDetailToJSON(value?: StockIncomingShipmentDetail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value['id'],
        'state': value['state'],
        'supplier': value['supplier'],
        'transportType': value['transportType'],
        'estimatedTimeofArrival': ((value['estimatedTimeofArrival']).toISOString()),
        'shipmentName': value['shipmentName'],
        'comment': value['comment'],
        'stockMoveId': value['stockMoveId'],
        'supplierId': value['supplierId'],
        'estimatedTimeofDeparture': !exists(value, 'estimatedTimeofDeparture') ? undefined : ((value['estimatedTimeofDeparture'] as any).toISOString()),
        'actualTimeofDeparture': !exists(value, 'actualTimeofDeparture') ? undefined : ((value['actualTimeofDeparture'] as any).toISOString()),
        'appliedDate': !exists(value, 'appliedDate') ? undefined : ((value['appliedDate'] as any).toISOString()),
        'lines': ((value['lines'] as Array<any>).map(StockShipmentLineDetailToJSON)),
    };
}

