const messages = {
  resources: {
    'product/raw-materials': {
      name: 'Nguyên liệu thô|||| Nguyên liệu thô',
      fields: {
        name: 'Tên nguyên liệu',
        articleNo: 'Article Number',
        supplierName: 'Supplier',
        leadTime: 'Thời gian sản xuất',
        moq: 'MOQ',
        price: 'Giá',
        categoryName: 'Loại',
        stockTracingName: 'Kho dự trữ',
        quantityTypeName: 'Loại số lượng',
        unit: 'Đơn vị',
        decimals: 'Số lượng',
        currencyName: 'Đơn vị tiền tệ',
        businessPartnerName: 'Đối tác',
        isActive: 'Kích hoạt',
        categoryId: 'Loại nguyên liệu',
        stockTracingCode: 'Kho dự trữ',
        quantityTypeId: 'Loại số lượng',
        currencyId: 'Đơn vị tiền tệ',
        businessPartnerId: 'Đối tác',
        stateCode: 'Trạng thái',
        detail: 'Thông tin nguyên liệu',
        stockConditioning: 'TODO',
        supplierConditioning: 'TODO',
      },
    },
  },
  mai: {
    rawMaterials: {
      stockTracingCode: {
        None: 'Không',
        Lot: 'Nhiều',
        Serial: 'Định kỳ',
      },
    },
  },
};

export default messages;
