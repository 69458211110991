/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: developer.contact@outfiz.vn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PurchasingPurchaseOrderLineSolo
 */
export interface PurchasingPurchaseOrderLineSolo {
    /**
     * 
     * @type {number}
     * @memberof PurchasingPurchaseOrderLineSolo
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchasingPurchaseOrderLineSolo
     */
    rawMaterialId: number;
    /**
     * 
     * @type {number}
     * @memberof PurchasingPurchaseOrderLineSolo
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof PurchasingPurchaseOrderLineSolo
     */
    comment?: string;
    /**
     * 
     * @type {Date}
     * @memberof PurchasingPurchaseOrderLineSolo
     */
    confirmedDeliveryDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof PurchasingPurchaseOrderLineSolo
     */
    quantityTypeId: number;
    /**
     * 
     * @type {string}
     * @memberof PurchasingPurchaseOrderLineSolo
     */
    purchaseOrderName: string;
    /**
     * 
     * @type {number}
     * @memberof PurchasingPurchaseOrderLineSolo
     */
    purchaseOrderId: number;
}

/**
 * Check if a given object implements the PurchasingPurchaseOrderLineSolo interface.
 */
export function instanceOfPurchasingPurchaseOrderLineSolo(value: object): boolean {
    if (!('rawMaterialId' in value)) return false;
    if (!('amount' in value)) return false;
    if (!('quantityTypeId' in value)) return false;
    if (!('purchaseOrderName' in value)) return false;
    if (!('purchaseOrderId' in value)) return false;
    return true;
}

export function PurchasingPurchaseOrderLineSoloFromJSON(json: any): PurchasingPurchaseOrderLineSolo {
    return PurchasingPurchaseOrderLineSoloFromJSONTyped(json, false);
}

export function PurchasingPurchaseOrderLineSoloFromJSONTyped(json: any, ignoreDiscriminator: boolean): PurchasingPurchaseOrderLineSolo {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'rawMaterialId': json['rawMaterialId'],
        'amount': json['amount'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'confirmedDeliveryDate': !exists(json, 'confirmedDeliveryDate') ? undefined : (new Date(json['confirmedDeliveryDate'])),
        'quantityTypeId': json['quantityTypeId'],
        'purchaseOrderName': json['purchaseOrderName'],
        'purchaseOrderId': json['purchaseOrderId'],
    };
}

export function PurchasingPurchaseOrderLineSoloToJSON(value?: PurchasingPurchaseOrderLineSolo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value['id'],
        'rawMaterialId': value['rawMaterialId'],
        'amount': value['amount'],
        'comment': value['comment'],
        'confirmedDeliveryDate': !exists(value, 'confirmedDeliveryDate') ? undefined : ((value['confirmedDeliveryDate'] as any).toISOString()),
        'quantityTypeId': value['quantityTypeId'],
        'purchaseOrderName': value['purchaseOrderName'],
        'purchaseOrderId': value['purchaseOrderId'],
    };
}

