import DateTimeRangeInput from 'components/commons/dateTimeRangeInput';
import EnumSelectField from 'components/input/enumSelectField';
import React from 'react';
import { TextInput } from 'react-admin';
import { incomingShipmentState } from './enum';

export default [
  <TextInput source="query" alwaysOn />,
  <EnumSelectField choices={incomingShipmentState} source="state" />,
  <DateTimeRangeInput source="eta" since="etaSince" until="etaUntil" />,
];
