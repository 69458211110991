import * as React from 'react';
import {
  TopToolbar,
  ListButton,
  EditButton,
  Button,
  Confirm,
  DeleteWithConfirmButton,
  HttpError,
  useRecordContext,
  useRefresh,
  Identifier,
} from 'react-admin';
import useNotify from 'utils/notify';
import api from 'repositories/api';
import repositories from 'repositories/stock/stockMoves';
import PictureAsPdf from '@mui/icons-material/PictureAsPdf';
import { StockMoveEntity } from 'api';
import { useParams } from 'react-router';
import formatString from 'utils/formatString';
import urls from 'app/urls';
import { Link } from 'react-router-dom';
import useResourcePermissions from 'components/layout/actions/resourcePermissions';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import useHasAccess from 'hooks/useHasAccess';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

function ApplyDirective() {
  const stockMove = useRecordContext<StockMoveEntity>();
  const [open, setOpen] = React.useState(false);
  const refresh = useRefresh();
  const notify = useNotify();

  if (!stockMove) {
    return null;
  }

  const toInt = (id: Identifier) => (typeof (id) === 'string' ? parseInt(id, 10) : id);

  const handleConfirm = async () => {
    setOpen(false);

    try {
      await repositories.applyStockMove(toInt(stockMove?.id));
      notify('mai.stockMove.apply_success', { type: 'success' });
      refresh();
    } catch (e) {
      if (e instanceof HttpError) {
        notify('mai.stockMove.apply_failed', { type: 'error' });
      }
    }
  };

  return (
    <>
      <Button
        label="mai.stockMove.apply_btn"
        startIcon={<FactCheckIcon />}
        onClick={() => setOpen(true)}
        disabled={!!stockMove.appliedDate}
      />
      <Confirm
        isOpen={open}
        title="mai.stockMove.apply_confirm_title"
        content="mai.stockMove.apply_confirm_content"
        onConfirm={handleConfirm}
        onClose={() => setOpen(false)}
      />
    </>
  );
}

interface EditStockMoveLineButtonProps {
  disabled: boolean
}

function EditStockMoveLineButton({ disabled }: EditStockMoveLineButtonProps) {
  const { id } = useParams();
  return (
    <Link
      to={`/${formatString(urls.StockMoves_AddLine.url, { id: id?.toString() ?? '' })}`}
    >
      <Button
        startIcon={<PlaylistAddIcon />}
        disabled={disabled}
        label="ra.action.edit_line"
      />
    </Link>
  );
}

function ExportPdfButton() {
  const stockMove = useRecordContext();
  if (!stockMove) {
    return null;
  }

  const { id } = stockMove;
  const exportPdf = () => {
    api.stockMovesPdfExport({ id: Number(id) }).then((blob: Blob) => {
      const objectUrl: string = URL.createObjectURL(blob);
      const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

      a.href = objectUrl;
      a.setAttribute('download', `stock_move-${id}`);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(objectUrl);
    });
  };
  return <Button onClick={exportPdf} label="ra.action.export_pdf"><PictureAsPdf /></Button>;
}

export default function StockMoveActions() {
  const permissions = useResourcePermissions();
  const hasAccess = useHasAccess();
  const stockMove: StockMoveEntity | undefined = useRecordContext<StockMoveEntity>();

  const canAddLines = hasAccess(urls.StockMoves_AddLine);
  const canApply = hasAccess(urls.StockMoves_ApplyStockMove);

  return (
    <TopToolbar>
      {permissions.update && <EditButton />}
      {canAddLines && <EditStockMoveLineButton disabled={!!stockMove?.appliedDate} />}
      {canApply && !stockMove?.appliedDate && <ApplyDirective />}
      {permissions.list && <ListButton />}
      {permissions.delete && (
        <DeleteWithConfirmButton
          label="ra.action.delete"
          mutationMode="pessimistic"
          disabled={!!stockMove?.appliedDate}
        />
      )}
      {stockMove && <ExportPdfButton />}
    </TopToolbar>
  );
}
