/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: developer.contact@outfiz.vn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StockMoveCreateEntity
 */
export interface StockMoveCreateEntity {
    /**
     * 
     * @type {Date}
     * @memberof StockMoveCreateEntity
     */
    plannedDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof StockMoveCreateEntity
     */
    comment?: string;
    /**
     * 
     * @type {number}
     * @memberof StockMoveCreateEntity
     */
    sourceStockLocationId?: number;
    /**
     * 
     * @type {number}
     * @memberof StockMoveCreateEntity
     */
    destinationStockLocationId?: number;
}

/**
 * Check if a given object implements the StockMoveCreateEntity interface.
 */
export function instanceOfStockMoveCreateEntity(value: object): boolean {
    return true;
}

export function StockMoveCreateEntityFromJSON(json: any): StockMoveCreateEntity {
    return StockMoveCreateEntityFromJSONTyped(json, false);
}

export function StockMoveCreateEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockMoveCreateEntity {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        
        'plannedDate': !exists(json, 'plannedDate') ? undefined : (new Date(json['plannedDate'])),
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'sourceStockLocationId': !exists(json, 'sourceStockLocationId') ? undefined : json['sourceStockLocationId'],
        'destinationStockLocationId': !exists(json, 'destinationStockLocationId') ? undefined : json['destinationStockLocationId'],
    };
}

export function StockMoveCreateEntityToJSON(value?: StockMoveCreateEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'plannedDate': !exists(value, 'plannedDate') ? undefined : ((value['plannedDate'] as any).toISOString()),
        'comment': value['comment'],
        'sourceStockLocationId': value['sourceStockLocationId'],
        'destinationStockLocationId': value['destinationStockLocationId'],
    };
}

