import vietnameseMessages from './defaults/ra-language-vietnamese';
import commons from './commons/vi';
import msg from './systemMessages/vi';
import pageUsers from './pages/users/vi';
import pageGroups from './pages/groups/vi';
import pageRawMaterials from './pages/rawMaterials/vi';
import pageWorkloads from './pages/workloads/vi';
import pageBusinessPartners from './pages/businessPartners/vi';
import pageCategories from './pages/categories/vi';
import pageProducts from './pages/products/vi';
import pageStockLines from './pages/stockLines/vi';
import pageStockMoves from './pages/stockMoves/vi';
import incomingShipment from './pages/incomingShipment/vn';
import inventory from './pages/inventory/vi';

const msgTitle: any = {};
const messages = {
  ...vietnameseMessages,
  resources: {
    ...pageUsers.resources,
    ...pageGroups.resources,
    ...pageRawMaterials.resources,
    ...pageWorkloads.resources,
    ...pageProducts.resources,
    ...pageBusinessPartners.resources,
    ...pageCategories.resources,
    ...pageStockLines.resources,
    ...pageStockMoves.resources,
    ...incomingShipment.resources,
    ...inventory.resources,
  },
  mai: {
    ...commons,
    ...pageUsers.mai,
    ...pageGroups.mai,
    ...pageRawMaterials.mai,
    ...pageWorkloads.mai,
    ...pageBusinessPartners.mai,
    ...pageCategories.mai,
    ...pageProducts.mai,
    ...pageStockLines.mai,
    ...pageStockMoves.mai,
    ...incomingShipment.mai,
    ...inventory.mai,
    msg: {
      ...msg,
    },
    msgTitle: {
      ...msgTitle,
    },
  },
};

export default messages;
