const messages = {
  resources: {
    billOfResources: {
      name: 'Bill of Resources |||| Bills of Resources',
      fields: {
        name: 'Name',
        id: 'Id',
        description: 'Description',
        dependencies: 'Workloads dependence',
        resources: 'Resources',
        resourceName: 'Resources name',
        amount: 'Amount',
        comment: 'Comment',
        detail: 'workloads details',
        lines: 'Lines',
        products: 'Products',
      },
    },
  },
  mai: {},
};
export default messages;
