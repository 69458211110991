/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: developer.contact@outfiz.vn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductRawMaterialUpdate
 */
export interface ProductRawMaterialUpdate {
    /**
     * 
     * @type {string}
     * @memberof ProductRawMaterialUpdate
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ProductRawMaterialUpdate
     */
    articleNo?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductRawMaterialUpdate
     */
    isActive: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductRawMaterialUpdate
     */
    isUniqueLotNo: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductRawMaterialUpdate
     */
    currencyId: string;
    /**
     * 
     * @type {number}
     * @memberof ProductRawMaterialUpdate
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof ProductRawMaterialUpdate
     */
    stockTracing: ProductRawMaterialUpdateStockTracingEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductRawMaterialUpdate
     */
    quantityTypeId: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductRawMaterialUpdate
     */
    categories: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof ProductRawMaterialUpdate
     */
    supplierId: number;
    /**
     * 
     * @type {number}
     * @memberof ProductRawMaterialUpdate
     */
    leadTime?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductRawMaterialUpdate
     */
    supplierArticleNo?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductRawMaterialUpdate
     */
    supplierArticleName?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductRawMaterialUpdate
     */
    supplierQuantityTypeId?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductRawMaterialUpdate
     */
    supplierQuantityFactor?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductRawMaterialUpdate
     */
    moq?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductRawMaterialUpdate
     */
    reserveQuantity?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductRawMaterialUpdate
     */
    stockConditioning?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductRawMaterialUpdate
     */
    supplierConditioning?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductRawMaterialUpdate
     */
    weightPerUnit?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductRawMaterialUpdate
     */
    hsCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductRawMaterialUpdate
     */
    originCountryId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductRawMaterialUpdate
     */
    incoterm?: ProductRawMaterialUpdateIncotermEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductRawMaterialUpdate
     */
    id: number;
}


/**
 * @export
 */
export const ProductRawMaterialUpdateStockTracingEnum = {
    None: 'None',
    Lot: 'Lot',
    Serial: 'Serial'
} as const;
export type ProductRawMaterialUpdateStockTracingEnum = typeof ProductRawMaterialUpdateStockTracingEnum[keyof typeof ProductRawMaterialUpdateStockTracingEnum];

/**
 * @export
 */
export const ProductRawMaterialUpdateIncotermEnum = {
    Cfr: 'CFR',
    Cif: 'CIF',
    Ddu: 'DDU',
    Exw: 'EXW',
    Fob: 'FOB'
} as const;
export type ProductRawMaterialUpdateIncotermEnum = typeof ProductRawMaterialUpdateIncotermEnum[keyof typeof ProductRawMaterialUpdateIncotermEnum];


/**
 * Check if a given object implements the ProductRawMaterialUpdate interface.
 */
export function instanceOfProductRawMaterialUpdate(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('isActive' in value)) return false;
    if (!('isUniqueLotNo' in value)) return false;
    if (!('currencyId' in value)) return false;
    if (!('price' in value)) return false;
    if (!('stockTracing' in value)) return false;
    if (!('quantityTypeId' in value)) return false;
    if (!('categories' in value)) return false;
    if (!('supplierId' in value)) return false;
    if (!('id' in value)) return false;
    return true;
}

export function ProductRawMaterialUpdateFromJSON(json: any): ProductRawMaterialUpdate {
    return ProductRawMaterialUpdateFromJSONTyped(json, false);
}

export function ProductRawMaterialUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductRawMaterialUpdate {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'articleNo': !exists(json, 'articleNo') ? undefined : json['articleNo'],
        'isActive': json['isActive'],
        'isUniqueLotNo': json['isUniqueLotNo'],
        'currencyId': json['currencyId'],
        'price': json['price'],
        'stockTracing': json['stockTracing'],
        'quantityTypeId': json['quantityTypeId'],
        'categories': json['categories'],
        'supplierId': json['supplierId'],
        'leadTime': !exists(json, 'leadTime') ? undefined : json['leadTime'],
        'supplierArticleNo': !exists(json, 'supplierArticleNo') ? undefined : json['supplierArticleNo'],
        'supplierArticleName': !exists(json, 'supplierArticleName') ? undefined : json['supplierArticleName'],
        'supplierQuantityTypeId': !exists(json, 'supplierQuantityTypeId') ? undefined : json['supplierQuantityTypeId'],
        'supplierQuantityFactor': !exists(json, 'supplierQuantityFactor') ? undefined : json['supplierQuantityFactor'],
        'moq': !exists(json, 'moq') ? undefined : json['moq'],
        'reserveQuantity': !exists(json, 'reserveQuantity') ? undefined : json['reserveQuantity'],
        'stockConditioning': !exists(json, 'stockConditioning') ? undefined : json['stockConditioning'],
        'supplierConditioning': !exists(json, 'supplierConditioning') ? undefined : json['supplierConditioning'],
        'weightPerUnit': !exists(json, 'weightPerUnit') ? undefined : json['weightPerUnit'],
        'hsCode': !exists(json, 'hsCode') ? undefined : json['hsCode'],
        'originCountryId': !exists(json, 'originCountryId') ? undefined : json['originCountryId'],
        'incoterm': !exists(json, 'incoterm') ? undefined : json['incoterm'],
        'id': json['id'],
    };
}

export function ProductRawMaterialUpdateToJSON(value?: ProductRawMaterialUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value['name'],
        'articleNo': value['articleNo'],
        'isActive': value['isActive'],
        'isUniqueLotNo': value['isUniqueLotNo'],
        'currencyId': value['currencyId'],
        'price': value['price'],
        'stockTracing': value['stockTracing'],
        'quantityTypeId': value['quantityTypeId'],
        'categories': value['categories'],
        'supplierId': value['supplierId'],
        'leadTime': value['leadTime'],
        'supplierArticleNo': value['supplierArticleNo'],
        'supplierArticleName': value['supplierArticleName'],
        'supplierQuantityTypeId': value['supplierQuantityTypeId'],
        'supplierQuantityFactor': value['supplierQuantityFactor'],
        'moq': value['moq'],
        'reserveQuantity': value['reserveQuantity'],
        'stockConditioning': value['stockConditioning'],
        'supplierConditioning': value['supplierConditioning'],
        'weightPerUnit': value['weightPerUnit'],
        'hsCode': value['hsCode'],
        'originCountryId': value['originCountryId'],
        'incoterm': value['incoterm'],
        'id': value['id'],
    };
}

