const messages = {
  resources: {
    'product/raw-materials': {
      name: 'Raw Materials |||| Raw Materials',
      fields: {
        name: 'Name',
        articleNo: 'Article Number',
        supplierName: 'Supplier Article Name',
        leadTime: 'Lead Time (days)',
        moq: 'MOQ',
        price: 'Price',
        categoryName: 'Category',
        stockTracingName: 'Stock Tracing',
        quantityTypeName: 'Quantity Type',
        unit: 'Unit',
        decimals: 'Decimals',
        currencyName: 'Currency',
        businessPartnerName: 'Business Partner',
        isActive: 'Active',
        categoryId: 'Category',
        stockTracingCode: 'Stock Tracing',
        quantityTypeId: 'Quantity Type',
        currencyId: 'Currency',
        businessPartnerId: 'Business Partner',
        stateCode: 'Active',
        detail: 'Raw material details',
        stockConditioning: 'Stock Conditioning',
        supplierConditioning: 'Supplier Conditioning',
      },
    },
  },
  mai: {
    rawMaterials: {
      stockTracingCode: {
        None: 'None',
        Lot: 'Lot',
        Serial: 'Serial',
      },
    },
  },
};

export default messages;
