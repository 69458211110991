import React from 'react';
import {
  HttpError,
  ReferenceInput,
  SelectInput,
} from 'react-admin';
import {
  Grid, Dialog, IconButton, Button, DialogTitle, DialogContent, Typography,
} from '@mui/material';
import useNotify from 'utils/notify';
import { StockLinesLineDetail, StockMoveEntity } from 'api';
import CloseIcon from '@mui/icons-material/Close';
import constants from 'utils/constants';
import Source from 'utils/source';
import useTranslate from 'utils/translate';
import useScanDetection from 'hooks/useScanDetection';
import repositories from 'repositories/stock/stockLines';
import repositoriyLocation from 'repositories/stock/stockLocations';
import QuantityInput from 'components/input/quantity/quantityInput';
import { UseFormReturn, useFormContext } from 'react-hook-form';
import StockLineChooser from 'components/chooser/stock/stockLine';
import FormState from '../state';
import styles from './popup.module.scss';

interface StockMoveLinePopupProps {
  onSubmit: (data: FormState) => unknown,
  data: StockMoveEntity,
}

export interface BaseFormData {
  stockLineId: number;
  amount: number;
  shelfId?: number;
}

export function useStockData<T extends BaseFormData>(
  form: UseFormReturn<T>,
  onSubmit: (data: T) => unknown,
): StockLinesLineDetail | null;

export function useStockData(
  form: UseFormReturn<BaseFormData & Record<string, unknown>>,
  onSubmit: (data: BaseFormData & Record<string, unknown>) => unknown,
) {
  const notify = useNotify();
  const [stockLine, setStockLine] = React.useState<StockLinesLineDetail | null>(null);
  const stockLineId = form.watch('stockLineId');

  const hasValue = React.useMemo(() => (
    !!stockLineId && stockLine?.id === stockLineId
  ), [stockLine, stockLineId]);

  React.useEffect(() => {
    const fetch = async () => {
      if (stockLineId !== undefined && stockLine?.id !== stockLineId) {
        const data = await repositories.getStockLineDetail(stockLineId);
        if (data) {
          setStockLine(data);
          if (data.amount !== undefined) {
            // form.setValue('stockLineId', data.id);
            form.setValue('amount', data.amount);
          }
        }
      } else {
        setStockLine(null);
      }
    };

    fetch();
  }, [stockLineId]);

  const getShelfDetail = async (stockShelfId: number) => {
    try {
      const stockShelf = await repositoriyLocation.getShelfDetail(stockShelfId);
      if (stockShelf) {
        form.setValue('shelfId', stockShelfId);
      }
    } catch (e) {
      if (e instanceof HttpError) {
        notify('Shelf Not Found', { type: 'error' });
      }
    }
  };

  useScanDetection({
    resetInput: true,
    averageWaitTime: 200,
    onComplete: async (code) => {
      const barCodeScanData = code.toString().trim();
      console.log(`barcode detected ${barCodeScanData}`);

      const matchShelf = constants.Labels.SHELF.regex.exec(barCodeScanData);
      if (matchShelf) {
        const stockShelfId = parseInt(matchShelf[1], 10);
        getShelfDetail(stockShelfId);
        return;
      }

      const match = constants.Labels.STOCKLINE.regex.exec(barCodeScanData);
      if (match) {
        const newStockLineId = parseInt(match[1], 10);
        if (hasValue) {
          const result = await form.trigger();
          if (result) {
            await onSubmit(form.getValues());
          } else {
            return;
          }
        }
        form.setValue('stockLineId', newStockLineId);
      } else {
        notify('QR not found', { type: 'error' });
      }
    },
    minLength: 2,
  });

  React.useEffect(() => {
    form.reset({
      stockLineId: undefined,
      amount: undefined,
      shelfId: undefined,
    });
  }, [form.formState.isSubmitSuccessful]);

  return stockLine;
}

export default function StockMoveLinePopup(props: StockMoveLinePopupProps) {
  const { data, onSubmit } = props;
  const translate = useTranslate();
  const sline = Source<FormState>();
  const form = useFormContext<FormState>();
  const stockLine = useStockData(form, onSubmit);

  const amount = form.watch('amount');
  React.useEffect(() => {
    if (stockLine) {
      if (stockLine.amount === amount) {
        form.setValue('moveCompleteLine', true);
      } else {
        form.setValue('moveCompleteLine', false);
      }
    }
  }, [amount, form, stockLine]);

  const onClose = () => {
    form.reset({
      stockLineId: undefined,
      amount: undefined,
      shelfId: undefined,
    });
  };

  return (
    <>
      {!data.appliedBy
        && (
          <StockLineChooser
            isFullWidth
            stockLocation={data.sourceStockLocationId}
            onChange={(value: number) => form.setValue('stockLineId', value)}
          />
        )}
      <Dialog
        disablePortal
        disableRestoreFocus
        open={stockLine !== null}
        onClose={onClose}
        fullWidth
        className={styles.popup}
      >
        <DialogTitle className="header">
          <Typography typography="h5">
            {`${!stockLine?.articleNo
              ? '' : `${stockLine?.articleNo}${' - '}`}${stockLine?.resourceName ?? ''}`}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item xs={1} />
          <Grid item xs={4}>
            <QuantityInput
              {...sline('amount')}
              required
              quantityType={stockLine?.quantityTypeId}
              autoFocus
            />
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={5}>
            <div>
              {`${translate('mai.inventoryControl.quantityInput')}: ${stockLine?.amount ?? ''}`}
            </div>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={1} />
          <Grid item xs={4}>
            <ReferenceInput
              label="Shelf"
              {...sline('shelfId')}
              reference="stock/stocklocations/shelf"
            >
              <SelectInput
                optionText="name"
                fullWidth
              />
            </ReferenceInput>
          </Grid>
        </Grid>
        <DialogContent>
          <div style={{
            display: 'flex', justifyContent: 'flex-end', width: '100%',
          }}
          >
            <Button
              size="small"
              type="submit"
            >
              {translate('ra.action.save')}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
