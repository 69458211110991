import { StockIncomingShipmentUpdate, StockShipmentLineSchema } from 'api';
import { ResourceQuantityInput } from 'components/input/quantity/resourceFields';
import React from 'react';
import { Grid } from '@mui/material';
import {
  ArrayInput, FormDataConsumer, SimpleFormIterator, TextInput,
} from 'react-admin';
import useTranslate from 'utils/translate';
import RawMaterialChooser from 'components/chooser/rawMaterialChooser';
import { Path, useFormContext } from 'react-hook-form';
import PasteBillOfResources from 'resources/product/pasteBillOfResources';
import { usePasteData } from 'resources/product/billOfResourceForm';

interface ShipmentLineFormProps {
  getSource: (q: Path<StockShipmentLineSchema>) => string,
  scopedFormData: StockShipmentLineSchema,
  defaultSupplier: number | undefined,
}

function ShipmentLineForm(props: ShipmentLineFormProps) {
  const {
    getSource,
    scopedFormData,
    defaultSupplier,
  } = props;
  const translate = useTranslate();

  return (
    <Grid container spacing={2} marginTop="10px" marginBottom="10px">
      <Grid item md={2} />
      <Grid item md={4}>

        <RawMaterialChooser
          // focused={false}
          required
          label={translate('mai.resources')}
          // validate={[required()]}
          source={getSource('rawMaterialId') || ''}
          className="resourcesSelect"
          defaultSupplier={defaultSupplier}
        // onChange={() => onChangeHandle(scopedFormData ?? '0')}
        />
      </Grid>
      <Grid item md={4}>
        <ResourceQuantityInput
          className="amount"
          label={translate('mai.amount')}
          source={getSource('amount')!}
          resourceId={scopedFormData?.rawMaterialId}
        />
      </Grid>
      <Grid item md={2} />

      <Grid item md={2} />
      <Grid item md={8}>
        <TextInput
          label={translate('mai.comment')}
          source={getSource('comment')!}
          fullWidth
          variant="outlined"
          helperText={false}
          className="comment"
        />

      </Grid>
      <Grid item md={2} />

    </Grid>
  );
}

interface ShipmentLinesFormProps {
  source: string;
}

export default function ShipmentLinesForm(props: ShipmentLinesFormProps) {
  const {
    source,
  } = props;
  const form = useFormContext<StockIncomingShipmentUpdate>();
  const supplierId = form.watch('supplierId');
  const getPasteData = usePasteData();

  const onPaste = React.useCallback(async (data: [number, number][]) => {
    const newData = getPasteData(data)
      .filter((el) => el.resourceId !== undefined && el.amount > 0)
      .map((el) => ({ rawMaterialId: el.resourceId as number, amount: el.amount, comment: '' }));

    form.setValue(
      'lines',
      newData,
    );
  }, [form, getPasteData]);

  return (
    <>

      <PasteBillOfResources onSubmit={onPaste} />
      <ArrayInput source={source}>
        <SimpleFormIterator className="form" disableClear>
          <FormDataConsumer>
            {({ getSource, scopedFormData }) => (
              <ShipmentLineForm
                getSource={getSource!}
                // @ts-ignore
                scopedFormData={scopedFormData}
                defaultSupplier={supplierId}
              />
            )}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </>
  );
}
