import React from 'react';
import QRCode from 'react-qr-code';
import exportPDF from 'utils/exportPDF';
import dayjs from 'dayjs';
import constants from 'utils/constants';
import styles from './qrCode.module.scss';

export interface StockLineQrCodeProps {
  amount: number;
  id: number;
  resourceName: string;
  articleNo: string;
  labelId: number;
  quantityTypeName: string;
  lotNo: string;
  lotDate: Date;
}

const formatId = (id: number) => {
  let remaining = id;
  const chars = '0123456789ABCDEFGHIJKLMNPQRSTUVWXYZ';
  const base = chars.length;
  const result = [];
  while (remaining > 0) {
    result.push(remaining % base);
    remaining = Math.floor(remaining / base);
  }

  return result.reverse().map((x) => chars[x]).join('');
};

function StockLineQrCode(props: StockLineQrCodeProps) {
  const {
    id,
    resourceName,
    articleNo,
    lotNo,
    lotDate,
    labelId,
    amount,
    quantityTypeName,
  } = props;

  console.log(id, formatId(id));

  const formattedDate = dayjs(lotDate).format('YYYY MM DD');
  return (
    <div className={styles.qr_code}>
      <div className="title">
        {resourceName}
      </div>
      <div className="content">
        <div style={{ minWidth: '42mm', fontSize: '12px' }}>
          <div>
            <span className="bold">ART NO: </span>
            <span>
              {articleNo}
            </span>
          </div>
          <div>
            <span className="bold">QTY: </span>
            <span>
              {`${amount} ${quantityTypeName}`}
            </span>
          </div>
          <div>
            <span className="bold">ARR: </span>
            <span>{formattedDate}</span>
          </div>
          <div>
            <span className="bold">BATCH: </span>
            <span style={{ maxWidth: '35mm' }}>{lotNo}</span>
          </div>
          <div>
            <span className="bold">ID: </span>
            <span>{labelId}</span>
          </div>
        </div>
        <QRCode
          className="code"
          value={`SL${id}_${amount}`}
        />
      </div>
    </div>
  );
}

export default function exportStockLineQrCode(data: StockLineQrCodeProps[]) {
  exportPDF(
    (
      <>
        {data.map((x) => <StockLineQrCode {...x} />)}
      </>
    ),
    constants.SizePrint.k58,
  );
}
