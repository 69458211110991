const messages = {
  profile: 'Thông tin cá nhân',
  changePassword: 'Đổi mật khẩu',
  logout: 'Đăng xuất',
  firstname: 'Họ',
  lastname: 'Tên',
  username: 'Tên đăng nhập',
  email: 'Email',
  phone: 'Số điện thoại',
  lastLogin: 'Đăng nhập lần cuối',
  lastLogout: 'Đăng xuất lần cuối',
  isActive: 'Kích hoạt TK',
  isVerifyEmail: 'Xác thực Email',
  status: 'Trạng thái',
  group: 'Nhóm tài khoản',
  groups: 'Nhóm tài khoản',
  gender: 'Giới tính',
  genders: {
    Male: 'Nam',
    Female: 'Nữ',
    Other: 'TODO!',
  },
  birthday: 'Sinh nhật',
  address: 'Địa chỉ',
  note: 'Ghi chú',
  password: 'Mật khẩu',
  oldPassword: 'Mật khẩu cũ',
  newPassword: 'Mật khẩu mới',
  confirmPassword: 'Nhập lại mật khẩu',
  using: 'Đang sử dụng',
  locking: 'Đang khoá',
  default_group: 'Nhóm mặt định',
  private_group: 'Nhóm bí mật',
  custom_group: 'Nhóm tuỳ chọn',
  resources: 'Tài nguyên',
  resource: 'Tài nguyên',
  categories: 'Loại sản phẩm',
  amount: 'Số lượng',
  comment: 'Ghi chú',
  configuration: 'Cài đặt',
  darklightmode: 'Chế độ sáng/tối',
  dark: 'Tối',
  light: 'Sáng',
  keyword: 'Từ khoá',
  signin: 'ĐĂNG NHẬP',
  detail: 'Thông tin chi tiết',
  dashboard: 'Trang chủ',
  second: 'giây',
  day: 'ngày',
  confirm: 'Xác nhận',
  menu: {
    dashboard: 'Bảng điều khiển',
    parties: 'Nhóm',
    users: 'Người dùng',
    groups: 'Nhóm tài khoản',
    business_partners: 'Đối tác',
    products: 'Sản phẩm',
    categories: 'Loại sản phẩm',
    raw_materials: 'Nguyên liệu thô',
    workloads: 'Workloads',
    stock: 'Kho',
    incoming_shipments: 'TODO',
    stocksplit: 'TODO',
    receive_shipments: 'Nhập kho',
    stock_lines: 'Tồn kho',
    moves: 'Chuyển kho',
    production: 'TODO!',
    purchasing: 'TODO!',
    inventory_controls: 'Kiểm kho',
    purchase_order: 'TODO!',
  },
};

export default messages;
