/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: developer.contact@outfiz.vn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StockInventoryControlList
 */
export interface StockInventoryControlList {
    /**
     * 
     * @type {number}
     * @memberof StockInventoryControlList
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof StockInventoryControlList
     */
    plannedDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StockInventoryControlList
     */
    finishedDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StockInventoryControlList
     */
    applyDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof StockInventoryControlList
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof StockInventoryControlList
     */
    stockMoveInFromName?: string;
    /**
     * 
     * @type {string}
     * @memberof StockInventoryControlList
     */
    stockMoveInToName?: string;
    /**
     * 
     * @type {string}
     * @memberof StockInventoryControlList
     */
    stockMoveOutFromName?: string;
    /**
     * 
     * @type {string}
     * @memberof StockInventoryControlList
     */
    stockMoveOutToName?: string;
    /**
     * 
     * @type {string}
     * @memberof StockInventoryControlList
     */
    stockLocationName?: string;
}

/**
 * Check if a given object implements the StockInventoryControlList interface.
 */
export function instanceOfStockInventoryControlList(value: object): boolean {
    if (!('id' in value)) return false;
    return true;
}

export function StockInventoryControlListFromJSON(json: any): StockInventoryControlList {
    return StockInventoryControlListFromJSONTyped(json, false);
}

export function StockInventoryControlListFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockInventoryControlList {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'plannedDate': !exists(json, 'plannedDate') ? undefined : (new Date(json['plannedDate'])),
        'finishedDate': !exists(json, 'finishedDate') ? undefined : (new Date(json['finishedDate'])),
        'applyDate': !exists(json, 'applyDate') ? undefined : (new Date(json['applyDate'])),
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'stockMoveInFromName': !exists(json, 'stockMoveInFromName') ? undefined : json['stockMoveInFromName'],
        'stockMoveInToName': !exists(json, 'stockMoveInToName') ? undefined : json['stockMoveInToName'],
        'stockMoveOutFromName': !exists(json, 'stockMoveOutFromName') ? undefined : json['stockMoveOutFromName'],
        'stockMoveOutToName': !exists(json, 'stockMoveOutToName') ? undefined : json['stockMoveOutToName'],
        'stockLocationName': !exists(json, 'stockLocationName') ? undefined : json['stockLocationName'],
    };
}

export function StockInventoryControlListToJSON(value?: StockInventoryControlList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value['id'],
        'plannedDate': !exists(value, 'plannedDate') ? undefined : ((value['plannedDate'] as any).toISOString()),
        'finishedDate': !exists(value, 'finishedDate') ? undefined : ((value['finishedDate'] as any).toISOString()),
        'applyDate': !exists(value, 'applyDate') ? undefined : ((value['applyDate'] as any).toISOString()),
        'comment': value['comment'],
        'stockMoveInFromName': value['stockMoveInFromName'],
        'stockMoveInToName': value['stockMoveInToName'],
        'stockMoveOutFromName': value['stockMoveOutFromName'],
        'stockMoveOutToName': value['stockMoveOutToName'],
        'stockLocationName': value['stockLocationName'],
    };
}

