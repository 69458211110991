import * as React from 'react';
import {
  Admin,
  Authenticated,
  CustomRoutes,
  Resource,
} from 'react-admin';
import { Route } from 'react-router';
import { BrowserRouter } from 'react-router-dom';

import { lightTheme } from 'components/shared/themes';

import LoginPage from 'components/shared/LoginPage';
import NoAccess from 'components/shared/noAccess';
import groups from 'resources/auth/groups';
import ChangePassword from 'resources/auth/profile/changePasswordContainer';
import Profile from 'resources/auth/profile/profileContainer';
import users from 'resources/auth/users';
import businessPartners from 'resources/businessPartners';
import Configuration from 'resources/configuration';
import dashboard from 'resources/dashboard';
import Categories from 'resources/product/categories';
import products from 'resources/product/products';
import rawMaterials from 'resources/product/rawMaterials';
import incomingShipments from 'resources/stock/incomingShipment';
import stockLines from 'resources/stock/stock';
import StockMoves from 'resources/stock/stockMove';
import stockSplit from 'resources/stock/stockSplit';
import inventoryControls from 'resources/stock/inventoryControl';
import purchaseOrder from 'resources/purchasing/purchaseOrder';
import getI18nProvider from 'i18n/i18nProvider';
import layout from './layout';

import authProvider from './authProvider';
import dataProvider from './dataProvider';
import history from './history';

import 'assets/scss/style.scss';

function App() {
  const i18nProvider = React.useMemo(
    getI18nProvider,
    [],
  );

  return (
    <BrowserRouter>
      <Admin
        history={history}
        layout={layout}
        dashboard={dashboard}
        loginPage={LoginPage}
        dataProvider={dataProvider}
        authProvider={authProvider}
        i18nProvider={i18nProvider}
        theme={lightTheme}
        disableTelemetry
      >
        <Resource {...users} />
        <Resource {...groups} />
        <Resource {...businessPartners} />
        <Resource {...products} />
        <Resource {...Categories} />
        <Resource {...rawMaterials} />
        <Resource {...stockLines} />
        <Resource {...StockMoves} />
        <Resource {...stockSplit} />
        <Resource {...incomingShipments} />
        <Resource {...inventoryControls} />
        <Resource {...purchaseOrder} />
        <CustomRoutes>
          <Route path="/configuration" element={<Authenticated><Configuration /></Authenticated>} />
          <Route path="/profile" element={<Authenticated><Profile /></Authenticated>} />
          <Route path="/change-password" element={<Authenticated><ChangePassword /></Authenticated>} />
          <Route path="/no-access" element={<NoAccess />} />
        </CustomRoutes>
      </Admin>
    </BrowserRouter>
  );
}

export default App;
