/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: developer.contact@outfiz.vn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StockInventoryControlCreate
 */
export interface StockInventoryControlCreate {
    /**
     * 
     * @type {Date}
     * @memberof StockInventoryControlCreate
     */
    plannedDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof StockInventoryControlCreate
     */
    stockLocationId: number;
    /**
     * 
     * @type {string}
     * @memberof StockInventoryControlCreate
     */
    comment?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StockInventoryControlCreate
     */
    partialInventory: boolean;
}

/**
 * Check if a given object implements the StockInventoryControlCreate interface.
 */
export function instanceOfStockInventoryControlCreate(value: object): boolean {
    if (!('stockLocationId' in value)) return false;
    if (!('partialInventory' in value)) return false;
    return true;
}

export function StockInventoryControlCreateFromJSON(json: any): StockInventoryControlCreate {
    return StockInventoryControlCreateFromJSONTyped(json, false);
}

export function StockInventoryControlCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockInventoryControlCreate {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        
        'plannedDate': !exists(json, 'plannedDate') ? undefined : (new Date(json['plannedDate'])),
        'stockLocationId': json['stockLocationId'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'partialInventory': json['partialInventory'],
    };
}

export function StockInventoryControlCreateToJSON(value?: StockInventoryControlCreate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'plannedDate': !exists(value, 'plannedDate') ? undefined : ((value['plannedDate'] as any).toISOString()),
        'stockLocationId': value['stockLocationId'],
        'comment': value['comment'],
        'partialInventory': value['partialInventory'],
    };
}

