import { StockIncomingShipmentList } from 'api';
import * as React from 'react';
import {
  DateField,
  FunctionField,
  TextField,
} from 'react-admin';
import Source from 'utils/source';
import EnumField from 'components/fields/enumField';
import { Badges, StatusBadge } from 'components/list/colorBadge';
import List from 'components/list/List';
import Datagrid from 'components/list/dataGrid';
import filters from './filters';
import { incomingShipmentState, incomingShipmentTransportType } from './enum';

export default function () {
  const s = Source<StockIncomingShipmentList>();
  return (
    <List
      filters={filters}
    >
      <Datagrid rowClick="show">
        <FunctionField render={(record: StockIncomingShipmentList) => {
          const isProcessing = record.state === 'during_shipment' || record.state === 'processing';
          return (
            <Badges>
              <StatusBadge green={record.state === 'done'} orange={isProcessing} />
            </Badges>
          );
        }}
        />
        <EnumField {...s('state')} choices={incomingShipmentState} />
        <TextField {...s('supplier')} />
        <TextField {...s('shipmentName')} label="Name" />
        <EnumField {...s('transportType')} choices={incomingShipmentTransportType} />
        <DateField {...s('estimatedTimeofArrival')} label="ETA" />
        <TextField {...s('comment')} />
      </Datagrid>
    </List>
  );
}
