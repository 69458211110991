import * as React from 'react';
import { BulkDeleteWithConfirmButton, BulkExportButton } from 'react-admin';

interface ActionProps {
  isDelete?: boolean;
  isExport?: boolean;
}

export default function BulkActions(props: ActionProps) {
  const { isDelete, isExport } = props;

  const canDelete = isDelete === undefined || isDelete === null ? true : isDelete;

  return (
    <>
      {isExport && <BulkExportButton />}
      {canDelete && <BulkDeleteWithConfirmButton mutationMode="pessimistic" />}
    </>
  );
}
