/* eslint-disable no-param-reassign */
import EditActions from 'components/layout/actions/edit';
import Loading from 'components/shared/loading';
import * as React from 'react';
import {
  Edit,
} from 'react-admin';
import useResourcesQuery from 'repositories/products/product';
import StockMoveForm from './form/stockMoveForm';

export default function () {
  const { data: resources } = useResourcesQuery();

  if (!resources) return <Loading />;

  return (
    <Edit
      actions={<EditActions />}
      mutationMode="pessimistic"
      className="mai-form"
      redirect="show"
    >
      <StockMoveForm alwaysEnable />
    </Edit>
  );
}
