import { StockMovesSearchMoveTypeEnum } from 'api';
import api from 'repositories/api';
import { getApiQuery } from 'repositories/base';
import enumToOptions from 'utils/enumToOptions';

export const useStockMoveDetail = getApiQuery(api.stockMovesGetDetail);

export const StockMoveTypes = enumToOptions(
  StockMovesSearchMoveTypeEnum,
  // (key) => `mai.rawMaterials.stockTracingCode.${key}`,
);

export default {
  applyStockMove: (id: number) => api.stockMovesApplyStockMove({ id }),
};
