import { Grid } from '@mui/material';
import { ProductUpdateSchema } from 'api/models/ProductUpdateSchema';
import PriceInput from 'components/input/priceInput';
import EnumSelectField from 'components/input/enumSelectField';
import Loading from 'components/shared/loading';
import useHasAccess from 'hooks/useHasAccess';
import * as React from 'react';
import {
  Create,
  FormDataConsumer,
  ListButton,
  SelectInput,
  SimpleForm,
  TextInput,
  TopToolbar,
  required,
} from 'react-admin';
import { useCurrencies, useEnumQuery } from 'repositories/app';
import { StockTracingChoices } from 'repositories/products/product';
import Source from 'utils/source';
import { CategoryInput } from 'components/chooser/categoryChoose';

export default React.memo(() => {
  const hasAccess = useHasAccess();
  const canSearch = hasAccess({ url: 'product/products/', httpMethod: 'GET' });
  const { data: enums } = useEnumQuery();
  const { data: currencies } = useCurrencies();
  const s = Source<ProductUpdateSchema>();

  if (!currencies || !enums) {
    return <Loading />;
  }
  return (
    <Create
      actions={(
        <TopToolbar>
          {canSearch && <ListButton />}
        </TopToolbar>
      )}
      className="mai-form"
    >
      <SimpleForm>
        <Grid container spacing={2} rowSpacing={2}>
          <Grid item xs={2} md={1} />
          <Grid item xs={4} md={5}>
            <TextInput
              {...s('name')}
              validate={required()}
              fullWidth
              inputProps={{ maxLength: 100 }}
              variant="outlined"
              helperText={false}
            />
          </Grid>
          <Grid item xs={4} md={5}>
            <EnumSelectField
              {...s('stockTracing')}
              label="resources.product/raw-materials.fields.stockTracingCode"
              choices={StockTracingChoices}
              validate={[required()]}
              fullWidth
              margin="none"
              helperText={false}
            />
          </Grid>
          <Grid item xs={2} md={1} />

          <Grid item xs={2} md={1} />
          <Grid item xs={4} md={5}>
            <CategoryInput
              {...s('categories')}
              validate={[required()]}
              required
              fullWidth
              label="category"
            />
          </Grid>
          <Grid item xs={4} md={5}>
            <SelectInput
              {...s('quantityTypeId')}
              validate={[required()]}
              choices={enums.quantityTypes}
              fullWidth
              variant="outlined"
              margin="none"
              helperText={false}
            />
          </Grid>
          <Grid item xs={2} md={1} />

          <Grid item xs={2} md={1} />
          <Grid item xs={4} md={5}>
            <SelectInput
              {...s('currencyId')}
              choices={currencies ?? []}
              validate={[required()]}
              fullWidth
              margin="none"
              variant="outlined"
              helperText={false}
            />
          </Grid>
          <Grid item xs={4} md={5}>
            <FormDataConsumer>
              {({ formData }) => <PriceInput {...s('price')} currencyId={formData.currencyId} />}
            </FormDataConsumer>

          </Grid>
          <Grid item xs={2} md={1} />
        </Grid>
      </SimpleForm>
    </Create>
  );
});
