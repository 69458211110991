import { Grid } from '@mui/material';
import { StockIncomingShipmentDetail } from 'api';
import * as React from 'react';
import {
  Loading,
  SimpleForm,
  TextInput,
  useRecordContext,
} from 'react-admin';
import CustomDatePicker from 'components/input/date/CustomDatePicker';
import useResourcesQuery from 'repositories/products/product';
import Source from 'utils/source';
import EnumSelectField from 'components/input/enumSelectField';
import BusinessPartnerInput from 'components/input/reference/businessPartner';
import ShipmentLineForm from './shipmentLine';
import { incomingShipmentTransportType } from '../enum';

export default function ShipmentForm() {
  const { data: resources } = useResourcesQuery();
  const record = useRecordContext<StockIncomingShipmentDetail>();
  const s = Source<StockIncomingShipmentDetail | undefined>();

  if (!resources) return <Loading />;

  return (
    <SimpleForm>
      <Grid container spacing={2} rowSpacing={1}>
        <Grid item xs={2} md={2} />

        <Grid item xs={8} md={8}>
          <BusinessPartnerInput {...s('supplierId')} required />
        </Grid>
        <Grid item xs={2} md={2} />

        <Grid item xs={2} md={2} />
        <Grid item xs={4} md={4}>
          <CustomDatePicker
            {...s('estimatedTimeofArrival')}
            required
            fullWidth
            variant="outlined"
            helperText={false}
          />
        </Grid>
        <Grid item xs={4} md={4}>
          <CustomDatePicker
            {...s('estimatedTimeofDeparture')}
            fullWidth
            variant="outlined"
            helperText={false}
          />
        </Grid>
        <Grid item xs={2} md={2} />

        <Grid item md={2} />
        <Grid item md={4} marginTop="8px">
          <CustomDatePicker
            {...s('actualTimeofDeparture')}
            fullWidth
            variant="outlined"
            helperText={false}
          />
        </Grid>
        <Grid item md={4}>
          <EnumSelectField
            {...s('transportType')}
            fullWidth
            choices={incomingShipmentTransportType}
            variant="outlined"
            helperText={false}
          />
        </Grid>
        <Grid item md={2} />

        <Grid item xs={2} md={2} />
        <Grid item xs={4} md={4}>
          <TextInput
            {...s('shipmentName')}
            fullWidth
            variant="outlined"
            helperText={false}
          />
        </Grid>
        <Grid item xs={4} md={4}>
          <TextInput
            {...s('comment')}
            fullWidth
            variant="outlined"
            helperText={false}
          />
        </Grid>
        <Grid item xs={2} md={2} />

        <Grid item xs={2} md={2} />
        {!record?.appliedDate && (
          <Grid item md={8}>
            <ShipmentLineForm {...s('lines')} />
          </Grid>
        )}
        <Grid item xs={2} md={2} />
      </Grid>
    </SimpleForm>
  );
}
