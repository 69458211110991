import * as React from 'react';
import {
  DateField,
  TextField,
} from 'react-admin';
import Source from 'utils/source';
import { StockInventoryControlList } from 'api';
import List from 'components/list/List';
import Datagrid from 'components/list/dataGrid';
import filters from './filters';

export default function () {
  const s = Source<StockInventoryControlList>();
  return (
    <List
      filters={filters}
    >
      <Datagrid rowClick="show">
        <DateField {...s('plannedDate')} showTime />
        <DateField {...s('finishedDate')} showTime />
        <DateField {...s('applyDate')} showTime />
        <TextField {...s('stockLocationName')} />
        <TextField {...s('comment')} />
      </Datagrid>
    </List>
  );
}
