/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: developer.contact@outfiz.vn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProductBillOfResourceLineDetail } from './ProductBillOfResourceLineDetail';
import {
    ProductBillOfResourceLineDetailFromJSON,
    ProductBillOfResourceLineDetailFromJSONTyped,
    ProductBillOfResourceLineDetailToJSON,
} from './ProductBillOfResourceLineDetail';

/**
 * 
 * @export
 * @interface ProductBillOfResourcesDetailDTO
 */
export interface ProductBillOfResourcesDetailDTO {
    /**
     * 
     * @type {Array<ProductBillOfResourceLineDetail>}
     * @memberof ProductBillOfResourcesDetailDTO
     */
    lines: Array<ProductBillOfResourceLineDetail>;
}

/**
 * Check if a given object implements the ProductBillOfResourcesDetailDTO interface.
 */
export function instanceOfProductBillOfResourcesDetailDTO(value: object): boolean {
    if (!('lines' in value)) return false;
    return true;
}

export function ProductBillOfResourcesDetailDTOFromJSON(json: any): ProductBillOfResourcesDetailDTO {
    return ProductBillOfResourcesDetailDTOFromJSONTyped(json, false);
}

export function ProductBillOfResourcesDetailDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductBillOfResourcesDetailDTO {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        
        'lines': ((json['lines'] as Array<any>).map(ProductBillOfResourceLineDetailFromJSON)),
    };
}

export function ProductBillOfResourcesDetailDTOToJSON(value?: ProductBillOfResourcesDetailDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lines': ((value['lines'] as Array<any>).map(ProductBillOfResourceLineDetailToJSON)),
    };
}

