// urls file - AUTOGENERATED - DO NOT EDIT
/* eslint-disable max-len */

export interface Endpoint {
  url: string,
  httpMethod: 'GET' | 'POST' | 'DELETE' | 'PUT',
}

const tempUrls = {
  IncomingShipment_Compare: { url: 'stock/incoming-shipments/{id}/compare', httpMethod: 'GET' },
  IncomingShipment_Update: { url: 'stock/incoming-shipments/{id}', httpMethod: 'PUT' },
  IncomingShipment_Get: { url: 'stock/incoming-shipments/{id}', httpMethod: 'GET' },
  IncomingShipment_DeleteOne: { url: 'stock/incoming-shipments/{id}', httpMethod: 'DELETE' },
  IncomingShipment_Search: { url: 'stock/incoming-shipments', httpMethod: 'GET' },
  IncomingShipment_Create: { url: 'stock/incoming-shipments', httpMethod: 'POST' },
  IncomingShipment_DeleteMany: { url: 'stock/incoming-shipments', httpMethod: 'DELETE' },
  IncomingShipmentFile_GetFiles: { url: 'stock/incoming-shipments/{id}/files', httpMethod: 'GET' },
  IncomingShipmentFile_UploadFile: { url: 'stock/incoming-shipments/{id}/files', httpMethod: 'POST' },
  IncomingShipmentFile_GetFile: { url: 'stock/incoming-shipments/{id}/files/{fileId}', httpMethod: 'GET' },
  IncomingShipmentFile_DeleteFile: { url: 'stock/incoming-shipments/{id}/files/{fileId}', httpMethod: 'DELETE' },
  ReceiveShipment_GetStockMove: { url: 'stock/incoming-shipments/{id}/receive', httpMethod: 'GET' },
  ReceiveShipment_AddLine: { url: 'stock/incoming-shipments/{id}/receive', httpMethod: 'POST' },
  ReceiveShipment_Apply: { url: 'stock/incoming-shipments/{id}/receive/apply', httpMethod: 'POST' },
  ReceiveShipment_DeleteLine: { url: 'stock/incoming-shipments/{id}/receive/{lineId}', httpMethod: 'DELETE' },
  InventoryControl_GetLines: { url: 'stock/inventory-controls/{id}/lines', httpMethod: 'GET' },
  InventoryControl_AddLine: { url: 'stock/inventory-controls/{id}/lines', httpMethod: 'POST' },
  InventoryControl_DeleteLine: { url: 'stock/inventory-controls/{id}/lines/{lineId}', httpMethod: 'DELETE' },
  InventoryControl_SetFinshedInventoryControl: { url: 'stock/inventory-controls/{id}/setfinished', httpMethod: 'POST' },
  InventoryControl_Apply: { url: 'stock/inventory-controls/{id}/apply', httpMethod: 'POST' },
  InventoryControl_Search: { url: 'stock/inventory-controls', httpMethod: 'GET' },
  InventoryControl_Create: { url: 'stock/inventory-controls', httpMethod: 'POST' },
  InventoryControl_DeleteMany: { url: 'stock/inventory-controls', httpMethod: 'DELETE' },
  InventoryControl_Get: { url: 'stock/inventory-controls/{id}', httpMethod: 'GET' },
  InventoryControl_Update: { url: 'stock/inventory-controls/{id}', httpMethod: 'PUT' },
  InventoryControl_DeleteOne: { url: 'stock/inventory-controls/{id}', httpMethod: 'DELETE' },
  ResourceStock_GetDetail: { url: 'stock/stock/{id}', httpMethod: 'GET' },
  ResourceStock_Search: { url: 'stock/stock', httpMethod: 'GET' },
  ResourceStock_Lots: { url: 'stock/stock/lots/{resourceId}', httpMethod: 'GET' },
  ResourceStock_GetLines: { url: 'stock/stock/lines/resource/{resourceId}', httpMethod: 'GET' },
  ResourceStock_GetLine: { url: 'stock/stock/lines/{id}', httpMethod: 'GET' },
  ResourceStock_ExcelExport: { url: 'stock/stock/export', httpMethod: 'GET' },
  StockLine_GetStockLine: { url: 'stock/stocklines/{id}', httpMethod: 'GET' },
  StockLine_Search: { url: 'stock/stocklines', httpMethod: 'GET' },
  StockLocation_Search: { url: 'stock/stocklocations', httpMethod: 'GET' },
  StockLocation_GetListShelf: { url: 'stock/stocklocations/shelf', httpMethod: 'GET' },
  StockLocation_GetDetailShelf: { url: 'stock/stocklocations/shelf/{id}', httpMethod: 'GET' },
  StockMoves_Search: { url: 'stock/stock-moves', httpMethod: 'GET' },
  StockMoves_Create: { url: 'stock/stock-moves', httpMethod: 'POST' },
  StockMoves_BulkDelete: { url: 'stock/stock-moves', httpMethod: 'DELETE' },
  StockMoves_GetDetail: { url: 'stock/stock-moves/{id}', httpMethod: 'GET' },
  StockMoves_Update: { url: 'stock/stock-moves/{id}', httpMethod: 'PUT' },
  StockMoves_Delete: { url: 'stock/stock-moves/{id}', httpMethod: 'DELETE' },
  StockMoves_GetListStockMoveLine: { url: 'stock/stock-moves/line/{id}', httpMethod: 'GET' },
  StockMoves_AddLine: { url: 'stock/stock-moves/line/{id}', httpMethod: 'POST' },
  StockMoves_DeleteLine: { url: 'stock/stock-moves/line/{id}', httpMethod: 'DELETE' },
  StockMoves_ApplyStockMove: { url: 'stock/stock-moves/apply/{id}', httpMethod: 'POST' },
  StockMoves_PdfExport: { url: 'stock/stock-moves/pdf-export/{id}', httpMethod: 'GET' },
  StockMoves_BulkPdfExport: { url: 'stock/stock-moves/bulk-pdf-export', httpMethod: 'GET' },
  StockMoves_ExcelExport: { url: 'stock/stock-moves/excel-export', httpMethod: 'GET' },
  StockSplit_Search: { url: 'stock/stock-split', httpMethod: 'GET' },
  StockSplit_Create: { url: 'stock/stock-split', httpMethod: 'POST' },
  StockSplit_GetDetail: { url: 'stock/stock-split/{id}', httpMethod: 'GET' },
  PurchaseOrder_GetLines: { url: 'purchasing/purchase-order/lines/{id}', httpMethod: 'GET' },
  PurchaseOrder_ExportPdf: { url: 'purchasing/purchase-order/pdf-export/{id}', httpMethod: 'GET' },
  PurchaseOrder_ExportPdfs: { url: 'purchasing/purchase-order/pdf-export', httpMethod: 'GET' },
  PurchaseOrder_ExportExcel: { url: 'purchasing/purchase-order/excel-export', httpMethod: 'GET' },
  PurchaseOrder_BulkCreate: { url: 'purchasing/purchase-order/bulk-create', httpMethod: 'POST' },
  PurchaseOrder_Search: { url: 'purchasing/purchase-order', httpMethod: 'GET' },
  PurchaseOrder_Create: { url: 'purchasing/purchase-order', httpMethod: 'POST' },
  PurchaseOrder_DeleteMany: { url: 'purchasing/purchase-order', httpMethod: 'DELETE' },
  PurchaseOrder_Get: { url: 'purchasing/purchase-order/{id}', httpMethod: 'GET' },
  PurchaseOrder_Update: { url: 'purchasing/purchase-order/{id}', httpMethod: 'PUT' },
  PurchaseOrder_DeleteOne: { url: 'purchasing/purchase-order/{id}', httpMethod: 'DELETE' },
  PurchaseOrderFile_GetFiles: { url: 'purchasing/purchase-order/{id}/files', httpMethod: 'GET' },
  PurchaseOrderFile_UploadFile: { url: 'purchasing/purchase-order/{id}/files', httpMethod: 'POST' },
  PurchaseOrderFile_GetFile: { url: 'purchasing/purchase-order/{id}/files/{fileId}', httpMethod: 'GET' },
  PurchaseOrderFile_DeleteFile: { url: 'purchasing/purchase-order/{id}/files/{fileId}', httpMethod: 'DELETE' },
  Categories_Search: { url: 'product/categories', httpMethod: 'GET' },
  Categories_Create: { url: 'product/categories', httpMethod: 'POST' },
  Categories_BulkDelete: { url: 'product/categories', httpMethod: 'DELETE' },
  Categories_GetList: { url: 'product/categories/list', httpMethod: 'GET' },
  Categories_GetDetail: { url: 'product/categories/{id}', httpMethod: 'GET' },
  Categories_Update: { url: 'product/categories/{id}', httpMethod: 'PUT' },
  Categories_Delete: { url: 'product/categories/{id}', httpMethod: 'DELETE' },
  Products_Copy: { url: 'product/products/{Id}/copy', httpMethod: 'POST' },
  Products_Search: { url: 'product/products', httpMethod: 'GET' },
  Products_Create: { url: 'product/products', httpMethod: 'POST' },
  Products_DeleteMany: { url: 'product/products', httpMethod: 'DELETE' },
  Products_Get: { url: 'product/products/{id}', httpMethod: 'GET' },
  Products_Update: { url: 'product/products/{id}', httpMethod: 'PUT' },
  Products_DeleteOne: { url: 'product/products/{id}', httpMethod: 'DELETE' },
  RawMaterials_Create: { url: 'product/raw-materials', httpMethod: 'POST' },
  RawMaterials_Search: { url: 'product/raw-materials', httpMethod: 'GET' },
  RawMaterials_DeleteMany: { url: 'product/raw-materials', httpMethod: 'DELETE' },
  RawMaterials_Get: { url: 'product/raw-materials/{id}', httpMethod: 'GET' },
  RawMaterials_Update: { url: 'product/raw-materials/{id}', httpMethod: 'PUT' },
  RawMaterials_DeleteOne: { url: 'product/raw-materials/{id}', httpMethod: 'DELETE' },
  Resources_GetDetail: { url: 'product/resources/{id}', httpMethod: 'GET' },
  Resources_Search: { url: 'product/resources', httpMethod: 'GET' },
  BusinessPartners_Search: { url: 'business-partner/business-partners', httpMethod: 'GET' },
  BusinessPartners_Create: { url: 'business-partner/business-partners', httpMethod: 'POST' },
  BusinessPartners_DeleteMany: { url: 'business-partner/business-partners', httpMethod: 'DELETE' },
  BusinessPartners_Get: { url: 'business-partner/business-partners/{id}', httpMethod: 'GET' },
  BusinessPartners_Update: { url: 'business-partner/business-partners/{id}', httpMethod: 'PUT' },
  BusinessPartners_DeleteOne: { url: 'business-partner/business-partners/{id}', httpMethod: 'DELETE' },
  Groups_Search: { url: 'auth/groups', httpMethod: 'GET' },
  Groups_BulkDelete: { url: 'auth/groups', httpMethod: 'DELETE' },
  Groups_Create: { url: 'auth/groups', httpMethod: 'POST' },
  Groups_Delete: { url: 'auth/groups/{id}', httpMethod: 'DELETE' },
  Groups_GetDetail: { url: 'auth/groups/{id}', httpMethod: 'GET' },
  Groups_Update: { url: 'auth/groups/{id}', httpMethod: 'PUT' },
  Login_GetLogin: { url: 'auth/login', httpMethod: 'GET' },
  Login_Login: { url: 'auth/login', httpMethod: 'POST' },
  Login_Logout: { url: 'auth/login/logout', httpMethod: 'GET' },
  Profile_Get: { url: 'auth/profile', httpMethod: 'GET' },
  Profile_Update: { url: 'auth/profile/{id}', httpMethod: 'PUT' },
  Profile_ChangePassword: { url: 'auth/profile/{id}/password', httpMethod: 'PUT' },
  Users_Search: { url: 'auth/users', httpMethod: 'GET' },
  Users_BulkDelete: { url: 'auth/users', httpMethod: 'DELETE' },
  Users_Create: { url: 'auth/users', httpMethod: 'POST' },
  Users_Delete: { url: 'auth/users/{id}', httpMethod: 'DELETE' },
  Users_GetDetail: { url: 'auth/users/{id}', httpMethod: 'GET' },
  Users_Update: { url: 'auth/users/{id}', httpMethod: 'PUT' },
  Users_ResetPassword: { url: 'auth/users/{id}/reset-password', httpMethod: 'PUT' },
  Users_LockAccount: { url: 'auth/users/{id}/lock', httpMethod: 'PUT' },
  Users_UnLockAccount: { url: 'auth/users/{id}/unlock', httpMethod: 'PUT' },
  Common_GetCurrencies: { url: 'master/common/currencies', httpMethod: 'GET' },
  Common_GetEnums: { url: 'master/common/enums', httpMethod: 'GET' },
  Common_GetCountries: { url: 'master/common/countries', httpMethod: 'GET' },
  Common_GetAppState: { url: 'master/common/app-state', httpMethod: 'GET' },
};

// @ts-ignore
const urls: Record<keyof typeof tempUrls, Endpoint> = tempUrls;
export default urls;
