/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: developer.contact@outfiz.vn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StockInventoryControlLineCreate
 */
export interface StockInventoryControlLineCreate {
    /**
     * 
     * @type {number}
     * @memberof StockInventoryControlLineCreate
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof StockInventoryControlLineCreate
     */
    stockLineId: number;
    /**
     * 
     * @type {string}
     * @memberof StockInventoryControlLineCreate
     */
    comment?: string;
    /**
     * 
     * @type {number}
     * @memberof StockInventoryControlLineCreate
     */
    shelfId?: number;
}

/**
 * Check if a given object implements the StockInventoryControlLineCreate interface.
 */
export function instanceOfStockInventoryControlLineCreate(value: object): boolean {
    if (!('amount' in value)) return false;
    if (!('stockLineId' in value)) return false;
    return true;
}

export function StockInventoryControlLineCreateFromJSON(json: any): StockInventoryControlLineCreate {
    return StockInventoryControlLineCreateFromJSONTyped(json, false);
}

export function StockInventoryControlLineCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockInventoryControlLineCreate {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        
        'amount': json['amount'],
        'stockLineId': json['stockLineId'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'shelfId': !exists(json, 'shelfId') ? undefined : json['shelfId'],
    };
}

export function StockInventoryControlLineCreateToJSON(value?: StockInventoryControlLineCreate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value['amount'],
        'stockLineId': value['stockLineId'],
        'comment': value['comment'],
        'shelfId': value['shelfId'],
    };
}

