import * as React from 'react';
import {
  TextField,
  EditButton,
  DeleteWithConfirmButton,
  ShowButton,
} from 'react-admin';

import { Source } from 'utils/source';
import { BusinessPartnerSchema } from 'api/models/BusinessPartnerSchema';
import List from 'components/list/List';
import Datagrid from 'components/list/dataGrid';
import filters from './filters';
import ListActions from '../../components/layout/actions/list';
import useHasAccess from '../../hooks/useHasAccess';
import styles from './businessPartner.module.scss';

export default React.memo(() => {
  const hasAccess = useHasAccess();
  const canPut = hasAccess({ url: 'business-partner/business-partners/{id}', httpMethod: 'PUT' });
  const canDelete = hasAccess({ url: 'business-partner/business-partners/{id}', httpMethod: 'DELETE' });
  const canShow = hasAccess({ url: 'business-partner/business-partners/{id}', httpMethod: 'GET' });
  const s = Source<BusinessPartnerSchema>();
  return (
    <List
      filters={filters}
      actions={<ListActions exporter />}
    >
      <Datagrid className={styles.bp_table}>
        <TextField {...s('name')} />
        <TextField {...s('phone')} />
        <TextField {...s('taxId')} />
        <TextField {...s('address.countryId')} sortBy="country" />
        <TextField {...s('address.zipcode')} sortBy="zipcode" />
        <TextField {...s('address.city')} sortBy="city" />
        <TextField {...s('address.state')} sortBy="state" />
        {canShow && <ShowButton label="" />}
        {canPut && <EditButton label="" />}
        {canDelete && <DeleteWithConfirmButton label="" mutationMode="pessimistic" />}
      </Datagrid>
    </List>
  );
});
