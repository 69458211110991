/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: developer.contact@outfiz.vn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StockLocationSchema } from './StockLocationSchema';
import {
    StockLocationSchemaFromJSON,
    StockLocationSchemaFromJSONTyped,
    StockLocationSchemaToJSON,
} from './StockLocationSchema';

/**
 * 
 * @export
 * @interface StockLinesLine
 */
export interface StockLinesLine {
    /**
     * 
     * @type {number}
     * @memberof StockLinesLine
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof StockLinesLine
     */
    lotNo: string;
    /**
     * 
     * @type {Date}
     * @memberof StockLinesLine
     */
    lotDate: Date;
    /**
     * 
     * @type {number}
     * @memberof StockLinesLine
     */
    labelId: number;
    /**
     * 
     * @type {number}
     * @memberof StockLinesLine
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof StockLinesLine
     */
    stockLocationId: number;
    /**
     * 
     * @type {StockLocationSchema}
     * @memberof StockLinesLine
     */
    stockLocation: StockLocationSchema;
}

/**
 * Check if a given object implements the StockLinesLine interface.
 */
export function instanceOfStockLinesLine(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('lotNo' in value)) return false;
    if (!('lotDate' in value)) return false;
    if (!('labelId' in value)) return false;
    if (!('amount' in value)) return false;
    if (!('stockLocationId' in value)) return false;
    if (!('stockLocation' in value)) return false;
    return true;
}

export function StockLinesLineFromJSON(json: any): StockLinesLine {
    return StockLinesLineFromJSONTyped(json, false);
}

export function StockLinesLineFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockLinesLine {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'lotNo': json['lotNo'],
        'lotDate': (new Date(json['lotDate'])),
        'labelId': json['labelId'],
        'amount': json['amount'],
        'stockLocationId': json['stockLocationId'],
        'stockLocation': StockLocationSchemaFromJSON(json['stockLocation']),
    };
}

export function StockLinesLineToJSON(value?: StockLinesLine | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value['id'],
        'lotNo': value['lotNo'],
        'lotDate': ((value['lotDate']).toISOString()),
        'labelId': value['labelId'],
        'amount': value['amount'],
        'stockLocationId': value['stockLocationId'],
        'stockLocation': StockLocationSchemaToJSON(value['stockLocation']),
    };
}

