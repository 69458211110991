/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: developer.contact@outfiz.vn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StockResource
 */
export interface StockResource {
    /**
     * 
     * @type {number}
     * @memberof StockResource
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof StockResource
     */
    articleNo?: string;
    /**
     * 
     * @type {string}
     * @memberof StockResource
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof StockResource
     */
    quantityTypeId: number;
    /**
     * 
     * @type {number}
     * @memberof StockResource
     */
    amount: number;
    /**
     * 
     * @type {Date}
     * @memberof StockResource
     */
    lastChange?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StockResource
     */
    lastInventory?: Date;
}

/**
 * Check if a given object implements the StockResource interface.
 */
export function instanceOfStockResource(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    if (!('quantityTypeId' in value)) return false;
    if (!('amount' in value)) return false;
    return true;
}

export function StockResourceFromJSON(json: any): StockResource {
    return StockResourceFromJSONTyped(json, false);
}

export function StockResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockResource {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'articleNo': !exists(json, 'articleNo') ? undefined : json['articleNo'],
        'name': json['name'],
        'quantityTypeId': json['quantityTypeId'],
        'amount': json['amount'],
        'lastChange': !exists(json, 'lastChange') ? undefined : (new Date(json['lastChange'])),
        'lastInventory': !exists(json, 'lastInventory') ? undefined : (new Date(json['lastInventory'])),
    };
}

export function StockResourceToJSON(value?: StockResource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value['id'],
        'articleNo': value['articleNo'],
        'name': value['name'],
        'quantityTypeId': value['quantityTypeId'],
        'amount': value['amount'],
        'lastChange': !exists(value, 'lastChange') ? undefined : ((value['lastChange'] as any).toISOString()),
        'lastInventory': !exists(value, 'lastInventory') ? undefined : ((value['lastInventory'] as any).toISOString()),
    };
}

