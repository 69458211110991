import { Grid } from '@mui/material';
import { StockInventoryControlCreate } from 'api';
import * as React from 'react';
import {
  Loading,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  useRecordContext,
  BooleanInput,
} from 'react-admin';
import useResourcesQuery from 'repositories/products/product';
import Source from 'utils/source';
import CustomDatePicker from 'components/input/date/CustomDatePicker';

export default function InventoryControlForm() {
  const { data: resources } = useResourcesQuery();
  const s = Source<StockInventoryControlCreate>();
  const record = useRecordContext();
  if (!resources) return <Loading />;

  return (
    <SimpleForm>
      <Grid container spacing={2} rowSpacing={0.1}>
        <Grid item xs={2} md={2} />
        <Grid item xs={4} md={4}>
          <CustomDatePicker
            {...s('plannedDate')}
            fullWidth
            variant="outlined"
            helperText={false}
          />
        </Grid>
        <Grid item xs={4} md={4}>
          <ReferenceInput
            label="Stocklocations"
            {...s('stockLocationId')}
            reference="stock/stocklocations"
          >
            <SelectInput
              optionText="name"
              fullWidth
              required
              sx={{ marginTop: '0px' }}
              disabled={record?.isDisableStockLocation ?? false}
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={2} md={2} />

        <Grid item xs={2} md={2} />
        <Grid item xs={4} md={4}>
          <TextInput
            {...s('comment')}
            fullWidth
            variant="outlined"
            helperText={false}
          />
        </Grid>
        <Grid item xs={4} md={4}>
          <BooleanInput
            {...s('partialInventory')}
            fullWidth
            defaultChecked={false}
            variant="outlined"
            helperText={false}
          />
        </Grid>
      </Grid>
    </SimpleForm>
  );
}
