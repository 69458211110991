import { PurchasingPurchaseOrderLineSolo } from 'api';
import urls from 'app/urls';
import QuantityTypeField from 'components/fields/QuantityTypeField';
import Datagrid from 'components/list/dataGrid';
import React from 'react';
import {
  DateField, Identifier, List, TextField, useCreatePath, useRecordContext,
} from 'react-admin';
import Source from 'utils/source';

export default function OpenOrderLines() {
  const s = Source<PurchasingPurchaseOrderLineSolo>();
  const { id: resourceId } = useRecordContext();
  const resource = urls.PurchaseOrder_Search.url;
  const getPath = useCreatePath();

  // const { data } = useOrderLines({ id: id as number });

  return (
    // @ts-ignore
    <List resource={urls.PurchaseOrder_GetLines.url.replace('{id}', resourceId)} title=" ">
      <Datagrid
        rowClick={(id: Identifier) => getPath({
          id,
          resource,
          type: 'show',
        })}
      >
        <TextField {...s('purchaseOrderName')} />
        <QuantityTypeField {...s('amount')} idsource={s('quantityTypeId').source} />
        <DateField {...s('confirmedDeliveryDate')} />
        <TextField {...s('comment')} />
      </Datagrid>
    </List>
  );
}
