const messages = {
  resources: {
    'stock/incomingShipment': {
      name: 'Incoming Shipment',
      fields: {
        state: 'State',
        comment: 'Comment',
        supplier: 'Supplier',
        transportType: 'Transport Type',
        estimatedTimeofArrival: 'ETA (Estimated time of arrival)',
        estimatedTimeofDeparture: 'ETD (Estimated time of departure)',
        shipmentName: 'Shipment Name',
        receiveShipment: 'Receive Shipment',
      },
    },
  },
  mai: {
    incomingShipment: {
      shipmentState: {
        WaitingForDeparture: 'Waiting for Departure',
        DuringShipment: 'During Shipment',
        Processing: 'Processing',
        Done: 'Done',
        Error: 'Error',
      },
      transportType: {
        Sea: 'Sea',
        Air: 'Air',
        Truck: 'Truck',
        Other: 'Other',
        Express: 'Express',
      },
      receive: {
        Product: 'Product',
        ArticleNo: 'Article No',
        LotNo: 'Lot No',
        PlannedAmount: 'Planned Amount',
        ActualAmount: 'Actual Amount',
        Difference: 'Difference',
        Amount: 'Amount',
        Resource: 'Resource',
      },
    },
  },
};

export default messages;
