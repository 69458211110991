/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: developer.contact@outfiz.vn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StockLinesLine } from './StockLinesLine';
import {
    StockLinesLineFromJSON,
    StockLinesLineFromJSONTyped,
    StockLinesLineToJSON,
} from './StockLinesLine';
import type { StockQuantityTypeEntity } from './StockQuantityTypeEntity';
import {
    StockQuantityTypeEntityFromJSON,
    StockQuantityTypeEntityFromJSONTyped,
    StockQuantityTypeEntityToJSON,
} from './StockQuantityTypeEntity';

/**
 * 
 * @export
 * @interface ProductRawMaterialDetailEntity
 */
export interface ProductRawMaterialDetailEntity {
    /**
     * 
     * @type {string}
     * @memberof ProductRawMaterialDetailEntity
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ProductRawMaterialDetailEntity
     */
    articleNo?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductRawMaterialDetailEntity
     */
    isActive: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductRawMaterialDetailEntity
     */
    isUniqueLotNo: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductRawMaterialDetailEntity
     */
    currencyId: string;
    /**
     * 
     * @type {number}
     * @memberof ProductRawMaterialDetailEntity
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof ProductRawMaterialDetailEntity
     */
    stockTracing: ProductRawMaterialDetailEntityStockTracingEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductRawMaterialDetailEntity
     */
    quantityTypeId: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductRawMaterialDetailEntity
     */
    categories: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof ProductRawMaterialDetailEntity
     */
    supplierId: number;
    /**
     * 
     * @type {number}
     * @memberof ProductRawMaterialDetailEntity
     */
    leadTime?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductRawMaterialDetailEntity
     */
    supplierArticleNo?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductRawMaterialDetailEntity
     */
    supplierArticleName?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductRawMaterialDetailEntity
     */
    supplierQuantityTypeId?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductRawMaterialDetailEntity
     */
    supplierQuantityFactor?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductRawMaterialDetailEntity
     */
    moq?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductRawMaterialDetailEntity
     */
    reserveQuantity?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductRawMaterialDetailEntity
     */
    stockConditioning?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductRawMaterialDetailEntity
     */
    supplierConditioning?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductRawMaterialDetailEntity
     */
    weightPerUnit?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductRawMaterialDetailEntity
     */
    hsCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductRawMaterialDetailEntity
     */
    originCountryId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductRawMaterialDetailEntity
     */
    incoterm?: ProductRawMaterialDetailEntityIncotermEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductRawMaterialDetailEntity
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ProductRawMaterialDetailEntity
     */
    currencyName: string;
    /**
     * 
     * @type {string}
     * @memberof ProductRawMaterialDetailEntity
     */
    currencySymbol: string;
    /**
     * 
     * @type {string}
     * @memberof ProductRawMaterialDetailEntity
     */
    supplierName: string;
    /**
     * 
     * @type {StockQuantityTypeEntity}
     * @memberof ProductRawMaterialDetailEntity
     */
    quantityType: StockQuantityTypeEntity;
    /**
     * 
     * @type {StockQuantityTypeEntity}
     * @memberof ProductRawMaterialDetailEntity
     */
    supplierQuantityType?: StockQuantityTypeEntity;
    /**
     * 
     * @type {Array<StockLinesLine>}
     * @memberof ProductRawMaterialDetailEntity
     */
    stockLines: Array<StockLinesLine>;
}


/**
 * @export
 */
export const ProductRawMaterialDetailEntityStockTracingEnum = {
    None: 'None',
    Lot: 'Lot',
    Serial: 'Serial'
} as const;
export type ProductRawMaterialDetailEntityStockTracingEnum = typeof ProductRawMaterialDetailEntityStockTracingEnum[keyof typeof ProductRawMaterialDetailEntityStockTracingEnum];

/**
 * @export
 */
export const ProductRawMaterialDetailEntityIncotermEnum = {
    Cfr: 'CFR',
    Cif: 'CIF',
    Ddu: 'DDU',
    Exw: 'EXW',
    Fob: 'FOB'
} as const;
export type ProductRawMaterialDetailEntityIncotermEnum = typeof ProductRawMaterialDetailEntityIncotermEnum[keyof typeof ProductRawMaterialDetailEntityIncotermEnum];


/**
 * Check if a given object implements the ProductRawMaterialDetailEntity interface.
 */
export function instanceOfProductRawMaterialDetailEntity(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('isActive' in value)) return false;
    if (!('isUniqueLotNo' in value)) return false;
    if (!('currencyId' in value)) return false;
    if (!('price' in value)) return false;
    if (!('stockTracing' in value)) return false;
    if (!('quantityTypeId' in value)) return false;
    if (!('categories' in value)) return false;
    if (!('supplierId' in value)) return false;
    if (!('id' in value)) return false;
    if (!('currencyName' in value)) return false;
    if (!('currencySymbol' in value)) return false;
    if (!('supplierName' in value)) return false;
    if (!('quantityType' in value)) return false;
    if (!('stockLines' in value)) return false;
    return true;
}

export function ProductRawMaterialDetailEntityFromJSON(json: any): ProductRawMaterialDetailEntity {
    return ProductRawMaterialDetailEntityFromJSONTyped(json, false);
}

export function ProductRawMaterialDetailEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductRawMaterialDetailEntity {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'articleNo': !exists(json, 'articleNo') ? undefined : json['articleNo'],
        'isActive': json['isActive'],
        'isUniqueLotNo': json['isUniqueLotNo'],
        'currencyId': json['currencyId'],
        'price': json['price'],
        'stockTracing': json['stockTracing'],
        'quantityTypeId': json['quantityTypeId'],
        'categories': json['categories'],
        'supplierId': json['supplierId'],
        'leadTime': !exists(json, 'leadTime') ? undefined : json['leadTime'],
        'supplierArticleNo': !exists(json, 'supplierArticleNo') ? undefined : json['supplierArticleNo'],
        'supplierArticleName': !exists(json, 'supplierArticleName') ? undefined : json['supplierArticleName'],
        'supplierQuantityTypeId': !exists(json, 'supplierQuantityTypeId') ? undefined : json['supplierQuantityTypeId'],
        'supplierQuantityFactor': !exists(json, 'supplierQuantityFactor') ? undefined : json['supplierQuantityFactor'],
        'moq': !exists(json, 'moq') ? undefined : json['moq'],
        'reserveQuantity': !exists(json, 'reserveQuantity') ? undefined : json['reserveQuantity'],
        'stockConditioning': !exists(json, 'stockConditioning') ? undefined : json['stockConditioning'],
        'supplierConditioning': !exists(json, 'supplierConditioning') ? undefined : json['supplierConditioning'],
        'weightPerUnit': !exists(json, 'weightPerUnit') ? undefined : json['weightPerUnit'],
        'hsCode': !exists(json, 'hsCode') ? undefined : json['hsCode'],
        'originCountryId': !exists(json, 'originCountryId') ? undefined : json['originCountryId'],
        'incoterm': !exists(json, 'incoterm') ? undefined : json['incoterm'],
        'id': json['id'],
        'currencyName': json['currencyName'],
        'currencySymbol': json['currencySymbol'],
        'supplierName': json['supplierName'],
        'quantityType': StockQuantityTypeEntityFromJSON(json['quantityType']),
        'supplierQuantityType': !exists(json, 'supplierQuantityType') ? undefined : StockQuantityTypeEntityFromJSON(json['supplierQuantityType']),
        'stockLines': ((json['stockLines'] as Array<any>).map(StockLinesLineFromJSON)),
    };
}

export function ProductRawMaterialDetailEntityToJSON(value?: ProductRawMaterialDetailEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value['name'],
        'articleNo': value['articleNo'],
        'isActive': value['isActive'],
        'isUniqueLotNo': value['isUniqueLotNo'],
        'currencyId': value['currencyId'],
        'price': value['price'],
        'stockTracing': value['stockTracing'],
        'quantityTypeId': value['quantityTypeId'],
        'categories': value['categories'],
        'supplierId': value['supplierId'],
        'leadTime': value['leadTime'],
        'supplierArticleNo': value['supplierArticleNo'],
        'supplierArticleName': value['supplierArticleName'],
        'supplierQuantityTypeId': value['supplierQuantityTypeId'],
        'supplierQuantityFactor': value['supplierQuantityFactor'],
        'moq': value['moq'],
        'reserveQuantity': value['reserveQuantity'],
        'stockConditioning': value['stockConditioning'],
        'supplierConditioning': value['supplierConditioning'],
        'weightPerUnit': value['weightPerUnit'],
        'hsCode': value['hsCode'],
        'originCountryId': value['originCountryId'],
        'incoterm': value['incoterm'],
        'id': value['id'],
        'currencyName': value['currencyName'],
        'currencySymbol': value['currencySymbol'],
        'supplierName': value['supplierName'],
        'quantityType': StockQuantityTypeEntityToJSON(value['quantityType']),
        'supplierQuantityType': StockQuantityTypeEntityToJSON(value['supplierQuantityType']),
        'stockLines': ((value['stockLines'] as Array<any>).map(StockLinesLineToJSON)),
    };
}

