import * as React from 'react';
import {
  TextField,
  DateField,
  BooleanField,
  ShowButton,
  EditButton,
  EmailField,
  DeleteWithConfirmButton,
} from 'react-admin';
import ListActions from 'components/layout/actions/list';
import useHasAccess from 'hooks/useHasAccess';
import Source from 'utils/source';
import { AuthUserEntity } from 'api';
import List from 'components/list/List';
import Datagrid from 'components/list/dataGrid';
import filters from './filters';

export default React.memo(() => {
  const hasAccess = useHasAccess();
  const canPut = hasAccess({ url: 'auth/users/{id}', httpMethod: 'PUT' });
  const canDelete = hasAccess({ url: 'auth/users/{id}', httpMethod: 'DELETE' });
  const canShow = hasAccess({ url: 'auth/users/{id}', httpMethod: 'GET' });
  const s = Source<AuthUserEntity>();
  return (
    <List filters={filters} actions={<ListActions exporter />}>
      <Datagrid optimized>
        <TextField {...s('username')} />
        <EmailField {...s('email')} />
        <DateField {...s('lastLogin')} showTime />
        <DateField {...s('lastLogout')} showTime />
        <BooleanField {...s('isActive')} />
        <BooleanField {...s('isVerifyEmail')} />
        {canShow && <ShowButton label="" />}
        {canPut && <EditButton label="" />}
        {canDelete && <DeleteWithConfirmButton label="" mutationMode="pessimistic" />}
      </Datagrid>
    </List>
  );
});
