/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: developer.contact@outfiz.vn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StockShelf
 */
export interface StockShelf {
    /**
     * 
     * @type {number}
     * @memberof StockShelf
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof StockShelf
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof StockShelf
     */
    stockLocationId?: string;
    /**
     * 
     * @type {string}
     * @memberof StockShelf
     */
    stockLocationName?: string;
}

/**
 * Check if a given object implements the StockShelf interface.
 */
export function instanceOfStockShelf(value: object): boolean {
    if (!('id' in value)) return false;
    return true;
}

export function StockShelfFromJSON(json: any): StockShelf {
    return StockShelfFromJSONTyped(json, false);
}

export function StockShelfFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockShelf {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'stockLocationId': !exists(json, 'stockLocationId') ? undefined : json['stockLocationId'],
        'stockLocationName': !exists(json, 'stockLocationName') ? undefined : json['stockLocationName'],
    };
}

export function StockShelfToJSON(value?: StockShelf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'stockLocationId': value['stockLocationId'],
        'stockLocationName': value['stockLocationName'],
    };
}

