const messages = {
  resources: {
    'stock/inventoryControl': {
      name: 'Inventory Control',
      fields: {
        status: 'Status',
        plannedDate: 'Planed date',
        finishedDate: 'Finished date',
        comment: 'Comment',
        stockLocationName: 'StockLocation Name',
        addInventoryLine: 'Check Inventory',
      },
    },
    'stock/inventoryControlLine': {
      fields: {
        articleNo: 'Article No',
        articleName: 'Article Name',
        lotNo: 'Lot No',
        changeAmount: 'Change Amount',
      },
    },
  },
  mai: {
    inventoryControl: {
      inventoryControlStatus: {
        Unapplied: 'Unapplied',
        Applied: 'Applied',
      },
      quantityInput: 'Quantity Input',
    },
  },
};

export default messages;
