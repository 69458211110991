/* eslint-disable max-len */
const msg = {
  NOT_ALLOWED: 'TODO',
  FORBIDDEN: 'TODO',
  VALIDATION_FAILED: 'TODO',
  USERNAME_OR_PASSWORD_NOT_CORRECT: 'Tài khoản hoặc mật khẩu không chính xác.',
  USERNAME_HAD_USED: 'Tên người dùng này đã được đăng ký. Vui lòng sử dụng tên người dùng khác!',
  GROUP_HAVE_ACCOUNT: 'Có một tài khoản trong nhóm này nên không thể xóa được, trước tiên hãy xóa tất cả các tài khoản của nhóm này.',
  HAS_ASSOCIATED_RECORDS: 'TODO',
  EMAIL_HAD_USED: 'Email này đã được đăng ký. Vui lòng sử dụng email khác!',
  ACCOUNT_WAS_LOCKED: 'Tài khoản đã tạm thời bị khóa, vui lòng liên hệ với quản trị viên để được hỗ trợ.',
  GROUP_NAME_INVALIDATE: 'Tên của nhóm làm mất hiệu lực.',
  GROUP_NAME_HAD_USED: 'Tên nhóm này đã được đăng ký. Vui lòng sử dụng tên nhóm khác!',
  STOCKLINE_INVALIDATE: 'TODO',
  CATEGORY_PARENT_NOT_FOUND: 'TODO',
  YOU_NOT_HAVE_PERMISSION: 'Bạn không có quyền thực hiện hành động này.',
  NOT_FOUND: 'Dữ liệu được yêu cầu không được tìm thấy.',
  SERVER_ERROR: 'Hệ thống có lỗi, vui lòng thử lại sau.',
  UNIQUE_CONSTRAINT: 'TODO',
  LOTNO_ALREADY_EXIST: 'Lot no đã tồn tại. Vui lòng render lot no mới!',
  NO_VALID_DATA_FOUND: 'Không tìm thấy dữ liệu hợp lệ. Vui lòng quét lại!',
  MUST_BE_NUMBER: 'Giá trị phải là một chữ số!',
};
export default msg;
