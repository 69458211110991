/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: developer.contact@outfiz.vn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StockInventoryControlLineSchema } from './StockInventoryControlLineSchema';
import {
    StockInventoryControlLineSchemaFromJSON,
    StockInventoryControlLineSchemaFromJSONTyped,
    StockInventoryControlLineSchemaToJSON,
} from './StockInventoryControlLineSchema';
import type { StockInventoryControlMoveLineSchema } from './StockInventoryControlMoveLineSchema';
import {
    StockInventoryControlMoveLineSchemaFromJSON,
    StockInventoryControlMoveLineSchemaFromJSONTyped,
    StockInventoryControlMoveLineSchemaToJSON,
} from './StockInventoryControlMoveLineSchema';

/**
 * 
 * @export
 * @interface StockInventoryControlDetail
 */
export interface StockInventoryControlDetail {
    /**
     * 
     * @type {number}
     * @memberof StockInventoryControlDetail
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof StockInventoryControlDetail
     */
    plannedDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StockInventoryControlDetail
     */
    finishedDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StockInventoryControlDetail
     */
    applyDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof StockInventoryControlDetail
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof StockInventoryControlDetail
     */
    stockMoveInFromName?: string;
    /**
     * 
     * @type {string}
     * @memberof StockInventoryControlDetail
     */
    stockMoveInToName?: string;
    /**
     * 
     * @type {string}
     * @memberof StockInventoryControlDetail
     */
    stockMoveOutFromName?: string;
    /**
     * 
     * @type {string}
     * @memberof StockInventoryControlDetail
     */
    stockMoveOutToName?: string;
    /**
     * 
     * @type {string}
     * @memberof StockInventoryControlDetail
     */
    stockLocationName?: string;
    /**
     * 
     * @type {number}
     * @memberof StockInventoryControlDetail
     */
    stockLocationId: number;
    /**
     * 
     * @type {boolean}
     * @memberof StockInventoryControlDetail
     */
    isDisableStockLocation: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StockInventoryControlDetail
     */
    partialInventory: boolean;
    /**
     * 
     * @type {Array<StockInventoryControlLineSchema>}
     * @memberof StockInventoryControlDetail
     */
    incoming?: Array<StockInventoryControlLineSchema>;
    /**
     * 
     * @type {Array<StockInventoryControlLineSchema>}
     * @memberof StockInventoryControlDetail
     */
    leaving?: Array<StockInventoryControlLineSchema>;
    /**
     * 
     * @type {Array<StockInventoryControlMoveLineSchema>}
     * @memberof StockInventoryControlDetail
     */
    stockMoveIn?: Array<StockInventoryControlMoveLineSchema>;
    /**
     * 
     * @type {Array<StockInventoryControlMoveLineSchema>}
     * @memberof StockInventoryControlDetail
     */
    stockMoveOut?: Array<StockInventoryControlMoveLineSchema>;
}

/**
 * Check if a given object implements the StockInventoryControlDetail interface.
 */
export function instanceOfStockInventoryControlDetail(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('stockLocationId' in value)) return false;
    if (!('isDisableStockLocation' in value)) return false;
    if (!('partialInventory' in value)) return false;
    return true;
}

export function StockInventoryControlDetailFromJSON(json: any): StockInventoryControlDetail {
    return StockInventoryControlDetailFromJSONTyped(json, false);
}

export function StockInventoryControlDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockInventoryControlDetail {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'plannedDate': !exists(json, 'plannedDate') ? undefined : (new Date(json['plannedDate'])),
        'finishedDate': !exists(json, 'finishedDate') ? undefined : (new Date(json['finishedDate'])),
        'applyDate': !exists(json, 'applyDate') ? undefined : (new Date(json['applyDate'])),
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'stockMoveInFromName': !exists(json, 'stockMoveInFromName') ? undefined : json['stockMoveInFromName'],
        'stockMoveInToName': !exists(json, 'stockMoveInToName') ? undefined : json['stockMoveInToName'],
        'stockMoveOutFromName': !exists(json, 'stockMoveOutFromName') ? undefined : json['stockMoveOutFromName'],
        'stockMoveOutToName': !exists(json, 'stockMoveOutToName') ? undefined : json['stockMoveOutToName'],
        'stockLocationName': !exists(json, 'stockLocationName') ? undefined : json['stockLocationName'],
        'stockLocationId': json['stockLocationId'],
        'isDisableStockLocation': json['isDisableStockLocation'],
        'partialInventory': json['partialInventory'],
        'incoming': !exists(json, 'incoming') ? undefined : ((json['incoming'] as Array<any>).map(StockInventoryControlLineSchemaFromJSON)),
        'leaving': !exists(json, 'leaving') ? undefined : ((json['leaving'] as Array<any>).map(StockInventoryControlLineSchemaFromJSON)),
        'stockMoveIn': !exists(json, 'stockMoveIn') ? undefined : ((json['stockMoveIn'] as Array<any>).map(StockInventoryControlMoveLineSchemaFromJSON)),
        'stockMoveOut': !exists(json, 'stockMoveOut') ? undefined : ((json['stockMoveOut'] as Array<any>).map(StockInventoryControlMoveLineSchemaFromJSON)),
    };
}

export function StockInventoryControlDetailToJSON(value?: StockInventoryControlDetail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value['id'],
        'plannedDate': !exists(value, 'plannedDate') ? undefined : ((value['plannedDate'] as any).toISOString()),
        'finishedDate': !exists(value, 'finishedDate') ? undefined : ((value['finishedDate'] as any).toISOString()),
        'applyDate': !exists(value, 'applyDate') ? undefined : ((value['applyDate'] as any).toISOString()),
        'comment': value['comment'],
        'stockMoveInFromName': value['stockMoveInFromName'],
        'stockMoveInToName': value['stockMoveInToName'],
        'stockMoveOutFromName': value['stockMoveOutFromName'],
        'stockMoveOutToName': value['stockMoveOutToName'],
        'stockLocationName': value['stockLocationName'],
        'stockLocationId': value['stockLocationId'],
        'isDisableStockLocation': value['isDisableStockLocation'],
        'partialInventory': value['partialInventory'],
        'incoming': !exists(value, 'incoming') ? undefined : ((value['incoming'] as Array<any>).map(StockInventoryControlLineSchemaToJSON)),
        'leaving': !exists(value, 'leaving') ? undefined : ((value['leaving'] as Array<any>).map(StockInventoryControlLineSchemaToJSON)),
        'stockMoveIn': !exists(value, 'stockMoveIn') ? undefined : ((value['stockMoveIn'] as Array<any>).map(StockInventoryControlMoveLineSchemaToJSON)),
        'stockMoveOut': !exists(value, 'stockMoveOut') ? undefined : ((value['stockMoveOut'] as Array<any>).map(StockInventoryControlMoveLineSchemaToJSON)),
    };
}

