import * as React from 'react';
import {
  TextField,
  useListFilterContext,
  DateField,
} from 'react-admin';

import ListActions from 'components/layout/actions/list';
import repositories from 'repositories/stock/stockLines';
import Source from 'utils/source';
import { StockResource } from 'api';
import ShowQuantityType from 'components/fields/QuantityTypeField';
import List from 'components/list/List';
import Datagrid from 'components/list/dataGrid';
import filters from './filters';

function useCustomListActions() {
  const filterContext = useListFilterContext();
  const doExport = () => {
    repositories.excelExport(filterContext.filterValues);
  };
  return <ListActions excelExporter={doExport} />;
}

export default React.memo(() => {
  const CustomListActions = useCustomListActions;
  const s = Source<StockResource>();
  return (
    <List filters={filters} actions={<CustomListActions />}>
      <Datagrid rowClick="show">
        <TextField {...s('articleNo')} />
        <TextField {...s('name')} label="Name" />
        <ShowQuantityType {...s('amount')} idsource="quantityTypeId" />
        <DateField {...s('lastChange')} label="Last Change" />
        <DateField {...s('lastInventory')} label="Last Inventory" />
      </Datagrid>
    </List>
  );
});
