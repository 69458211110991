const messages = {
  resources: {
    'product/products': {
      name: 'Product |||| Products',
      fields: {
        name: 'Name',
        price: 'Price',
        categoryName: 'Category',
        categoryId: 'Category',
        stockTracingName: 'StockTracing',
        stockTracingCode: 'StockTracing',
        quantityTypeName: 'Quantity Type',
        quantityTypeId: 'Quantity Type',
        currencyName: 'Currency',
        currencyId: 'Currency',
        isActive: 'Active',
        stateCode: 'State',
        detail: 'Product details',
        billofresources: 'Bill of resources',
      },
      tabs: {
        detail: 'Detail',
        billofresources: 'Bill of Materials',
        openOrders: 'Open Orders',
        stockLine: 'Stock Lines',
      },
    },
  },
  mai: {
    product: {
      categoryId: 'Category',
      stateCode: 'Active',
      stateActive: 'Active',
      stateInactive: 'Inactive',
      stateNull: 'Either',
    },
  },
};
export default messages;
