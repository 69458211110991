/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: developer.contact@outfiz.vn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResponseInfo
 */
export interface ResponseInfo {
    /**
     * 
     * @type {number}
     * @memberof ResponseInfo
     */
    code: number;
    /**
     * 
     * @type {string}
     * @memberof ResponseInfo
     */
    msgNo?: ResponseInfoMsgNoEnum;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ResponseInfo
     */
    listError: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ResponseInfo
     */
    data: { [key: string]: string; };
}


/**
 * @export
 */
export const ResponseInfoMsgNoEnum = {
    Forbidden: 'FORBIDDEN',
    NotAllowed: 'NOT_ALLOWED',
    ValidationFailed: 'VALIDATION_FAILED',
    UsernameOrPasswordNotCorrect: 'USERNAME_OR_PASSWORD_NOT_CORRECT',
    UsernameHadUsed: 'USERNAME_HAD_USED',
    LotnoAlreadyExist: 'LOTNO_ALREADY_EXIST',
    GroupHaveAccount: 'GROUP_HAVE_ACCOUNT',
    HasAssociatedRecords: 'HAS_ASSOCIATED_RECORDS',
    EmailHadUsed: 'EMAIL_HAD_USED',
    AccountWasLocked: 'ACCOUNT_WAS_LOCKED',
    GroupNameInvalidate: 'GROUP_NAME_INVALIDATE',
    GroupNameHadUsed: 'GROUP_NAME_HAD_USED',
    StocklineInvalidate: 'STOCKLINE_INVALIDATE',
    CategoryParentNotFound: 'CATEGORY_PARENT_NOT_FOUND',
    YouNotHavePermission: 'YOU_NOT_HAVE_PERMISSION',
    NotFound: 'NOT_FOUND',
    ServerError: 'SERVER_ERROR',
    UniqueConstraint: 'UNIQUE_CONSTRAINT'
} as const;
export type ResponseInfoMsgNoEnum = typeof ResponseInfoMsgNoEnum[keyof typeof ResponseInfoMsgNoEnum];


/**
 * Check if a given object implements the ResponseInfo interface.
 */
export function instanceOfResponseInfo(value: object): boolean {
    if (!('code' in value)) return false;
    if (!('listError' in value)) return false;
    if (!('data' in value)) return false;
    return true;
}

export function ResponseInfoFromJSON(json: any): ResponseInfo {
    return ResponseInfoFromJSONTyped(json, false);
}

export function ResponseInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponseInfo {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        
        'code': json['code'],
        'msgNo': !exists(json, 'msgNo') ? undefined : json['msgNo'],
        'listError': json['listError'],
        'data': json['data'],
    };
}

export function ResponseInfoToJSON(value?: ResponseInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value['code'],
        'msgNo': value['msgNo'],
        'listError': value['listError'],
        'data': value['data'],
    };
}

