import * as React from 'react';
import {
  Show,
  FunctionField,
  TabbedShowLayout,
  ArrayField,
  TextField,
  Datagrid,
} from 'react-admin';
import { Grid } from '@mui/material';
import Source from 'utils/source';
import ShowTitle from 'components/commons/showTitle';
import ShowLabel from 'components/commons/showLabel';
import ShowField from 'components/commons/showField';
import ShowDate from 'components/commons/showDate';
import { ProductBillOfResourceLineDetail, StockMoveDetail, StockMoveEntity } from 'api';
import QuantityTypeField from 'components/fields/QuantityTypeField';
import ShowStockMoveActions from './showStockMoveActions';
import { ShowStockMoveLines } from './lines/stockMoveLines';

export default function ShowStockMove() {
  const s = Source<StockMoveDetail>();
  const bl = Source<ProductBillOfResourceLineDetail>();
  return (
    <Show actions={<ShowStockMoveActions />}>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="details">
          <Grid container rowSpacing={1} spacing={1} sx={{ marginLeft: '3px', marginTop: '3px', marginBottom: '10px' }}>
            <Grid item xs={12} md={12}>
              <ShowTitle />
            </Grid>

            <Grid item xs={2} md={1.5} sx={{ borderBottom: '1' }} className="show-row-odd">
              <ShowLabel label="resources.stock/stock-moves.fields.source_location" />
            </Grid>
            <Grid item xs={8} md={9} className="show-row-odd">
              <ShowField {...s('sourceStockLocationName')} />
            </Grid>
            <Grid item xs={2} md={1.5} className="show-row-odd" />

            <Grid item xs={2} md={1.5} sx={{ borderBottom: '1' }} className="show-row-odd">
              <ShowLabel label="resources.stock/stock-moves.fields.target_location" />
            </Grid>
            <Grid item xs={8} md={9} className="show-row-odd">
              <ShowField {...s('destinationStockLocationName')} />
            </Grid>
            <Grid item xs={2} md={1.5} className="show-row-odd" />

            <Grid item xs={2} md={1.5} sx={{ borderBottom: '1' }} className="show-row-odd">
              <ShowLabel label="resources.stock/stock-moves.fields.comment" />
            </Grid>
            <Grid item xs={8} md={9} className="show-row-odd">
              <ShowField {...s('comment')} />
            </Grid>
            <Grid item xs={2} md={1.5} className="show-row-odd" />

            <Grid item xs={2} md={1.5} sx={{ borderBottom: '1' }}>
              <ShowLabel label="resources.stock/stock-moves.fields.plannedDate" />
            </Grid>
            <Grid item xs={8} md={9}>
              <ShowDate {...s('plannedDate')} showTime />
            </Grid>
            <Grid item xs={2} md={1.5} />

            <Grid item xs={2} md={1.5} sx={{ borderBottom: '1' }} className="show-row-odd">
              <ShowLabel label="resources.stock/stock-moves.fields.appliedDate" />
            </Grid>
            <Grid item xs={8} md={9} className="show-row-odd">
              <ShowDate {...s('appliedDate')} showTime />
            </Grid>
            <Grid item xs={2} md={1.5} className="show-row-odd" />

            <Grid item xs={2} md={1.5} sx={{ borderBottom: '1' }}>
              <ShowLabel label="resources.stock/stock-moves.fields.applier" />
            </Grid>
            <Grid item xs={8} md={9}>
              <ShowField {...s('applier')} className="highLightField" />
            </Grid>
            <Grid item xs={2} md={1.5} />

            <Grid item xs={2} md={1.5} sx={{ borderBottom: '1' }}>
              <ShowLabel label="resources.stock/stock-moves.fields.creator" />
            </Grid>
            <Grid item xs={8} md={9}>
              <ShowField {...s('createdBy')} className="highLightField" />
            </Grid>
            <Grid item xs={2} md={1.5} />
          </Grid>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="lines">
          <FunctionField render={(record: StockMoveEntity) => (
            <ShowStockMoveLines
              id={record.id.toString()}
              isApplied={!!record?.appliedDate}
              canDelete={false}
            />
          )}
          />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="planned">

          <ArrayField label={false} {...s('billOfResources.lines')}>
            <Datagrid bulkActionButtons={false}>
              <TextField label="resources.billOfResources.fields.name" {...bl('resourceName')} />
              <QuantityTypeField label="resources.billOfResources.fields.amount" {...bl('amount')} />
              <TextField label="resources.billOfResources.fields.comment" {...bl('comment')} />
            </Datagrid>
          </ArrayField>
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </Show>
  );
}
