const messages = {
  resources: {
    'stock/stock-moves': {
      name: 'StockMoves',
      fields: {
        comment: 'Comment',
        plannedDate: 'Planned date',
        appliedDate: 'Applied date',
        appliedBy: 'Applied By',
        applier: 'Applied By',
        planed: 'Planed',
        planedSince: 'Planed since',
        planedUntil: 'Planed until',
        applied: 'Applied',
        appliedSince: 'Applied since',
        appliedUntil: 'Applied until',
        stockMoveLines: 'Stock move lines',
        totalAmount: 'Total amount',
        source_location: 'Source location',
        target_location: 'Target location',
        creator: 'Created By',
      },
    },
  },
  mai: {
    stockMove: {
      apply_btn: 'APPLY',
      apply_confirm_title: 'Apply stock move',
      apply_confirm_content: 'Do you want to apply this stock move?',
      apply_success: 'Apply successful',
      apply_failed: 'Apply failed.',
      undo_apply_btn: 'UNDO',
      undo_apply_confirm_title: 'Undo stock move',
      undo_apply_confirm_content: 'Do you want to undo this stock move?',
      undo_apply_success: 'Undo successful',
      undo_apply_failed: 'Undo failed.',
    },
    stockRawMaterials: {
      suplierEntered: 'Supplier entered',
    },
  },
};

export default messages;
