import * as React from 'react';
import {
  Show,
  SimpleShowLayout,
  FunctionField,
} from 'react-admin';
import { Grid } from '@mui/material';
import Source from 'utils/source';
import ShowTitle from 'components/commons/showTitle';
import ShowLabel from 'components/commons/showLabel';
import ShowField from 'components/commons/showField';
import ShowDate from 'components/commons/showDate';
import { StockMoveEntity } from 'api';
import { ShowStockMoveLines } from '../stockMove/lines/stockMoveLines';

export default function ShowSplitMove() {
  const s = Source<StockMoveEntity>();
  return (
    <Show>
      <SimpleShowLayout>
        <Grid container rowSpacing={1} spacing={1} sx={{ marginLeft: '3px', marginTop: '3px', marginBottom: '10px' }}>
          <Grid item xs={12} md={12}>
            <ShowTitle />
          </Grid>

          <Grid item xs={2} md={1.5} sx={{ borderBottom: '1' }} className="show-row-odd">
            <ShowLabel label="resources.stock/stock-moves.fields.source_location" />
          </Grid>
          <Grid item xs={8} md={9} className="show-row-odd">
            <ShowField {...s('sourceStockLocationName')} />
          </Grid>
          <Grid item xs={2} md={1.5} className="show-row-odd" />

          <Grid item xs={2} md={1.5} sx={{ borderBottom: '1' }} className="show-row-odd">
            <ShowLabel label="resources.stock/stock-moves.fields.comment" />
          </Grid>
          <Grid item xs={8} md={9} className="show-row-odd">
            <ShowField {...s('comment')} />
          </Grid>
          <Grid item xs={2} md={1.5} className="show-row-odd" />

          <Grid item xs={2} md={1.5} sx={{ borderBottom: '1' }} className="show-row-odd">
            <ShowLabel label="resources.stock/stock-moves.fields.appliedDate" />
          </Grid>
          <Grid item xs={8} md={9} className="show-row-odd">
            <ShowDate {...s('appliedDate')} showTime />
          </Grid>
          <Grid item xs={2} md={1.5} className="show-row-odd" />

          <Grid item xs={2} md={1.5} sx={{ borderBottom: '1' }}>
            <ShowLabel label="resources.stock/stock-moves.fields.creator" />
          </Grid>
          <Grid item xs={8} md={9}>
            <ShowField {...s('createdBy')} className="highLightField" />
          </Grid>
          <Grid item xs={2} md={1.5} />

          <Grid item xs={8} md={8}>
            <FunctionField render={(record: StockMoveEntity) => (
              <ShowStockMoveLines
                id={record.id.toString()}
                isApplied={!!record?.appliedDate}
                canDelete={false}
                hideSourceStockLine
                hideShelf
              />
            )}
            />
          </Grid>
        </Grid>
      </SimpleShowLayout>
    </Show>
  );
}
