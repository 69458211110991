/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: developer.contact@outfiz.vn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StockLinesLine } from './StockLinesLine';
import {
    StockLinesLineFromJSON,
    StockLinesLineFromJSONTyped,
    StockLinesLineToJSON,
} from './StockLinesLine';

/**
 * 
 * @export
 * @interface StockLinesResourceDetail
 */
export interface StockLinesResourceDetail {
    /**
     * 
     * @type {number}
     * @memberof StockLinesResourceDetail
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof StockLinesResourceDetail
     */
    articleNo?: string;
    /**
     * 
     * @type {string}
     * @memberof StockLinesResourceDetail
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof StockLinesResourceDetail
     */
    quantityTypeId: number;
    /**
     * 
     * @type {string}
     * @memberof StockLinesResourceDetail
     */
    quantityTypeName: string;
    /**
     * 
     * @type {Array<StockLinesLine>}
     * @memberof StockLinesResourceDetail
     */
    lines: Array<StockLinesLine>;
}

/**
 * Check if a given object implements the StockLinesResourceDetail interface.
 */
export function instanceOfStockLinesResourceDetail(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    if (!('quantityTypeId' in value)) return false;
    if (!('quantityTypeName' in value)) return false;
    if (!('lines' in value)) return false;
    return true;
}

export function StockLinesResourceDetailFromJSON(json: any): StockLinesResourceDetail {
    return StockLinesResourceDetailFromJSONTyped(json, false);
}

export function StockLinesResourceDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockLinesResourceDetail {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'articleNo': !exists(json, 'articleNo') ? undefined : json['articleNo'],
        'name': json['name'],
        'quantityTypeId': json['quantityTypeId'],
        'quantityTypeName': json['quantityTypeName'],
        'lines': ((json['lines'] as Array<any>).map(StockLinesLineFromJSON)),
    };
}

export function StockLinesResourceDetailToJSON(value?: StockLinesResourceDetail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value['id'],
        'articleNo': value['articleNo'],
        'name': value['name'],
        'quantityTypeId': value['quantityTypeId'],
        'quantityTypeName': value['quantityTypeName'],
        'lines': ((value['lines'] as Array<any>).map(StockLinesLineToJSON)),
    };
}

