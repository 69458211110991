/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: developer.contact@outfiz.vn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProductBillOfResourcesDTO } from './ProductBillOfResourcesDTO';
import {
    ProductBillOfResourcesDTOFromJSON,
    ProductBillOfResourcesDTOFromJSONTyped,
    ProductBillOfResourcesDTOToJSON,
} from './ProductBillOfResourcesDTO';

/**
 * 
 * @export
 * @interface ProductUpdateSchema
 */
export interface ProductUpdateSchema {
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateSchema
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateSchema
     */
    articleNo?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductUpdateSchema
     */
    isActive: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductUpdateSchema
     */
    isUniqueLotNo: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateSchema
     */
    currencyId: string;
    /**
     * 
     * @type {number}
     * @memberof ProductUpdateSchema
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateSchema
     */
    stockTracing: ProductUpdateSchemaStockTracingEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductUpdateSchema
     */
    quantityTypeId: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductUpdateSchema
     */
    categories: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof ProductUpdateSchema
     */
    id: number;
    /**
     * 
     * @type {ProductBillOfResourcesDTO}
     * @memberof ProductUpdateSchema
     */
    billOfResources?: ProductBillOfResourcesDTO;
}


/**
 * @export
 */
export const ProductUpdateSchemaStockTracingEnum = {
    None: 'None',
    Lot: 'Lot',
    Serial: 'Serial'
} as const;
export type ProductUpdateSchemaStockTracingEnum = typeof ProductUpdateSchemaStockTracingEnum[keyof typeof ProductUpdateSchemaStockTracingEnum];


/**
 * Check if a given object implements the ProductUpdateSchema interface.
 */
export function instanceOfProductUpdateSchema(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('isActive' in value)) return false;
    if (!('isUniqueLotNo' in value)) return false;
    if (!('currencyId' in value)) return false;
    if (!('price' in value)) return false;
    if (!('stockTracing' in value)) return false;
    if (!('quantityTypeId' in value)) return false;
    if (!('categories' in value)) return false;
    if (!('id' in value)) return false;
    return true;
}

export function ProductUpdateSchemaFromJSON(json: any): ProductUpdateSchema {
    return ProductUpdateSchemaFromJSONTyped(json, false);
}

export function ProductUpdateSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductUpdateSchema {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'articleNo': !exists(json, 'articleNo') ? undefined : json['articleNo'],
        'isActive': json['isActive'],
        'isUniqueLotNo': json['isUniqueLotNo'],
        'currencyId': json['currencyId'],
        'price': json['price'],
        'stockTracing': json['stockTracing'],
        'quantityTypeId': json['quantityTypeId'],
        'categories': json['categories'],
        'id': json['id'],
        'billOfResources': !exists(json, 'billOfResources') ? undefined : ProductBillOfResourcesDTOFromJSON(json['billOfResources']),
    };
}

export function ProductUpdateSchemaToJSON(value?: ProductUpdateSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value['name'],
        'articleNo': value['articleNo'],
        'isActive': value['isActive'],
        'isUniqueLotNo': value['isUniqueLotNo'],
        'currencyId': value['currencyId'],
        'price': value['price'],
        'stockTracing': value['stockTracing'],
        'quantityTypeId': value['quantityTypeId'],
        'categories': value['categories'],
        'id': value['id'],
        'billOfResources': ProductBillOfResourcesDTOToJSON(value['billOfResources']),
    };
}

