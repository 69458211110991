const messages = {
  resources: {
    'stock/inventoryControl': {
      name: 'Kiểm kho',
      fields: {
        status: 'Trạng thái',
        plannedDate: 'Ngày dự định',
        finishedDate: 'Ngày kết thúc',
        comment: 'Ghi chú',
        stockLocationName: 'Kho kiểm',
        addInventoryLine: 'Nhập kiểm kho',
      },
    },
    'stock/inventoryControlLine': {
      fields: {
        articleNo: 'Article No',
        articleName: 'Article Name',
        lotNo: 'Lot No',
        changeAmount: 'Số lượng thay đổi',
      },
    },
  },
  mai: {
    inventoryControl: {
      inventoryControlStatus: {
        Unapplied: 'Chưa thực thi',
        Applied: 'Đã thực thi',
      },
      quantityInput: 'Số lượng trong kho',
    },
  },
};

export default messages;
