/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: developer.contact@outfiz.vn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Paging } from './Paging';
import {
    PagingFromJSON,
    PagingFromJSONTyped,
    PagingToJSON,
} from './Paging';
import type { StockLinesLineDetail } from './StockLinesLineDetail';
import {
    StockLinesLineDetailFromJSON,
    StockLinesLineDetailFromJSONTyped,
    StockLinesLineDetailToJSON,
} from './StockLinesLineDetail';

/**
 * 
 * @export
 * @interface PaginatedListStockLineDetail
 */
export interface PaginatedListStockLineDetail {
    /**
     * 
     * @type {Array<StockLinesLineDetail>}
     * @memberof PaginatedListStockLineDetail
     */
    list: Array<StockLinesLineDetail>;
    /**
     * 
     * @type {Paging}
     * @memberof PaginatedListStockLineDetail
     */
    paging: Paging;
}

/**
 * Check if a given object implements the PaginatedListStockLineDetail interface.
 */
export function instanceOfPaginatedListStockLineDetail(value: object): boolean {
    if (!('list' in value)) return false;
    if (!('paging' in value)) return false;
    return true;
}

export function PaginatedListStockLineDetailFromJSON(json: any): PaginatedListStockLineDetail {
    return PaginatedListStockLineDetailFromJSONTyped(json, false);
}

export function PaginatedListStockLineDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedListStockLineDetail {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        
        'list': ((json['list'] as Array<any>).map(StockLinesLineDetailFromJSON)),
        'paging': PagingFromJSON(json['paging']),
    };
}

export function PaginatedListStockLineDetailToJSON(value?: PaginatedListStockLineDetail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'list': ((value['list'] as Array<any>).map(StockLinesLineDetailToJSON)),
        'paging': PagingToJSON(value['paging']),
    };
}

