const messages = {
  resources: {
    billOfResources: {
      name: 'Bill of Resources |||| Bills of Resources',
      fields: {
        name: 'Tên workload',
        id: 'Id',
        description: 'Mô tả về workload',
        dependencies: 'Các workloads phụ thuộc',
        resources: 'Tài nguyên',
        resourceName: 'Tài nguyên',
        amount: 'Số lượng',
        comment: 'Bình luận',
        detail: 'thông tin workloads',
        lines: 'Hóa đơn của tài nguyên',
        products: 'Danh sách sản phẩm',
      },
    },
  },
  mai: {},
};
export default messages;
