/* eslint-disable max-len */
import api from 'repositories/api';
import { ReceiveShipmentAddLineRequest, ReceiveShipmentDeleteLineRequest } from 'api';
import { getApiQuery } from 'repositories/base';

export const useReceiveShipment = getApiQuery(api.receiveShipmentGetStockMove);
export const useShipmentComparison = getApiQuery(api.incomingShipmentCompare);

export default {
  addReceiveLine: (request: ReceiveShipmentAddLineRequest) => api.receiveShipmentAddLine(request),
  deleteReceiveLine:
    (request: ReceiveShipmentDeleteLineRequest) => api.receiveShipmentDeleteLine(request),
  applyReceiveShipment: (id: number) => api.receiveShipmentApply({ id }),
  getStockReceiveShipmentDetail: (id: number) => api.receiveShipmentGetStockMove({
    id,
  }),
};
