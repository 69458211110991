import * as React from 'react';
import {
  TextField,
  BooleanField,
  EditButton,
  DeleteWithConfirmButton,
  ShowButton,
} from 'react-admin';
import ListActions from 'components/layout/actions/list';
import useHasAccess from 'hooks/useHasAccess';
import ShowQuantityType from 'components/fields/QuantityTypeField';
import { ProductRawMaterialDetailEntity } from 'api/models/ProductRawMaterialDetailEntity';
import { Source } from 'utils/source';
import { StockTracingChoices } from 'repositories/products/product';
import EnumField from 'components/fields/enumField';
import List from 'components/list/List';
import Datagrid from 'components/list/dataGrid';
import CategoryField from 'components/fields/categoryField';
import filters from './filters';

export default function () {
  const hasAccess = useHasAccess();
  const canPut = hasAccess({ url: 'product/raw-materials/{id}', httpMethod: 'PUT' });
  const canDelete = hasAccess({ url: 'product/raw-materials/{id}', httpMethod: 'DELETE' });
  const canShow = hasAccess({ url: 'product/raw-materials/{id}', httpMethod: 'GET' });
  const s = Source<ProductRawMaterialDetailEntity>();
  return (
    <List
      filters={filters}
      actions={<ListActions exporter />}
      filterDefaultValues={{ isActive: true }}
      sort={{ field: 'articleNo', order: 'ASC' }}
    >
      <Datagrid>
        <TextField {...s('articleNo')} />
        <TextField {...s('name')} />
        <TextField {...s('supplierName')} />
        <TextField {...s('supplierArticleName')} />
        <CategoryField {...s('categories')} />
        <TextField {...s('leadTime')} />
        <ShowQuantityType {...s('moq')} />
        <EnumField {...s('stockTracing')} choices={StockTracingChoices} />
        <BooleanField {...s('isActive')} />
        {canShow && <ShowButton label="" />}
        {canPut && <EditButton label="" />}
        {canDelete && <DeleteWithConfirmButton label="" mutationMode="pessimistic" />}
      </Datagrid>
    </List>
  );
}
