/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: developer.contact@outfiz.vn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Paging } from './Paging';
import {
    PagingFromJSON,
    PagingFromJSONTyped,
    PagingToJSON,
} from './Paging';
import type { StockSplitEntity } from './StockSplitEntity';
import {
    StockSplitEntityFromJSON,
    StockSplitEntityFromJSONTyped,
    StockSplitEntityToJSON,
} from './StockSplitEntity';

/**
 * 
 * @export
 * @interface PaginatedListStockSplitEntity
 */
export interface PaginatedListStockSplitEntity {
    /**
     * 
     * @type {Array<StockSplitEntity>}
     * @memberof PaginatedListStockSplitEntity
     */
    list: Array<StockSplitEntity>;
    /**
     * 
     * @type {Paging}
     * @memberof PaginatedListStockSplitEntity
     */
    paging: Paging;
}

/**
 * Check if a given object implements the PaginatedListStockSplitEntity interface.
 */
export function instanceOfPaginatedListStockSplitEntity(value: object): boolean {
    if (!('list' in value)) return false;
    if (!('paging' in value)) return false;
    return true;
}

export function PaginatedListStockSplitEntityFromJSON(json: any): PaginatedListStockSplitEntity {
    return PaginatedListStockSplitEntityFromJSONTyped(json, false);
}

export function PaginatedListStockSplitEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedListStockSplitEntity {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        
        'list': ((json['list'] as Array<any>).map(StockSplitEntityFromJSON)),
        'paging': PagingFromJSON(json['paging']),
    };
}

export function PaginatedListStockSplitEntityToJSON(value?: PaginatedListStockSplitEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'list': ((value['list'] as Array<any>).map(StockSplitEntityToJSON)),
        'paging': PagingToJSON(value['paging']),
    };
}

