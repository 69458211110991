import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Collapse from '@mui/material/Collapse';

function SubMenu({
  handleToggle,
  isOpen,
  name,
  icon,
  children,
}: any) {
  return (
    <>
      <ListItem
        dense
        button
        onClick={handleToggle}
        className="list-item"
      >
        <ListItemIcon className="list-item-icon">{isOpen ? <ExpandMoreIcon /> : icon}</ListItemIcon>
        <ListItemText
          inset
          primary={isOpen ? name : ''}
          secondary={isOpen ? '' : name}
          title={name}
          className="list-item-text"
        />
      </ListItem>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List
          dense
          component="div"
          disablePadding
          className="list-child-menu"
        >
          {children}
        </List>
        <Divider />
      </Collapse>
    </>
  );
}

export default SubMenu;
