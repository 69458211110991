/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: developer.contact@outfiz.vn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StockLinesLineDetail } from './StockLinesLineDetail';
import {
    StockLinesLineDetailFromJSON,
    StockLinesLineDetailFromJSONTyped,
    StockLinesLineDetailToJSON,
} from './StockLinesLineDetail';

/**
 * 
 * @export
 * @interface StockMoveAddLineResult
 */
export interface StockMoveAddLineResult {
    /**
     * 
     * @type {number}
     * @memberof StockMoveAddLineResult
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof StockMoveAddLineResult
     */
    amount: number;
    /**
     * 
     * @type {StockLinesLineDetail}
     * @memberof StockMoveAddLineResult
     */
    stockLine: StockLinesLineDetail;
    /**
     * 
     * @type {StockLinesLineDetail}
     * @memberof StockMoveAddLineResult
     */
    destinationStockLine?: StockLinesLineDetail;
}

/**
 * Check if a given object implements the StockMoveAddLineResult interface.
 */
export function instanceOfStockMoveAddLineResult(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('amount' in value)) return false;
    if (!('stockLine' in value)) return false;
    return true;
}

export function StockMoveAddLineResultFromJSON(json: any): StockMoveAddLineResult {
    return StockMoveAddLineResultFromJSONTyped(json, false);
}

export function StockMoveAddLineResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockMoveAddLineResult {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'amount': json['amount'],
        'stockLine': StockLinesLineDetailFromJSON(json['stockLine']),
        'destinationStockLine': !exists(json, 'destinationStockLine') ? undefined : StockLinesLineDetailFromJSON(json['destinationStockLine']),
    };
}

export function StockMoveAddLineResultToJSON(value?: StockMoveAddLineResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value['id'],
        'amount': value['amount'],
        'stockLine': StockLinesLineDetailToJSON(value['stockLine']),
        'destinationStockLine': StockLinesLineDetailToJSON(value['destinationStockLine']),
    };
}

