const messages = {
  resources: {
    'product/categories': {
      name: 'Loại sản phẩm',
      fields: {
        name: 'Tên',
        fullName: 'Tên đầy đủ',
        position: 'Vị trí',
        description: 'Mô tả',
        query: 'Tìm kiếm',
        parentId: 'Cha',
      },
    },
  },
  mai: {},
};

export default messages;
