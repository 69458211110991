export default {
  GroupType: {
    PRIVATE: 10,
    DEFAULT: 20,
    CUSTOM: 30,
  },
  DefaultGroup: {
    ADMIN: 'ADM',
    EMPLOYEE: 'EMP',
  },
  SizePrint: {
    k58: {
      width: '58mm',
      height: '40mm',
    },
  },
  Labels: {
    SHELF: {
      string: 'SHELF{id}',
      regex: /SHELF(\d+)/,
    },
    STOCKLINE: {
      string: 'SL{id}_{amount}',
      regex: /SL(\d+)_(\d+)/,
    },
  },
};
