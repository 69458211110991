/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: developer.contact@outfiz.vn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuthUserEntity
 */
export interface AuthUserEntity {
    /**
     * 
     * @type {number}
     * @memberof AuthUserEntity
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof AuthUserEntity
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof AuthUserEntity
     */
    email?: string;
    /**
     * 
     * @type {Date}
     * @memberof AuthUserEntity
     */
    lastLogin?: Date;
    /**
     * 
     * @type {Date}
     * @memberof AuthUserEntity
     */
    lastLogout?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof AuthUserEntity
     */
    isActive: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AuthUserEntity
     */
    isVerifyEmail: boolean;
}

/**
 * Check if a given object implements the AuthUserEntity interface.
 */
export function instanceOfAuthUserEntity(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('username' in value)) return false;
    if (!('isActive' in value)) return false;
    if (!('isVerifyEmail' in value)) return false;
    return true;
}

export function AuthUserEntityFromJSON(json: any): AuthUserEntity {
    return AuthUserEntityFromJSONTyped(json, false);
}

export function AuthUserEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthUserEntity {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'username': json['username'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'lastLogin': !exists(json, 'lastLogin') ? undefined : (new Date(json['lastLogin'])),
        'lastLogout': !exists(json, 'lastLogout') ? undefined : (new Date(json['lastLogout'])),
        'isActive': json['isActive'],
        'isVerifyEmail': json['isVerifyEmail'],
    };
}

export function AuthUserEntityToJSON(value?: AuthUserEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value['id'],
        'username': value['username'],
        'email': value['email'],
        'lastLogin': !exists(value, 'lastLogin') ? undefined : ((value['lastLogin'] as any).toISOString()),
        'lastLogout': !exists(value, 'lastLogout') ? undefined : ((value['lastLogout'] as any).toISOString()),
        'isActive': value['isActive'],
        'isVerifyEmail': value['isVerifyEmail'],
    };
}

