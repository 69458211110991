/* eslint-disable max-len */
const msg = {
  NOT_ALLOWED: 'Not allowed',
  FORBIDDEN: 'Forbidden',
  VALIDATION_FAILED: 'Validation error',
  USERNAME_OR_PASSWORD_NOT_CORRECT: 'Incorrect account or password',
  USERNAME_HAD_USED: 'This username is already registered. Please use another username!',
  GROUP_HAVE_ACCOUNT: 'There is an account in this group, so it can\'t be deleted, please delete all accounts of this group first.',
  HAS_ASSOCIATED_RECORDS: 'Record has associated records!',
  EMAIL_HAD_USED: 'This email is already registered. Please use another email!',
  ACCOUNT_WAS_LOCKED: 'The account has been temporarily locked, please contact the administrator for assistance.',
  GROUP_NAME_INVALIDATE: 'Name of group invalidate.',
  GROUP_NAME_HAD_USED: 'This group name is already registered. Please use another group name!',
  STOCKLINE_INVALIDATE: 'TODO',
  CATEGORY_PARENT_NOT_FOUND: 'TODO',
  YOU_NOT_HAVE_PERMISSION: 'You do not have permission to perform this action.',
  NOT_FOUND: 'Requested data not found.',
  SERVER_ERROR: 'The system failed, please try again later.',
  UNIQUE_CONSTRAINT: 'Unique constraint',
  LOTNO_ALREADY_EXIST: 'Lot no already exists. Please render another lot no!',
  NO_VALID_DATA_FOUND: 'No valid data found. Please scan again',
  MUST_BE_NUMBER: 'The value must be a number',
};

export default msg;
