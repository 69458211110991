/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: developer.contact@outfiz.vn
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SystemTupleInt64Decimal
 */
export interface SystemTupleInt64Decimal {
    /**
     * 
     * @type {number}
     * @memberof SystemTupleInt64Decimal
     */
    item1: number;
    /**
     * 
     * @type {number}
     * @memberof SystemTupleInt64Decimal
     */
    item2: number;
}

/**
 * Check if a given object implements the SystemTupleInt64Decimal interface.
 */
export function instanceOfSystemTupleInt64Decimal(value: object): boolean {
    if (!('item1' in value)) return false;
    if (!('item2' in value)) return false;
    return true;
}

export function SystemTupleInt64DecimalFromJSON(json: any): SystemTupleInt64Decimal {
    return SystemTupleInt64DecimalFromJSONTyped(json, false);
}

export function SystemTupleInt64DecimalFromJSONTyped(json: any, ignoreDiscriminator: boolean): SystemTupleInt64Decimal {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        
        'item1': json['item1'],
        'item2': json['item2'],
    };
}

export function SystemTupleInt64DecimalToJSON(value?: SystemTupleInt64Decimal | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'item1': value['item1'],
        'item2': value['item2'],
    };
}

