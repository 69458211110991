const messages = {
  resources: {
    'product/products': {
      name: 'Sản phẩm |||| Sản phẩm',
      fields: {
        name: 'Tên',
        price: 'Giá',
        categoryName: 'Loại sản phẩm',
        categoryId: 'Loại sản phẩm',
        stockTracingName: 'Kho dự trữ',
        stockTracingCode: 'Kho dự trữ',
        quantityTypeName: 'Loại số lượng',
        quantityTypeId: 'Loại số lượng',
        currencyName: 'Đơn vị tiền tệ',
        currencyId: 'Đơn vị tiền tệ',
        isActive: 'Đang hoạt động',
        stateCode: 'Trạng thái hoạt động',
        detail: 'Thông tin sản phẩm',
        billofresources: 'Bill of resources',
      },
      tabs: {
        detail: 'Detail',
        billofresources: 'Bill of Materials',
        openOrders: 'Open Orders',
        stockLine: 'Stock Lines',
      },
    },
  },
  mai: {
    product: {
      categoryId: 'Loại sản phẩm',
      stateCode: 'Trạng thái hoạt động',
      stateActive: 'Hoạt động',
      stateInactive: 'Không hoạt động',
      stateNull: 'Tất cả',
    },
  },
};
export default messages;
